import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { imagePath } from '../utils/Constants';
import { AppContext } from '../utils/Contexts';

export default function Footer() {
  const { isScreen950Query } = useContext(AppContext);
  const [isScreen950, setIsScreen950] = useState(isScreen950Query.matches);

  useEffect(() => { // Mount
    isScreen950Query.addEventListener('change', checkScreen950); // To avoid re-rendering the whole page and losing data, we have to listen and state change on the sublevel

    return () => { // Unmount
      isScreen950Query.removeEventListener('change', checkScreen950);
    };
  }, []);

  function checkScreen950() {
    setIsScreen950(isScreen950Query.matches);
  }

  return (
    <footer>
      <u title='Scroll to top of page' onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>Back to Top</u>
      <span style={{ fontSize: isScreen950 ? '13px' : '17.5px', verticalAlign: 'middle' }}>
        <a style={{ fontSize: isScreen950 ? '13px' : '17.5px', verticalAlign: 'middle', textDecoration: 'none' }} href='https://twitter.com/ygoprog' target='_blank' rel='noreferrer'>
          <img src={imagePath + 'TwitterLogo.png'} alt='+' width={isScreen950 ? '16px' : '24px'} height={isScreen950 ? '13.17px' : '19.75px'}
            style={{ margin: isScreen950 ? 'auto 6px auto 0px' : 'auto 10px auto 0px', verticalAlign: 'middle' }} />
          @YGOProg
        </a>
        <div style={isScreen950 ? { height: '2px' } : { display: 'inline' }}>&nbsp;&nbsp;&nbsp;</div>
        <a style={{ fontSize: isScreen950 ? '13px' : '17.5px', verticalAlign: 'middle', textDecoration: 'none' }} href='https://discord.com/invite/46tQbC69mN' target='_blank' rel='noreferrer'>
          <img src={imagePath + 'DiscordLogo.png'} alt='+' width={isScreen950 ? '16px' : '23.76px'} height={isScreen950 ? '12.12px' : '18px'}
            style={{ margin: isScreen950 ? 'auto 6px auto 0px' : 'auto 8px auto 0px', verticalAlign: 'middle' }} />
          YGO Prog Discord
        </a>
        <div style={isScreen950 ? { height: '3px' } : { display: 'inline' }}>&nbsp;&nbsp;&nbsp;</div>
        <a style={{ fontSize: isScreen950 ? '13px' : '17.5px', textDecoration: 'none' }} href="mailto: admin@ygoprog.com">
          <img src={imagePath + 'Mail.png'} alt='+' width={isScreen950 ? '16px' : '21.25px'} height={isScreen950 ? '12.8px' : '17px'}
            style={{ margin: isScreen950 ? 'auto 6px auto 0px' : 'auto 10px auto 0px', verticalAlign: 'middle' }} />
          admin@ygoprog.com
        </a>
      </span>
      <span style={isScreen950 ? { marginTop: '4px' } : {}}>&#169; 2024 YGO Prog, LLC. All Rights Reserved.&nbsp;&nbsp;<Link to='/PrivacyPolicy'>Privacy Policy</Link> | <Link to='/About'>About</Link></span>
      <span>
        Backing data and most product images courtesy of <a href='https://github.com/DawnbrandBots/yaml-yugi' target='_blank' rel='noreferrer'>yaml-yugi</a>
        &nbsp;and <a href='https://yugipedia.com' target='_blank' rel='noreferrer'>Yugipedia</a>.
        <br />Card images provided by <a href='https://ygoprodeck.com/' target='_blank' rel='noreferrer'>YGOPRODeck.com</a>. <Link to='/About'>Other Credits</Link>
      </span>
      <p>
        All information and graphics on this site relating to Yu-Gi-Oh!, including but not limited to card and pack images, card/game symbols and icons,
        related fonts, and card/game text, is copyright 4K Media Inc, a subsidiary of Konami Digital Entertainment, Inc. This website is not produced,
        endorsed, supported, or affiliated with in any way by 4K Media or Konami Digital Entertainment.
      </p>
    </footer>
  );
}
