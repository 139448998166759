import { useEffect, useCallback, useContext, useRef, memo, useState } from 'react';
import { useDrop } from 'react-dnd';
import DropCard from './DropCard';
import { AppContext, BuilderContext } from '../utils/Contexts';

interface DropZoneProps {
  zone: string,
  zoneListRef: React.MutableRefObject<DropCard[]>,
  zoneList: DropCard[],
  zoneMax: number,
  gridWidth: number,
  minimize: boolean,
  styleClassName: string,
}

const lastDraggedPos = { pos: { x: 0, y: 0 } }; // Shared across zones

function DropZoneComp(props: DropZoneProps) {

  const { isScreen1150Query } = useContext(AppContext);
  const [isScreen1150, setIsScreen1150] = useState(isScreen1150Query.matches);
  const { moveCard, checkCardAllowed, dragTask, currentDragCard,
    dragLock, clearCurrentDragCard, zoneCalcShifts, currentBanlist, banlistData, banlistDates } = useContext(BuilderContext);
  const dropZone = useRef<any>();

  useEffect(() => { // Mount
    isScreen1150Query.addEventListener('change', checkScreen1150); // To avoid re-rendering the whole page and losing data, we have to listen and state change on the sublevel

    return () => { // Unmount
      isScreen1150Query.removeEventListener('change', checkScreen1150);
    };
  }, []);

  useEffect(() => {
    dropZone.current = document.getElementById(props.zone + 'Drop');
    zoneCalcShifts[props.zone] = calculateShift;
  }, [props.zone]);

  function checkScreen1150() {
    setIsScreen1150(isScreen1150Query.matches);
  }

  const calculateShift = useCallback((item: DragCard, monitor: any, dropOp: boolean) => {
    dragLock.current = true;
    const itemOffset = monitor.getClientOffset();
    if (itemOffset && (itemOffset.x != lastDraggedPos.pos.x || itemOffset.y != lastDraggedPos.pos.y)) { // If drag has moved, calculate indexed position
      lastDraggedPos.pos = { ...itemOffset };
      const boundRect = dropZone.current.getBoundingClientRect();
      let index = Math.floor(Math.max(0, itemOffset.x - boundRect.x) / (boundRect.width / props.gridWidth)) +
        (props.gridWidth * Math.floor(Math.max(0, itemOffset.y - boundRect.y) / (boundRect.height / Math.ceil(props.zoneListRef.current.length / props.gridWidth)))); // Div-relative pos over cell size clamped to index range

      if (index < 0) index = 0;
      else if (index >= props.zoneListRef.current.length) {
        if (currentDragCard.zone == props.zone && currentDragCard.index == props.zoneListRef.current.length - 1) index = currentDragCard.index; // Don't keep moving it to the end if it's already there
        else index = props.zoneListRef.current.length; // Limit to the bounds of the array
      }

      if (index != currentDragCard.index || props.zone != currentDragCard.zone) {
        if (dragTask.flag != null) {
          clearTimeout(dragTask.flag);
          dragTask.flag = null;
        }

        if (!dropOp) {
          dragTask.flag = setTimeout(() => {
            dragTask.flag = null;
            moveCard.current('', '', props.zone, index, true, dropOp);
          }, 0);
        }
        else if (currentDragCard.card != null) {
          moveCard.current('', '', props.zone, index, true, dropOp);
        }
      }
      else {
        dragLock.current = false;
        if (dropOp) clearCurrentDragCard();
      }
    }
    else {
      dragLock.current = false;
      if (dropOp) clearCurrentDragCard();
    }
  }, [props.zone, props.zoneList, props.gridWidth, currentDragCard]);

  useEffect(() => {
    zoneCalcShifts[props.zone] = calculateShift;
  }, [calculateShift]);

  const [, drop] = useDrop(
    () => ({
      accept: 'dropcard',
      hover(item: DragCard, monitor) {
        currentDragCard.lastHoveredZone = props.zone;
        currentDragCard.lastZoneCalcShift = calculateShift;
        if (dragLock.current || !monitor.canDrop() || (props.zone[0] != 's' && item.uniqueID[0] != props.zone) ||
          (props.zoneListRef.current.length >= props.zoneMax && item.originalZone != props.zone)) return; // We prefix the uniqueID with the card's deck type when we make it

        calculateShift(item, monitor, false);
      },
      canDrop(item: DragCard, monitor) {
        return checkCardAllowed.current(props.zone, item.uniqueID, item.originalZone);
      },
      drop(item, monitor) {
        if (dragTask.flag != null) {
          clearTimeout(dragTask.flag);
          dragTask.flag = null;
        }

        if (currentDragCard.card != null) {
          calculateShift(item, monitor, true);
        }
        dragLock.current = false;
      },
    }),
    [props.zone, props.zoneList, props.gridWidth, currentDragCard, calculateShift],
  );

  return (
    <div className={'content-box builder-listbox ' + props.styleClassName + (props.minimize ? '-minimized' : '')} ref={drop} style={{ position: 'relative' }}>
      <div style={{
        position: 'absolute',
        display: 'block',
        width: 'calc(100% + 30px)',
        height: 'calc(100% + 60px)',
        top: '-30px',
        left: '-15px',
        right: '15px',
        bottom: '30px',
      }}/>
      <div id={props.zone + 'Drop'} className='dropzone-inner' style={{
        gridTemplateColumns: 'repeat(' + props.gridWidth + ',1fr)',
        gap: '5px',
        minHeight: props.minimize ? '0px' : '100px',
      }}>
        {props.minimize ? '' : props.zoneList.map((card: DropCard) => {
          return (
            <DropCard
              {...{
                card: card,
                dropZone: props.zone,
                limit: (currentBanlist !== undefined && currentBanlist != '' ?
                  (card.all_prints.some((d: Date) => isNaN(d.valueOf()) || (d > banlistDates[0] && d < banlistDates[1])) ?
                    (banlistData[card.id] ?? undefined) : 0) : undefined),
                limitSize: isScreen1150 ? '30px' : '45px',
              }}
              key={card.uniqueID}
            />
          );
        })}
      </div>
    </div>
  );
}

export const DropZone = memo(DropZoneComp);
export default DropZone;
