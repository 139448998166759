import { useEffect, useState } from 'react';
import * as Constants from '../utils/Constants';
import {
  passwordValidator,
  confirmPasswordValidator,
} from '../utils/validators/LoginAndRegistrationValidator';
import makeApiCall from '../utils/ApiMiddleware';
import { closeBasicModal, openBasicModal, openErrorModal, openResetPassModal } from '../utils/BasicModalFuncs';
import { useParams, useHistory } from 'react-router-dom';
import localforage from 'localforage';
import { Helmet } from 'react-helmet';
import { contactString } from '../utils/Constants';

export default function ResetPassLanding(props: { refresh: any }) {
  const [error, setError] = useState({
    passwordMessage: '',
    confirmPasswordMessage: '',
  });
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorList, setErrorList] = useState<string[]>([]);
  const [resetInProgress, setResetInProgess] = useState(false);
  const [resetSuccessful, setResetSuccessful] = useState(false);
  const [timerDisplay, setTimerDisplay] = useState(5);
  const history = useHistory();
  const { token } = useParams<any>();
  let redirectTimer: number = 5;

  function handlePassReset() {
    if (newPassword != '' && (error.passwordMessage + error.confirmPasswordMessage) == '') {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ newPassword: newPassword }),
      };
      setResetInProgess(true);
      makeApiCall(Constants.cookies.get('refresh'), Constants.cookies.get('userId'), Constants.apiPath.concat('user/reset/' + token), requestOptions)
        .then(async (response) => {
          setResetInProgess(false);

          if (!response.ok) {
            const resError = response.status;
            openErrorModal('Issue resetting password.<br />Error Code: ' + resError + '<br />' + contactString);
            return Promise.reject(resError);
          }

          const data = await response.json();

          if (data.message == 'UserNotFound') {
            openErrorModal('Issue resetting password - link might be invalid.<br />' + contactString);
          }
          else if (data.message.includes('Expired')) {
            openErrorModal('Issue resetting password - reset link expired.<br />You can send a new email from the Login dialog.');
          }
          else if (data.success) {
            setErrorList([]);
            openBasicModal('Password Changed', 'Your password was successfully changed. Redirecting to Pack Opener in 5 seconds.', 'Ok');
            Constants.sessionMemory.lastBinderSelected = '';
            Constants.removeCookie('user');
            Constants.removeCookie('userId');
            Constants.removeCookie('token');
            Constants.removeCookie('refresh');
            Constants.sessionMemory.lastBinderSelected = '';
            setResetSuccessful(true);
            localforage.removeItem('decks')
              .catch(() => {
                openErrorModal('Issue clearing decks on logout.<br />' + contactString);
              });
            localforage.removeItem('banlists')
              .catch(() => {
                openErrorModal('Issue clearing banlists on logout.<br />' + contactString);
              });
            localforage.removeItem('binders')
              .catch(() => {
                openErrorModal('Issue clearing binders on logout.<br />' + contactString);
              });
            setTimeout(redirectCountdown, 1000);
          }
          else {
            openErrorModal('Password change failed, try double checking your fields are properly filled.' +
              '<br />If this persists, ' + contactString);
          }
        });
    }
    else if (newPassword == '') {
      setErrorList(['Password is required', error.confirmPasswordMessage]);
    }
    else {
      setErrorList([error.passwordMessage, error.confirmPasswordMessage]);
    }
  }

  function redirectCountdown() {
    if (redirectTimer > 1) {
      redirectTimer = redirectTimer - 1;
      setTimerDisplay(redirectTimer);
      setTimeout(redirectCountdown, 1000);
    }
    else {
      history.push('/PackOpener');
      closeBasicModal();
    }
  }

  function handleNewPassword(event: React.ChangeEvent<any>) {
    const password = `${event.target.value}`;
    const newError = error;

    newError.passwordMessage = passwordValidator(password);

    newError.confirmPasswordMessage = confirmPasswordValidator(
      password,
      confirmPassword,
    );

    setError(newError);
    setNewPassword(password);
  }

  function handleNewPasswordConfirm(event: React.ChangeEvent<any>) {
    const confirmPassword = `${event.target.value}`;
    const newError = error;

    newError.confirmPasswordMessage = confirmPasswordValidator(
      newPassword,
      confirmPassword,
    );

    setError(newError);
    setConfirmPassword(confirmPassword);
  }

  return (
    <main>
      <Helmet>
        <meta content="YGO Prog" property="og:title" />
        <meta
          content="YGO Prog is a tool for simulating Yu-Gi-Oh! pack opening, collection management, deck building, banlist building, and more!"
          property="og:description"
        />
        <meta content="https://www.ygoprog.com" property="og:url" />
        <meta
          name="description"
          content="YGO Prog is a tool for simulating Yu-Gi-Oh! pack opening, collection management, deck building, banlist building, and more!"
        />
        <title>YGO Prog</title>
      </Helmet>
      <h1 style={{ marginBottom: '10px' }}>Reset Password</h1>
      <div className='content-box' style={{ padding: '12px 20px' }}>
        <div className='vertical-form-text-input'>
          <label htmlFor='password-input'>New Password:</label>
          <div className='text-input'>
            <input id='password-input' type="password" title='Must be between 8 and 255 characters long'
              onChange={handleNewPassword}
              onKeyDown={(e) => {if (e.code === 'Enter') handlePassReset();}} />
          </div>
          <div className='tooltip' style={{ display: 'inline-block' }}>
            <img src={Constants.imagePath + 'Info.png'} width='30px' height='30px' style={{ verticalAlign: 'middle' }} />
            <ul className='tooltip-text' style={{ width: '180px', listStylePosition: 'inside', textIndent: '-18px', paddingLeft: '25px' }}>
              Password:
              <li><span>May contain any letters, numbers, or special characters</span></li>
              <li><span>Must be at least 8 characters long</span></li>
              <li><span>Should not contain username or any personal information</span></li>
            </ul>
          </div>
        </div>

        <div className='vertical-form-text-input'>
          <label htmlFor='confirm-password-input'>Confirm Password:</label>
          <div className='text-input' style={{ marginRight: '30px' }}>
            <input id='confirm-password-input' type="password" title='Must match password'
              onChange={handleNewPasswordConfirm}
              onKeyDown={(e) => {if (e.code === 'Enter') handlePassReset();}} />
          </div>
        </div>

        {errorList.map((value: string, index: number) => <span className='validation-error' key={index}>{value}</span>)}

        {resetSuccessful ?
          <p style={{ margin: '0px', padding: '0px' }}>Password successfully changed.<br />Redirecting to Pack Opener in {timerDisplay}...</p> :
          resetInProgress ?
            <img src={'/images/LoadingAnim.png'} width='30px' height='30px' style={{ margin: 'auto', verticalAlign: 'middle', marginRight: '0px' }} /> :
            <button className='black-button' type='button' title='Change password' onClick={handlePassReset} style={{ padding: '0px 25px', marginTop: '7px' }}><span>Change Password</span></button>}
      </div>
    </main>
  );
}
