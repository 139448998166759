import { useEffect } from 'react';
import { openErrorModal } from '../utils/BasicModalFuncs';

interface StringExportModalProps {
  id: string,
  exportStringObj: { type: string, exportString: string },
  dispatch: any,
}

export default function StringExportModal(props: StringExportModalProps) {

  useEffect(() => {
    copyToClipboard();
  }, []);

  function closeStringExportModal() {
    props.dispatch();
    document.getElementById(props.id)!.style.display = 'none';
  }

  function copyToClipboard() {
    navigator.clipboard.writeText(props.exportStringObj.exportString)
      .catch((err: any) => {
        openErrorModal('Issue copying ' + props.exportStringObj.type + ' to clipboard.<br />Your browser may not support this feature, manually copy and paste instead.');
        return;
      });
    const popup = document.getElementById('string-copied-popup')!;
    popup.style.transition = '0s';
    popup.style.opacity = '100';
    popup.style.visibility = 'visible';

    setTimeout(function() {
      popup.style.transition = '.8s';
      popup.style.opacity = '0';
      popup.style.visibility = 'hidden';
    }, 400);
  }

  return (
    <div id={props.id} className='modal' style={{ display: 'block' }}>
      <button className='modal-close' title='Close modal' onClick={closeStringExportModal} />
      <div className='content-box modal-content' style={{ top: '5%', padding: '0px', paddingTop: '10px', paddingBottom: '10px' }}>
        <div id='string-copied-popup' className='clipboard-popup'><span>{props.exportStringObj.type + ' copied to clipboard!'}</span></div>
        <h5 id='basic-modal-title' style={{ marginBottom: '0px', paddingLeft: '10px', paddingRight: '10px' }}>{'Export to ' + props.exportStringObj.type}</h5>
        <p id='basic-modal-text' style={{ textAlign: 'left', maxWidth: '600px', whiteSpace: 'pre-wrap', overflowWrap: 'anywhere' }} title='Copy to clipboard' onClick={copyToClipboard}>
          <b>Copy &amp; Paste:</b>
          <br/>
          {props.exportStringObj.exportString}
        </p>
        <button className='black-button' style={{ marginRight: '7px' }} title='Close modal' onClick={closeStringExportModal}><span id='basic-modal-button'>Done</span></button>
      </div>
    </div>
  );
}
