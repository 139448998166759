import { CSSProperties, useContext, useEffect, useState } from 'react';
import { AppContext, PackOpenerContext } from '../utils/Contexts';
import * as Constants from '../utils/Constants';
import { cardSets } from '../utils/CardDataCache';
import LazyImage from './LazyImage';
import { useHistory } from 'react-router-dom';
import Ad from './Ad';

export default function PickNextPack() {

  const { refreshRouteFunc, refresh, isScreen950Query } = useContext(AppContext);
  const [isScreen950, setIsScreen950] = useState(isScreen950Query.matches);
  const { state, dispatch: setPackOpenerState } = useContext(PackOpenerContext);
  const history = useHistory();

  useEffect(() => { // Mount
    window.scrollTo({ top: 0, behavior: 'auto' });
    isScreen950Query.addEventListener('change', checkScreen950); // To avoid re-rendering the whole page and losing data, we have to listen and state change on the sublevel

    return () => { // Unmount
      isScreen950Query.removeEventListener('change', checkScreen950);
    };
  }, []);

  function checkScreen950() {
    setIsScreen950(isScreen950Query.matches);
  }

  function packSelected(e: any) {
    setPackOpenerState({ type: 'OpenPack', payload: e.currentTarget.dataset.packprefix });
  }

  function returnToSelect(e: any) {
    history.push('/PackOpener');
    refreshRouteFunc(!refresh);
  }

  function replaceWithDefaultPackImg(event: any) {
    event.currentTarget.onerror = '';
    event.currentTarget.src = Constants.imagePath + 'DefaultPack.png';
    return true;
  }

  function packNameRestyle(name: string): CSSProperties {
    let cssFontSize = '22px';
    let cssLineHeight = 'normal';
    let cssHeight = '26.67px';
    let cssMarginTop = '9px';
    let cssPaddingLeft = '0px';
    let cssPaddingRight = '0px';

    if (name.length > 37) {
      cssFontSize = '16px';
      cssLineHeight = 'initial';
      cssHeight = '33.67px';
      cssMarginTop = '2px';
      cssPaddingLeft = '15px';
      cssPaddingRight = '15px';
    }
    else if (name.length > 30) {
      cssFontSize = '17px';
      cssLineHeight = '26.67px';
    }
    else if (name.length > 25) {
      cssFontSize = '19px';
      cssLineHeight = '26.67px';
    }

    if (isScreen950) {
      return { fontSize: '15px', lineHeight: name.length < 26 ? '36px' : '1.05', height: '36px', marginTop: '2px',
        paddingLeft: '2px', paddingRight: '2px', paddingTop: '3px', overflow: 'hidden', verticalAlign: 'middle' };
    }
    else {
      return { fontSize: cssFontSize, lineHeight: cssLineHeight, height: cssHeight, marginTop: cssMarginTop,
        paddingLeft: cssPaddingLeft, paddingRight: cssPaddingRight };
    }
  }

  function cropName(name: string) {
    return isScreen950 && name.length > 46 ? name.substring(0, 43) + '...' : name;
  }

  return (
    <main>
      <Ad styleName='bar_ad' />
      <Ad styleName='left_sidebar_ad_1400' />
      <Ad styleName='right_sidebar_ad_1400' />
      <h2 style={{ marginTop: '35px' }}>Select Next Product To Open</h2>

      <div className='packs-container content-box'>
        {(Object.values(state.packsToOpen) as PackCountInfo[])
          // .sort((a: PackCountInfo, b: PackCountInfo) => {return (cardSets[a.prefix].release_date > cardSets[b.prefix].release_date) ? 1 : -1;}) // Sort by release date, oldest first
          .map((pack: PackCountInfo) => {
            const cardset = cardSets[pack.prefix];
            const adjustedPrefix = cardset.prefix + (pack.productType.slice(0, 2));

            if (pack.pullsCompleted) {
              return (
                <div className='cardset-box pack-select-box-completed' key={adjustedPrefix} >
                  <img className='pack-select-checkmark' src={Constants.imagePath + 'Checkmark.png'} alt='Completed' width='34px' height='34px' />
                  <div className='gray-out' />
                  <h4 style={packNameRestyle(cardset.name)}>{cardset.name}</h4>
                  <LazyImage {...{
                    src: Constants.packImagePath + cardset.prefix + '.jpg',
                    width: 0,
                    height: isScreen950 ? 130 : 200,
                    alt: cardset.name,
                    className: 'cardset-image',
                    style: {},
                    onError: replaceWithDefaultPackImg,
                    onLoad: () => {},
                  }}/>
                  <span className='pack-select-count'>{(pack.count > 1) ? (pack.count.toString() + ' ' + (pack.productType.slice(0, 2) == 'Se' ? 'Packs' : pack.productType)) :
                    ('1 ' + (pack.productType.slice(0, 2) == 'Se' ? 'Pack' : (pack.productType.slice(0, 2) == 'Bo' ? 'Box' : pack.productType.slice(0, -1))))}</span>
                </div>
              );
            }
            else {
              return (
                <div className='cardset-box pack-select-box' key={adjustedPrefix} data-packprefix={adjustedPrefix} title='Open packs' onClick={packSelected}>
                  <h4 style={packNameRestyle(cardset.name)}>{cropName(cardset.name)}</h4>
                  <LazyImage {...{
                    src: Constants.packImagePath + cardset.prefix + '.jpg',
                    width: 0,
                    height: isScreen950 ? 130 : 200,
                    alt: cardset.name,
                    className: 'cardset-image',
                    style: {},
                    onError: replaceWithDefaultPackImg,
                    onLoad: () => {},
                  }}/>
                  <span className='pack-select-count'>{(pack.count > 1) ? (pack.count.toString() + ' ' + (pack.productType.slice(0, 2) == 'Se' ? 'Packs' : pack.productType)) :
                    ('1 ' + (pack.productType.slice(0, 2) == 'Se' ? 'Pack' : (pack.productType.slice(0, 2) == 'Bo' ? 'Box' : pack.productType.slice(0, -1))))}</span>
                </div>
              );
            }
          })}
      </div>
      <button className='black-button' title='Abandon pack open and return to select page' style={{ display: 'block', margin: 'auto', marginTop: '10px' }} onClick={returnToSelect}>
        <span>Return To Pack Select</span>
      </button>

      <Ad styleName='bar_ad_footer' />
    </main>
  );
}
