export function passwordValidator(password: string) {
  let passwordError = '';

  if (!password || password.length === 0) {
    passwordError = 'Password is required';
  }
  else if (password.length < 8) {
    passwordError = 'Password must contain at least 8 characters';
  }
  else if (password.length > 255) {
    passwordError = 'Password must contain fewer than 255 characters';
  }
  return passwordError;
}

export function confirmPasswordValidator(password: string, confirmPassword: string) {
  let confirmPasswordError = '';

  if (confirmPassword.length && password.length) {
    if (password !== confirmPassword) {
      confirmPasswordError = 'Confirm password does not match';
    }
  }
  else if (!confirmPassword || confirmPassword.length === 0) {
    confirmPasswordError = 'Confirm password does not match';
  }

  return confirmPasswordError;
}

export function loginPasswordValidator(password: string) {
  let passwordError = '';

  if (!password || password.length === 0) {
    passwordError = 'Password is required';
  }
  return passwordError;
}

export function emailEntryValidator(email: string) {
  let error = '';
  if (!email || email.length === 0) {
    error = 'Email is required';
  } // Regex from https://www.emailregex.com/, note thate input=email also has a more naive regex on it
  else if (email &&
    !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)) {
    error = 'Invalid Email';
  }
  return error;
}

export function nameValidator(name: string) {
  let error = '';
  if (!name || name.length === 0) {
    error = 'Username is required';
  }
  else if (name.length > 255) {
    error = 'Username must contain fewer than 255 characters';
  }
  else if (name && !/^[a-zA-Z0-9]*$/.test(name)) {
    error = 'Invalid Username';
  }
  else if (name.split(' ').length > 1) {
    error = 'Invalid name';
  }
  return error;
}

export function loginUsernameValidator(username: string) {
  let usernameError = '';

  if (!username || username.length === 0) {
    usernameError = 'Username is required,';
  }

  return usernameError;
}
