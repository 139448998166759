import { useState, useEffect, useCallback, useRef, useContext, CSSProperties } from 'react';
import { cookies, onCookieChangeListeners, apiPath,
  sessionMemory, imagePath, cardLink, HTML5toTouch, shouldPagesRefresh, sortOrderOptions,
  filterSearchSortOptions, cardCategoryOptions, cardTypeOptions, subtypeOptions,
  attributeOptions, levelOptions, linkMarkerOptions, multiSelectStyles, zoneSortFunc, builderSearchSort,
  getDateString, DATE_MAX, DATE_MIN, rarityOptions, frontEndPath, contactString, lowercaseContactString } from '../utils/Constants';
import BlackDropdown from './BlackDropdown';
import SearchBox from './SearchBox';
import makeApiCall from '../utils/ApiMiddleware';
import { DndProvider } from 'react-dnd-multi-backend';
import DropZone from './DropZone';
import { AppContext, BuilderContext } from '../utils/Contexts';
import { openErrorModal } from '../utils/BasicModalFuncs';
import { cards, cardSetDates, cardsLoaded, filteredCardSearch, getBinderCards } from '../utils/CardDataCache';
import WindowedSelect, { MultiValue } from 'react-windowed-select';
import Select from 'react-select';
import { FixedSizeList } from 'react-window';
import BuilderSearchCard from './BuilderSearchCard';
import DeleteConfirmModal from './DeleteConfirmModal';
import { Prompt, useHistory, useLocation } from 'react-router-dom';
import PerformanceWarningModal from './PerformanceWarningModal';
import ZonesImageExport from './ZonesImageExport';
import queryString from 'query-string';
import StringExportModal from './StringExportModal';
import { json2csv } from 'json-2-csv';
import localforage from 'localforage';
import { Helmet } from 'react-helmet';
import Ad from './Ad';
import SaveAsGroupModal from './SaveAsGroupModal';
import GroupManagementWindow from './GroupManagementWindow';

const currentDragCard = { zone: '', index: -1, card: null as DropCard | null, dropCallback: null as any }; // I don't have these as state because they need to be updated immediately for processing so setState doesn't cut it
const zoneCalcShifts = { 'sf': null as any, 'sl': null as any, 'ss': null as any, 'su': null as any };

let searchTask: any;
let popupFade: any;
let checkForCardsTask: any;
let queuedCurrentBanlist: string|null = null;
const dragTask: { flag: NodeJS.Timeout | null } = { flag: null };

export default function BanlistBuilder(props: { refresh: any }) {
  const [username, setUsername] = useState(cookies.get('user'));
  const [banlistLoading, setBanlistLoading] = useState(false);
  const [banlistsList, setBanlistsList] = useState<DropdownOption[]>([]);
  const [banlistGroupsList, setBanlistGroupsList] = useState<DropdownOption[]>([]);
  const [templatesList, setTemplatesList] = useState<DropdownOption[]>([]);
  const [forbiddenList, setForbiddenList] = useState<DropCard[]>([]);
  const [limitedList, setLimitedList] = useState<DropCard[]>([]);
  const [semilimitedList, setSemilimitedList] = useState<DropCard[]>([]);
  const [unlimitedList, setUnlimitedList] = useState<DropCard[]>([]);
  const [binnedBanlistData, setBinnedBanlistData] = useState<{ [id: number]: number }>({});
  const [whitelistDateRange, setWhitelistDateRange] = useState<Date[]>([DATE_MIN, DATE_MAX]);
  const [minimizeForbiddenList, setMinimizeForbiddenList] = useState(false);
  const [minimizeLimitedList, setMinimizeLimitedList] = useState(false);
  const [minimizeSemilimitedList, setMinimizeSemilimitedList] = useState(false);
  const [minimizeUnlimitedList, setMinimizeUnlimitedList] = useState(false);
  const [groupManagementOpen, setGroupManagementOpen] = useState(false);

  const [searchField, setSearchField] = useState<string>('all');
  const [searchString, setSearchString] = useState<string>('');
  const [setsFilter, setSetsFilter] = useState<string[]>([]);
  const [rarityFilter, setRarityFilter] = useState<string[]>([]);
  const [categoryFilter, setCategoryFilter] = useState<string[]>([]);
  const [typeFilter, setTypeFilter] = useState<string[]>([]);
  const [subtypeFilter, setSubtypeFilter] = useState<string[]>([]);
  const [attributeFilter, setAttributeFilter] = useState<string[]>([]);
  const [levelFilter, setLevelFilter] = useState<number[]>([]);
  const [linkMarkersFilter, setLinkMarkersFilter] = useState<string[]>([]);
  const [pendScaleFilter, setPendScaleFilter] = useState<string[]>([]);
  const [atkFilter, setAtkFilter] = useState<number[]>([]);
  const [defFilter, setDefFilter] = useState<number[]>([]);
  const [dateFilter, setDateFilter] = useState<Date[]>([]);
  const setsFilterField = useRef<any>();
  const rarityFilterField = useRef<any>();
  const categoryFilterField = useRef<any>();
  const typeFilterField = useRef<any>();
  const subtypeFilterField = useRef<any>();
  const attributeFilterField = useRef<any>();
  const levelFilterField = useRef<any>();
  const linkMarkersFilterField = useRef<any>();
  const pendScaleFilterField = useRef<any>();
  const atkFilterFieldLeft = useRef<any>();
  const atkFilterFieldRight = useRef<any>();
  const defFilterFieldLeft = useRef<any>();
  const defFilterFieldRight = useRef<any>();
  const dateFilterFieldLeft = useRef<any>();
  const dateFilterFieldRight = useRef<any>();

  const [sortField, setSortField] = useState<string>('name');
  const [sortOrder, setSortOrder] = useState<string>('asc');

  const [searchResults, setSearchResults] = useState<Card[]>([]);
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchActionsDisabled, setSearchActionsDisabled] = useState(false);
  const isMounted = useRef(false);
  const searchMounted = useRef(false);
  const [cardsAreLoaded, setCardsAreLoaded] = useState(cardsLoaded.flag);

  const [saveAsDisabled, setSaveAsDisabled] = useState(false);
  const [savingList, setSavingList] = useState(false);
  const [currentBanlist, setCurrentBanlist] = useState('');
  const [currentBanlistName, setCurrentBanlistName] = useState('');
  const [currentBanlistGroup, setCurrentBanlistGroup] = useState('');
  const [selectedBanlistGroup, setSelectedBanlistGroup] = useState('');
  const [banlistIsPublic, setBanlistIsPublic] = useState(false);
  const [confirmMatchString, setConfirmMatchString] = useState('');
  const [cardAddedPopupText, setCardAddedPopupText] = useState('');
  const [binderList, setBinderList] = useState<DropdownOption[]>([]);

  const { refreshRouteFunc, refresh, isScreen1300Query, isScreen1150Query, isScreen950Query } = useContext(AppContext);
  const [isScreen1300, setIsScreen1300] = useState(isScreen1300Query.matches);
  const [isScreen1150, setIsScreen1150] = useState(isScreen1150Query.matches);
  const [isScreen950, setIsScreen950] = useState(isScreen950Query.matches);
  const [navToLocation, setNavToLocation] = useState<any>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const [listSavedOrExported, setListSavedOrExported] = useState(true);
  const [exportingImage, setExportingImage] = useState(false);
  const [exportUrl, setExportUrl] = useState<{ type: string, exportString: string }>({ type: 'Shareable Link', exportString: '' });
  const history = useHistory();
  const { search } = useLocation();

  const forbiddenListRef = useRef<DropCard[]>([]); // To avoid having to cascade re-set a bunch of lodged callbacks every frame; the sets don't change so we don't need them here
  const limitedListRef = useRef<DropCard[]>([]);
  const semilimitedListRef = useRef<DropCard[]>([]);
  const unlimitedListRef = useRef<DropCard[]>([]);
  const binnedBanlistDataRef = useRef<{ [id: number]: number }>({});
  forbiddenListRef.current = forbiddenList;
  limitedListRef.current = limitedList;
  semilimitedListRef.current = semilimitedList;
  unlimitedListRef.current = unlimitedList;
  binnedBanlistDataRef.current = binnedBanlistData;

  const dragLock = useRef<boolean>();

  useEffect(() => { // Mount
    isScreen1300Query.addEventListener('change', checkScreen1300); // To avoid re-rendering the whole page and losing data, we have to listen and state change on the sublevel
    isScreen1150Query.addEventListener('change', checkScreen1150);
    isScreen950Query.addEventListener('change', checkScreen950);
    updateBanlistsList();
    onCookieChangeListeners.push(cookieRefresh);
    if (!cardsAreLoaded) {
      checkForCardsTask = checkForCardsLoop();
    }

    makeApiCall(cookies.get('refresh'), cookies.get('userId'), apiPath + 'banlist/templates', {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    })
      .then(async (response) => {
        if (!response.ok) {
          const error = response.status;
          openErrorModal('Issue retrieving banlist templates.<br />' +
            (error == 401 ? '<br />Try logging out and logging back in.<br />If this persists, ' + lowercaseContactString : '') +
            (error == 400 ? '<br />You have been inactive for a long time. Please log out and back in.<br /><br />If this persists, ' + lowercaseContactString : '') +
            (error != 401 && error != 400 ? '<br />' + contactString : ''));
          setBanlistLoading(false);
          return Promise.reject(error);
        }

        const data = await response.json();

        setTemplatesList(data.map((banlist: any) => {return { value: banlist._id, text: banlist.name, callback: () => copyTemplate(banlist._id) };})
          .sort((a: any, b: any) => {return a.text == b.text ? 0 : a.text > b.text ? -1 : 1;}));
      });

    shouldPagesRefresh.val = false;

    dragLock.current = false;
    return () => { // Unmount
      isScreen1300Query.removeEventListener('change', checkScreen1300);
      isScreen1150Query.removeEventListener('change', checkScreen1150);
      isScreen950Query.removeEventListener('change', checkScreen950);
      window.onbeforeunload = null;
      if (!shouldPagesRefresh.val) {
        shouldPagesRefresh.val = true;
      }
      onCookieChangeListeners.splice(onCookieChangeListeners.indexOf(cookieRefresh), 1);
      if (checkForCardsTask != null) {
        clearTimeout(checkForCardsTask);
        checkForCardsTask = null;
      }
    };
  }, []);

  useEffect(() => {
    if (listSavedOrExported) window.onbeforeunload = null;
    else {
      if (search != '') history.push({ search: '' });
      window.onbeforeunload = (e) => {
        e.preventDefault();
        e.returnValue = 'You have not saved or exported changes to your banlist, if you leave this page it will be lost.';
        return 'You have not saved or exported changes to your banlist, if you leave this page it will be lost.'; // Custom message doesn't work on most browsers
      };
    }
  }, [listSavedOrExported]);

  useEffect(() => {
    if (confirmedNavigation) {
      if (navToLocation) {
        history.push(navToLocation.pathname);
        refreshRouteFunc(!refresh);
      }
    }
  }, [confirmedNavigation]);

  useEffect(() => {
    if (isMounted.current && cardsAreLoaded) {
      if (searchTask != null) clearTimeout(searchTask);
      searchTask = setTimeout(searchCards, 300);
      if (searchString != '' || setsFilter.length + rarityFilter.length +
      categoryFilter.length + typeFilter.length + subtypeFilter.length + attributeFilter.length + levelFilter.length + linkMarkersFilter.length +
      pendScaleFilter.length + atkFilter.length + defFilter.length + dateFilter.length > 0) {
        document.getElementById('clear-all-button')!.style.display = 'inline-block';
      }
      else document.getElementById('clear-all-button')!.style.display = 'none';
    }
    else isMounted.current = true;
  }, [setsFilter, rarityFilter, categoryFilter, typeFilter, subtypeFilter, attributeFilter, levelFilter,
    linkMarkersFilter, pendScaleFilter, atkFilter, defFilter, dateFilter, searchField, searchString]);

  useEffect(() => {
    setSearchActionsDisabled(false);
    setSearchLoading(false);
  }, [searchResults]);

  useEffect(() => {
    if (cardsAreLoaded) {
      if (searchTask != null) clearTimeout(searchTask);
      searchCards();
      const values = queryString.parse(search);
      if (values.b !== undefined) {
        getPublicBanlist(values.b);
      }
    }
  }, [cardsAreLoaded]);

  useEffect(() => {
    if (searchMounted.current && !searchLoading) {
      setSearchLoading(true);
      setSearchActionsDisabled(true);
      sortSearch();
    }
    else searchMounted.current = true;
  }, [sortField, sortOrder]);

  useEffect(() => {
    queuedCurrentBanlist = null;
    if (!saveAsDisabled && currentBanlist != '') {
      getCurrentBanlist();
    }
    else setSaveAsDisabled(false);
  }, [currentBanlist]);

  useEffect(() => {
    if (queuedCurrentBanlist != null) setCurrentBanlist(queuedCurrentBanlist);
    setBanlistGroupsList([{ text: 'None', value: '' } as DropdownOption,
      ...banlistsList.filter((group) => group.text != '')
        .map((group) => {return { text: group.text, value: group.text, subItems: undefined } as DropdownOption;})]);
    setSelectedBanlistGroup('');
  }, [banlistsList]);

  useEffect(() => {
    if (confirmMatchString != '') {
      setSaveAsDisabled(true);
      document.getElementById('delete-confirm-modal')!.style.display = 'flex';
    }
    else setSaveAsDisabled(false);
  }, [confirmMatchString]);

  useEffect(() => {
    dragLock.current = false;
  }, [forbiddenList, limitedList, semilimitedList, unlimitedList]);

  function showCardAddedPopup() {
    const popup = document.getElementById('card-added-popup')!;
    popup.style.transition = '0s';
    popup.style.opacity = '100';
    popup.style.visibility = 'visible';

    if (popupFade != null) clearTimeout(popupFade);
    popupFade = setTimeout(function() {
      popup.style.transition = '.5s';
      popup.style.opacity = '0';
      popup.style.visibility = 'hidden';
    }, 500);
  }

  function checkScreen1150() {
    setIsScreen1150(isScreen1150Query.matches);
  }

  function checkScreen1300() {
    setIsScreen1300(isScreen1300Query.matches);
  }

  function checkScreen950() {
    setIsScreen950(isScreen950Query.matches);
  }

  function cookieRefresh() {
    setUsername(cookies.get('user'));
    updateBanlistsList();
  }

  function checkForCardsLoop() {
    return setTimeout(() => {
      checkForCardsTask = null;
      if (cardsLoaded.flag) setCardsAreLoaded(true);
      else {
        checkForCardsTask = checkForCardsLoop();
      }
    }, 200);
  }

  function updateBanlistsList(newCurrent: string|null = null) {
    localforage.getItem('banlists').then((banlists: any) => {
      if (banlists != null && banlists.length > 0) {
        queuedCurrentBanlist = newCurrent;
        setBanlistsList(banlists);
      }
      else {
        setBanlistsList([]);
        setCurrentBanlist('');
        setCurrentBanlistName('');
        setCurrentBanlistGroup('');
        setBanlistIsPublic(false);
      }
    })
      .catch(() => {
        openErrorModal('Issue getting banlists for Banlist Builder.<br />' + contactString);
      });
  }

  function toggleResultsBox() {
    const resultsBox = document.getElementById('banlistbuilder-results')!;
    if (resultsBox.style.left == '' || resultsBox.style.left.startsWith('-')) resultsBox.style.left = '0px';
    else resultsBox.style.left = '-' + (resultsBox.clientWidth + 2) + 'px';
  }

  function toggleFilterBox() {
    const filterBox = document.getElementById('banlistbuilder-filters')!;
    if (filterBox.style.right == '' || filterBox.style.right.startsWith('-')) filterBox.style.right = '0px';
    else filterBox.style.right = '-' + (filterBox.clientWidth + 2) + 'px';
  }

  function setFilter(selected: MultiValue<{value: string, label: string}> | null, setFunc: any) {
    if (selected != null) setFunc(selected.map((val: {value: string, label: string}) => val.value));
    else setFunc([]);
  }

  function rangeFilterOnChange(e: any) { // There's gotta be a better way to condense this
    let numRange: number[];
    let dateRange: Date[];
    switch (e.currentTarget.name) {
      case 'MinAtk':
        numRange = [...atkFilter];
        if (e.currentTarget.value != '') {
          if (Number(e.currentTarget.value) > 9999) e.currentTarget.value = 9999;
          else if (Number(e.currentTarget.value) < 0) e.currentTarget.value = 0;

          if (numRange.length > 0) numRange[0] = Number(e.currentTarget.value);
          else numRange = [Number(e.currentTarget.value), 9999];
        }
        else if (numRange.length > 0) {
          if (numRange[1] < 9999) numRange[0] = 0;
          else numRange = [];
        }
        setAtkFilter(numRange);
        break;
      case 'MaxAtk':
        numRange = [...atkFilter];
        if (e.currentTarget.value != '') {
          if (Number(e.currentTarget.value) > 9999) e.currentTarget.value = 9999;
          else if (Number(e.currentTarget.value) < 0) e.currentTarget.value = 0;

          if (numRange.length > 0) numRange[1] = Number(e.currentTarget.value);
          else numRange = [0, Number(e.currentTarget.value)];
        }
        else if (numRange.length > 0) {
          if (numRange[0] > 0) numRange[1] = 9999;
          else numRange = [];
        }
        setAtkFilter(numRange);
        break;
      case 'MinDef':
        numRange = [...defFilter];
        if (e.currentTarget.value != '') {
          if (Number(e.currentTarget.value) > 9999) e.currentTarget.value = 9999;
          else if (Number(e.currentTarget.value) < 0) e.currentTarget.value = 0;

          if (numRange.length > 0) numRange[0] = Number(e.currentTarget.value);
          else numRange = [Number(e.currentTarget.value), 9999];
        }
        else if (numRange.length > 0) {
          if (numRange[1] < 9999) numRange[0] = 0;
          else numRange = [];
        }
        setDefFilter(numRange);
        break;
      case 'MaxDef':
        numRange = [...defFilter];
        if (e.currentTarget.value != '') {
          if (Number(e.currentTarget.value) > 9999) e.currentTarget.value = 9999;
          else if (Number(e.currentTarget.value) < 0) e.currentTarget.value = 0;

          if (numRange.length > 0) numRange[1] = Number(e.currentTarget.value);
          else numRange = [0, Number(e.currentTarget.value)];
        }
        else if (numRange.length > 0) {
          if (numRange[0] > 0) numRange[1] = 9999;
          else numRange = [];
        }
        setDefFilter(numRange);
        break;
      case 'MinDate':
        dateRange = [...dateFilter];
        if (e.currentTarget.value != '') {
          let val: Date = new Date(e.currentTarget.value);

          if (dateRange.length > 0) {
            if (val > dateRange[1]) {
              val = dateRange[1];
              e.currentTarget.value = getDateString(val);
            }
            dateRange[0] = val;
          }
          else dateRange = [val, DATE_MAX];
        }
        else if (dateRange.length > 0) {
          if (dateRange[1] < DATE_MAX) dateRange[0] = DATE_MIN;
          else dateRange = [];
        }
        setDateFilter(dateRange);
        break;
      case 'MaxDate':
        dateRange = [...dateFilter];
        if (e.currentTarget.value != '') {
          let val: Date = new Date(e.currentTarget.value);

          if (dateRange.length > 0) {
            if (val < dateRange[0]) {
              val = dateRange[0];
              e.currentTarget.value = getDateString(val);
            }
            dateRange[1] = val;
          }
          else dateRange = [DATE_MIN, val];
        }
        else if (dateRange.length > 0) {
          if (dateRange[0] > DATE_MIN) dateRange[1] = DATE_MAX;
          else dateRange = [];
        }
        setDateFilter(dateRange);
        break;
    }
  }

  async function searchCards() {
    searchTask = null;
    setSearchLoading(true);

    const filters: { fieldName: string, type: string, value: any[] }[] = [];
    if (setsFilter.length > 0) filters.push({ fieldName: 'card_sets', type: 'Sets', value: setsFilter });
    if (rarityFilter.length > 0) filters.push({ fieldName: 'all_rarities', type: 'N:N', value: rarityFilter });
    if (categoryFilter.length > 0) filters.push({ fieldName: 'type', type: '1:N', value: categoryFilter });

    const subtypes: string[] = [];
    if (typeFilter.length > 0) subtypes.push(...typeFilter);
    if (subtypeFilter.length > 0) subtypes.push(...subtypeFilter);
    if (subtypes.length > 0) filters.push({ fieldName: 'subtype', type: 'N:Nx', value: subtypes });

    if (attributeFilter.length > 0) filters.push({ fieldName: 'attribute', type: '1:N', value: attributeFilter });
    if (levelFilter.length > 0) filters.push({ fieldName: 'level', type: '1:N', value: levelFilter });
    if (linkMarkersFilter.length > 0) filters.push({ fieldName: 'linkmarkers', type: 'N:Nx', value: linkMarkersFilter });
    if (pendScaleFilter.length > 0) filters.push({ fieldName: 'scale', type: '1:N', value: pendScaleFilter });

    if (atkFilter.length > 0) filters.push({ fieldName: 'atk', type: 'Range', value: atkFilter });
    if (defFilter.length > 0) filters.push({ fieldName: 'def', type: 'Range', value: defFilter });
    if (dateFilter.length > 0) filters.push({ fieldName: 'all_prints', type: 'RangeArray', value: dateFilter });

    const results = filteredCardSearch(searchField, searchString, filters, sortField, sortOrder == 'asc', null, null, null);
    setSearchResults(results);
  }

  function clearAllFilters() {
    (document.getElementById('search-search-input')! as HTMLInputElement).value = '';
    setSearchString('');
    setsFilterField.current.setValue([]);
    rarityFilterField.current.setValue([]);
    categoryFilterField.current.setValue([]);
    typeFilterField.current.setValue([]);
    subtypeFilterField.current.setValue([]);
    attributeFilterField.current.setValue([]);
    levelFilterField.current.setValue([]);
    linkMarkersFilterField.current.setValue([]);
    pendScaleFilterField.current.setValue([]);
    atkFilterFieldLeft.current.value = '';
    atkFilterFieldRight.current.value = '';
    setAtkFilter([]);
    defFilterFieldLeft.current.value = '';
    defFilterFieldRight.current.value = '';
    setDefFilter([]);
    dateFilterFieldLeft.current.value = '';
    dateFilterFieldRight.current.value = '';
    setDateFilter([]);
  }

  function uploadLflist() {
    document.getElementById('lflist-import-field')!.click();
  }

  async function importFromLflist() {
    setBanlistLoading(true);
    const inputField = document.getElementById('lflist-import-field')!;

    const lflistData = (await ((inputField as HTMLInputElement).files as FileList).item(0)!.text()!).split(new RegExp('\\r?\\n'));
    (inputField as HTMLInputElement).value = '';

    setTimeout(() => {
      const newForbiddenIds: string[] = [];
      const newLimitedIds: string[] = [];
      const newSemilimitedIds: string[] = [];
      try {
        const dateRange: Date[] = [DATE_MIN, DATE_MAX];
        for (let i: number = 0; i < lflistData.length; i++) {
          const line = lflistData[i].trim();
          if (line == '' || ['#', '$', '!'].includes(line[0])) {
            if (line.startsWith('#blacklist')) break; // If we have one of our files, we've stapled the date-based blacklist to the end so ignore it
            else continue;
          }
          else if (line.startsWith('--')) {
            if (line.startsWith('--StartDate')) dateRange[0] = new Date(line.split(' ')[1]);
            else if (line.startsWith('--EndDate')) dateRange[1] = new Date(line.split(' ')[1]);
            continue;
          }

          const vals = line.split(' ');
          if (vals.length >= 2) {
            switch (Number(vals[1].trim())) { // Other cases like -1 and 3 just fall through, we don't deal with whitelists outside date ranges
              case 0:
              case -1:
                newForbiddenIds.push(vals[0].trim());
                break;
              case 1:
                newLimitedIds.push(vals[0].trim());
                break;
              case 2:
                newSemilimitedIds.push(vals[0].trim());
                break;
            }
          }
        }

        setWhitelistDates(dateRange);
        populateListsFromIds(newForbiddenIds, newLimitedIds, newSemilimitedIds);
        setListSavedOrExported(false);
      }
      catch (err) {
        openErrorModal('Issue importing banlist. Please ensure .lflist.conf file is correctly formatted.<br />' +
          'If this persists, ' + contactString);
      }
    }, 500); // Set a timeout so the rerender with the loading icon happens
  }

  function populateListsFromIds(newForbiddenIds: string[], newLimitedIds: string[], newSemilimitedIds: string[], newUnlimitedIds?: string[]) {
    try {
      const newForbidden: DropCard[] = [];
      const newLimited: DropCard[] = [];
      const newSemilimited: DropCard[] = [];
      const newUnlimited: DropCard[] = [];
      const newBanlistData: { [id: number]: number } = {};

      newForbiddenIds.forEach((id: string, index: number) => {
        const card: DropCard = { ...cards[Number(id)], uniqueID: 'm-' + id };
        if (card.name !== undefined && newBanlistData[card.id] === undefined) {
          newForbidden.push(card);
          newBanlistData[card.id] = 0;
        }
      });
      newLimitedIds.forEach((id: string, index: number) => {
        const card: DropCard = { ...cards[Number(id)], uniqueID: 'm-' + id };
        if (card.name !== undefined && newBanlistData[card.id] === undefined) {
          newLimited.push(card);
          newBanlistData[card.id] = 1;
        }
      });
      newSemilimitedIds.forEach((id: string, index: number) => {
        const card: DropCard = { ...cards[Number(id)], uniqueID: 'm-' + id };
        if (card.name !== undefined && newBanlistData[card.id] === undefined) {
          newSemilimited.push(card);
          newBanlistData[card.id] = 2;
        }
      });
      if (newUnlimitedIds !== undefined) {
        newUnlimitedIds.forEach((id: string, index: number) => {
          const card: DropCard = { ...cards[Number(id)], uniqueID: 'm-' + id };
          if (card.name !== undefined && newBanlistData[card.id] === undefined) {
            newUnlimited.push(card);
            newBanlistData[card.id] = 3;
          }
        });
      }

      setBinnedBanlistData(newBanlistData);
      setForbiddenList(newForbidden);
      setLimitedList(newLimited);
      setSemilimitedList(newSemilimited);
      setUnlimitedList(newUnlimited);
      setBanlistLoading(false);
    }
    catch (err) {
      setBanlistLoading(false);
      openErrorModal('Issue populating banlist.<br />' + contactString);
      return;
    }
  }

  function setWhitelistDates(newDateRange: Date[]) {
    if (newDateRange[0] > DATE_MIN) (document.getElementById('whitelist-start-date')! as HTMLInputElement).value = getDateString(newDateRange[0]);
    else (document.getElementById('whitelist-start-date')! as HTMLInputElement).value = '';

    if (newDateRange[1] < DATE_MAX) (document.getElementById('whitelist-end-date')! as HTMLInputElement).value = getDateString(newDateRange[1]);
    else (document.getElementById('whitelist-end-date')! as HTMLInputElement).value = '';

    setWhitelistDateRange(newDateRange);
  }

  function whitelistRangeOnChange(e: any) {
    const dateRange = [...whitelistDateRange];

    if (e.currentTarget.value != '') {
      let val: Date = new Date(e.currentTarget.value);

      if (e.currentTarget.name == 'MinPrintDate') {
        if (val > dateRange[1]) {
          val = dateRange[1];
          e.currentTarget.value = getDateString(val);
        }
        dateRange[0] = val;
      }
      else {
        if (val < dateRange[0]) {
          val = dateRange[0];
          e.currentTarget.value = getDateString(val);
        }
        dateRange[1] = val;
      }
    }
    else if (e.currentTarget.name == 'MinPrintDate') dateRange[0] = DATE_MIN;
    else dateRange[1] = DATE_MAX;
    setWhitelistDates(dateRange);
  }

  function exportToUrl() {
    if (currentBanlist != '' && banlistIsPublic) {
      setExportUrl({ type: 'Shareable Link', exportString: frontEndPath + 'BanlistBuilder?b=' + currentBanlist });
    }
  }

  function exportToLflist() {
    setBanlistLoading(true);
    setTimeout(() => {
      let lflistString = '!' + (currentBanlistName != '' ? currentBanlistName : 'Untitled Banlist') + '\n';
      let whitelistCards: { [id: number]: Card } | null = null;
      let blacklistCards: { [id: number]: Card } | null = null;

      if (whitelistDateRange[0] > DATE_MIN || whitelistDateRange[1] < DATE_MAX) {
        lflistString += '--StartDate ' + getDateString(whitelistDateRange[0]) + '\n' +
          '--EndDate ' + getDateString(whitelistDateRange[1]) + '\n';

        if (whitelistDateRange[1] < DATE_MAX) { // If we have an end date it's a whitelist, otherwise it's a blacklist
          lflistString += '$whitelist\n';
          const whitelistCardArray = filteredCardSearch('all', '', // Grab cards from date range
            [{ fieldName: 'all_prints', type: 'RangeArray', value: whitelistDateRange }], 'name', true, null, null, null);

          whitelistCards = {};
          for (let i: number = 0; i < whitelistCardArray.length; i++) {
            whitelistCards[whitelistCardArray[i].id] = whitelistCardArray[i];
          }
        }
        else {
          const blacklistCardArray = filteredCardSearch('all', '', // Grab cards from date range
            [{ fieldName: 'all_prints', type: 'RangeArray', value: whitelistDateRange }], 'name', true, null, null, null);

          blacklistCards = {};
          for (let i: number = 0; i < blacklistCardArray.length; i++) {
            blacklistCards[blacklistCardArray[i].id] = blacklistCardArray[i];
          }
        }
      }

      lflistString += '#forbidden\n';
      forbiddenList.forEach((card: BaseCard) => {
        lflistString += card.id + ' 0 --' + card.name + '\n';
        if (whitelistCards != null) delete whitelistCards[card.id];
        if (blacklistCards != null) delete blacklistCards[card.id];
      });

      lflistString += '#limited\n';
      limitedList.forEach((card: BaseCard) => {
        lflistString += card.id + ' 1 --' + card.name + '\n';
        if (whitelistCards != null) delete whitelistCards[card.id];
        if (blacklistCards != null) delete blacklistCards[card.id];
      });

      lflistString += '#semi-limited\n';
      semilimitedList.forEach((card: BaseCard) => {
        lflistString += card.id + ' 2 --' + card.name + '\n';
        if (whitelistCards != null) delete whitelistCards[card.id];
        if (blacklistCards != null) delete blacklistCards[card.id];
      });

      if (blacklistCards != null) {
        lflistString += '#blacklist\n';
        Object.values(blacklistCards).forEach((card: BaseCard) => {lflistString += card.id + ' 0 --' + card.name + '\n';});
      }

      if (whitelistCards != null) {
        lflistString += '#whitelist\n';
        Object.values(whitelistCards).forEach((card: BaseCard) => {lflistString += card.id + ' 3 --' + card.name + '\n';});
      }

      const element = document.createElement('a');
      const file = new Blob([lflistString], { type: 'text/plain' });
      element.href = URL.createObjectURL(file);
      element.target = '_blank';
      element.download = (currentBanlistName != '' ? currentBanlistName : 'Untitled Banlist') + '.lflist.conf';
      document.body.appendChild(element);
      element.click();
      setBanlistLoading(false);
      setListSavedOrExported(true);
    }, 500);
  }

  function openExportWithBinderModal() {
    localforage.getItem('binders').then((binders: any) => {
      if (binders != null && binders.length > 0) {
        const binderOpts: DropdownOption[] = binders.map((binder: any) => {
          return { value: binder.id, text: binder.name } as DropdownOption;
        });

        setBinderList(binderOpts);
      }
      else {
        setBinderList([]);
        sessionMemory.lastBinderSelected = '';
      }

      document.getElementById('export-with-binder-modal')!.style.display = 'flex';
    })
      .catch(() => {
        openErrorModal('Issue getting binder list for banlist export.<br />' + contactString);
        setBinderList([]);
        sessionMemory.lastBinderSelected = '';
      });

  }

  function closeExportWithBinderModal() {
    document.getElementById('export-with-binder-modal')!.style.display = 'none';
  }

  function exportWithBinder(binderId: string) {

    setBanlistLoading(true);
    setTimeout(() => {
      getBinderCards(binderId, true)
        .then(async (results) => {
          if (results != null) {
            let lflistString = '!' + (currentBanlistName != '' ? currentBanlistName : 'Untitled Banlist') + '\n';
            const whitelistCards: { [id: number]: BinderCard } = {};
            for (let i: number = 0; i < results.length; i++) {
              whitelistCards[results[i].id] = results[i];
            }

            if (whitelistDateRange[0] > DATE_MIN || whitelistDateRange[1] < DATE_MAX) {
              lflistString += '--StartDate ' + getDateString(whitelistDateRange[0]) + '\n' +
                '--EndDate ' + getDateString(whitelistDateRange[1]) + '\n';
            }

            lflistString += '$whitelist\n';

            lflistString += '#forbidden\n';
            forbiddenList.forEach((card: BaseCard) => {
              lflistString += card.id + ' 0 --' + card.name + '\n';
              delete whitelistCards[card.id];
            });

            lflistString += '#limited\n';
            limitedList.forEach((card: BaseCard) => {
              if (whitelistCards[card.id] !== undefined) {
                lflistString += card.id + ' 1 --' + card.name + '\n';
                delete whitelistCards[card.id];
              }
            });

            lflistString += '#semi-limited\n';
            semilimitedList.forEach((card: BaseCard) => {
              if (whitelistCards[card.id] !== undefined && whitelistCards[card.id].count >= 2) {
                lflistString += card.id + ' 2 --' + card.name + '\n';
                delete whitelistCards[card.id];
              }
            });

            if (whitelistCards != null) {
              lflistString += '#whitelist\n';
              Object.values(whitelistCards).forEach((card: BinderCard) => {
                lflistString += card.id + ' ' + (card.count < 3 ? card.count : '3') + ' --' + card.name + '\n';
              });
            }

            const element = document.createElement('a');
            const file = new Blob([lflistString], { type: 'text/plain' });
            element.href = URL.createObjectURL(file);
            element.target = '_blank';
            element.download = (currentBanlistName != '' ? currentBanlistName :
              'Untitled Banlist') + ' & ' + binderList.find((val: DropdownOption) => val.value == binderId)!.text + '.lflist.conf';
            document.body.appendChild(element);
            element.click();
            setBanlistLoading(false);
            setListSavedOrExported(true);
          }
          else {
            openErrorModal('Issue getting binder for banlist export.<br />' + contactString);
            setBanlistLoading(false);
          }
        });

    }, 500);
    closeExportWithBinderModal();
  }

  function exportToCsv() {
    setTimeout(() => {
      const json: {
        'Card Type': string,
        'Card Name': string,
        'Limit': string,
        'Remarks': string,
        'Allowed Print Start Date': string,
        'Allowed Print End Date': string,
      }[] = [];

      if (whitelistDateRange[0] > DATE_MIN || whitelistDateRange[1] < DATE_MAX) {
        json.push({
          'Card Type': '',
          'Card Name': '',
          'Limit': '',
          'Remarks': '',
          'Allowed Print Start Date': whitelistDateRange[0] > DATE_MIN ? getDateString(whitelistDateRange[0]) : '',
          'Allowed Print End Date': whitelistDateRange[1] < DATE_MAX ? getDateString(whitelistDateRange[1]) : '',
        });
      }

      const typesList = ['Link', 'Xyz', 'Synchro', 'Fusion', 'Ritual', 'Effect'];

      forbiddenList.forEach((card: DropCard) => {
        const subtype = card.type == 'Monster' ? typesList.find((t: string) => card.subtype.includes(t))??'' : '';
        json.push({
          'Card Type': card.type + (subtype != '' ? '/' + subtype : ''),
          'Card Name': card.name.toUpperCase(),
          'Limit': 'Forbidden',
          'Remarks': '',
          'Allowed Print Start Date': '',
          'Allowed Print End Date': '',
        });
      });

      limitedList.forEach((card: DropCard) => {
        const subtype = card.type == 'Monster' ? typesList.find((t: string) => card.subtype.includes(t))??'' : '';
        json.push({
          'Card Type': card.type + (subtype != '' ? '/' + subtype : ''),
          'Card Name': card.name.toUpperCase(),
          'Limit': 'Limited',
          'Remarks': '',
          'Allowed Print Start Date': '',
          'Allowed Print End Date': '',
        });
      });

      semilimitedList.forEach((card: DropCard) => {
        const subtype = card.type == 'Monster' ? typesList.find((t: string) => card.subtype.includes(t))??'' : '';
        json.push({
          'Card Type': card.type + (subtype != '' ? '/' + subtype : ''),
          'Card Name': card.name.toUpperCase(),
          'Limit': 'Semi-Limited',
          'Remarks': '',
          'Allowed Print Start Date': '',
          'Allowed Print End Date': '',
        });
      });

      unlimitedList.forEach((card: DropCard) => {
        const subtype = card.type == 'Monster' ? typesList.find((t: string) => card.subtype.includes(t))??'' : '';
        json.push({
          'Card Type': card.type + (subtype != '' ? '/' + subtype : ''),
          'Card Name': card.name.toUpperCase(),
          'Limit': 'Unlimited',
          'Remarks': '',
          'Allowed Print Start Date': '',
          'Allowed Print End Date': '',
        });
      });

      json2csv(json, function(err: any, csv?: string) {
        if (err || csv == null) {
          openErrorModal('Issue exporting banlist csv.<br />' + contactString);
          return;
        }

        const element = document.createElement('a');
        const file = new Blob([csv], { type: 'text/csv' });
        element.href = URL.createObjectURL(file);
        element.target = '_blank';
        element.download = (currentBanlistName != '' ? currentBanlistName : 'Untitled Banlist') + '.csv';
        document.body.appendChild(element);
        element.click();
        setBanlistLoading(false);
      });

    }, 500);
  }

  async function addAllResultsToList(destinationList: string) {
    setBanlistLoading(true);

    setTimeout(() => {

      const newBanlistData = binnedBanlistData;
      if (destinationList == 'semilimited') {
        const newSemilimited = [...semilimitedList];

        for (let i: number = 0; i < searchResults.length; i++) {
          const cardInfo = searchResults[i];
          const uniqueID = 'm-' + cardInfo.id + '-' + Date.now() + Math.random() + i;
          if (checkCardAllowed('ss', uniqueID, 'b')) {
            newSemilimited.push({ ...cardInfo, uniqueID: uniqueID });
            newBanlistData[cardInfo.id] = 2;
          }
        }

        setSemilimitedList(newSemilimited);
      }
      else if (destinationList == 'limited') {
        const newLimited = [...limitedList];

        for (let i: number = 0; i < searchResults.length; i++) {
          const cardInfo = searchResults[i];
          const uniqueID = 'm-' + cardInfo.id + '-' + Date.now() + Math.random() + i;
          if (checkCardAllowed('sl', uniqueID, 'b')) {
            newLimited.push({ ...cardInfo, uniqueID: uniqueID });
            newBanlistData[cardInfo.id] = 1;
          }
        }

        setLimitedList(newLimited);
      }
      else if (destinationList == 'forbidden') {
        const newForbidden = [...forbiddenList];

        for (let i: number = 0; i < searchResults.length; i++) {
          const cardInfo = searchResults[i];
          const uniqueID = 'm-' + cardInfo.id + '-' + Date.now() + Math.random() + i;
          if (checkCardAllowed('sf', uniqueID, 'b')) {
            newForbidden.push({ ...cardInfo, uniqueID: uniqueID });
            newBanlistData[cardInfo.id] = 0;
          }
        }

        setForbiddenList(newForbidden);
      }
      else { // Unlimited
        const newUnlimited = [...unlimitedList];

        for (let i: number = 0; i < searchResults.length; i++) {
          const cardInfo = searchResults[i];
          const uniqueID = 'm-' + cardInfo.id + '-' + Date.now() + Math.random() + i;
          if (checkCardAllowed('su', uniqueID, 'b')) {
            newUnlimited.push({ ...cardInfo, uniqueID: uniqueID });
            newBanlistData[cardInfo.id] = 3;
          }
        }

        setUnlimitedList(newUnlimited);
      }
      setBinnedBanlistData(newBanlistData);

      setListSavedOrExported(false);
      setBanlistLoading(false);
    }, 500);
  }

  function sortSearch() {
    setSearchResults(builderSearchSort([...searchResults], sortField as keyof (BinderCard | Card), sortOrder == 'asc'));
  }

  function updateSearchString(newSearchTerm: string) {
    if (newSearchTerm != searchString) setSearchString(newSearchTerm);
    else {
      if (searchTask != null) clearTimeout(searchTask); // Force search refresh on click
      searchTask = setTimeout(searchCards, 300);
    }
  }

  function sortLists() {
    const sortedForbidden = [...forbiddenList].sort(zoneSortFunc);
    setForbiddenList(sortedForbidden);

    const sortedLimited = [...limitedList].sort(zoneSortFunc);
    setLimitedList(sortedLimited);

    const sortedSemilimited = [...semilimitedList].sort(zoneSortFunc);
    setSemilimitedList(sortedSemilimited);

    const sortedUnlimited = [...unlimitedList].sort(zoneSortFunc);
    setUnlimitedList(sortedUnlimited);
    setListSavedOrExported(false);
  }

  function clearBanlist() {
    setBinnedBanlistData({});
    setForbiddenList([]);
    setLimitedList([]);
    setSemilimitedList([]);
    setUnlimitedList([]);
    setWhitelistDateRange([DATE_MIN, DATE_MAX]);

    setListSavedOrExported(false);
  }

  function resetBanlist() {
    if (currentBanlist != '') getCurrentBanlist();
    else clearBanlist();
    setListSavedOrExported(true);
  }

  function setCurrentDragCard(card: DropCard, listZone: string, dropCallback: any) {
    currentDragCard.zone = listZone;
    currentDragCard.card = card;
    currentDragCard.dropCallback = dropCallback;
    currentDragCard.index = findCard(listZone, card.uniqueID).index;
  }

  function setCurrentDragCardPos(listZone: string, index: number) {
    currentDragCard.zone = listZone;
    currentDragCard.index = index;
  }

  function setCurrentDragCardDropCallback(dropCallback: any) {
    currentDragCard.dropCallback = dropCallback;
  }

  function clearCurrentDragCard() {
    if (currentDragCard.dropCallback != null) currentDragCard.dropCallback();
    currentDragCard.zone = '';
    currentDragCard.card = null;
    currentDragCard.dropCallback = null;
    currentDragCard.index = -1;
  }

  function checkCardAllowed(listZone: string, uniqueID: string, oldListZone: string) {
    if (oldListZone == 'b') { // Limit 1
      const cardId: number = Number(uniqueID.split('-')[1]);
      if (forbiddenList.some((c: DropCard) => c.id == cardId && c.uniqueID != uniqueID) || // Only one can exist
        limitedList.some((c: DropCard) => c.id == cardId && c.uniqueID != uniqueID) ||
        semilimitedList.some((c: DropCard) => c.id == cardId && c.uniqueID != uniqueID) ||
        unlimitedList.some((c: DropCard) => c.id == cardId && c.uniqueID != uniqueID)) return false;
    }

    return true;
  }

  function findCard(listZone: string, uniqueID: string) {
    let card: DropCard;
    let index: number;
    const cardID = Number(uniqueID.split('-')[1]);
    if (listZone == 'sf') { // Forbidden
      card = forbiddenListRef.current.filter((c: DropCard) => c.id == cardID)[0];
      index = forbiddenListRef.current.indexOf(card);
    }
    else if (listZone == 'sl') { // Limited
      card = limitedListRef.current.filter((c: DropCard) => c.id == cardID)[0];
      index = limitedListRef.current.indexOf(card);
    }
    else if (listZone == 'ss') { // Semilimited
      card = semilimitedListRef.current.filter((c: DropCard) => c.id == cardID)[0];
      index = semilimitedListRef.current.indexOf(card);
    }
    else if (listZone == 'su') { // Unlimited
      card = unlimitedListRef.current.filter((c: DropCard) => c.id == cardID)[0];
      index = unlimitedListRef.current.indexOf(card);
    }
    else { // Search. We don't ever actually use the index on this so we can do a less expensive op
      card = { ...cards[Number(uniqueID.split('-')[1])], uniqueID: uniqueID };
      index = -1;
    }

    return { card: card, index: index };
  }

  function removeCardUtility(listZone: string, index: number, cardType: string, cardSubtype: string[]) {
    if (listZone == 'sf') { // Forbidden
      const newForbidden = [...forbiddenListRef.current];
      newForbidden.splice(index, 1);
      setForbiddenList(newForbidden);
    }
    else if (listZone == 'sl') { // Limited
      const newLimited = [...limitedListRef.current];
      newLimited.splice(index, 1);
      setLimitedList(newLimited);
    }
    else if (listZone == 'ss') { // Semilimited
      const newSemilimited = [...semilimitedListRef.current];
      newSemilimited.splice(index, 1);
      setSemilimitedList(newSemilimited);
    }
    else if (listZone == 'su') { // Unlimited
      const newUnlimited = [...unlimitedListRef.current];
      newUnlimited.splice(index, 1);
      setUnlimitedList(newUnlimited);
    }
  }

  function moveCard(listZone: string, uniqueID: string, newListZone: string, newIndex: number, isCurrentDragCard: boolean, dropOp: boolean) {
    if (isCurrentDragCard) {
      dragLock.current = true; // An attempt at ensuring nothing fires while we're in the middle of this function and gets locked in with an old index
      listZone = currentDragCard.zone;
    }
    const cardInfo = isCurrentDragCard ? { card: currentDragCard.card!, index: currentDragCard.index } : findCard(listZone, uniqueID);
    if (!['sf', 'sl', 'ss', 'su'].includes(newListZone)) newListZone = 'sf'; // Account for builder card right click

    if (cardInfo.card == null || (cardInfo.index == newIndex && newListZone == listZone)) {
      dragLock.current = false;
      if (listZone == 'b' && isScreen950 && !isCurrentDragCard) {
        setCardAddedPopupText(cardInfo.card.name + ' could not be added- it is already in the list!');
        showCardAddedPopup();
      }
      return; // Limit 1
    }
    if (listZone == newListZone) { // There's probably a better way to optimize/condense this later
      if (listZone == 'sf') { // Forbidden
        const newForbidden = [...forbiddenListRef.current];
        newForbidden.splice(newIndex, 0, newForbidden.splice(cardInfo.index, 1)[0]);
        setForbiddenList(newForbidden);
        if (newIndex >= newForbidden.length) newIndex = newForbidden.length - 1;
      }
      else if (listZone == 'sl') { // Limited
        const newLimited = [...limitedListRef.current];
        newLimited.splice(newIndex, 0, newLimited.splice(cardInfo.index, 1)[0]);
        setLimitedList(newLimited);
        if (newIndex >= newLimited.length) newIndex = newLimited.length - 1;
      }
      else if (listZone == 'ss') { // Semilimited
        const newSemilimited = [...semilimitedListRef.current];
        newSemilimited.splice(newIndex, 0, newSemilimited.splice(cardInfo.index, 1)[0]);
        setSemilimitedList(newSemilimited);
        if (newIndex >= newSemilimited.length) newIndex = newSemilimited.length - 1;
      }
      else if (listZone == 'su') { // Unlimited
        const newUnlimited = [...unlimitedListRef.current];
        newUnlimited.splice(newIndex, 0, newUnlimited.splice(cardInfo.index, 1)[0]);
        setUnlimitedList(newUnlimited);
        if (newIndex >= newUnlimited.length) newIndex = newUnlimited.length - 1;
      }
    }
    else {
      if (!checkCardAllowed(newListZone, cardInfo.card.uniqueID, listZone)) {
        dragLock.current = false;
        if (listZone == 'b' && isScreen950 && !isCurrentDragCard) {
          setCardAddedPopupText(cardInfo.card.name + ' could not be added- it is already in the list!');
          showCardAddedPopup();
        }
        return; // Limit 1
      }

      removeCardUtility(listZone, cardInfo.index, cardInfo.card.type, cardInfo.card.subtype);

      const newBanlistData = binnedBanlistDataRef.current;
      if (newListZone == 'sf') { // Forbidden
        const newForbidden = [...forbiddenListRef.current];
        newIndex = newIndex > -1 ? newIndex : newForbidden.length;
        newForbidden.splice(newIndex, 0, cardInfo.card);
        newBanlistData[cardInfo.card.id] = 0;
        setForbiddenList(newForbidden);
        if (newIndex >= newForbidden.length) newIndex = newForbidden.length - 1;
      }
      else if (newListZone == 'sl') { // Limited
        const newLimited = [...limitedListRef.current];
        newIndex = newIndex > -1 ? newIndex : newLimited.length;
        newLimited.splice(newIndex, 0, cardInfo.card);
        newBanlistData[cardInfo.card.id] = 1;
        setLimitedList(newLimited);
        if (newIndex >= newLimited.length) newIndex = newLimited.length - 1;
      }
      else if (newListZone == 'ss') { // Semilimited
        const newSemilimited = [...semilimitedListRef.current];
        newIndex = newIndex > -1 ? newIndex : newSemilimited.length;
        newSemilimited.splice(newIndex, 0, cardInfo.card);
        newBanlistData[cardInfo.card.id] = 2;
        setSemilimitedList(newSemilimited);
        if (newIndex >= newSemilimited.length) newIndex = newSemilimited.length - 1;
      }
      else if (newListZone == 'su') { // Unlimited
        const newUnlimited = [...unlimitedListRef.current];
        newIndex = newIndex > -1 ? newIndex : newUnlimited.length;
        newUnlimited.splice(newIndex, 0, cardInfo.card);
        newBanlistData[cardInfo.card.id] = 3;
        setUnlimitedList(newUnlimited);
        if (newIndex >= newUnlimited.length) newIndex = newUnlimited.length - 1;
      }
      setBinnedBanlistData(newBanlistData);

      if (listZone == 'b' && isScreen950 && !isCurrentDragCard) {
        setCardAddedPopupText(cardInfo.card.name + ' added to Forbidden List!');
        showCardAddedPopup();
      }
    }

    if (isCurrentDragCard) {
      setCurrentDragCardPos(newListZone, newIndex);
    }

    if (dropOp) {
      clearCurrentDragCard();
    }
    setListSavedOrExported(false);
  }

  function removeCard(listZone: string, uniqueID: string, isCurrentDragCard: boolean) {
    if (isCurrentDragCard) listZone = currentDragCard.zone;
    if (listZone != 'b') {
      dragLock.current = true;
      const cardInfo = isCurrentDragCard ? { card: currentDragCard.card!, index: currentDragCard.index } : findCard(listZone, uniqueID);

      removeCardUtility(listZone, cardInfo.index, cardInfo.card.type, cardInfo.card.subtype);
      const newBanlistData = binnedBanlistDataRef.current;
      delete newBanlistData[cardInfo.card.id];
      setBinnedBanlistData(newBanlistData);
    }

    if (isCurrentDragCard) {
      clearCurrentDragCard();
    }
    setListSavedOrExported(false);
  }

  const resultsRow = useCallback(({ index, style }: { index: number, style: CSSProperties }) => {
    const card = searchResults[index];
    return (
      <div style={style} key={card.id+'banlistsearchcardcont'}>
        <div className='builder-search-card'>
          <div className='builder-search-card-left'>
            <BuilderSearchCard
              {...{
                card: card,
                limit: (binnedBanlistData[card.id] ?? undefined),
                limitSize: '30px',
              }}
              key={card.id+'banlistsearchcard'}
            />
          </div>
          <div className='builder-search-card-right'>
            <h3><a href={cardLink(card.id)} target='_blank' rel='noreferrer'>{card.name}</a></h3>
            <span>
              {(card.type == 'Monster' ? ((card.subtype.includes('Xyz') ? 'Rank ' : (card.subtype.includes('Link') ? 'Link ' : 'Level ')) + card.level +
                ' / ' + card.subtype.slice(1).join(' ')) : card.subtype.join(' ')) + ' ' + card.type}
            </span>
            {card.type == 'Monster' ? <span>{card.attribute + ' / ' + card.subtype[0]}</span> : ''}
          </div>
        </div>
      </div>
    );
  }, [searchResults]);

  function openBanlistRenameModal() {
    document.getElementById('banlist-rename-modal')!.style.display = 'flex';
  }

  function closeBanlistRenameModal() {
    document.getElementById('banlist-rename-modal')!.style.display = 'none';
    (document.getElementById('banlist-rename-name')! as HTMLInputElement).value = '';
  }

  function openBanlistSaveAsModal() {
    document.getElementById('banlist-saveas-modal')!.style.display = 'flex';
  }

  function closeBanlistSaveAsModal() {
    document.getElementById('banlist-saveas-modal')!.style.display = 'none';
    (document.getElementById('banlist-saveas-name')! as HTMLInputElement).value = '';
  }

  function copyTemplate(id: any) {
    if (id == '') return;
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    };
    setBanlistLoading(true);
    makeApiCall(cookies.get('refresh'), cookies.get('userId'), apiPath + 'banlist/public/' + id, requestOptions)
      .then(async (response) => {
        if (!response.ok) {
          const error = response.status;
          openErrorModal('Issue copying banlist template.<br />' +
            (error == 401 ? '<br />Try logging out and logging back in.<br />If this persists, ' + lowercaseContactString : '') +
            (error == 400 ? '<br />You have been inactive for a long time. Please log out and back in.<br /><br />If this persists, ' + lowercaseContactString : '') +
            (error != 401 && error != 400 ? '<br />' + contactString : ''));
          setBanlistLoading(false);
          return Promise.reject(error);
        }

        const data = await response.json();

        setBanlistIsPublic(true);
        setWhitelistDates([data.start_date !== undefined ? new Date(data.start_date) : DATE_MIN,
          data.end_date !== undefined ? new Date(data.end_date) : DATE_MAX]);
        populateListsFromIds(data.forbidden, data.limited, data.semi_limited, data.unlimited);
        setBanlistLoading(false);
        setListSavedOrExported(false);
      });
  }

  function getPublicBanlist(id: any) {
    if (id == '') return;
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    };
    setBanlistLoading(true);
    makeApiCall(cookies.get('refresh'), cookies.get('userId'), apiPath + 'banlist/public/' + id, requestOptions)
      .then(async (response) => {
        if (!response.ok) {
          const error = response.status;
          if (error == 404 || error == 501) openErrorModal('Issue retrieving public banlist.<br />This banlist may not be public or may not exist.');
          else {
            openErrorModal('Issue retrieving public banlist.<br />' +
            (error == 401 ? '<br />Try logging out and logging back in.<br />If this persists, ' + lowercaseContactString : '') +
            (error == 400 ? '<br />You have been inactive for a long time. Please log out and back in.<br /><br />If this persists, ' + lowercaseContactString : '') +
            (error != 401 && error != 400 ? '<br />' + contactString : ''));
          }
          setBanlistLoading(false);
          return Promise.reject(error);
        }

        const data = await response.json();

        setBanlistIsPublic(true);
        setWhitelistDates([data.start_date !== undefined ? new Date(data.start_date) : DATE_MIN,
          data.end_date !== undefined ? new Date(data.end_date) : DATE_MAX]);
        setCurrentBanlistName(data.name);
        populateListsFromIds(data.forbidden, data.limited, data.semi_limited, data.unlimited);
        setBanlistLoading(false);
        setListSavedOrExported(true);
      });
  }

  function getCurrentBanlist() {
    if (currentBanlist == '') return;
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + cookies.get('token') },
    };
    setBanlistLoading(true);
    makeApiCall(cookies.get('refresh'), cookies.get('userId'), apiPath + 'banlist/' + (currentBanlistGroup != '' ? (currentBanlistGroup + '/') : '') + currentBanlist, requestOptions)
      .then(async (response) => {
        if (!response.ok) {
          const error = response.status;
          openErrorModal('Issue retrieving banlist.<br />' +
            (error == 401 ? '<br />Try logging out and logging back in.<br />If this persists, ' + lowercaseContactString : '') +
            (error == 400 ? '<br />You have been inactive for a long time. Please log out and back in.<br /><br />If this persists, ' + lowercaseContactString : '') +
            (error != 401 && error != 400 ? '<br />' + contactString : ''));
          setBanlistLoading(false);
          return Promise.reject(error);
        }

        const data = await response.json();

        setBanlistIsPublic(data.public);
        setWhitelistDates([data.start_date !== undefined ? new Date(data.start_date) : DATE_MIN,
          data.end_date !== undefined ? new Date(data.end_date) : DATE_MAX]);
        setCurrentBanlistName(data.name);
        populateListsFromIds(data.forbidden, data.limited, data.semi_limited, data.unlimited);
        setBanlistLoading(false);
        setListSavedOrExported(true);
      });
  }

  function selectCurrentBanlist(id: string, group: string) {
    setCurrentBanlist(id);
    setCurrentBanlistGroup(group);
  }

  function onBanlistNameInput(e: any) {
    checkBanlistNameInvalid(e.target.value);
  }

  function checkBanlistNameInvalid(name: string) {
    if (name == '' || name.length > 80) { // Limit name
      setSaveAsDisabled(true);
      return true;
    }
    else {
      setSaveAsDisabled(false);
      return false;
    }
  }

  function saveAsNewBanlist() {
    const newName = (document.getElementById('banlist-saveas-name')! as HTMLInputElement).value;
    if (checkBanlistNameInvalid(newName)) return;
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + cookies.get('token') },
      body: JSON.stringify({
        name: newName,
        start_date: whitelistDateRange[0] > DATE_MIN ? whitelistDateRange[0] : null,
        end_date: whitelistDateRange[1] < DATE_MAX ? whitelistDateRange[1] : null,
        public: banlistIsPublic,
        forbidden: forbiddenList.map((c: DropCard) => c.id),
        limited: limitedList.map((c: DropCard) => c.id),
        semi_limited: semilimitedList.map((c: DropCard) => c.id),
        unlimited: unlimitedList.length > 0 ? unlimitedList.map((c: DropCard) => c.id) : null,
        group: selectedBanlistGroup,
      }),
    };
    setSavingList(true);
    makeApiCall(cookies.get('refresh'), cookies.get('userId'), apiPath + 'banlist', requestOptions)
      .then(async (response) => {
        setSaveAsDisabled(true);

        if (!response.ok) {
          const error = response.status;
          openErrorModal('Issue creating banlist.<br />' +
            (error == 401 ? '<br />Try logging out and logging back in.<br />If this persists, ' + lowercaseContactString : '') +
            (error == 400 ? '<br />You have been inactive for a long time. Please log out and back in.<br /><br />If this persists, ' + lowercaseContactString : '') +
            (error != 401 && error != 400 ? '<br />' + contactString : ''));
          setSavingList(false);
          return Promise.reject(error);
        }

        const data = await response.json();

        console.log(banlistsList);
        const newBanlistsList = [...banlistsList];
        newBanlistsList.find((banlistGroup: any) => banlistGroup.text == selectedBanlistGroup)!.subItems!.push({ value: data._id, text: newName } as DropdownOption); // We'll always have created the group first

        localforage.setItem('banlists', newBanlistsList).then(() => {
          updateBanlistsList(data._id);
          closeBanlistSaveAsModal();
          setCurrentBanlistName(newName);
          setCurrentBanlistGroup(selectedBanlistGroup);
          setSavingList(false);
          setListSavedOrExported(true);
        })
          .catch(() => {
            updateBanlistsList();
            openErrorModal('Issue saving banlists after creation.<br />' + contactString);
            setSavingList(false);
          });
      });
  }

  function renameCurrentBanlist() {
    const newName = (document.getElementById('banlist-rename-name')! as HTMLInputElement).value;
    if (checkBanlistNameInvalid(newName)) return;
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + cookies.get('token') },
      body: JSON.stringify({
        name: newName,
        start_date: whitelistDateRange[0] > DATE_MIN ? whitelistDateRange[0] : null,
        end_date: whitelistDateRange[1] < DATE_MAX ? whitelistDateRange[1] : null,
        public: banlistIsPublic,
        forbidden: forbiddenList.map((c: DropCard) => c.id),
        limited: limitedList.map((c: DropCard) => c.id),
        semi_limited: semilimitedList.map((c: DropCard) => c.id),
        unlimited: unlimitedList.length > 0 ? unlimitedList.map((c: DropCard) => c.id) : null,
      }),
    };
    setSavingList(true);
    makeApiCall(cookies.get('refresh'), cookies.get('userId'), apiPath + 'banlist/' + (currentBanlistGroup != '' ? (currentBanlistGroup + '/') : '') + currentBanlist, requestOptions)
      .then(async (response) => {
        if (!response.ok) {
          const error = response.status;
          openErrorModal('Issue renaming banlist.<br />' +
            (error == 401 ? '<br />Try logging out and logging back in.<br />If this persists, ' + lowercaseContactString : '') +
            (error == 400 ? '<br />You have been inactive for a long time. Please log out and back in.<br /><br />If this persists, ' + lowercaseContactString : '') +
            (error != 401 && error != 400 ? '<br />' + contactString : ''));
          setSavingList(false);
          return Promise.reject(error);
        }

        const newBanlistsList = [...banlistsList];
        newBanlistsList.find((banlistGroup: any) => banlistGroup.text == currentBanlistGroup)!
          .subItems!.find((deck: DropdownOption) => deck.value == currentBanlist)!.text = newName;

        localforage.setItem('banlists', newBanlistsList).then(() => {
          updateBanlistsList();
          closeBanlistRenameModal();
          setSaveAsDisabled(false);
          setCurrentBanlistName(newName);
          setSavingList(false);
          setListSavedOrExported(true);
        })
          .catch(() => {
            updateBanlistsList();
            openErrorModal('Issue saving banlists after rename.<br />' + contactString);
            setSaveAsDisabled(false);
            setCurrentBanlistName(newName);
            setSavingList(false);
          });
      });
  }

  function saveCurrentBanlist() {
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + cookies.get('token') },
      body: JSON.stringify({
        name: currentBanlistName,
        start_date: whitelistDateRange[0] > DATE_MIN ? whitelistDateRange[0] : null,
        end_date: whitelistDateRange[1] < DATE_MAX ? whitelistDateRange[1] : null,
        public: banlistIsPublic,
        forbidden: forbiddenList.map((c: DropCard) => c.id),
        limited: limitedList.map((c: DropCard) => c.id),
        semi_limited: semilimitedList.map((c: DropCard) => c.id),
        unlimited: unlimitedList.length > 0 ? unlimitedList.map((c: DropCard) => c.id) : null,
      }),
    };
    setSavingList(true);
    makeApiCall(cookies.get('refresh'), cookies.get('userId'), apiPath + 'banlist/' + (currentBanlistGroup != '' ? (currentBanlistGroup + '/') : '') + currentBanlist, requestOptions)
      .then(async (response) => {
        setSavingList(false);

        if (!response.ok) {
          const error = response.status;
          openErrorModal('Issue saving banlist.<br />' +
            (error == 401 ? '<br />Try logging out and logging back in.<br />If this persists, ' + lowercaseContactString : '') +
            (error == 400 ? '<br />You have been inactive for a long time. Please log out and back in.<br /><br />If this persists, ' + lowercaseContactString : '') +
            (error != 401 && error != 400 ? '<br />' + contactString : ''));
          return Promise.reject(error);
        }
        setListSavedOrExported(true);
      });
  }

  function togglePublic() {
    const newPublic = !banlistIsPublic;
    if (currentBanlist != '') {
      const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + cookies.get('token') },
        body: JSON.stringify({
          public: newPublic,
        }),
      };
      setSavingList(true);
      makeApiCall(cookies.get('refresh'), cookies.get('userId'), apiPath + 'banlist/' + (currentBanlistGroup != '' ? (currentBanlistGroup + '/') : '') + currentBanlist + '/public', requestOptions)
        .then(async (response) => {
          setSavingList(false);

          if (!response.ok) {
            const error = response.status;
            openErrorModal('Issue toggling banlist permission.<br />' +
              (error == 401 ? '<br />Try logging out and logging back in.<br />If this persists, ' + lowercaseContactString : '') +
              (error == 400 ? '<br />You have been inactive for a long time. Please log out and back in.<br /><br />If this persists, ' + lowercaseContactString : '') +
              (error != 401 && error != 400 ? '<br />' + contactString : ''));
            return Promise.reject(error);
          }
        });
    }
    setBanlistIsPublic(newPublic);
  }

  function deleteCurrentBanlist() {
    const requestOptions = {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + cookies.get('token') },
    };
    setSavingList(true);
    setSaveAsDisabled(true);
    makeApiCall(cookies.get('refresh'), cookies.get('userId'), apiPath + 'banlist/' + (currentBanlistGroup != '' ? (currentBanlistGroup + '/') : '') + currentBanlist, requestOptions)
      .then(async (response) => {
        if (!response.ok) {
          const error = response.status;
          openErrorModal('Issue deleting banlist.<br />' +
            (error == 401 ? '<br />Try logging out and logging back in.<br />If this persists, ' + lowercaseContactString : '') +
            (error == 400 ? '<br />You have been inactive for a long time. Please log out and back in.<br /><br />If this persists, ' + lowercaseContactString : '') +
            (error != 401 && error != 400 ? '<br />' + contactString : ''));
          setSavingList(false);
          return Promise.reject(error);
        }

        const newBanlistsList = [...banlistsList];
        const group = newBanlistsList.find((banlistGroup: any) => banlistGroup.text == currentBanlistGroup)!;
        group.subItems = group.subItems!.filter((banlist: DropdownOption) => banlist.value != currentBanlist);

        setCurrentBanlist('');
        setCurrentBanlistName('');
        setCurrentBanlistGroup('');
        setBanlistIsPublic(false);
        setBanlistsList(newBanlistsList);
        setConfirmMatchString('');

        localforage.setItem('banlists', newBanlistsList).then(() => {
          updateBanlistsList();
          setSavingList(false);
          setListSavedOrExported(true);
        })
          .catch(() => {
            updateBanlistsList();
            openErrorModal('Issue saving after deleting banlist.<br />' + contactString);
            setSavingList(false);
          });
      });
  }

  function openDeleteBanlistConfirm() {
    setConfirmMatchString('DELETE'); // after the set finishes the useffect opens modal
  }

  function showNavCheckModal(location: any) {
    if (!confirmedNavigation) { // Need this gate, otherwise when they confirm they'll just infinitely keep popping the dialog up
      setNavToLocation(location);
      document.getElementById('navCheckModal')!.style.display = 'flex';
      return false;
    }

    return true;
  }

  function closeNavCheckModal() {
    setNavToLocation(null);
    document.getElementById('navCheckModal')!.style.display = 'none';
  }

  function acceptNavCheckModal() {
    setConfirmedNavigation(true); // Will then trigger nav on callback from setState
  }

  function openNewGroupModal() {
    document.getElementById('create-group-modal')!.style.display = 'flex';
  }

  function saveNewBanlistsList(newList: DropdownOption[], newGroup: string) {
    localforage.setItem('banlists', newList).then(() => {
      updateBanlistsList();
    })
      .catch(() => {
        openErrorModal('Issue saving groups.<br />' + contactString);
      });
  }

  async function moveAllFromZone(fromZone: string, toZone: string) {
    setBanlistLoading(true);

    setTimeout(() => {
      let fromZoneCards: DropCard[] = [];
      if (fromZone == 'semilimited') {
        fromZoneCards = [...semilimitedList];
        setSemilimitedList([]);
      }
      else if (fromZone == 'limited') {
        fromZoneCards = [...limitedList];
        setLimitedList([]);
      }
      else if (fromZone == 'forbidden') {
        fromZoneCards = [...forbiddenList];
        setForbiddenList([]);
      }
      else { // Unlimited
        fromZoneCards = [...unlimitedList];
        setUnlimitedList([]);
      }

      const newBanlistData = binnedBanlistData;
      if (toZone == 'semilimited') {
        const newSemilimited = [...semilimitedList, ...fromZoneCards];
        for (const card of fromZoneCards) {
          newBanlistData[card.id] = 2;
        }
        setSemilimitedList(newSemilimited);
      }
      else if (toZone == 'limited') {
        const newLimited = [...limitedList, ...fromZoneCards];
        for (const card of fromZoneCards) {
          newBanlistData[card.id] = 1;
        }
        setLimitedList(newLimited);
      }
      else if (toZone == 'forbidden') {
        const newForbidden = [...forbiddenList, ...fromZoneCards];
        for (const card of fromZoneCards) {
          newBanlistData[card.id] = 0;
        }
        setForbiddenList(newForbidden);
      }
      else { // Unlimited
        const newUnlimited = [...unlimitedList, ...fromZoneCards];
        for (const card of fromZoneCards) {
          newBanlistData[card.id] = 3;
        }
        setUnlimitedList(newUnlimited);
      }
      setBinnedBanlistData(newBanlistData);

      setListSavedOrExported(false);
      setBanlistLoading(false);
    }, 500);
  }

  function getGridWidth() {
    // if (isScreen950) return 5;
    if (isScreen1150) return 6;
    else if (isScreen1300) return 8;
    else return 10;
  }

  const checkCardAllowedRef = useRef<any>();
  checkCardAllowedRef.current = checkCardAllowed;
  const moveCardRef = useRef<any>();
  moveCardRef.current = moveCard;
  const removeCardRef = useRef<any>();
  removeCardRef.current = removeCard;

  // const dndBackend: BackendFactory = isTouchDevice() ? TouchBackend : HTML5Backend;

  if (cardsAreLoaded) {
    return ( // I should use useReducer here like I do in PackOpener, but I think this is honestly cleaner since I need the Refs
      <BuilderContext.Provider value={{ moveCard: moveCardRef, removeCard: removeCardRef, checkCardAllowed: checkCardAllowedRef, dragTask: dragTask,
        setCurrentDragCard: setCurrentDragCard, clearCurrentDragCard: clearCurrentDragCard, setCurrentDragCardDropCallback: setCurrentDragCardDropCallback,
        currentDragCard: currentDragCard, dragLock: dragLock, zoneCalcShifts: zoneCalcShifts, builderType: 'b' }}>
        <Helmet>
          <meta content="YGO Prog - Banlist Builder" property="og:title" />
          <meta
            content="YGO Prog's Banlist Builder lets you create, manage, and export custom Yu-Gi-Oh! banlist files for EDOPRO and YGO Omega, or as images and spreadsheets for other uses!"
            property="og:description"
          />
          <meta content="https://www.ygoprog.com/BanlistBuilder" property="og:url" />
          <meta
            name="description"
            content="YGO Prog's Banlist Builder lets you create, manage, and export custom Yu-Gi-Oh! banlist files for EDOPRO and YGO Omega, or as images and spreadsheets for other uses!"
          />
          <title>YGO Prog - Banlist Builder</title>
        </Helmet>
        <main>
          <DndProvider options={HTML5toTouch as any}>
            <DeleteConfirmModal {...{
              id: 'delete-confirm',
              title: 'Confirm Banlist Delete',
              matchString: confirmMatchString,
              callback: deleteCurrentBanlist,
              clearMatch: setConfirmMatchString,
              preConfirmStatement: '',
            }} />
            <div id={'navCheckModal'} className='modal'>
              <button className='modal-close' title='Close modal' onClick={closeNavCheckModal} />
              <div className='content-box modal-content'>
                <h5 style={{ marginBottom: '0px' }}>Leaving Without Saving</h5>
                <p className='largetext' style={{ paddingTop: '5px', marginBottom: '0px' }}>You have not saved or exported changes to your banlist,<br />if you leave this page they will be lost.</p>
                <button className='black-button white-button' style={{ marginRight: isScreen1150 ? '5px' : '7px' }} title='Leave page' onClick={acceptNavCheckModal}><span>Leave</span></button>
                <button className='black-button' style={{ marginRight: isScreen1150 ? '5px' : '7px' }} title='Stay on page' onClick={closeNavCheckModal}><span>Stay</span></button>
              </div>
            </div>

            <PerformanceWarningModal {...{
              text: 'This action will add a large number of cards to the banlist,<br />and may cause performance issues on some machines.',
              callback: addAllResultsToList,
            }} />

            {exportUrl.exportString != '' ? <StringExportModal {...{
              id: 'url-export-modal',
              exportStringObj: exportUrl,
              dispatch: () => setExportUrl({ type: 'YDKe', exportString: '' }),
            }} /> : ''}

            <div id={'export-with-binder-modal'} className='modal' style={{ display: 'none' }}>
              <button className='modal-close' title='Close modal' onClick={closeExportWithBinderModal} />
              <div className='content-box modal-content' style={{ top: '15%', padding: '0px', paddingTop: '10px', paddingBottom: '10px' }}>
                <h5 id='basic-modal-title' style={{ marginBottom: '0px' }}>Export With Binder Limits To Lflist</h5>
                <p id='basic-modal-text' style={{ textAlign: 'center', padding: '7px 15px' }}>Select a binder to combine its limits with the current banlist and export:</p>
                <BlackDropdown {...{
                  uniqueID: 'binder-select',
                  isSelector: true,
                  disabledVar: false,
                  optionsList: binderList,
                  startingOption: null,
                  defaultValue: '',
                  defaultDisplay: 'Select Binder...',
                  nonSelectorDisplay: '',
                  width: '200px',
                  stateVar: '',
                  dispatch: exportWithBinder,
                  title: 'Select binder to combine with banlist',
                  style: { marginRight: isScreen1150 ? '5px' : '7px' },
                  ulStyle: { width: '100%' },
                }} />
              </div>
            </div>

            <SaveAsGroupModal {...{ id: 'create-group-modal', isRename: false, groupName: currentBanlistGroup, list: banlistsList, updateListDispatch: saveNewBanlistsList }} />
            {groupManagementOpen ? <GroupManagementWindow {...{
              id: 'group-management',
              titleCaseSingularType: 'Banlist',
              visibleStateDispatch: setGroupManagementOpen,
              storageName: 'banlists',
              list: banlistsList,
              updateListDispatch: updateBanlistsList,
              currentItem: currentBanlist,
              updateCurrentItemDispatch: setCurrentBanlist,
              currentGroup: currentBanlistGroup,
              updateCurrentGroupDispatch: setCurrentBanlistGroup,
              openNewGroupModal: openNewGroupModal,
              isScreen950: isScreen950,
            }} /> : ''}

            <Prompt
              when={!listSavedOrExported && search == ''}
              message={showNavCheckModal}
            />

            {exportingImage ? <ZonesImageExport {...{
              id: 'img-export',
              listName: currentBanlistName != '' ? currentBanlistName :
                'Custom Forbidden & Limited Lists',
              zoneNames: ['Forbidden', 'Limited', 'Semi-Limited', ...(unlimitedList.length > 0 ? ['Unlimited'] : [])],
              zoneStyleClassNames: ['forbidden-box', 'limited-box', 'semilimited-box', ...(unlimitedList.length > 0 ? ['unlimited-box'] : [])],
              allowedPrintings: whitelistDateRange,
              zoneLists: [forbiddenList, limitedList, semilimitedList, ...(unlimitedList.length > 0 ? [unlimitedList] : [])],
              finishedDispatch: setExportingImage,
              builderType: 'Banlist Builder',
            }} /> : ''}

            <div id='banlist-rename-modal' className='modal'>
              <button className='modal-close' title='Close modal' onClick={closeBanlistRenameModal} />
              <div className='content-box modal-content'>
                <h5 style={{ marginBottom: '0px' }}>Rename and Save Banlist</h5>
                <div className='vertical-form-text-input'>
                  <label htmlFor='banlist-rename-name'>Name:</label>
                  <div className='text-input'><input id='banlist-rename-name' type='text' onInput={onBanlistNameInput} onKeyDown={(e) => {if (e.code === 'Enter') renameCurrentBanlist();}} /></div>
                </div>
                {savingList ?
                  <img src={'/images/LoadingAnim.png'} width='30px' height='30px' style={{ margin: 'auto', verticalAlign: 'middle', marginRight: '7px' }} /> :
                  <div>
                    <button className='black-button' style={{ marginRight: isScreen1150 ? '5px' : '7px' }} title='Rename banlist and save (overwrites current banlist)'
                      onClick={renameCurrentBanlist} disabled={currentBanlist == '' || saveAsDisabled || typeof(username) == 'undefined'}><span>Rename and Save</span></button>
                  </div>}
              </div>
            </div>

            <div id='banlist-saveas-modal' className='modal'>
              <button className='modal-close' title='Close modal' onClick={closeBanlistSaveAsModal} />
              <div className='content-box modal-content'>
                <h5 style={{ marginBottom: '0px' }}>Save Banlist As New</h5>
                <div className='vertical-form-text-input' style={{ textAlign: 'center' }}>
                  <label htmlFor='banlist-saveas-name'>Name:</label>
                  <div className='text-input'><input id='banlist-saveas-name' type='text' onInput={onBanlistNameInput} onKeyDown={(e) => {if (e.code === 'Enter') saveAsNewBanlist();}} /></div>
                </div>
                <div className='vertical-form-text-input'>
                  <label>Group:</label>
                  <BlackDropdown {...{
                    uniqueID: 'saveas-group-select',
                    isSelector: true,
                    disabledVar: false,
                    optionsList: banlistGroupsList,
                    startingOption: selectedBanlistGroup != '' ? {
                      value: selectedBanlistGroup,
                      text: selectedBanlistGroup,
                    } as DropdownOption : null,
                    defaultValue: '',
                    defaultDisplay: 'None',
                    nonSelectorDisplay: '',
                    width: '170px',
                    stateVar: selectedBanlistGroup,
                    dispatch: setSelectedBanlistGroup,
                    title: 'Select group to create banlist in',
                    style: { marginRight: '5px' },
                    firstOptionItalic: true,
                  }} />
                  <button className='black-button text-icon-button' title='Add new banlist group' onClick={openNewGroupModal} style={{ marginRight: '0px' }}>
                    <img src={imagePath + 'BigPlus.png'} width='18px' height='20px' style={{ margin: 'auto', marginLeft: '4px' }} />
                    <span style={{ marginLeft: '5px', marginRight: '5px' }}>New</span>
                  </button>
                </div>
                {savingList ?
                  <img src={'/images/LoadingAnim.png'} width='30px' height='30px' style={{ margin: 'auto', verticalAlign: 'middle', marginRight: isScreen1150 ? '5px' : '7px' }} /> :
                  <div>
                    <button className='black-button' style={{ marginRight: isScreen1150 ? '5px' : '7px' }} title='Create new banlist'
                      onClick={saveAsNewBanlist} disabled={saveAsDisabled || typeof(username) == 'undefined'}><span>Save As New</span></button>
                  </div>}
              </div>
            </div>

            <Ad styleName='bar_ad' />
            <Ad styleName='left_sidebar_ad_1400' />
            <Ad styleName='right_sidebar_ad_1400' />

            <div style={{ display: 'flex', margin: '0px auto 0px auto', alignItems: 'center' }}>
              <div style={{ margin: '0px auto 0px auto' }}>
                <h1 style={{ display: 'inline-flex', marginRight: isScreen1150 ? '5px' : '7px', padding: '0px' }}>Banlist Builder</h1>
                {/* <img src={imagePath + 'Beta.png'} width='38px' height='20px' style={{ display: 'inline-flex', position: 'relative', left: '0px' }} /> */}
              </div>
            </div>

            <p className='content-box' style={{ marginTop: '7px', marginBottom: '30px', textAlign: 'left' }}>
              Banlists in .lflist.conf format may be used in digital clients like YGO Omega and EDOPro, simply export and place the file in the appropriate folder:<br />
              <b>YGO Omega-</b> &#91;Your install location&#93;\Duelists Unite\YGO Omega\YGO Omega_Data\Files\Banlists<br />
              <b>EDOPro-</b> &#91;Your install location&#93;\ProjectIgnis\lflists
            </p>

            <div className='builder-wrapper'>
              <div className='builder-left'>
                <div style={{ marginBottom: isScreen1150 ? '5px' : '7px' }}>
                  <span className='button-bar-label'>Banlist:</span>
                  <BlackDropdown {...{
                    uniqueID: 'banlist-select',
                    isSelector: true,
                    disabledVar: typeof(username) == 'undefined',
                    optionsList: banlistsList,
                    startingOption: null,
                    defaultValue: '',
                    defaultDisplay: 'Select...',
                    nonSelectorDisplay: '',
                    width: '200px',
                    stateVar: currentBanlist,
                    dispatch: selectCurrentBanlist,
                    title: 'Select banlist to view/edit',
                    style: { marginRight: isScreen1150 ? '5px' : '7px' },
                    ulStyle: { width: '100%' },
                    stateGroupVar: currentBanlistGroup,
                  }} />
                  {savingList ?
                    <img src={'/images/LoadingAnim.png'} width='30px' height='30px' style={{ margin: 'auto', verticalAlign: 'middle', marginRight: isScreen1150 ? '5px' : '7px' }} /> :
                    <button className='black-button text-icon-button' title='Manage banlists & groups' disabled={banlistLoading || typeof(username) == 'undefined'}
                      onClick={() => setGroupManagementOpen(true)} style={{ marginRight: isScreen1150 ? '5px' : '7px' }}>
                      <img src={imagePath + 'Tools.png'} width='18px' height='20px' style={{ margin: 'auto', marginLeft: '4px' }} />
                      <span style={{ marginLeft: '5px', marginRight: '5px' }}>Manage</span>
                    </button>}

                  {isScreen1150 ? <div style={{ marginBottom: '5px' }} /> : ''}

                  {savingList ?
                    <img src={'/images/LoadingAnim.png'} width='30px' height='30px' style={{ margin: 'auto', verticalAlign: 'middle', marginRight: isScreen1150 ? '5px' : '7px' }} /> :
                    <button className='black-button text-icon-button' title='Save banlist (overwrites current banlist)' disabled={currentBanlist == '' || banlistLoading || typeof(username) == 'undefined'}
                      onClick={saveCurrentBanlist} style={{ marginRight: isScreen1150 ? '5px' : '7px' }}>
                      <img src={imagePath + 'Save.png'} width='18px' height='20px' style={{ margin: 'auto', marginLeft: '4px' }} />
                      <span style={{ marginLeft: '5px', marginRight: '5px' }}>Save</span>
                    </button>}

                  {savingList ?
                    <img src={'/images/LoadingAnim.png'} width='30px' height='30px' style={{ margin: 'auto', verticalAlign: 'middle', marginRight: isScreen1150 ? '5px' : '7px' }} /> :
                    <button className='black-button text-icon-button' title='Rename and save banlist (overwrites current banlist)' onClick={openBanlistRenameModal}
                      style={{ marginRight: isScreen1150 ? '5px' : '7px' }} disabled={currentBanlist == '' || saveAsDisabled || typeof(username) == 'undefined'}>
                      <img src={imagePath + 'Edit.png'} width='18px' height='20px' style={{ margin: 'auto', marginLeft: '4px' }} />
                      <span style={{ marginLeft: '5px', marginRight: '5px' }}>Rename</span>
                    </button>}

                  {savingList ?
                    <img src={'/images/LoadingAnim.png'} width='30px' height='30px' style={{ margin: 'auto', verticalAlign: 'middle', marginRight: isScreen1150 ? '5px' : '7px' }} /> :
                    <button className='black-button text-icon-button' title='Save as new banlist' onClick={openBanlistSaveAsModal}
                      style={{ marginRight: isScreen1150 ? '5px' : '7px' }} disabled={banlistLoading || typeof(username) == 'undefined'}>
                      <img src={imagePath + 'BigPlus.png'} width='18px' height='20px' style={{ margin: 'auto', marginLeft: '4px' }} />
                      <span style={{ marginLeft: '5px', marginRight: '5px' }}>Save As New</span>
                    </button>}

                  {savingList ?
                    <img src={'/images/LoadingAnim.png'} width='30px' height='30px' style={{ margin: 'auto', verticalAlign: 'middle', marginRight: isScreen1150 ? '5px' : '7px' }} /> :
                    <button className='black-button text-icon-button' title='Delete current banlist' disabled={currentBanlist == '' || banlistLoading || typeof(username) == 'undefined'}
                      onClick={openDeleteBanlistConfirm} style={{ marginRight: isScreen1150 ? '5px' : '7px' }}>
                      <img src={imagePath + 'Trashcan.png'} width='18px' height='20px' style={{ margin: 'auto', marginLeft: '4px' }} />
                      <span style={{ marginLeft: '5px', marginRight: '5px' }}>Delete</span>
                    </button>}

                  {isScreen950 ? <div style={{ marginBottom: '5px' }} /> : ''}
                  <div className={'checkbox' + (savingList || banlistLoading || typeof(username) == 'undefined' ? ' checkbox-disabled' : '')} title='Allow banlist to be shared via link'
                    onClick={togglePublic} style={{ marginRight: '5px' }} >
                    <img className='checkbox-checkmark' src={imagePath + 'CheckmarkBlack.png'} width='20px' height='20px' style={{ display: (banlistIsPublic ? 'inline-block' : 'none') }} />
                  </div>
                  <span className={'button-bar-label' + (savingList || banlistLoading || typeof(username) == 'undefined' ? ' label-disabled' : '')}>Public</span>
                  <span className='save-warning' style={{ display: typeof(username) == 'undefined' ? 'inline-flex' : 'none' }}>Must be logged in to save banlists.</span>
                </div>

                <div style={{ marginBottom: isScreen1150 ? '10px' : '20px' }}>
                  <span className='button-bar-label'>Copy Template:</span>
                  <BlackDropdown {...{
                    uniqueID: 'template-select',
                    isSelector: false,
                    disabledVar: false,
                    optionsList: templatesList,
                    startingOption: null,
                    defaultValue: '',
                    defaultDisplay: '',
                    nonSelectorDisplay: <div style={{ width: '100%', display: 'inline-flex', alignItems: 'center',
                      justifyContent: 'space-between', padding: isScreen1150 ? '3px 5px 3px 5px' : '4px 7px 4px 7px' }}>
                      <span id={'template-select-dropdown-label'} className={'template-select-click'}>Select A Template</span>
                      <img id={'template-select-dropdown-arrow'} className={'black-dropdown-arrow template-select-click'} src={imagePath + 'DownArrowWhite.png'} width='14px' height='14px' />
                    </div>,
                    width: '200px',
                    stateVar: '',
                    dispatch: null,
                    title: 'Select banlist template to copy',
                    style: { marginRight: isScreen1150 ? '5px' : '7px' },
                    ulStyle: { width: '100%' },
                  }} />
                </div>

                <h5 style={{ margin: '0px 10px -5px 0px', textAlign: 'left', display: 'inline-block', fontSize: '43px' }}>
                  {currentBanlistName != '' ? currentBanlistName : 'New Banlist'}
                </h5>
                <div style={{ display: 'flex', width: '100%' }}>
                  <h5 style={{ margin: '0px 10px -3px 0px', textAlign: 'left', display: 'inline-block' }}>Forbidden</h5>
                  {banlistLoading ?
                    <img src={'/images/LoadingAnim.png'} width='40px' height='40px' style={{ margin: 'auto 0px auto 0px', verticalAlign: 'middle', display: 'inline-block' }} /> : ''}

                  {isScreen950 ? '' : <div className='tooltip' style={{ display: 'inline-block', alignSelf: 'center', marginLeft: 'auto' }}>
                    <img src={imagePath + 'Info.png'} width='30px' height='30px' style={{ verticalAlign: 'middle' }} />
                    <span className='button-bar-label' style={{ marginRight: '0px', paddingTop: '1px' }}>Hotkeys</span>
                    <ul className='tooltip-text' style={{ width: '250px', listStylePosition: 'inside', textIndent: '-18px', paddingLeft: '25px' }}>
                      <span style={{ display: 'block', textIndent: '0px', marginLeft: '-18px' }}>Hotkeys in search results:</span>
                      <li><span>Right-Click: Add card to Forbidden</span></li>
                      <li><span>Shift + Right-Click: Add card to Limited</span></li>
                      <li><span>Ctrl + Right-Click: Add card to Semi-Limited</span></li>
                      <li><span>Alt + Right-Click: Add card to Unlimited</span></li>
                      <li><span>Shift + Ctrl + Right-Click: Remove card from banlist</span></li>
                      <br />
                      <span style={{ display: 'block', textIndent: '0px', marginLeft: '-18px' }}>Hotkeys in banlist zones:</span>
                      <li><span>Right-Click: Remove card</span></li>
                      <li><span>Shift + Right-Click: Move card 1 zone up (ex. Limited-&gt;Forbidden)</span></li>
                      <li><span>Ctrl + Right-Click: Move card 1 zone down (ex. Forbidden-&gt;Limited)</span></li>
                    </ul>
                  </div>}
                </div>

                <div className='drop-zone-controls banlist-builder-controls'>
                  <div className='grid-left'>
                    <span className='button-bar-label'>Allowed Printings:</span>
                    {isScreen950 ? <div style={{ marginBottom: '3px' }} /> : ''}
                    <div className='input-box grid-left' style={isScreen1300 ? { marginTop: isScreen950 ? '0px' : '3px' } : {}}>
                      <input type='date' id='whitelist-start-date' disabled={banlistLoading} placeholder={'Min Date'} name='MinPrintDate' onInput={whitelistRangeOnChange}
                        style={{ width: '110px', border: '1px solid #1E1E1E', borderRadius: '4px', color: whitelistDateRange[0] > DATE_MIN ? '#1E1E1E' : '#777' }} />
                    </div>
                    <b className='middle-margin grid-middle button-bar-label' style={{ paddingBottom: '0px' }}>—</b>
                    <div className='input-box grid-right' style={isScreen1300 ? { marginTop: '3px' } : {}}>
                      <input type='date' id='whitelist-end-date' disabled={banlistLoading} placeholder={'Max Date'} name='MaxPrintDate' onInput={whitelistRangeOnChange}
                        style={{ width: '110px', border: '1px solid #1E1E1E', borderRadius: '4px', color: whitelistDateRange[1] < DATE_MAX ? '#1E1E1E' : '#777' }} />
                    </div>
                  </div>
                  {isScreen1300 ? '' : <div className='grid-middle' />}
                  <div className={(isScreen1300 ? 'grid-left grid-row2' : 'grid-right')} style={{ marginTop: '0px', zIndex: 3 }}>
                    <button className='black-button text-icon-button' title='Import banlist from .lflist.conf' disabled={banlistLoading} onClick={uploadLflist}
                      style={{ marginRight: isScreen1150 ? '5px' : '7px' }}>
                      <img src={imagePath + 'Import.png'} width='18px' height='20px' style={{ margin: 'auto', marginLeft: '4px' }} />
                      <span style={{ marginLeft: '5px', marginRight: '5px' }}>Import</span>
                      <input type='file' id='lflist-import-field' accept='.lflist.conf' onChange={importFromLflist} style={{ display: 'none' }} />
                    </button>
                    <BlackDropdown {...{
                      uniqueID: 'export-banlist',
                      isSelector: false,
                      disabledVar: banlistLoading,
                      optionsList: [{ value: 'lflist', text: 'Export to .lflist.conf', callback: exportToLflist },
                        ...(cookies.get('user') !== undefined ? [{ value: 'binderlflist', text: 'Export with Binder to .lflist.conf', callback: openExportWithBinderModal }] : []),
                        { value: 'csv', text: 'Export to basic .csv', callback: exportToCsv },
                        ...(currentBanlist != '' && banlistIsPublic ? [{ value: 'url', text: 'Export to Shareable Link', callback: exportToUrl }] : []),
                        { value: 'png', text: 'Export to .png', callback: () => setExportingImage(true) }],
                      startingOption: null,
                      defaultValue: '',
                      defaultDisplay: '',
                      nonSelectorDisplay: <div className='text-icon-button'>
                        <img src={imagePath + 'Export.png'} width='18px' height='20px' style={{ margin: 'auto', marginLeft: '4px' }} />
                        <span style={{ marginLeft: '5px', marginRight: '5px' }}>Export</span>
                      </div>,
                      width: 'initial',
                      stateVar: '',
                      dispatch: null,
                      title: 'Export banlist',
                      style: { marginRight: isScreen1150 ? '5px' : '7px' },
                    }}/>
                    {isScreen950 ? <div style={{ marginBottom: '5px' }} /> : ''}
                    <div className='right-controls'>
                      <button className='black-button text-icon-button' title='Sort banlist by type' disabled={banlistLoading} onClick={sortLists}
                        style={{ marginRight: isScreen1150 ? '5px' : '7px' }}>
                        <img src={imagePath + 'Sort.png'} width='18px' height='18px' style={{ margin: 'auto', marginLeft: '4px' }} />
                        <span style={{ marginLeft: '5px', marginRight: '5px' }}>Sort</span>
                      </button>
                      <button className='black-button text-icon-button' title='Clear banlist' disabled={banlistLoading} onClick={clearBanlist}
                        style={{ marginRight: isScreen1150 ? '5px' : '7px' }}>
                        <img src={imagePath + 'Broom.png'} width='18px' height='18px' style={{ margin: 'auto', marginLeft: '4px' }} />
                        <span style={{ marginLeft: '5px', marginRight: '5px' }}>Clear</span>
                      </button>
                      <button className='black-button text-icon-button' title='Reset to last saved state' disabled={banlistLoading} onClick={resetBanlist}
                        style={{ marginRight: '0px' }}>
                        <img src={imagePath + 'Reset.png'} width='18px' height='18px' style={{ margin: 'auto', marginLeft: '4px' }} />
                        <span style={{ marginLeft: '5px', marginRight: '5px' }}>Reset</span>
                      </button>
                    </div>
                  </div>
                </div>
                <DropZone
                  {...{
                    zone: 'sf',
                    zoneListRef: forbiddenListRef,
                    zoneList: forbiddenList,
                    zoneMax: 9000000000000000,
                    gridWidth: getGridWidth(),
                    minimize: minimizeForbiddenList,
                    styleClassName: 'forbidden-box',
                  }}
                />
                <div className='' style={{ width: '100%' }}>
                  <BlackDropdown {...{
                    uniqueID: 'forbidden-move',
                    isSelector: false,
                    disabledVar: banlistLoading,
                    optionsList: [{ value: 'limited', text: 'Move All To Limited', callback: () => moveAllFromZone('forbidden', 'limited') },
                      { value: 'semilimited', text: 'Move All To Semi-Limited', callback: () => moveAllFromZone('forbidden', 'semilimited') },
                      { value: 'unlimited', text: 'Move All To Unlimited', callback: () => moveAllFromZone('forbidden', 'unlimited') }],
                    startingOption: null,
                    defaultValue: '',
                    defaultDisplay: '',
                    nonSelectorDisplay: <div className='text-icon-button'>
                      <img src={imagePath + 'Swap.png'} width='18px' height='20px' style={{ margin: 'auto', marginLeft: '4px' }} />
                      <span style={{ marginLeft: '5px', marginRight: '5px' }}>Move All</span>
                    </div>,
                    width: 'initial',
                    stateVar: '',
                    dispatch: null,
                    title: 'Move all cards in Forbidden to another zone',
                    style: { margin: '0px 0px 0px 0px' },
                  }} />
                  <img className='dropzone-arrow' src={imagePath + 'DownArrow.png'} width='30px' height='30px' style={{ transform: minimizeForbiddenList ? 'none' : 'scaleY(-1)' }}
                    title='Collapse/Expand Forbidden zone' onClick={() => setMinimizeForbiddenList((prev: boolean) => !prev)} />
                </div>

                <h5 style={{ marginTop: '10px', marginBottom: '5px', textAlign: 'left' }}>Limited</h5>
                <DropZone
                  {...{
                    zone: 'sl',
                    zoneListRef: limitedListRef,
                    zoneList: limitedList,
                    zoneMax: 9000000000000000,
                    gridWidth: getGridWidth(),
                    minimize: minimizeLimitedList,
                    styleClassName: 'limited-box',
                  }}
                />
                <div className=''>
                  <BlackDropdown {...{
                    uniqueID: 'limited-move',
                    isSelector: false,
                    disabledVar: banlistLoading,
                    optionsList: [{ value: 'forbidden', text: 'Move All To Forbidden', callback: () => moveAllFromZone('limited', 'forbidden') },
                      { value: 'semilimited', text: 'Move All To Semi-Limited', callback: () => moveAllFromZone('limited', 'semilimited') },
                      { value: 'unlimited', text: 'Move All To Unimited', callback: () => moveAllFromZone('limited', 'unlimited') }],
                    startingOption: null,
                    defaultValue: '',
                    defaultDisplay: '',
                    nonSelectorDisplay: <div className='text-icon-button'>
                      <img src={imagePath + 'Swap.png'} width='18px' height='20px' style={{ margin: 'auto', marginLeft: '4px' }} />
                      <span style={{ marginLeft: '5px', marginRight: '5px' }}>Move All</span>
                    </div>,
                    width: 'initial',
                    stateVar: '',
                    dispatch: null,
                    title: 'Move all cards in Limited to another zone',
                    style: { margin: '0px 0px 0px 0px' },
                  }} />
                  <img className='dropzone-arrow' src={imagePath + 'DownArrow.png'} width='30px' height='30px' style={{ transform: minimizeLimitedList ? 'none' : 'scaleY(-1)' }}
                    title='Collapse/Expand Limited zone' onClick={() => setMinimizeLimitedList((prev: boolean) => !prev)} />
                </div>

                <h5 style={{ marginTop: '10px', marginBottom: '5px', textAlign: 'left' }}>Semi-Limited</h5>
                <DropZone
                  {...{
                    zone: 'ss',
                    zoneListRef: semilimitedListRef,
                    zoneList: semilimitedList,
                    zoneMax: 9000000000000000,
                    gridWidth: getGridWidth(),
                    minimize: minimizeSemilimitedList,
                    styleClassName: 'semilimited-box',
                  }}
                />
                <div className=''>
                  <BlackDropdown {...{
                    uniqueID: 'semilimited-move',
                    isSelector: false,
                    disabledVar: banlistLoading,
                    optionsList: [{ value: 'forbidden', text: 'Move All To Forbidden', callback: () => moveAllFromZone('semilimited', 'forbidden') },
                      { value: 'limited', text: 'Move All To Limited', callback: () => moveAllFromZone('semilimited', 'limited') },
                      { value: 'unlimited', text: 'Move All To Unlimited', callback: () => moveAllFromZone('semilimited', 'unlimited') }],
                    startingOption: null,
                    defaultValue: '',
                    defaultDisplay: '',
                    nonSelectorDisplay: <div className='text-icon-button'>
                      <img src={imagePath + 'Swap.png'} width='18px' height='20px' style={{ margin: 'auto', marginLeft: '4px' }} />
                      <span style={{ marginLeft: '5px', marginRight: '5px' }}>Move All</span>
                    </div>,
                    width: 'initial',
                    stateVar: '',
                    dispatch: null,
                    title: 'Move all cards in Semi-Limited to another zone',
                    style: { margin: '0px 0px 0px 0px' },
                  }} />
                  <img className='dropzone-arrow' src={imagePath + 'DownArrow.png'} width='30px' height='30px' style={{ transform: minimizeSemilimitedList ? 'none' : 'scaleY(-1)' }}
                    title='Collapse/Expand Semi-Limited zone' onClick={() => setMinimizeSemilimitedList((prev: boolean) => !prev)} />
                </div>

                <h5 style={{ marginTop: '10px', marginBottom: '5px', textAlign: 'left' }}>Unlimited</h5>
                <DropZone
                  {...{
                    zone: 'su',
                    zoneListRef: unlimitedListRef,
                    zoneList: unlimitedList,
                    zoneMax: 9000000000000000,
                    gridWidth: getGridWidth(),
                    minimize: minimizeUnlimitedList,
                    styleClassName: 'unlimited-box',
                  }}
                />
                <div className=''>
                  <BlackDropdown {...{
                    uniqueID: 'unlimited-move',
                    isSelector: false,
                    disabledVar: banlistLoading,
                    optionsList: [{ value: 'forbidden', text: 'Move All To Forbidden', callback: () => moveAllFromZone('unlimited', 'forbidden') },
                      { value: 'limited', text: 'Move All To Limited', callback: () => moveAllFromZone('unlimited', 'limited') },
                      { value: 'semilimited', text: 'Move All To Semi-Limited', callback: () => moveAllFromZone('unlimited', 'semilimited') }],
                    startingOption: null,
                    defaultValue: '',
                    defaultDisplay: '',
                    nonSelectorDisplay: <div className='text-icon-button'>
                      <img src={imagePath + 'Swap.png'} width='18px' height='20px' style={{ margin: 'auto', marginLeft: '4px' }} />
                      <span style={{ marginLeft: '5px', marginRight: '5px' }}>Move All</span>
                    </div>,
                    width: 'initial',
                    stateVar: '',
                    dispatch: null,
                    title: 'Move all cards in Unlimited to another zone',
                    style: { margin: '0px 0px 0px 0px' },
                  }} />
                  <img className='dropzone-arrow' src={imagePath + 'DownArrow.png'} width='30px' height='30px' style={{ transform: minimizeUnlimitedList ? 'none' : 'scaleY(-1)' }}
                    title='Collapse/Expand Unlimited zone' onClick={() => setMinimizeUnlimitedList((prev: boolean) => !prev)} />
                </div>
              </div>

              <div id='banlistbuilder-results' className={isScreen950 ? 'small-screen-builder-results' : 'builder-right sticky-panel'}>
                <div id='card-added-popup' className='card-added-popup'><span>{cardAddedPopupText}</span></div>
                {isScreen950 ? <button className='black-button icon-button left-tab' title='Show/hide search results' onClick={toggleResultsBox}
                  style={{ width: '34px', marginRight: '0px' }}>
                  <img src={imagePath + 'SearchIcon.png'} width='20px' height='20px' style={{ margin: 'auto' }} />
                </button> : ''}
                <div className='filter-field'>
                  {isScreen950 ? <img src={imagePath + 'Close.png'} width='15px' height='15px' className='close-button' title='Hide search results' onClick={toggleResultsBox}
                    style={{ margin: '0px 10px auto 0px', display: 'inline-block', cursor: 'pointer' }} /> : ''}
                  <span className='button-bar-label'>Sort By:</span>
                  <BlackDropdown {...{
                    uniqueID: 'search-sort',
                    isSelector: true,
                    disabledVar: searchActionsDisabled,
                    optionsList: filterSearchSortOptions,
                    startingOption: null,
                    defaultValue: 'name',
                    defaultDisplay: 'Name',
                    nonSelectorDisplay: '',
                    width: '100px',
                    stateVar: sortField,
                    dispatch: setSortField,
                    title: 'Select field to sort search results by',
                    style: { marginRight: isScreen1150 ? '5px' : '7px' },
                  }} />
                  <BlackDropdown {...{
                    uniqueID: 'sort-order',
                    isSelector: true,
                    disabledVar: searchActionsDisabled,
                    optionsList: sortOrderOptions,
                    startingOption: null,
                    defaultValue: 'asc',
                    defaultDisplay: 'Asc',
                    nonSelectorDisplay: '',
                    width: '70px',
                    stateVar: sortOrder,
                    dispatch: setSortOrder,
                    title: 'Select order to sort search results',
                    style: { marginRight: isScreen1150 ? (isScreen950 ? '0px' : '5px') : '7px' },
                  }} />
                  {isScreen950 ? '' : <button className='black-button icon-button' title='Show/hide filters' onClick={toggleFilterBox}
                    style={{ width: '30px', marginRight: isScreen1150 ? '5px' : '7px' }}>
                    <img src={imagePath + 'Filter.png'} width='20px' height='20px' style={{ margin: 'auto' }} />
                  </button>}
                </div>
                <div className={'small-screen-builder-results-box' + (isScreen950 ? '' : ' content-box')}>
                  {searchLoading ? <img src={'/images/LoadingAnim.png'} width='100px' height='100px' style={{ margin: '5px auto auto auto' }} /> :
                    searchResults.length > 0 ?
                      <FixedSizeList
                        height={isScreen950 ? Math.min((window.innerHeight / 100) * 70, 790) : 790}
                        width={isScreen950 ? 250 : 390}
                        style={{ marginRight: '0px' }}
                        itemSize={95}
                        itemCount={searchResults.length}>
                        {resultsRow}
                      </FixedSizeList> :
                      <span className='empty-search' style={{ marginTop: '5px' }}>No Matching Cards Found</span>}
                </div>

                <BlackDropdown {...{
                  uniqueID: 'add-all',
                  isSelector: false,
                  disabledVar: banlistLoading || searchLoading,
                  optionsList: [{ value: 'forbidden', text: 'Add All To Forbidden', callback: () => addAllResultsToList('forbidden') },
                    { value: 'limited', text: 'Add All To Limited', callback: () => addAllResultsToList('limited') },
                    { value: 'semilimited', text: 'Add All To Semi-Limited', callback: () => addAllResultsToList('semilimited') },
                    { value: 'unlimited', text: 'Add All To Unlimited', callback: () => addAllResultsToList('unlimited') }],
                  startingOption: null,
                  defaultValue: '',
                  defaultDisplay: '',
                  nonSelectorDisplay: <div className='text-icon-button'>
                    <img src={imagePath + 'BigPlus.png'} width='18px' height='20px' style={{ margin: 'auto', marginLeft: '4px' }} />
                    <span style={{ marginLeft: '5px', marginRight: '5px' }}>Add All</span>
                  </div>,
                  width: 'initial',
                  stateVar: '',
                  dispatch: null,
                  title: 'Add all search results to banlist',
                  style: { margin: '0px 0px 0px auto', display: 'flex', alignSelf: 'end' },
                }} />
              </div>
            </div>

            <div id='banlistbuilder-filters' className='filters-box'>
              {isScreen950 ? <button className='black-button icon-button right-tab' title='Show/hide filters' onClick={toggleFilterBox}
                style={{ width: '34px', marginRight: '0px' }}>
                <img src={imagePath + 'Filter.png'} width='20px' height='20px' style={{ margin: 'auto' }} />
              </button> : ''}
              <div style={{ marginTop: '0px', marginBottom: '5px', textAlign: 'left' }}>
                <h5 style={{ margin: '0px', textAlign: 'left', display: 'inline-block', width: 'fit-content' }}>Filters</h5>
                <span id='clear-all-button' className='forgot-pass-button' title='Clear all filters' onClick={clearAllFilters} style={{ marginLeft: '3px', marginRight: 'auto', display: 'none' }}>
                  Clear All
                </span>
                <img src={imagePath + 'Close.png'} width='15px' height='15px' className='close-button' title='Hide filters' onClick={toggleFilterBox}
                  style={{ margin: 'auto', display: 'inline-block', position: 'absolute', right: '5px', top: '5px', cursor: 'pointer' }} />
              </div>

              <SearchBox {...{
                uniqueID: 'search',
                emptyDisplay: 'Search cards...',
                width: '100%',
                searchFunc: updateSearchString,
                style: { marginBottom: '5px', width: '100%', boxShadow: 'none' },
                searchOnValueChange: true,
              }}/>

              <WindowedSelect windowThreshold={100} ref={setsFilterField}
                options={Object.keys(cardSetDates).map((cs: string) => ({ value: cs, label: cs }))}
                onChange={(newValue: any, actionMeta: any) => setFilter(newValue, setSetsFilter)}
                isDisabled={searchActionsDisabled} isMulti={true} styles={multiSelectStyles} placeholder='Set' noOptionsMessage={() => 'No More Options'} />

              <Select options={rarityOptions} isMulti={true} styles={multiSelectStyles} onChange={(newValue: any, actionMeta: any) => setFilter(newValue, setRarityFilter)}
                isDisabled={searchActionsDisabled} placeholder='Rarity' noOptionsMessage={() => 'No More Options'} ref={rarityFilterField} />

              <Select options={cardCategoryOptions} isMulti={true} styles={multiSelectStyles} onChange={(newValue: any, actionMeta: any) => setFilter(newValue, setCategoryFilter)}
                isDisabled={searchActionsDisabled} placeholder='Card Category' noOptionsMessage={() => 'No More Options'} ref={categoryFilterField} />

              <Select options={cardTypeOptions} isMulti={true} styles={multiSelectStyles} onChange={(newValue: any, actionMeta: any) => setFilter(newValue, setTypeFilter)}
                isDisabled={searchActionsDisabled} placeholder='Type' noOptionsMessage={() => 'No More Options'} ref={typeFilterField} />

              <Select options={subtypeOptions} isMulti={true} styles={multiSelectStyles} onChange={(newValue: any, actionMeta: any) => setFilter(newValue, setSubtypeFilter)}
                isDisabled={searchActionsDisabled} placeholder='Subtype' noOptionsMessage={() => 'No More Options'} ref={subtypeFilterField} />

              <Select options={attributeOptions} isMulti={true} styles={multiSelectStyles} onChange={(newValue: any, actionMeta: any) => setFilter(newValue, setAttributeFilter)}
                isDisabled={searchActionsDisabled} placeholder='Attribute' noOptionsMessage={() => 'No More Options'} ref={attributeFilterField} />

              <Select options={levelOptions} isMulti={true} styles={multiSelectStyles} onChange={(newValue: any, actionMeta: any) => setFilter(newValue, setLevelFilter)}
                isDisabled={searchActionsDisabled} placeholder='Level/Rank/Link' noOptionsMessage={() => 'No More Options'} ref={levelFilterField} />

              <Select options={linkMarkerOptions} isMulti={true} styles={multiSelectStyles} onChange={(newValue: any, actionMeta: any) => setFilter(newValue, setLinkMarkersFilter)}
                isDisabled={searchActionsDisabled} placeholder='Link Markers' noOptionsMessage={() => 'No More Options'} ref={linkMarkersFilterField} />

              <Select options={levelOptions} isMulti={true} styles={multiSelectStyles} onChange={(newValue: any, actionMeta: any) => setFilter(newValue, setPendScaleFilter)}
                isDisabled={searchActionsDisabled} placeholder='Pendulum Scale' noOptionsMessage={() => 'No More Options'} ref={pendScaleFilterField} />

              <div className='filter-field filter-range'>
                <div className='input-box grid-left' style={{ boxShadow: 'none' }}><input type='number' min='0' max='9999' pattern='[0-9]{4}' name='MinAtk' ref={atkFilterFieldLeft}
                  disabled={searchActionsDisabled} onInput={rangeFilterOnChange} placeholder={'Min ATK'} style={{ width: '70px' }} /></div>
                <b className='middle-margin grid-middle'>-</b>
                <div className='input-box grid-right' style={{ boxShadow: 'none' }}><input type='number' min='0' max='9999' pattern='[0-9]{4}' name='MaxAtk' ref={atkFilterFieldRight}
                  disabled={searchActionsDisabled} onInput={rangeFilterOnChange} placeholder={'Max ATK'} style={{ width: '70px' }} /></div>
              </div>
              <div className='filter-field filter-range'>
                <div className='input-box grid-left' style={{ boxShadow: 'none' }}><input type='number' min='0' max='9999' pattern='[0-9]{4}' name='MinDef' ref={defFilterFieldLeft}
                  disabled={searchActionsDisabled} onInput={rangeFilterOnChange} placeholder={'Min DEF'} style={{ width: '70px' }} /></div>
                <b className='middle-margin grid-middle'>-</b>
                <div className='input-box grid-right' style={{ boxShadow: 'none' }}><input type='number' min='0' max='9999' pattern='[0-9]{4}' name='MaxDef' ref={defFilterFieldRight}
                  disabled={searchActionsDisabled} onInput={rangeFilterOnChange} placeholder={'Max DEF'} style={{ width: '70px' }} /></div>
              </div>
              <div className='filter-field filter-range'>
                <div className='input-box grid-left' style={{ boxShadow: 'none' }}>
                  <input type='date' disabled={searchActionsDisabled} placeholder={'Min Date'} name='MinDate' onInput={rangeFilterOnChange} ref={dateFilterFieldLeft}
                    style={{ width: '100px', color: dateFilter.length > 0 && dateFilter[0] > DATE_MIN ? '#1E1E1E' : '#777' }} />
                </div>
                <b className='middle-margin grid-middle'>-</b>
                <div className='input-box grid-right' style={{ boxShadow: 'none' }}>
                  <input type='date' disabled={searchActionsDisabled} placeholder={'Max Date'} name='MaxDate' onInput={rangeFilterOnChange} ref={dateFilterFieldRight}
                    style={{ width: '100px', color: dateFilter.length > 0 && dateFilter[1] < DATE_MAX ? '#1E1E1E' : '#777' }} />
                </div>
              </div>
            </div>

            <Ad styleName='bar_ad_footer' />
          </DndProvider>
        </main>
      </BuilderContext.Provider>
    );
  }
  else {
    return (
      <div style={{ margin: 'auto', textAlign: 'center' }}>
        <Helmet>
          <meta content="YGO Prog - Banlist Builder" property="og:title" />
          <meta
            content="YGO Prog's Banlist Builder lets you create, manage, and export custom Yu-Gi-Oh! banlist files for EDOPRO and YGO Omega, or as images and spreadsheets for other uses!"
            property="og:description"
          />
          <meta content="https://www.ygoprog.com/BanlistBuilder" property="og:url" />
          <meta
            name="description"
            content="YGO Prog's Banlist Builder lets you create, manage, and export custom Yu-Gi-Oh! banlist files for EDOPRO and YGO Omega, or as images and spreadsheets for other uses!"
          />
          <title>YGO Prog - Banlist Builder</title>
        </Helmet>
        <h1 style={{ paddingBottom: '0px', marginBottom: '0px' }}>Loading</h1>
        <img src={'/images/LoadingAnim.png'} width='150px' height='150px' style={{ margin: 'auto', marginTop: '5px', padding: '0' }} />
      </div>
    );
  }
}
