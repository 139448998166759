import { useState } from 'react';


export default function DeleteConfirmModal(props: { id: string, title: string, matchString: string, callback: any, clearMatch: any, preConfirmStatement: string }) {

  const [deleteDisabled, setDeleteDisabled] = useState(true);

  function closeDeleteConfirmModal(isCancel: boolean) {
    if (isCancel && props.clearMatch != null) props.clearMatch('');
    document.getElementById(props.id + '-modal')!.style.display = 'none';
    (document.getElementById(props.id + '-match')! as HTMLInputElement).value = '';
  }

  function deleteConfirmed() {
    if (props.callback != null) props.callback();
    closeDeleteConfirmModal(false);
  }

  function checkDeleteName(e: any) {
    if (e.target.value.toLowerCase() == props.matchString.toLowerCase()) setDeleteDisabled(false);
    else setDeleteDisabled(true);
  }

  return (
    <div id={props.id + '-modal'} className='modal' style={{ zIndex: 9015 }}>
      <button className='modal-close' title='Close modal' onClick={() => closeDeleteConfirmModal(true)} />
      <div className='content-box modal-content' style={{ zIndex: 9016 }}>
        <h5 style={{ marginBottom: '0px' }}>{props.title}</h5>
        <div className='vertical-form-text-input' style={{ margin: '0px auto 7px auto', textAlign: 'center' }}>
          <label htmlFor={props.id + '-match'} style={{ whiteSpace: 'pre-wrap', textAlign: 'center', marginBottom: '3px', display: 'block' }}>
            {props.preConfirmStatement + 'Confirm deletion by typing'}<br />{props.matchString}
          </label>
          <div className='text-input'><input id={props.id + '-match'} type='text' onInput={checkDeleteName} /></div>
        </div>
        <button className='black-button' title='Confirm delete' onClick={deleteConfirmed} disabled={deleteDisabled}><span>Delete</span></button>
      </div>
    </div>
  );
}
