import { useState, useEffect, useContext, useRef } from 'react';
import { exportComponentAsPNG } from '../react-component-export-image/index';
import { openErrorModal } from '../utils/BasicModalFuncs';
import { imagePath, cardImagePath, DATE_MIN, DATE_MAX, getDateString, contactString } from '../utils/Constants';
import { AppContext, BuilderContext } from '../utils/Contexts';

interface ZonesExportProps {
  id: string,
  listName: string,
  zoneNames: string[],
  zoneStyleClassNames: string[],
  allowedPrintings: Date[],
  zoneLists: Card[][],
  finishedDispatch: any,
  builderType: string,
}

export default function ZonesImageExport(props: ZonesExportProps) {

  const { isScreen1150Query, isScreen1300Query } = useContext(AppContext);
  const [isScreen1150, setIsScreen1150] = useState(isScreen1150Query.matches);
  const [isScreen1300, setIsScreen1300] = useState(isScreen1300Query.matches);
  const { currentBanlist, banlistData, banlistDates } = useContext(BuilderContext);
  const [imageFailedToLoad, setImageFailedToLoad] = useState(false);
  const [imageResult, setImageResult] = useState<any>(null);
  const [clipboardResult, setClipboardResult] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const imageExportZone = useRef<any>();

  useEffect(() => {
    isScreen1150Query.addEventListener('change', checkScreen1150); // To avoid re-rendering the whole page and losing data, we have to listen and state change on the sublevel
    isScreen1300Query.addEventListener('change', checkScreen1300);
    exportComponentAsPNG(imageExportZone, {
      fileName: props.listName,
      html2CanvasOptions: {
        scrollX: -window.scrollX,
        scrollY: -window.scrollY,
        windowWidth: document.documentElement.offsetWidth,
      },
    }).then(async (data: any) => {
      const result = await data;
      setImageResult(result.dataURL);
      if (result.blob != null) {
        try {
          const clipItem = [new ClipboardItem({ 'image/png': result.blob })];
          setClipboardResult(clipItem);
        }
        catch (err) {
          console.log('Clipboard not supported');
          setLoading(false);
        }
      }
      else setLoading(false);
    });

    return () => { // Unmount
      isScreen1150Query.removeEventListener('change', checkScreen1150);
      isScreen1300Query.removeEventListener('change', checkScreen1300);
    };
  }, []);

  useEffect(() => {
    if (clipboardResult != null) {
      setLoading(false);
    }
  }, [clipboardResult]);

  function checkScreen1150() {
    setIsScreen1150(isScreen1150Query.matches);
  }

  function checkScreen1300() {
    setIsScreen1300(isScreen1300Query.matches);
  }

  function closeZonesImageExportModal() {
    document.getElementById(props.id)!.style.display = 'none';
    props.finishedDispatch(false);
  }

  function replaceWithDefaultImg(event: any) {
    setImageFailedToLoad(true);
  }

  function imageProperlyLoaded(event: any) {
    setImageFailedToLoad(false);
  }

  function copyToClipboard() {
    if (clipboardResult != null) {
      navigator.clipboard.write(clipboardResult)
        .catch((err: any) => {
          openErrorModal('Issue copying image to clipboard.<br />If this persists, ' + contactString);
          return;
        });
      const popup = document.getElementById('img-copied-popup')!;
      popup.style.transition = '0s';
      popup.style.opacity = '100';
      popup.style.visibility = 'visible';

      setTimeout(function() {
        popup.style.transition = '.8s';
        popup.style.opacity = '0';
        popup.style.visibility = 'hidden';
      }, 400);
    }
  }

  return (
    <div id={props.id} className='modal' style={{ display: 'block' }}>
      <button className='modal-close' title='Close modal' onClick={closeZonesImageExportModal} />
      <div className='content-box modal-content' style={{ top: '5%', padding: '0px', paddingTop: '10px', paddingBottom: '10px' }}>
        <div id='img-copied-popup' className='clipboard-popup'><span>Image copied to clipboard!</span></div>
        <h5 style={{ marginBottom: '0px' }}>Export as Image</h5>
        {loading ? <img src={'/images/LoadingAnim.png'} width='50px' height='50px' style={{ margin: '5px auto auto auto' }} /> :
          (clipboardResult != null ? <button className='black-button' style={{ margin: '5px auto auto auto', display: 'block' }} disabled={imageResult == null}
            title='Copy image to clipboard' onClick={copyToClipboard}><span>Copy to Clipboard</span></button> :
            <span className='largetext-label'>Image complete, right click to copy</span>)}
        {clipboardResult == null ? <div style={{ background: 'var(--contentBoxColor)', padding: '5px 20px 10px 17px', margin: '0px' }} ref={imageExportZone}>
          <div style={{
            display: 'grid',
            gridTemplateColumns: '6fr repeat(1, auto) 6fr',
            justifyItems: 'center',
            margin: 'auto',
            height: 'fit-content',
            alignItems: 'center',
            textAlign: 'left',
            verticalAlign: 'middle',
            marginTop: '10px',
            marginBottom: '0px',
          }}>
            <div className='grid-left'>
              <h5 style={{ whiteSpace: 'pre-wrap', marginTop: '0px', marginBottom: '0px', textAlign: 'left', fontSize: isScreen1150 ? '32px' : '46px' }}>{props.listName}</h5>
            </div>
            <div className='grid-middle' />
            <div className='grid-right' style={{ height: '54px' }}>
              <h5 className='largetext-label' style={{ fontWeight: 900, fontSize: isScreen1150 ? '18px' : '22px', margin: '0px', display: 'block', textAlign: 'right' }}>
                YGOProg.com
              </h5>
              <span className='largetext-label' style={{ fontWeight: 500, fontSize: isScreen1150 ? '13px' : '16px', margin: '0px', display: 'block' }}>
                Made with the {props.builderType}
              </span>
            </div>
          </div>
          {props.zoneNames.map((name: string, index: number) => {
            return <div key={'imgzone' + index}>
              <div style={{ marginTop: index > 0 ? '10px' : '5px', marginBottom: '5px', marginLeft: '0px', textAlign: 'left', fontSize: '25px', display: 'block' }}>
                <h3 style={{ margin: '0px', textAlign: 'left', fontSize: '27px', display: 'inline-block' }}>{name}</h3>
                {index == 0 && props.allowedPrintings.length > 0 && (props.allowedPrintings[0] > DATE_MIN || props.allowedPrintings[1] < DATE_MAX) ?
                  <span className='largetext-label' style={{ fontWeight: 500, marginLeft: '10px', fontSize: '18px', marginBottom: '0px', height: '23px' }}>
                    {'Allowed Printings: ' +
                      (props.allowedPrintings[0] > DATE_MIN && props.allowedPrintings[1] < DATE_MAX ?
                        (props.allowedPrintings[0].toDateString().substring(4) + ' to ' + props.allowedPrintings[1].toDateString().substring(4)) :
                        (props.allowedPrintings[0] > DATE_MIN ? ('After ' + props.allowedPrintings[0].toDateString().substring(4)) :
                          ('Before ' + props.allowedPrintings[1].toDateString().substring(4))))}
                  </span> : ''}
              </div>
              <div className={'content-box builder-listbox ' + props.zoneStyleClassNames[index]}>
                <div className='dropzone-inner' style={{
                  gridTemplateColumns: 'repeat(' + (isScreen1300 ? (isScreen1150 ? '6' : '8') : '10') + ',1fr)',
                  gap: '5px',
                  minHeight: '100px',
                }}>
                  {props.zoneLists[index].map((card: Card, n: number) => {
                    const limit = (currentBanlist !== undefined && currentBanlist != '' ?
                      (card.all_prints.some((d: Date) => d > banlistDates[0] && d < banlistDates[1]) ?
                        (banlistData[card.id] ?? undefined) : 0) : undefined);
                    return (
                      <div style={{ margin: '0px', width: '100%' }} key={'img' + index + n}>
                        <div className='card-link'>
                          {limit !== undefined ?
                            <img className='lf-icon' src={imagePath + 'Limit' + limit + '.png'} alt={limit.toString()} width='45px' height='45px' /> : ''}
                          {imageFailedToLoad ?
                            <div className='card-image' style={{ width: '100%', height: 'auto', position: 'relative' }}>
                              <img className='card-image' src={imagePath + 'FailedCardLoad.png' + '?' + Date.now()} alt={card.name}
                                width={isScreen1150 ? '68.57px' : '120px'} height={isScreen1150 ? '100px' : '175px'}
                                style={{ width: isScreen1150 ? '68.57px' : '120px', height: isScreen1150 ? '100px' : '175px' }} />
                              <span className='replacement-card-title' style={{ display: 'inline', fontSize: '9px' }}>{card.name}</span>
                            </div> :
                            <div className='card-image'>
                              <img
                                src={cardImagePath + card.id + '.jpg'}
                                alt={card.name}
                                width={isScreen1150 ? '68.57px' : '120px'}
                                height={isScreen1150 ? '100px' : '175px'}
                                style={{ width: '100%', maxWidth: '120px', height: 'auto', maxHeight: '175px' }}
                                onLoad={imageProperlyLoaded}
                                onError={replaceWithDefaultImg}
                              />
                            </div>}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>;
          })}
        </div> :
          <img
            src={imageResult}
            alt={props.listName + ' Image'}
            style={{ maxWidth: 'min(1315px, 90vw)' }}
          />}
      </div>
    </div>
  );
}
