import * as Constants from './Constants';
import { openBasicModal, openErrorModal } from './BasicModalFuncs';
import { contactString } from './Constants';

export default function makeApiCall(
  refresh: string,
  userId: string,
  input: RequestInfo,
  init?: RequestInit,
) {

  return fetch(input, init).then(async (response) => {
    if (
      response.status === 401 &&
      (await response.clone().text()) === 'TokenExpiredError'
    ) {
      const refreshRequestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          userId: userId,
          refresh_token: refresh,
        }),
      };
      // make a call to get new auth token
      const refreshedToken = await fetch(Constants.apiPath + 'refresh', refreshRequestOptions).then(
        async (refTokResponse) => {
          // save new auth token to cookies
          if (refTokResponse.ok) {
            const refreshResponse = await refTokResponse.json();

            Constants.setCookie('token', refreshResponse.token, false);
            Constants.setCookie('refresh', refresh, true);
            Constants.setCookie('userId', userId, true);
            Constants.setCookie('user', Constants.cookies.get('user'), true);
            if (refreshResponse.userVerified != true) {
              openBasicModal('Duelist Not Verified', 'You have not verified your account email, please do so.<br />' +
                'If you do not verify your email, we have no way to help you reset your password if you forget it.<br />' +
                'You can resend the verification email from your <a class="in-text-link" href="' + Constants.frontEndPath + 'Account">Account Page</a>.', 'Ok');
            }

            return refreshResponse.token;
          }
          else if (refTokResponse.status == 400) {
            return '400';
          }
          else {
            // this does nothing lmao
            openErrorModal('Your login token could not be refreshed, try logging out and logging back in.' +
              '<br />If it keeps happening, ' + contactString);
          }
        },
      );

      if (refreshedToken == '400') {
        return new Response(null, { status: 400, statusText: 'Failed to reauth because expired token' });
      }

      if (init !== undefined) {
        (init.headers as Record<string, string>).Authorization = 'Bearer ' + refreshedToken;
      }
      // redo the call
      return fetch(input, init).then(async (secondTryResponse) => {
        if (
          secondTryResponse.status === 401 &&
          (await secondTryResponse.clone().text()) === 'TokenExpiredError'
        ) {
          openErrorModal('Your account did not properly authenticate, try logging out and logging back in.' +
            '<br />If it keeps happening, ' + contactString);
        }
        else if (!secondTryResponse.ok) {
          openErrorModal('Error Code: ' + secondTryResponse.status + '<br />' + contactString);
        }

        return secondTryResponse;
      });
    }
    return response;
  });
}
