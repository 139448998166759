import { useState, useContext, CSSProperties, useEffect, useCallback } from 'react';
import * as Constants from '../utils/Constants';
import { AppContext, PackOpenerContext } from '../utils/Contexts';
import MultiToggleBox from './MultiToggleBox';
import BlackDropdown from './BlackDropdown';
import SearchBox from './SearchBox';
import { openErrorModal } from '../utils/BasicModalFuncs';
import { cardSetDates, cardSets } from '../utils/CardDataCache';
import LazyImage from './LazyImage';
import StringExportModal from './StringExportModal';
import { DndProvider } from 'react-dnd-multi-backend';
import { SortablePackTrayEntry } from './SortablePackTrayEntry';
import Ad from './Ad';

const setTypeHeaderText: { [key: string]: string } = {
  'set': 'Sets',
  'tin': 'Bundles',
  'deck': 'Decks',
  'All': 'All Products',
  'Sets': 'Sets',
  'Boxes': 'Boxes',
  'Core': 'TCG Core Booster Packs',
  'Side': 'TCG Side Set Booster Packs',
  'Tournament': 'Tournament Award Packs',
  'Duelist': 'Duelist Booster Packs',
  'LegendaryDuelist': 'Legendary Duelist Packs',
  'Box': 'Box Set Packs',
  'Gold': 'Gold Series Booster Packs',
  'Mega': 'Mega Packs',
  'Compilation': 'Compilation Packs',
  'Reprint': 'Reprint Packs',
  'Battle': 'Battle Packs',
  'Star': 'Star Packs',
  'Movie': 'Movie Packs',
  'Championship': 'World Championship Packs',
  'Misc': 'Miscellaneous Packs',
  'Bundles': 'Bundles',
  'MiscB': 'Miscellaneous Bundles',
  'CompilationBundles': 'Compilation Bundles',
  'Decks': 'Decks',
  'Starter Deck': 'Starter Decks',
  'Structure Deck': 'Structure Decks',
};

export default function PackSelect() {

  const [configLoading, setConfigLoading] = useState(false);
  const [openButtonDisabled, setOpenButtonDisabled] = useState(true);
  const { state, dispatch: setPackOpenerState } = useContext(PackOpenerContext);
  const { isScreen950Query } = useContext(AppContext);
  const [isScreen950, setIsScreen950] = useState(isScreen950Query.matches);
  const [productTypeMode, setProductTypeMode] = useState('All');
  const [setSearchString, setSetSearchString] = useState('');
  const [setSort, setSetSort] = useState('date,asc');
  const [setGroupingEnabled, setSetGroupingEnabled] = useState(true);
  const [boxOddsEnabled, setBoxOddsEnabled] = useState(false);
  const [setResults, setSetResults] = useState<{[key: string]: string[]}>({});
  const [packsToOpenDisplay, setPacksToOpenDisplay] = useState<{ [key: string]: PackCountInfo[] }>({
    'Sets': [] as PackCountInfo[],
    'Boxes': [] as PackCountInfo[],
    'Bundles': [] as PackCountInfo[],
    'Decks': [] as PackCountInfo[],
  });
  const [exportString, setExportString] = useState<{ type: string, exportString: string }>({ type: 'Shareable Config', exportString: '' });
  const [currentDragEntry, setCurrentDragEntry] = useState({ prefix: '', creationTime: Date.now() });

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'auto' });
    isScreen950Query.addEventListener('change', checkScreen950); // To avoid re-rendering the whole page and losing data, we have to listen and state change on the sublevel
    return () => { // Unmount
      isScreen950Query.removeEventListener('change', checkScreen950);
    };
  }, []);

  function checkScreen950() {
    setIsScreen950(isScreen950Query.matches);
  }

  function packNumInputCheck(e: any) {
    if (e.currentTarget.value !== '') {
      if (parseFloat(e.currentTarget.value as string) > parseFloat(e.currentTarget.max as string)) e.currentTarget.value = e.currentTarget.max;
      else if (parseFloat(e.currentTarget.value as string) < parseFloat(e.currentTarget.min as string)) e.currentTarget.value = e.currentTarget.min;
    }

    const setInfo = cardSets[e.currentTarget.dataset.packprefix];
    if (typeof setInfo == 'undefined') {
      openErrorModal('Issue finding product "' + e.currentTarget.dataset.packprefix + '".<br />' + Constants.contactString);
      return;
    }
    const productType = boxOddsEnabled ? 'Boxes' : setTypeHeaderText[cardSets[e.currentTarget.dataset.packprefix].product_category];

    const adjustedPrefix = e.currentTarget.dataset.packprefix + (productType.slice(0, 2));
    const existingEntry = state.packsToOpen[adjustedPrefix]; // NOTE: You could do the three ops using .map and such, but it's visually a lot messier and I'm pretty sure it's equal or lesser in terms of performance -T
    const newCardSetList: any = Object.assign({}, state.packsToOpen); // Must use assign to create a copy so we don't mutate the state
    const newCardSetDisplay: any = { ...packsToOpenDisplay };
    Object.keys(newCardSetDisplay).forEach((key: string) => {
      newCardSetDisplay[key] = [...packsToOpenDisplay[key]];
    });
    const existingDisplayEntryIndex = newCardSetDisplay[productType].findIndex((cs: PackCountInfo) => cs.prefix == adjustedPrefix);
    if (e.currentTarget.value !== '' && e.currentTarget.value > 0) {
      if (typeof existingEntry !== 'undefined') {
        newCardSetList[adjustedPrefix].count = Number(e.currentTarget.value); // Replace the count in existing entry
        if (existingDisplayEntryIndex > -1) newCardSetDisplay[productType][existingDisplayEntryIndex].count = Number(e.currentTarget.value);
      }
      else {
        newCardSetList[adjustedPrefix] = { prefix: e.currentTarget.dataset.packprefix, order: newCardSetDisplay[productType].length,
          count: Number(e.currentTarget.value), productType: productType, pullsCompleted: false }; // Add new entry
        newCardSetDisplay[productType].push({ prefix: adjustedPrefix, order: newCardSetDisplay[productType].length,
          count: Number(e.currentTarget.value), productType: productType, pullsCompleted: false });
      }
    }
    else if (typeof existingEntry !== 'undefined') {
      delete newCardSetList[adjustedPrefix]; // Remove existing entry
      newCardSetDisplay[productType].splice(existingDisplayEntryIndex, 1);
    }

    setPackOpenerState({ type: 'packsToOpen', payload: newCardSetList });
    setPacksToOpenDisplay(newCardSetDisplay);

    if (Object.keys(newCardSetList).length > 0) {
      setOpenButtonDisabled(false);
    }
    else {
      setOpenButtonDisabled(true);
    }
  }

  function addAllFromCategory(e: any) {
    const setType = e.currentTarget.dataset.settype;
    const newCardSetList: any = Object.assign({}, state.packsToOpen); // Must use assign to create a copy so we don't mutate the state
    const newCardSetDisplay: any = { ...packsToOpenDisplay };
    Object.keys(newCardSetDisplay).forEach((key: string) => {
      newCardSetDisplay[key] = [...packsToOpenDisplay[key]];
    });

    setResults[setType].forEach((prefix: string) => {
      const setInfo = cardSets[prefix];
      if (typeof setInfo == 'undefined') {
        openErrorModal('Issue finding product "' + prefix + '".<br />' + Constants.contactString);
        return; // This doesn't exit the function, just skips this entry; it will only ever show the modal for the final error. We may need to TODO go back here and add cascading error handling
      }
      const productType = boxOddsEnabled ? 'Boxes' : setTypeHeaderText[setInfo.product_category]; // We could be adding an ungrouped All category, so this has to be by set
      const adjustedPrefix = prefix + (productType.slice(0, 2));
      const existingEntry = state.packsToOpen[adjustedPrefix];
      const existingDisplayEntryIndex = newCardSetDisplay[productType].findIndex((cs: PackCountInfo) => cs.prefix == adjustedPrefix);

      if (typeof existingEntry !== 'undefined') {
        newCardSetList[adjustedPrefix].count += 1; // Replace the count in existing entry
        if (existingDisplayEntryIndex > -1) newCardSetDisplay[productType][existingDisplayEntryIndex].count += 1;
      }
      else {
        newCardSetList[adjustedPrefix] = { prefix: prefix, order: newCardSetDisplay[productType].length,
          count: 1, productType: productType, pullsCompleted: false }; // Add new entry
        newCardSetDisplay[productType].push({ prefix: adjustedPrefix, order: newCardSetDisplay[productType].length,
          count: 1, productType: productType, pullsCompleted: false });
      }
    });

    setPackOpenerState({ type: 'packsToOpen', payload: newCardSetList });
    setPacksToOpenDisplay(newCardSetDisplay);

    if (Object.keys(newCardSetList).length > 0) {
      setOpenButtonDisabled(false);
    }
    else {
      setOpenButtonDisabled(true);
    }
  }

  function packNameRestyle(name: string): CSSProperties {
    let cssFontSize = '22px';
    let cssLineHeight = 'normal';
    let cssHeight = '26.67px';
    let cssMarginTop = '9px';
    let cssPaddingLeft = '0px';
    let cssPaddingRight = '0px';

    if (name.length > 37) {
      cssFontSize = '16px';
      cssLineHeight = 'initial';
      cssHeight = '33.67px';
      cssMarginTop = '2px';
      cssPaddingLeft = '15px';
      cssPaddingRight = '15px';
    }
    else if (name.length > 30) {
      cssFontSize = '17px';
      cssLineHeight = '26.67px';
    }
    else if (name.length > 25) {
      cssFontSize = '19px';
      cssLineHeight = '26.67px';
    }

    if (isScreen950) {
      return { fontSize: '15px', lineHeight: name.length < 26 ? '36px' : '1.05', height: '36px', marginTop: '2px',
        paddingLeft: '2px', paddingRight: '2px', paddingTop: '3px', overflow: 'hidden', verticalAlign: 'middle' };
    }
    else {
      return { fontSize: cssFontSize, lineHeight: cssLineHeight, height: cssHeight, marginTop: cssMarginTop,
        paddingLeft: cssPaddingLeft, paddingRight: cssPaddingRight };
    }
  }

  function cropName(name: string) {
    return isScreen950 && name.length > 46 ? name.substring(0, 43) + '...' : name;
  }

  function configExportSuccess() {
    setExportString({ type: 'Shareable Config', exportString: '' });
  }

  function exportToConfigString() {
    let pullsConfig = state.packOpenMode + ':\n';
    Object.keys(packsToOpenDisplay).forEach((setType: string) => {
      packsToOpenDisplay[setType].forEach((packInfo: PackCountInfo) => {
        if (packInfo.prefix.includes('_')) {
          const adjPrefix = packInfo.prefix.split('_')[0];
          pullsConfig += adjPrefix.slice(0, -2) + (adjPrefix.slice(-2) == 'Bo' ? ' (Box)' : '') + '*' + packInfo.count + '\n';
        }
        else pullsConfig += packInfo.prefix.slice(0, -2) + (packInfo.prefix.slice(-2) == 'Bo' ? ' (Box)' : '') + '*' + packInfo.count + '\n';
      });
    });

    setExportString({ type: 'Shareable Config', exportString: pullsConfig.trim() });
  }

  function importFromConfigString() {
    setConfigLoading(true);

    setTimeout(() => {
      const configData = (document.getElementById('config-input-field')! as HTMLInputElement).value.split(new RegExp('\\r?\\n'));
      let errString = '';

      const newCardSetList: any = {};
      const newCardSetDisplay: { [key: string]: PackCountInfo[] } = {
        'Sets': [] as PackCountInfo[],
        'Boxes': [] as PackCountInfo[],
        'Bundles': [] as PackCountInfo[],
        'Decks': [] as PackCountInfo[],
      };

      let packOpenMode = 'Sealed';
      for (let i: number = 0; i < configData.length; i++) {
        if (configData[i].trim() == '') continue;
        if (i == 0) {
          if (configData[i].trim() == 'Draft:') {
            packOpenMode = 'Draft';
            continue;
          }
          if (configData[i].trim() == 'Sealed:') continue; // If we don't have any mode mark it's also just sealed, but we only skip the line if it has the mark
        }
        const pullInfo = configData[i].trim().split('*');

        try {
          const setPrefix = pullInfo[0].split(' ');
          let boxOdds = false;
          if (setPrefix.length > 1) {
            if (setPrefix[1].toLowerCase().includes('box')) boxOdds = true;
            else throw new Error('(Unknown odds specifier)');
          }

          let count = Number(pullInfo[1]);
          const setInfo = cardSets[setPrefix[0]];
          if (boxOdds && !(setInfo as CardSet).box_odds_enabled) throw new Error('(Box odds not available for set)');
          const productType = boxOdds ? 'Boxes' : setTypeHeaderText[setInfo.product_category];
          if (packOpenMode == 'Draft' && productType != 'Sets') throw new Error('(Product type not available for draft)');
          if (count > 0) {
            let adjustedPrefix = setInfo.prefix + productType.slice(0, 2);
            if (newCardSetList[adjustedPrefix] !== undefined) {
              if (packOpenMode == 'Draft') adjustedPrefix = adjustedPrefix + '_' + i;
              else count += newCardSetList[adjustedPrefix].count;
            }
            newCardSetList[adjustedPrefix] = { prefix: setInfo.prefix, order: i, count: count, productType: productType, pullsCompleted: false }; // TODO: potential bug here with collapsing packs but not collapsing display
            newCardSetDisplay[productType].push({ prefix: adjustedPrefix, order: i, count: count, productType: productType, pullsCompleted: false });
          }
        }
        catch (err: any) {
          errString += '<br />' + configData[i].replaceAll(/(\\r?\\n)|;|<|>/g, '?') + (err?.message[0] == '(' ? err.message : '');
        }
      }

      setPackOpenerState({ type: 'packsToOpen', payload: newCardSetList });
      setPackOpenerState({ type: 'packOpenMode', payload: packOpenMode });
      setPacksToOpenDisplay(newCardSetDisplay);
      setConfigLoading(false);

      if (Object.keys(newCardSetList).length > 0) {
        setOpenButtonDisabled(false);
      }
      else {
        setOpenButtonDisabled(true);
      }

      closeConfigImportModal();
      if (errString != '') openErrorModal('Ignored some config lines, config text may be misconfigured or simulation not available for:' + errString);
    }, 500); // Set a timeout so the rerender with the loading icon happens
  }

  function openConfigImportModal() {
    document.getElementById('config-import-modal')!.style.display = 'flex';
  }

  function closeConfigImportModal() {
    document.getElementById('config-import-modal')!.style.display = 'none';
  }

  function clearSelectedPacks() {
    setPackOpenerState({ type: 'packsToOpen', payload: {} });
    setPacksToOpenDisplay({
      'Sets': [] as PackCountInfo[],
      'Boxes': [] as PackCountInfo[],
      'Bundles': [] as PackCountInfo[],
      'Decks': [] as PackCountInfo[],
    });
    setOpenButtonDisabled(true);

    searchSets(setSearchString, setSort, productTypeMode, setGroupingEnabled, boxOddsEnabled, state.packOpenMode);
  }

  function toggleCollapsibleBox(e: any) {
    const docDiv = document.getElementById('open-packs-tray');
    const div: any = (typeof docDiv === 'undefined') ? e.target.parentNode : docDiv; // Not sure why parentNode doesn't work and I have to use the id find here, but whatever
    if (div === e.target.parentNode) {
      openErrorModal('Issue toggling the pack tray.<br />' + Constants.contactString);
    }
    if (div.style.right !== '-5px') {
      div.style.right = '-5px';
      e.target.src = Constants.imagePath + 'RightArrow.png';
    }
    else {
      div.style.right = '-215px';
      e.target.src = Constants.imagePath + 'LeftArrow.png';
    }
  }

  function goToNextPage(e: any) {
    const numPacks = Object.keys(state.packsToOpen).length;
    if (state.packOpenMode == 'Draft') {
      if (numPacks > 0) {
        const sortedPacksToOpen: any = Object.assign({}, state.packsToOpen);
        packsToOpenDisplay['Sets'].forEach((packInfo: PackCountInfo, index: number) => {
          if (sortedPacksToOpen[packInfo.prefix] !== undefined) {
            sortedPacksToOpen[packInfo.prefix].order = index;
          }
        });
        setPackOpenerState({ type: 'DraftPacks', payload: sortedPacksToOpen });
      }
      else console.error('No packs selected');
    }
    else {
      if (numPacks > 1) {
        setPackOpenerState({ type: 'page', payload: 'PickNextPack' });
      }
      else if (numPacks > 0) {
        setPackOpenerState({ type: 'OpenPack', payload: Object.keys(state.packsToOpen)[0] });
      }
      else console.error('No packs selected');
    }
  }

  function replaceWithDefaultPackImg(event: any) {
    event.currentTarget.onerror = '';
    event.currentTarget.src = Constants.imagePath + 'DefaultPack.png';
    return true;
  }

  function toggleSetGrouping() {
    const newSetGrouping = !setGroupingEnabled;
    setSetGroupingEnabled(newSetGrouping);
    searchSets(setSearchString, setSort, productTypeMode, newSetGrouping, boxOddsEnabled, state.packOpenMode);
  }

  function toggleBoxOdds() {
    const newBoxOdds = !boxOddsEnabled;
    setBoxOddsEnabled(newBoxOdds);
    searchSets(setSearchString, setSort, productTypeMode, setGroupingEnabled, newBoxOdds, state.packOpenMode);
  }

  function toggleDraftMode() {
    const newPackOpenMode = state.packOpenMode == 'Sealed' ? 'Draft' : 'Sealed';

    if (newPackOpenMode == 'Draft' && Object.values(state.packsToOpen).some((product: any) => product.productType != 'Sets')) {
      document.getElementById('draftCheckModal')!.style.display = 'flex';
    } // Must remove unavailable product types
    else {
      setPackOpenerState({ type: 'packOpenMode', payload: newPackOpenMode });
      setBoxOddsEnabled(false);
      searchSets(setSearchString, setSort, productTypeMode, setGroupingEnabled, false, newPackOpenMode);
    }

    if (newPackOpenMode != 'Draft') {
      const newCardSetList: any = Object.assign({}, state.packsToOpen); // Must collapse duplicate products for sealed
      const newCardSetDisplay: { [key: string]: PackCountInfo[] } = {
        'Sets': [] as PackCountInfo[],
        'Boxes': [] as PackCountInfo[],
        'Bundles': [] as PackCountInfo[],
        'Decks': [] as PackCountInfo[],
      };

      Object.keys(state.packsToOpen).forEach((key: string) => {
        if (key.includes('_')) {
          const adjPrefix = key.split('_')[0];
          if (newCardSetList[adjPrefix] !== undefined) {
            newCardSetList[adjPrefix].count += newCardSetList[key].count;
            delete newCardSetList[key];
          }
          else {
            newCardSetList[adjPrefix] = { ...newCardSetList[key] };
            delete newCardSetList[key];
          }
        }
      });

      Object.keys(newCardSetList).forEach((adjPrefix: any) => { // Could do this as part of the above loop, but that's messier and loops extra times
        newCardSetDisplay[newCardSetList[adjPrefix].productType].push({ ...newCardSetList[adjPrefix], prefix: adjPrefix });
      });

      setPacksToOpenDisplay(newCardSetDisplay);
      setPackOpenerState({ type: 'packsToOpen', payload: newCardSetList });
    }
  }

  function closeDraftCheckModal() {
    document.getElementById('draftCheckModal')!.style.display = 'none';
  }

  function acceptDraftCheckModal() {
    const newPackOpenMode = state.packOpenMode == 'Sealed' ? 'Draft' : 'Sealed';
    setPackOpenerState({ type: 'packOpenMode', payload: newPackOpenMode });
    setBoxOddsEnabled(false);
    searchSets(setSearchString, setSort, productTypeMode, setGroupingEnabled, false, newPackOpenMode);

    const newCardSetList: any = Object.assign({}, state.packsToOpen); // Must use assign to create a copy so we don't mutate the state
    const newCardSetDisplay: { [key: string]: PackCountInfo[] } = {
      'Sets': [...packsToOpenDisplay['Sets']],
      'Boxes': [] as PackCountInfo[],
      'Bundles': [] as PackCountInfo[],
      'Decks': [] as PackCountInfo[],
    };

    Object.keys(state.packsToOpen).forEach((key: string) => {
      if (newCardSetList[key].productType != 'Sets') delete newCardSetList[key];
    });

    setPacksToOpenDisplay(newCardSetDisplay);
    setPackOpenerState({ type: 'packsToOpen', payload: newCardSetList });
    document.getElementById('draftCheckModal')!.style.display = 'none';
  }

  function updateProductTypeMode(newProductTypeMode: string) {
    setProductTypeMode(newProductTypeMode);
    searchSets(setSearchString, setSort, newProductTypeMode, setGroupingEnabled, boxOddsEnabled, state.packOpenMode);
  }

  function updateSetSort(newSortOrder: string) {
    setSetSort(newSortOrder);
    searchSets(setSearchString, newSortOrder, productTypeMode, setGroupingEnabled, boxOddsEnabled, state.packOpenMode);
  }

  function runSetSearch(newSearchTerm: string) {
    newSearchTerm = newSearchTerm.toUpperCase();
    setSetSearchString(newSearchTerm);
    searchSets(newSearchTerm, setSort, productTypeMode, setGroupingEnabled, boxOddsEnabled, state.packOpenMode);
  }

  function searchSets(searchString: string, sortOrder: string, productType: string, groupSets: boolean, boxOdds: boolean, openMode: string) {
    const typeFilter = [] as string[];
    switch (productType) {
      case 'Sets':
        typeFilter.push('set');
        break;
      case 'Bundles':
        if (openMode != 'Draft') typeFilter.push('tin');
        break;
      case 'Decks':
        if (openMode != 'Draft') typeFilter.push('deck');
        break;
      default:
        typeFilter.push('set');
        if (openMode != 'Draft') {
          typeFilter.push('tin');
          typeFilter.push('deck');
        }
        break;
    }
    const sortedSets: CardProduct[] = (Object.values(cardSets))
      .filter((cs: CardProduct) => {
        return (typeFilter.includes(cs.product_category) &&
        (boxOdds ? (cs as CardSet).box_odds_enabled : true) &&
        (searchString != '' ? (cs.name.toUpperCase().includes(searchString) || cs.prefix.includes(searchString) || cs.type.toUpperCase().includes(searchString)) : true));
      })
      .sort((a: CardProduct, b: CardProduct) => {
        if (sortOrder == 'name,asc') return a.name > b.name ? 1 : -1;
        else if (sortOrder == 'name,desc') return a.name < b.name ? 1 : -1;
        else if (sortOrder == 'date,desc') return a.release_date < b.release_date ? 1 : (a.release_date > b.release_date ? -1 : (a.name > b.name ? 1 : -1));
        else if (sortOrder == 'date,asc') return a.release_date > b.release_date ? 1 : (a.release_date < b.release_date ? -1 : (a.name > b.name ? 1 : -1));
        else { // Descending included count
          const aCount = state.packsToOpen[a.prefix + boxOddsEnabled ? 'Bo' : setTypeHeaderText[a.product_category].slice(0, 2)]?.count??0;
          const bCount = state.packsToOpen[b.prefix + boxOddsEnabled ? 'Bo' : setTypeHeaderText[a.product_category].slice(0, 2)]?.count??0;
          return aCount < bCount ? 1 : (aCount > bCount ? -1 : (a.name > b.name ? 1 : -1));
        }
      });

    const groupedSets: { [key: string]: string[] } = {};
    if (groupSets) {
      sortedSets.forEach((set: CardProduct) => {
        if (!groupedSets[set.type]) groupedSets[set.type] = [];
        groupedSets[set.type].push(set.prefix);
      });
    }
    else groupedSets[productType] = sortedSets.map((cs: CardProduct) => cs.prefix);

    setSetResults(groupedSets);
  }

  const moveSortablePackTrayEntry = useCallback((dragIndex: number, hoverIndex: number) => {
    setPacksToOpenDisplay((prevPacksToOpenDisplay: { [key: string]: PackCountInfo[] }) => {
      const newSetsArray = [...prevPacksToOpenDisplay['Sets']];
      const moveEntry = newSetsArray.splice(dragIndex, 1);
      newSetsArray.splice(hoverIndex, 0, moveEntry[0]); // Remove the dragged card from its current spot and move to the new one
      return {
        'Sets': newSetsArray,
        'Boxes': [] as PackCountInfo[],
        'Bundles': [] as PackCountInfo[],
        'Decks': [] as PackCountInfo[],
      };
    });
  }, []);

  const renderSortablePackTrayEntry = useCallback((entry: { prefix: string, text: string }, index: number) => {
    return (
      <SortablePackTrayEntry
        key={entry.prefix + index + 'sortentry'}
        text={entry.text}
        index={index}
        prefix={entry.prefix}
        currentDragEntry={currentDragEntry}
        setCurrentDragEntry={setCurrentDragEntry}
        moveEntry={moveSortablePackTrayEntry}
      />
    );
  }, [currentDragEntry]);

  return (
    <main>
      {exportString.exportString != '' ? <StringExportModal {...{
        id: 'config-export-modal',
        exportStringObj: exportString,
        dispatch: configExportSuccess,
      }} /> : ''}

      <div id='config-import-modal' className='modal'>
        <button className='modal-close' title='Close modal' onClick={closeConfigImportModal} />
        <div className='content-box modal-content'>
          <h5 style={{ marginBottom: '0px' }}>Import from Shareable Config</h5>
          <div className='vertical-form-text-input' style={{ textAlign: 'center' }}>
            <label htmlFor='config-input-field'>Config Text:</label>
            <br />
            <div className='text-input para-text-input' style={{ marginTop: '3px', width: '250px' }}>
              <textarea id='config-input-field' spellCheck={false} /* onKeyDown={(e) => {if (e.code === 'Enter') importFromConfigString();}} */ />
            </div>
          </div>
          {configLoading ?
            <img src={'/images/LoadingAnim.png'} width='30px' height='30px' style={{ margin: 'auto', verticalAlign: 'middle', marginRight: isScreen950 ? '5px' : '7px' }} /> :
            <div>
              <button className='black-button' style={{ marginRight: isScreen950 ? '5px' : '7px' }} title='Import pulls selections from Shareable Config text'
                onClick={importFromConfigString}><span>Import</span></button>
            </div>}
        </div>
      </div>

      <div id={'draftCheckModal'} className='modal'>
        <button className='modal-close' title='Close modal' onClick={closeDraftCheckModal} />
        <div className='content-box modal-content'>
          <h5 style={{ marginBottom: '0px' }}>Remove Incompatible Products</h5>
          <p className='largetext' style={{ paddingTop: '5px', marginBottom: '0px' }}>Box Odds, Bundles, and Decks are incompatible with solo draft and will be removed from your selection.</p>
          <button className='black-button white-button' style={{ marginRight: isScreen950 ? '5px' : '7px' }} title='Stay in Sealed mode' onClick={closeDraftCheckModal}>
            <span>Cancel</span>
          </button>
          <button className='black-button' style={{ marginRight: isScreen950 ? '5px' : '7px' }} title='Remove products and switch to Draft' onClick={acceptDraftCheckModal}><span>Continue</span></button>
        </div>
      </div>

      <Ad styleName='bar_ad' />

      <h1>Pack Opener</h1>
      <p className='content-box'>
        Select a number of units to open of each product you wish to pull, then press the Open Packs button.<br />Click on a pack to view a
        summary.<br /> Draft Mode enables picking individual cards from opened packs in a single player draft-like format, but excludes box odds, bundles, and starter
        decks.<br /> Pack and settings configurations can be imported and exported using the buttons. Draft selections can be reordered by clicking and dragging in the selection
        tray.<br />Config imports for draft mode allow advanced ordering by splitting multiple
        openings of one product.
      </p>

      <h2 style={{ marginTop: '20px' }}>Settings</h2>

      <div className='content-box' style ={{ padding: '10px', marginBottom: '0px' }}>
        <MultiToggleBox {...{
          uniqueID: 'producttypemode-toggle',
          disabledVar: false,
          options: ['All', 'Sets', 'Bundles', 'Decks'],
          defaultOption: 'All',
          optionWidth: '60px',
          toggleStateVar: productTypeMode,
          dispatch: updateProductTypeMode,
          style: { marginRight: isScreen950 ? '5px' : '10px' },
          title: 'Select product type(s) to show',
        }} />
        <div style={{ display: 'inline-block' }}>
          <div className={'checkbox' + (state.packOpenMode == 'Draft' ? ' checkbox-disabled' : '')} title={state.packOpenMode == 'Draft' ? 'Box odds not available for draft' : 'Show products with box odds'}
            onClick={state.packOpenMode != 'Draft' ? toggleBoxOdds : () => {}} style={{ marginRight: '5px' }}>
            <img className='checkbox-checkmark' src={Constants.imagePath + 'CheckmarkBlack.png'} width='20px' height='20px' style={{ display: (boxOddsEnabled ? 'inline-block' : 'none') }} />
          </div>
          <span className={'button-bar-label' + (state.packOpenMode == 'Draft' ? ' label-disabled' : '')}>Box Odds</span>
        </div>

        <div style={{ display: 'block', marginBottom: isScreen950 ? '3px' : '7px' }} />

        <span className='button-bar-label'>Sort:</span>
        <BlackDropdown {...{
          uniqueID: 'set-sort',
          isSelector: true,
          disabledVar: false,
          optionsList: [{ value: 'date,asc', text: 'Release Date (Asc)' } as DropdownOption,
          { value: 'date,desc', text: 'Release Date (Desc)' } as DropdownOption,
          { value: 'name,asc', text: 'Name (Asc)' } as DropdownOption,
          { value: 'name,desc', text: 'Name (Desc)' } as DropdownOption,
          { value: 'count,desc', text: 'Included Count' } as DropdownOption],
          startingOption: { value: 'date,asc', text: 'Release Date (Asc)' } as DropdownOption,
          defaultValue: '',
          defaultDisplay: '',
          nonSelectorDisplay: '',
          width: '160px',
          stateVar: setSort,
          dispatch: updateSetSort,
          title: 'Select field to sort products by',
          style: { marginRight: isScreen950 ? '5px' : '10px' },
        }} />

        <div style={{ display: 'inline-block' }}>
          <div className='checkbox' title='Group products by type' onClick={toggleSetGrouping} style={{ marginRight: '5px' }}>
            <img className='checkbox-checkmark' src={Constants.imagePath + 'CheckmarkBlack.png'} width='20px' height='20px' style={{ display: (setGroupingEnabled ? 'inline-block' : 'none') }} />
          </div>
          <span className='button-bar-label'>Group By Type</span>
        </div>

        <div style={{ display: 'block', marginBottom: isScreen950 ? '3px' : '7px' }} />

        <span className='button-bar-label'>Search:</span>
        <SearchBox {...{
          uniqueID: 'set-search',
          emptyDisplay: 'Search ' + productTypeMode.toLowerCase() + '...',
          width: '160px',
          stateVar: setSearchString,
          searchFunc: runSetSearch,
          style: { marginRight: '0px' },
          searchOnValueChange: true,
        }} />

        <div style={{ display: 'block', marginBottom: isScreen950 ? '3px' : '7px' }} />

        <button className='black-button text-icon-button' title='Import pulls selections from Shareable Config text' onClick={openConfigImportModal}
          style={{ marginRight: isScreen950 ? '5px' : '10px' }}>
          <img src={Constants.imagePath + 'Import.png'} width='18px' height='20px' style={{ margin: 'auto', marginLeft: '4px' }}/>
          <span style={{ marginLeft: '5px', marginRight: '5px' }}>Import</span>
        </button>
        <button className='black-button text-icon-button' title='Export pulls selections to Shareable Config text' onClick={exportToConfigString}
          style={{ marginRight: isScreen950 ? '5px' : '10px' }}>
          <img src={Constants.imagePath + 'Export.png'} width='18px' height='20px' style={{ margin: 'auto', marginLeft: '4px' }}/>
          <span style={{ marginLeft: '5px', marginRight: '5px' }}>Export</span>
        </button>
        <div className='checkbox' onClick={toggleDraftMode} style={{ marginRight: '5px', marginLeft: '0px' }}>
          <img className='checkbox-checkmark' src={Constants.imagePath + 'CheckmarkBlack.png'} width='20px' height='20px'
            style={{ display: (state.packOpenMode == 'Draft' ? 'inline-block' : 'none') }} />
        </div>
        <span className='button-bar-label' style={{ marginRight: '0px' }}>Draft Mode</span>
      </div>

      <Ad styleName='left_sidebar_ad_1400' />
      <Ad styleName='right_sidebar_ad_1400' />
      {(Object.keys(setResults).length > 0 && Object.values(setResults)[0].length > 0) ? (() => {
        return Object.keys(setResults)
          .sort((a: string, b: string) => (Constants.PackSort[a] > Constants.PackSort[b]) ? 1 : -1) // Sort for display convenience order
          .map((setType: string) => {
            const header: JSX.Element = <h2 style={{ marginTop: '35px' }}>
              {(Object.prototype.hasOwnProperty.call(setTypeHeaderText, setType) ? setTypeHeaderText[setType] : setType) + (boxOddsEnabled ? ' (Box)' : '')}
            </h2>;

            return (
              <div key={setType}>
                {header}
                <button className='black-button text-icon-button' title='Add one of all visible products in category' onClick={addAllFromCategory} data-settype={setType}
                  style={{ display: 'flex', margin: '-10px auto 5px auto' }}>
                  <img src={Constants.imagePath + 'BigPlus.png'} width='18px' height='20px' style={{ margin: 'auto', marginLeft: '4px' }}/>
                  <span style={{ marginLeft: '5px', marginRight: '5px' }}>Add 1 Of Each</span>
                </button>

                <div className='packs-container content-box'>
                  {setResults[setType]
                    .map((prefix: string) => {
                      const setname: string = cardSets[prefix].name;
                      const productType = boxOddsEnabled ? 'Bo' : setTypeHeaderText[cardSets[prefix].product_category].slice(0, 2);
                      const existingCount: number | undefined = state.packsToOpen[prefix + productType]?.count;

                      return <div className='cardset-box' key={prefix + productType}>
                        <a className='cardset-link' href={Constants.productLink(setname)} target='_blank' rel='noreferrer' title='Open product&apos;s Yugipedia page'>
                          <h4 style={packNameRestyle(setname)}>{cropName(setname)}</h4>
                          <div className='cardset-date-tip' style={{ display: 'inline-block' }}>
                            <div className='cardset-date-tip-text'>
                              <span>
                                TCG Release:
                                <br />
                                {cardSetDates[setname].toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' })}
                              </span>
                            </div>
                            <LazyImage {...{
                              src: Constants.packImagePath + prefix + '.jpg',
                              width: 0,
                              height: isScreen950 ? 130 : 200,
                              alt: setname,
                              className: 'cardset-image',
                              style: {},
                              onError: replaceWithDefaultPackImg,
                              onLoad: () => {},
                            }}/>
                          </div>
                        </a>
                        <div className='pack-num-entry' style={{ width: 'initial' }}>
                          <label>Count:
                            <input type='number' min='0' max={productType == 'Bo' ? Constants.boxOpenLimit : Constants.packOpenLimit} pattern='[0-9]{2}'
                              data-packprefix={prefix} onInput={packNumInputCheck} value={(typeof existingCount !== 'undefined') ? existingCount : '' } />
                            {boxOddsEnabled ? ' x' + (cardSets[prefix] as CardSet).pack_count : ''}
                          </label>
                        </div>
                      </div>;
                    })}
                </div>
              </div>
            );
          });
      })() :
        <p className='content-box' style={{ marginTop: '20px' }}>
          {'No matching products ' + (state.packOpenMode == 'Draft' ? 'available for draft' : (boxOddsEnabled ? 'with box odds' : '')) + ' found.'}
        </p>}

      <div id='open-packs-tray' style={{ right: '-5px' }}>
        <button id='collapse-tray-button' title='Show/hide pack selection' type='button' onClick={toggleCollapsibleBox}>
          {/* Arrow image courtesy of Smashicons, via flaticons.com */}
          <img src={Constants.imagePath + 'RightArrow.png'} alt='Toggle' width='28px' height='28px' />
        </button>
        <span id='open-packs-tray-title'>Selected Sets:</span>
        <div id='packstoopen-scroll'>
          <DndProvider options={Constants.HTML5toTouch as any}>
            {
              state.packOpenMode != 'Draft' ? packsToOpenDisplay['Sets']
                .map((pack: PackCountInfo) => {
                  return <p key={pack.prefix + 'tray'}>{pack.count}x {cardSets[pack.prefix.split('_')[0].slice(0, -2)].name}</p>;
                }) :
                packsToOpenDisplay['Sets']
                  .map((pack: PackCountInfo, index: number) => {
                    return renderSortablePackTrayEntry({ prefix: pack.prefix, text: pack.count + 'x ' + cardSets[pack.prefix.split('_')[0].slice(0, -2)].name }, index);
                  })
            }

            {
              state.packOpenMode != 'Draft' ? Object.entries(packsToOpenDisplay).map((entry: [string, PackCountInfo[]]) => { // Sets is the only valid entry type for draft anyways
                if (entry[0] != 'Sets' && entry[1].length > 0) {
                  return (<div key={entry[0] + 'Display'}>
                    <div style={{ borderTop: '1px solid #606060', borderRadius: '5px', marginRight: 'auto', marginLeft: '20px', marginTop: '10px', marginBottom: '0px', width: '80%' }}/>
                    <span id='open-packs-tray-title' style={{ marginLeft: '40px', marginBottom: '8px' }}>{'Selected ' + entry[0] + ':'}</span>
                    <div style={{ clear: 'both' }} />{entry[1]
                      // .sort((a: PackCountInfo, b: PackCountInfo) => {
                      //   return (Constants.cardSets[a.prefix].release_date > Constants.cardSets[b.prefix].release_date) ? 1 : -1;
                      // })
                      .map((pack: PackCountInfo) => {
                        return <p key={pack.prefix + entry[0] + 'tray'}>{pack.count + 'x' +
                          (entry[0] == 'Boxes' ? '(' + (cardSets[pack.prefix.split('_')[0].slice(0, -2)] as CardSet).pack_count + ')' : '') + ' ' + cardSets[pack.prefix.split('_')[0].slice(0, -2)].name}</p>;
                      })}
                  </div>);
                }
              }) : ''
            }

          </DndProvider>
        </div>
        <button id='open-packs-button' title='Open selected products' type='button' disabled={openButtonDisabled} onClick={goToNextPage} style={{ display: 'inline-block' }}>
          <img src={Constants.imagePath + 'CardIcon.png'} alt='' width='25px' height='25px' />
          <span>{(state.packOpenMode == 'Draft' ? 'Draft' : 'Open') + ' Packs'}</span>
        </button>
        <span id='clear-all-button' className='forgot-pass-button' title='Clear selection' onClick={clearSelectedPacks}
          style={{ color: 'var(--themeWhite)', fontSize: '14px', float: 'right', margin: '12px 25px 10px 7px', display: openButtonDisabled ? 'none' : 'inline-flex' }}>
          Clear All
        </span>
      </div>

      <Ad styleName='bar_ad_footer' />
    </main>
  );
}
