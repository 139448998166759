import { useEffect, useState } from 'react';
import * as Constants from '../utils/Constants';
import {
  passwordValidator,
  confirmPasswordValidator,
} from '../utils/validators/LoginAndRegistrationValidator';
import makeApiCall from '../utils/ApiMiddleware';
import { closeBasicModal, openBasicModal, openErrorModal, openResetPassModal } from '../utils/BasicModalFuncs';
import { useParams, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { contactString } from '../utils/Constants';

export default function VerifyLanding(props: { refresh: any }) {
  const [verifyInProgress, setVerifyInProgess] = useState(true);
  const [verifySuccessful, setVerifySuccessful] = useState(false);
  const [timerDisplay, setTimerDisplay] = useState(5);
  const history = useHistory();
  const { token } = useParams<any>();
  let redirectTimer: number = 5;

  useEffect(() => { // Mount
    verifyEmail();
  }, []);

  function verifyEmail() {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: '',
    };
    setVerifyInProgess(true);
    makeApiCall(Constants.cookies.get('refresh'), Constants.cookies.get('userId'), Constants.apiPath.concat('user/verify/' + token), requestOptions)
      .then(async (response) => {
        setVerifyInProgess(false);

        if (!response.ok) {
          const resError = response.status;
          openErrorModal('Issue verifying duelist account.<br />Error Code: ' + resError + '<br />' + contactString);
          return Promise.reject(resError);
        }

        const data = await response.json();

        if (data.message == 'UserNotFound') {
          openErrorModal('Issue verifying duelist account - link might be invalid.<br />' + contactString);
        }
        else if (data.message.includes('Expired')) {
          openErrorModal('Issue verifying duelist account - verify link expired.<br />You can send a new email from the <a class="in-text-link" href="' + Constants.frontEndPath + 'Account">Account Page</a>.');
        }
        else if (data.success) {
          openBasicModal('Duelist Account Verified', 'Your account has been verified. Redirecting to Pack Opener in 5 seconds.', 'Ok');
          setVerifySuccessful(true);
          setTimeout(redirectCountdown, 1000);
        }
        else {
          openErrorModal('Duelist account verification failed.<br />If this persists, ' + contactString);
        }
      });
  }

  function redirectCountdown() {
    if (redirectTimer > 1) {
      redirectTimer = redirectTimer - 1;
      setTimerDisplay(redirectTimer);
      setTimeout(redirectCountdown, 1000);
    }
    else {
      history.push('/PackOpener');
      closeBasicModal();
    }
  }

  return (
    <main>
      <Helmet>
        <meta content="YGO Prog" property="og:title" />
        <meta
          content="YGO Prog is a tool for simulating Yu-Gi-Oh! pack opening, collection management, deck building, banlist building, and more!"
          property="og:description"
        />
        <meta content="https://www.ygoprog.com" property="og:url" />
        <meta
          name="description"
          content="YGO Prog is a tool for simulating Yu-Gi-Oh! pack opening, collection management, deck building, banlist building, and more!"
        />
        <title>YGO Prog</title>
      </Helmet>
      <h1 style={{ marginBottom: '10px' }}>Duelist Account Verification</h1>
      <div className='content-box' style={{ padding: '12px 20px' }}>

        {verifySuccessful ?
          <p style={{ margin: '0px', padding: '0px' }}>Duelist account verified. Redirecting to Pack Opener in {timerDisplay}...</p> :
          verifyInProgress ?
            <img src={'/images/LoadingAnim.png'} width='60px' height='60px' style={{ margin: 'auto', verticalAlign: 'middle', marginRight: '0px' }} /> :
            <div>
              <p style={{ margin: '0px', padding: '0px' }}>Duelist account verification failed.</p>
              <button className='black-button' type='button' title='Retry verification' onClick={verifyEmail} style={{ padding: '0px 25px', marginTop: '7px' }}><span>Retry Verification</span></button>
            </div>}
      </div>
    </main>
  );
}
