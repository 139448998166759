import HoverableCard from './HoverableCard';
import { useDrag } from 'react-dnd';
import { useContext, useEffect } from 'react';
import { BuilderContext } from '../utils/Contexts';
import { ExtraDeckSubtypes, imagePath } from '../utils/Constants';

interface DropCardProps {
  card: DropCard
  dropZone: string,
  limit?: number,
  limitSize: string,
}

export default function DropCard(props: DropCardProps) {

  const context = useContext(BuilderContext);

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: 'dropcard',
      item: () => {
        context.setCurrentDragCard(props.card, props.dropZone, dropDrag);
        return { uniqueID: props.card.uniqueID, originalZone: props.dropZone } as DragCard;
      },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
      isDragging: (monitor) => {
        return context.currentDragCard.card?.uniqueID == props.card.uniqueID;
      },
      end: (item: DragCard, monitor) => {
        const didDrop = monitor.didDrop();
        if (!didDrop) {
          if (context.dragTask.flag != null) {
            clearTimeout(context.dragTask.flag);
            context.dragTask.flag = null;
          }
          const itemOffset = monitor.getClientOffset();
          if (itemOffset != null) {
            const zones = Object.keys(context.zoneCalcShifts);
            for (let i: number = 0; i < 3; i++) {
              const zone = zones[i];
              const boundRect = document.getElementById(zone + 'Drop')!.getBoundingClientRect();
              if ((itemOffset.x >= Math.floor(boundRect.left) && itemOffset.x <= Math.ceil(boundRect.right)) &&
              (itemOffset.y >= Math.floor(boundRect.top) && itemOffset.y <= Math.ceil(boundRect.bottom))) {
                context.zoneCalcShifts[zone](item, monitor, true);
                return;
              }
            }
          }
          context.removeCard.current('', '', true); // Was particularly nasty to figure this out- when we swap zones this becomes uncoupled and can't update, so we have to use refs here
        }
      },
    }),
    [props.card, props.dropZone],
  );

  useEffect(() => {
    if (isDragging) {
      context.currentDragCard.dropCallback();
      context.setCurrentDragCardDropCallback(dropDrag);
    }
  }, []);

  function dropDrag() { // Use this to do any state changes on drop in case we end up dropping a different remounted component
    // console.log('drag dropped');
  }

  function rightClickRemove(e: any) {
    e.preventDefault();
    if (context.builderType == 'd' && e.shiftKey) { // Move to opposite zone in deckbuilder
      const deckType = (props.card.subtype.some((st: string) => ExtraDeckSubtypes.includes(st)) ? 'e' : 'm');
      context.moveCard.current(
        props.dropZone,
        props.card.uniqueID,
        props.dropZone == 's' ? deckType : 's',
        -1,
        false,
        false);
    }
    else if (context.builderType == 'b' && e.shiftKey || e.ctrlKey) {
      let newZone = 'sf';
      switch (props.dropZone) {
        case 'sf':
          if (e.shiftKey) newZone = 'su';
          else newZone = 'sl';
          break;
        case 'sl':
          if (e.shiftKey) newZone = 'sf';
          else newZone = 'ss';
          break;
        case 'ss':
          if (e.shiftKey) newZone = 'sl';
          else newZone = 'su';
          break;
        case 'su':
          if (e.shiftKey) newZone = 'ss';
          else newZone = 'sf';
          break;
      }

      context.moveCard.current(
        props.dropZone,
        props.card.uniqueID,
        newZone,
        -1,
        false,
        false);
    }
    else context.removeCard.current(props.dropZone, props.card.uniqueID, false);
  }

  return (
    <div id={props.card.uniqueID+'dropcard'} className='drop-card' ref={drag}>
      <HoverableCard {...{
        cardID: props.card.id,
        cardName: props.card.name,
        width: 91,
        height: 132.71,
        beforeElement: props.limit !== undefined ? <img className='lf-icon' src={imagePath + 'Limit' + props.limit + '.png'}
          alt={props.limit.toString()} width={props.limitSize} height={props.limitSize} /> : null,
        afterElement: null,
        style: { margin: '0px', width: '100%' },
        autosize: true,
        contextMenu: rightClickRemove,
      }} classNames={'drop-card' + (isDragging ? ' drag-preview' : '')} disableHover={isDragging} />
    </div>
  );
}
