import { Helmet } from 'react-helmet';

export default function PagePlaceholder(props: { refresh: any }) {
  return (
    <main>
      <Helmet>
        <meta content="YGO Prog" property="og:title" />
        <meta
          content="YGO Prog is a tool for simulating Yu-Gi-Oh! pack opening, collection management, deck building, banlist building, and more!"
          property="og:description"
        />
        <meta content="https://www.ygoprog.com" property="og:url" />
        <meta
          name="description"
          content="YGO Prog is a tool for simulating Yu-Gi-Oh! pack opening, collection management, deck building, banlist building, and more!"
        />
        <title>YGO Prog</title>
      </Helmet>
      <h1>Coming Soon!</h1>
      <p className='content-box'>
        Pardon our dust! This section is a work in progress, please check back later for the full app.
      </p>
    </main>
  );
}
