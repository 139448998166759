import { useState, useEffect, useCallback, useRef, useContext, CSSProperties } from 'react';
import { cookies, onCookieChangeListeners, apiPath,
  sessionMemory, imagePath, cardLink, HTML5toTouch,
  ExtraDeckSubtypes, exportYdk, shuffle, shouldPagesRefresh,
  MAIN_DECK_MAX, EXTRA_DECK_MAX, SIDE_DECK_MAX, loadDeck, sortOrderOptions,
  filterSearchSortOptions, cardCategoryOptions, cardTypeOptions, subtypeOptions,
  attributeOptions, levelOptions, linkMarkerOptions, multiSelectStyles, zoneSortFunc, builderSearchSort,
  getDateString, DATE_MAX, DATE_MIN, rarityOptions, RarityCode, frontEndPath, contactString, lowercaseContactString } from '../utils/Constants';
import BlackDropdown from './BlackDropdown';
import SearchBox from './SearchBox';
import makeApiCall from '../utils/ApiMiddleware';
import { DndProvider } from 'react-dnd-multi-backend';
import DropZone from './DropZone';
import { AppContext, BuilderContext } from '../utils/Contexts';
import { openErrorModal } from '../utils/BasicModalFuncs';
import { altIdMap, cards, cardSetDates, cardsLoaded, filteredCardSearch, getBinderCards } from '../utils/CardDataCache';
import WindowedSelect, { MultiValue } from 'react-windowed-select';
import Select from 'react-select';
import { FixedSizeList } from 'react-window';
import BuilderSearchCard from './BuilderSearchCard';
import DeleteConfirmModal from './DeleteConfirmModal';
import { Prompt, useHistory, useLocation } from 'react-router-dom';
import PerformanceWarningModal from './PerformanceWarningModal';
import ZonesImageExport from './ZonesImageExport';
import queryString from 'query-string';
import StringExportModal from './StringExportModal';
import * as ydke from 'ydke';
import localforage from 'localforage';
import { Helmet } from 'react-helmet';
import Ad from './Ad';
import SaveAsGroupModal from './SaveAsGroupModal';
import GroupManagementWindow from './GroupManagementWindow';

const currentDragCard = { zone: '', index: -1, card: null as DropCard | null, dropCallback: null as any }; // I don't have these as state because they need to be updated immediately for processing so setState doesn't cut it
const zoneCalcShifts = { 'm': null as any, 's': null as any, 'e': null as any };
const limitOptions = [
  { value: 0, label: 'Forbidden' },
  { value: 1, label: 'Limited' },
  { value: 2, label: 'Semi-Limited' },
  { value: 3, label: 'Unlimited' },
];

let queuedCurrentDeck: string|null = null;
let searchTask: any;
let popupFade: any;
let checkForCardsTask: any;
const dragTask: { flag: NodeJS.Timeout | null } = { flag: null };

export default function DeckBuilder(props: { refresh: any }) {
  const [username, setUsername] = useState(cookies.get('user'));
  const [deckLoading, setDeckLoading] = useState(false);
  const [binderList, setBinderList] = useState<DropdownOption[]>([]);
  const [decksList, setDecksList] = useState<DropdownOption[]>([]);
  const [deckGroupsList, setDeckGroupsList] = useState<DropdownOption[]>([]);
  const [mainDeck, setMainDeck] = useState<DropCard[]>([]);
  const [extraDeck, setExtraDeck] = useState<DropCard[]>([]);
  const [sideDeck, setSideDeck] = useState<DropCard[]>([]);
  const [mainDeckCounts, setMainDeckCounts] = useState({ Monster: 0, Spell: 0, Trap: 0 }); // We do all the counting for these at the point of change to avoid extra loops
  const [extraDeckCounts, setExtraDeckCounts] = useState({ Fusion: 0, Synchro: 0, Xyz: 0, Link: 0 });
  const [sideDeckCounts, setSideDeckCounts] = useState({ Monster: 0, Spell: 0, Trap: 0 });
  const [currentBinder, setCurrentBinder] = useState(sessionMemory.lastBinderSelected??'');
  const [banlistsList, setBanlistsList] = useState<DropdownOption[]>([]);
  const [currentBanlist, setCurrentBanlist] = useState('');
  const [currentBanlistGroup, setCurrentBanlistGroup] = useState('');
  const [banlistData, setBanlistData] = useState<{ [id: number]: number }>({});
  const [banlistDateRange, setBanlistDateRange] = useState([DATE_MIN, DATE_MAX]);
  const [enforceLimits, setEnforceLimits] = useState(false);
  const [minimizeMainDeck, setMinimizeMainDeck] = useState(false);
  const [minimizeExtraDeck, setMinimizeExtraDeck] = useState(false);
  const [minimizeSideDeck, setMinimizeSideDeck] = useState(false);
  const [groupManagementOpen, setGroupManagementOpen] = useState(false);

  const [searchField, setSearchField] = useState<string>('all');
  const [searchString, setSearchString] = useState<string>('');
  const [setsFilter, setSetsFilter] = useState<string[]>([]);
  const [rarityFilter, setRarityFilter] = useState<string[]>([]);
  const [limitFilter, setLimitFilter] = useState<number[]>([]);
  const [categoryFilter, setCategoryFilter] = useState<string[]>([]);
  const [typeFilter, setTypeFilter] = useState<string[]>([]);
  const [subtypeFilter, setSubtypeFilter] = useState<string[]>([]);
  const [attributeFilter, setAttributeFilter] = useState<string[]>([]);
  const [levelFilter, setLevelFilter] = useState<number[]>([]);
  const [linkMarkersFilter, setLinkMarkersFilter] = useState<string[]>([]);
  const [pendScaleFilter, setPendScaleFilter] = useState<string[]>([]);
  const [atkFilter, setAtkFilter] = useState<number[]>([]);
  const [defFilter, setDefFilter] = useState<number[]>([]);
  const [dateFilter, setDateFilter] = useState<Date[]>([]);
  const setsFilterField = useRef<any>();
  const rarityFilterField = useRef<any>();
  const limitFilterField = useRef<any>();
  const categoryFilterField = useRef<any>();
  const typeFilterField = useRef<any>();
  const subtypeFilterField = useRef<any>();
  const attributeFilterField = useRef<any>();
  const levelFilterField = useRef<any>();
  const linkMarkersFilterField = useRef<any>();
  const pendScaleFilterField = useRef<any>();
  const atkFilterFieldLeft = useRef<any>();
  const atkFilterFieldRight = useRef<any>();
  const defFilterFieldLeft = useRef<any>();
  const defFilterFieldRight = useRef<any>();
  const dateFilterFieldLeft = useRef<any>();
  const dateFilterFieldRight = useRef<any>();

  const [sortField, setSortField] = useState<string>('name');
  const [sortOrder, setSortOrder] = useState<string>('asc');

  const [binderCards, setBinderCards] = useState<BinderCard[]>([]);
  const [searchResults, setSearchResults] = useState<BinderCard[] | Card[]>([]);
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchActionsDisabled, setSearchActionsDisabled] = useState(false);
  const isMounted = useRef(false);
  const binderMounted = useRef(false);
  const searchMounted = useRef(false);
  const [cardsAreLoaded, setCardsAreLoaded] = useState(cardsLoaded.flag);

  const [saveAsDisabled, setSaveAsDisabled] = useState(false);
  const [savingDeck, setSavingDeck] = useState(false);
  const [currentDeck, setCurrentDeck] = useState('');
  const [currentDeckName, setCurrentDeckName] = useState('');
  const [currentDeckGroup, setCurrentDeckGroup] = useState('');
  const [selectedDeckGroup, setSelectedDeckGroup] = useState('');
  const [deckIsPublic, setDeckIsPublic] = useState(false);
  const [confirmMatchString, setConfirmMatchString] = useState('');
  const [cardAddedPopupText, setCardAddedPopupText] = useState('');

  const { refreshRouteFunc, refresh, isScreen1300Query, isScreen1150Query, isScreen950Query } = useContext(AppContext);
  const [isScreen1300, setIsScreen1300] = useState(isScreen1300Query.matches);
  const [isScreen1150, setIsScreen1150] = useState(isScreen1150Query.matches);
  const [isScreen950, setIsScreen950] = useState(isScreen950Query.matches);
  const [navToLocation, setNavToLocation] = useState<any>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const [deckSavedOrExported, setDeckSavedOrExported] = useState(true);
  const [exportingImage, setExportingImage] = useState(false);
  const [exportUrl, setExportUrl] = useState<{ type: string, exportString: string }>({ type: 'YDKe', exportString: '' });
  const history = useHistory();
  const { search } = useLocation();

  const mainDeckRef = useRef<DropCard[]>([]); // To avoid having to cascade re-set a bunch of lodged callbacks every frame; the sets don't change so we don't need them here
  const mainDeckCountsRef = useRef<any>();
  const extraDeckRef = useRef<DropCard[]>([]);
  const extraDeckCountsRef = useRef<any>();
  const sideDeckRef = useRef<DropCard[]>([]);
  const sideDeckCountsRef = useRef<any>();
  const enforceLimitsRef = useRef<any>();
  const banlistRef = useRef<any>();
  const banlistDatesRef = useRef<any>();
  mainDeckRef.current = mainDeck;
  mainDeckCountsRef.current = mainDeckCounts;
  extraDeckRef.current = extraDeck;
  extraDeckCountsRef.current = extraDeckCounts;
  sideDeckRef.current = sideDeck;
  sideDeckCountsRef.current = sideDeckCounts;
  enforceLimitsRef.current = enforceLimits;
  banlistRef.current = banlistData;
  banlistDatesRef.current = banlistDateRange;

  const dragLock = useRef<boolean>();

  useEffect(() => { // Mount
    isScreen1300Query.addEventListener('change', checkScreen1300); // To avoid re-rendering the whole page and losing data, we have to listen and state change on the sublevel
    isScreen1150Query.addEventListener('change', checkScreen1150);
    isScreen950Query.addEventListener('change', checkScreen950);
    updateBinderList();
    updateDecksList();
    updateBanlistsList();
    onCookieChangeListeners.push(cookieRefresh);
    // const searchWorker = new Worker('FilteredCardSearch.ts');
    if (!cardsAreLoaded) {
      checkForCardsTask = checkForCardsLoop();
    }

    shouldPagesRefresh.val = false;

    dragLock.current = false;
    return () => { // Unmount
      isScreen1300Query.removeEventListener('change', checkScreen1300);
      isScreen1150Query.removeEventListener('change', checkScreen1150);
      isScreen950Query.removeEventListener('change', checkScreen950);
      window.onbeforeunload = null;
      if (!shouldPagesRefresh.val) {
        shouldPagesRefresh.val = true;
      }
      onCookieChangeListeners.splice(onCookieChangeListeners.indexOf(cookieRefresh), 1);
      if (checkForCardsTask != null) {
        clearTimeout(checkForCardsTask);
        checkForCardsTask = null;
      }
    };
  }, []);

  useEffect(() => {
    if (deckSavedOrExported) window.onbeforeunload = null;
    else {
      if (search != '') history.push({ search: '' });
      window.onbeforeunload = (e) => {
        e.preventDefault();
        e.returnValue = 'You have not saved or exported changes to your deck, if you leave this page it will be lost.';
        return 'You have not saved or exported changes to your deck, if you leave this page it will be lost.'; // Custom message doesn't work on most browsers
      };
    }
  }, [deckSavedOrExported]);

  useEffect(() => {
    if (confirmedNavigation) {
      if (navToLocation) {
        history.push(navToLocation.pathname);
        refreshRouteFunc(!refresh);
      }
    }
  }, [confirmedNavigation]);

  useEffect(() => {
    if (confirmMatchString != '') {
      setSaveAsDisabled(true);
      document.getElementById('delete-confirm-modal')!.style.display = 'flex';
    }
    else setSaveAsDisabled(false);
  }, [confirmMatchString]);

  useEffect(() => {
    if (isMounted.current && cardsAreLoaded) {
      if (searchTask != null) clearTimeout(searchTask);
      searchTask = setTimeout(searchCards, 300);
      if (searchString != '' || setsFilter.length + rarityFilter.length + limitFilter.length +
      categoryFilter.length + typeFilter.length + subtypeFilter.length + attributeFilter.length + levelFilter.length + linkMarkersFilter.length +
      pendScaleFilter.length + atkFilter.length + defFilter.length + dateFilter.length > 0) {
        document.getElementById('clear-all-button')!.style.display = 'inline-block';
      }
      else document.getElementById('clear-all-button')!.style.display = 'none';
    }
    else isMounted.current = true;
  }, [setsFilter, rarityFilter, limitFilter, categoryFilter, typeFilter, subtypeFilter, attributeFilter, levelFilter,
    linkMarkersFilter, pendScaleFilter, atkFilter, defFilter, dateFilter, searchField, searchString, banlistData, banlistDateRange]);

  useEffect(() => {
    setSearchActionsDisabled(false);
    setSearchLoading(false);
  }, [searchResults]);

  useEffect(() => {
    if (cardsAreLoaded) {
      sessionMemory.lastBinderSelected = currentBinder;
      if (currentBinder != '') {
        setSearchActionsDisabled(true);
        setSearchLoading(true);

        getBinderCards(currentBinder, true)
          .then(async (results) => {
            if (results != null) {
              setBinderCards(results);
            }
            else {
              setBinderCards([]);
            }
          });
      }
      else {
        if (searchTask != null) clearTimeout(searchTask);
        searchCards();
      }
    }
  }, [currentBinder, cardsAreLoaded]);

  useEffect(() => {
    if (cardsAreLoaded) {
      if (loadDeck.mainDeck.length + loadDeck.extraDeck.length + loadDeck.sideDeck.length > 0) {
        setMainDeck(loadDeck.mainDeck); // Load in the the deck then clear it
        setExtraDeck(loadDeck.extraDeck);
        setSideDeck(loadDeck.sideDeck);

        loadDeck.mainDeck = [];
        loadDeck.extraDeck = [];
        loadDeck.sideDeck = [];
      }
      const values = queryString.parse(search);
      if (values.d !== undefined) {
        getPublicDeck(values.d);
      }
    }
  }, [cardsAreLoaded]);

  useEffect(() => {
    if (binderMounted.current) {
      if (searchTask != null) clearTimeout(searchTask);
      searchCards();
    }
    else binderMounted.current = true;
  }, [binderCards]);

  useEffect(() => {
    if (searchMounted.current && !searchLoading) {
      setSearchLoading(true);
      setSearchActionsDisabled(true);
      sortSearch();
    }
    else searchMounted.current = true;
  }, [sortField, sortOrder]);

  useEffect(() => {
    queuedCurrentDeck = null;
    if (!saveAsDisabled && currentDeck != '') {
      getCurrentDeck();
    }
    else setSaveAsDisabled(false);
  }, [currentDeck]);

  useEffect(() => {
    if (queuedCurrentDeck != null) setCurrentDeck(queuedCurrentDeck);
    setDeckGroupsList([{ text: 'None', value: '' } as DropdownOption,
      ...decksList.filter((group) => group.text != '')
        .map((group) => {return { text: group.text, value: group.text, subItems: undefined } as DropdownOption;})]);
    setSelectedDeckGroup('');
  }, [decksList]);

  useEffect(() => {
    if (currentBanlist != '') {
      getCurrentBanlist();
    }
    else {
      setBanlistData({});
      setBanlistDateRange([DATE_MIN, DATE_MAX]);
    }
  }, [currentBanlist]);

  useEffect(() => {
    dragLock.current = false;
  }, [mainDeck, extraDeck, sideDeck]);

  function showCardAddedPopup() {
    const popup = document.getElementById('card-added-popup')!;
    popup.style.transition = '0s';
    popup.style.opacity = '100';
    popup.style.visibility = 'visible';

    if (popupFade != null) clearTimeout(popupFade);
    popupFade = setTimeout(function() {
      popup.style.transition = '.5s';
      popup.style.opacity = '0';
      popup.style.visibility = 'hidden';
    }, 500);
  }

  function checkScreen1300() {
    setIsScreen1300(isScreen1300Query.matches);
  }

  function checkScreen1150() {
    setIsScreen1150(isScreen1150Query.matches);
  }

  function checkScreen950() {
    setIsScreen950(isScreen950Query.matches);
  }

  function cookieRefresh() {
    setUsername(cookies.get('user'));
    updateBinderList();
    updateDecksList();
    updateBanlistsList();
  }

  function checkForCardsLoop() {
    return setTimeout(() => {
      checkForCardsTask = null;
      if (cardsLoaded.flag) setCardsAreLoaded(true);
      else {
        checkForCardsTask = checkForCardsLoop();
      }
    }, 200);
  }

  function updateBinderList() {
    localforage.getItem('binders').then((binders: any) => {
      if (binders != null && binders.length > 0) {
        const binderOpts: DropdownOption[] = binders.map((b: any) => {
          return { value: b.id, text: b.name } as DropdownOption;
        });
        binderOpts.unshift({ value: '', text: 'All Cards' } as DropdownOption);

        setBinderList(binderOpts);
      }
      else {
        setBinderList([]);
        setCurrentBinder('');
        sessionMemory.lastBinderSelected = '';
      }
    })
      .catch(() => {
        openErrorModal('Issue getting binders for Deck Builder.<br />' + contactString);
      });
  }

  function updateDecksList(newCurrent: string|null = null) {
    localforage.getItem('decks').then((decks: any) => {
      if (decks != null && decks.length > 0) {
        queuedCurrentDeck = newCurrent;
        setDecksList(decks);
      }
      else {
        setDecksList([]);
        setCurrentDeck('');
        setCurrentDeckName('');
        setCurrentDeckGroup('');
        setDeckIsPublic(false);
      }
    })
      .catch(() => {
        openErrorModal('Issue getting decks for Deck Builder.<br />' + contactString);
      });
  }

  function updateBanlistsList() {
    makeApiCall(cookies.get('refresh'), cookies.get('userId'), apiPath + 'banlist/common', {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    })
      .then(async (response) => {
        if (!response.ok) {
          const error = response.status;
          openErrorModal('Issue retrieving common banlists.<br />' +
            (error == 401 ? '<br />Try logging out and logging back in.<br />If this persists, ' + lowercaseContactString : '') +
            (error == 400 ? '<br />You have been inactive for a long time. Please log out and back in.<br /><br />If this persists, ' + lowercaseContactString : '') +
            (error != 401 && error != 400 ? '<br />' + contactString : ''));
          return Promise.reject(error);
        }

        const data = await response.json();

        localforage.getItem('banlists').then((banlists: any) => {
          if (banlists != null && banlists.length > 0) {
            setBanlistsList([{ value: '', text: 'No Banlist' }, {
              value: 'group', text: 'Common', subItems: data.map((banlist: any) => {return { value: banlist._id, text: banlist.name };})
                .sort((a: any, b: any) => {return a.text == b.text ? 0 : a.text > b.text ? -1 : 1;}),
            },
            ...banlists]);
          }
          else {
            setBanlistsList([{ value: '', text: 'No Banlist' } as DropdownOption, {
              value: 'group', text: 'Common', subItems: data.map((banlist: any) => {return { value: 'Common/' + banlist._id, text: banlist.name };})
                .sort((a: any, b: any) => {return a.text == b.text ? 0 : a.text > b.text ? -1 : 1;}),
            } as DropdownOption]);
            setBanlistData({});
            setBanlistDateRange([DATE_MIN, DATE_MAX]);
            setCurrentBanlist('');
          }
        })
          .catch(() => {
            openErrorModal('Issue getting banlists for Deck Builder.<br />' + contactString);
          });
      });
  }

  function getCurrentBanlist() {
    if (currentBanlist == '') return;
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + cookies.get('token') },
    };
    setDeckLoading(true);
    makeApiCall(cookies.get('refresh'), cookies.get('userId'), apiPath + 'banlist/' + (typeof(username) == 'undefined' ? 'public/' :
      (currentBanlistGroup != '' ? (currentBanlistGroup + '/') : '')) + currentBanlist, requestOptions)
      .then(async (response) => {
        if (!response.ok) {
          const error = response.status;
          openErrorModal('Issue retrieving banlist.<br />' +
            (error == 401 ? '<br />Try logging out and logging back in.<br />If this persists, ' + lowercaseContactString : '') +
            (error == 400 ? '<br />You have been inactive for a long time. Please log out and back in.<br /><br />If this persists, ' + lowercaseContactString : '') +
            (error != 401 && error != 400 ? '<br />' + contactString : ''));
          setDeckLoading(false);
          return Promise.reject(error);
        }

        const data = await response.json();

        const newBanlistData: { [id: number]: number } = {};
        for (const cardId of data.forbidden) {
          newBanlistData[cardId] = 0;
        }
        for (const cardId of data.limited) {
          newBanlistData[cardId] = 1;
        }
        for (const cardId of data.semi_limited) {
          newBanlistData[cardId] = 2;
        }
        setBanlistData(newBanlistData);
        setBanlistDateRange([data.start_date !== undefined ? new Date(data.start_date) : DATE_MIN,
          data.end_date !== undefined ? new Date(data.end_date) : DATE_MAX]);
        setDeckLoading(false);
      });
  }

  function toggleEnforceLimits() {
    const newEnforceLimits = !enforceLimits;
    setEnforceLimits(newEnforceLimits);
  }

  function toggleResultsBox() {
    const resultsBox = document.getElementById('deckbuilder-results')!;
    if (resultsBox.style.left == '' || resultsBox.style.left.startsWith('-')) resultsBox.style.left = '0px';
    else resultsBox.style.left = '-' + (resultsBox.clientWidth + 2) + 'px';
  }

  function toggleFilterBox() {
    const filterBox = document.getElementById('deckbuilder-filters')!;
    if (filterBox.style.right == '' || filterBox.style.right.startsWith('-')) filterBox.style.right = '0px';
    else filterBox.style.right = '-' + (filterBox.clientWidth + 2) + 'px';
  }

  function setFilter(selected: MultiValue<{value: string, label: string}> | null, setFunc: any) {
    if (selected != null) setFunc(selected.map((val: {value: string, label: string}) => val.value));
    else setFunc([]);
  }

  function rangeFilterOnChange(e: any) { // There's gotta be a better way to condense this
    let numRange: number[];
    let dateRange: Date[];
    switch (e.currentTarget.name) {
      case 'MinAtk':
        numRange = [...atkFilter];
        if (e.currentTarget.value != '') {
          if (Number(e.currentTarget.value) > 9999) e.currentTarget.value = 9999;
          else if (Number(e.currentTarget.value) < 0) e.currentTarget.value = 0;

          if (numRange.length > 0) numRange[0] = Number(e.currentTarget.value);
          else numRange = [Number(e.currentTarget.value), 9999];
        }
        else if (numRange.length > 0) {
          if (numRange[1] < 9999) numRange[0] = 0;
          else numRange = [];
        }
        setAtkFilter(numRange);
        break;
      case 'MaxAtk':
        numRange = [...atkFilter];
        if (e.currentTarget.value != '') {
          if (Number(e.currentTarget.value) > 9999) e.currentTarget.value = 9999;
          else if (Number(e.currentTarget.value) < 0) e.currentTarget.value = 0;

          if (numRange.length > 0) numRange[1] = Number(e.currentTarget.value);
          else numRange = [0, Number(e.currentTarget.value)];
        }
        else if (numRange.length > 0) {
          if (numRange[0] > 0) numRange[1] = 9999;
          else numRange = [];
        }
        setAtkFilter(numRange);
        break;
      case 'MinDef':
        numRange = [...defFilter];
        if (e.currentTarget.value != '') {
          if (Number(e.currentTarget.value) > 9999) e.currentTarget.value = 9999;
          else if (Number(e.currentTarget.value) < 0) e.currentTarget.value = 0;

          if (numRange.length > 0) numRange[0] = Number(e.currentTarget.value);
          else numRange = [Number(e.currentTarget.value), 9999];
        }
        else if (numRange.length > 0) {
          if (numRange[1] < 9999) numRange[0] = 0;
          else numRange = [];
        }
        setDefFilter(numRange);
        break;
      case 'MaxDef':
        numRange = [...defFilter];
        if (e.currentTarget.value != '') {
          if (Number(e.currentTarget.value) > 9999) e.currentTarget.value = 9999;
          else if (Number(e.currentTarget.value) < 0) e.currentTarget.value = 0;

          if (numRange.length > 0) numRange[1] = Number(e.currentTarget.value);
          else numRange = [0, Number(e.currentTarget.value)];
        }
        else if (numRange.length > 0) {
          if (numRange[0] > 0) numRange[1] = 9999;
          else numRange = [];
        }
        setDefFilter(numRange);
        break;
      case 'MinDate':
        dateRange = [...dateFilter];
        if (e.currentTarget.value != '') {
          let val: Date = new Date(e.currentTarget.value);

          if (dateRange.length > 0) {
            if (val > dateRange[1]) {
              val = dateRange[1];
              e.currentTarget.value = getDateString(val);
            }
            dateRange[0] = val;
          }
          else dateRange = [val, DATE_MAX];
        }
        else if (dateRange.length > 0) {
          if (dateRange[1] < DATE_MAX) dateRange[0] = DATE_MIN;
          else dateRange = [];
        }
        setDateFilter(dateRange);
        break;
      case 'MaxDate':
        dateRange = [...dateFilter];
        if (e.currentTarget.value != '') {
          let val: Date = new Date(e.currentTarget.value);

          if (dateRange.length > 0) {
            if (val < dateRange[0]) {
              val = dateRange[0];
              e.currentTarget.value = getDateString(val);
            }
            dateRange[1] = val;
          }
          else dateRange = [DATE_MIN, val];
        }
        else if (dateRange.length > 0) {
          if (dateRange[0] > DATE_MIN) dateRange[1] = DATE_MAX;
          else dateRange = [];
        }
        setDateFilter(dateRange);
        break;
    }
  }

  async function searchCards() {
    searchTask = null;
    setSearchLoading(true);

    const filters: { fieldName: string, type: string, value: any[] }[] = [];
    if (setsFilter.length > 0) filters.push({ fieldName: 'card_sets', type: 'Sets', value: setsFilter });
    if (rarityFilter.length > 0) filters.push({ fieldName: 'all_rarities', type: 'N:N', value: rarityFilter });
    if (limitFilter.length > 0) filters.push({ fieldName: 'limit', type: 'Banlist', value: limitFilter });
    if (categoryFilter.length > 0) filters.push({ fieldName: 'type', type: '1:N', value: categoryFilter });

    const subtypes: string[] = [];
    if (typeFilter.length > 0) subtypes.push(...typeFilter);
    if (subtypeFilter.length > 0) subtypes.push(...subtypeFilter);
    if (subtypes.length > 0) filters.push({ fieldName: 'subtype', type: 'N:Nx', value: subtypes });

    if (attributeFilter.length > 0) filters.push({ fieldName: 'attribute', type: '1:N', value: attributeFilter });
    if (levelFilter.length > 0) filters.push({ fieldName: 'level', type: '1:N', value: levelFilter });
    if (linkMarkersFilter.length > 0) filters.push({ fieldName: 'linkmarkers', type: 'N:Nx', value: linkMarkersFilter });
    if (pendScaleFilter.length > 0) filters.push({ fieldName: 'scale', type: '1:N', value: pendScaleFilter });

    if (atkFilter.length > 0) filters.push({ fieldName: 'atk', type: 'Range', value: atkFilter });
    if (defFilter.length > 0) filters.push({ fieldName: 'def', type: 'Range', value: defFilter });
    if (dateFilter.length > 0) filters.push({ fieldName: 'all_prints', type: 'RangeArray', value: dateFilter });

    const results = filteredCardSearch(searchField, searchString, filters, sortField, sortOrder == 'asc',
      currentBinder == '' ? null : binderCards, currentBanlist == '' ? null : banlistData, banlistDateRange);
    setSearchResults(results);
  }

  function clearAllFilters() {
    (document.getElementById('search-search-input')! as HTMLInputElement).value = '';
    setSearchString('');
    setsFilterField.current.setValue([]);
    rarityFilterField.current.setValue([]);
    limitFilterField.current.setValue([]);
    categoryFilterField.current.setValue([]);
    typeFilterField.current.setValue([]);
    subtypeFilterField.current.setValue([]);
    attributeFilterField.current.setValue([]);
    levelFilterField.current.setValue([]);
    linkMarkersFilterField.current.setValue([]);
    pendScaleFilterField.current.setValue([]);
    atkFilterFieldLeft.current.value = '';
    atkFilterFieldRight.current.value = '';
    setAtkFilter([]);
    defFilterFieldLeft.current.value = '';
    defFilterFieldRight.current.value = '';
    setDefFilter([]);
    dateFilterFieldLeft.current.value = '';
    dateFilterFieldRight.current.value = '';
    setDateFilter([]);
  }

  function uploadDeck() {
    document.getElementById('deck-import-field')!.click();
  }

  async function importFromYdk() {
    setDeckLoading(true);
    const inputField = document.getElementById('deck-import-field')!;

    const ydkData = (await ((inputField as HTMLInputElement).files as FileList).item(0)!.text()!).split(new RegExp('\\r?\\n'));
    (inputField as HTMLInputElement).value = '';

    setTimeout(() => {
      let deckZone = '';
      const newMainIds: string[] = [];
      const newExtraIds: string[] = [];
      const newSideIds: string[] = [];
      for (let i: number = 0; i < ydkData.length; i++) {
        const line = ydkData[i].trim();
        if (line == '') continue;
        if (line[0] == '#' || line[0] == '!') {
          if (line == '#main' || line == '!main') deckZone = 'm';
          else if (line == '#extra' || line == '!extra') deckZone = 'e';
          else if (line == '#side' || line == '!side') deckZone = 's';
          continue;
        }

        switch (deckZone) {
          case 'm':
            newMainIds.push(line);
            break;
          case 'e':
            newExtraIds.push(line);
            break;
          case 's':
            newSideIds.push(line);
            break;
        }
      }

      populateDecksFromIds(newMainIds, newExtraIds, newSideIds);
      setDeckSavedOrExported(false);
    }, 500); // Set a timeout so the rerender with the loading icon happens
  }

  function importFromYdke() {
    setDeckLoading(true);

    setTimeout(() => {
      const ydkeURL = (document.getElementById('ydke-input-field')! as HTMLInputElement).value;

      try {
        const ydkeData = ydke.parseURL(ydkeURL);

        populateDecksFromIds(Array.from(ydkeData.main), Array.from(ydkeData.extra), Array.from(ydkeData.side));
        setDeckSavedOrExported(false);
        closeYdkeImportModal();
      }
      catch (err) {
        openErrorModal('Issue importing deck from YDKe. Is your URL properly formatted?<br />' + contactString);
      }

    }, 500); // Set a timeout so the rerender with the loading icon happens
  }

  function populateDecksFromIds(newMainIds: any[], newExtraIds: any[], newSideIds: any[]) {
    try {
      const newMain: DropCard[] = [];
      const newExtra: DropCard[] = [];
      const newSide: DropCard[] = [];
      const mDeckCounts = { Monster: 0, Spell: 0, Trap: 0 };
      const eDeckCounts = { Fusion: 0, Synchro: 0, Xyz: 0, Link: 0 };
      const sDeckCounts = { Monster: 0, Spell: 0, Trap: 0 };

      newMainIds.forEach((id: any, index: number) => {
        const card: DropCard = { ...cards[altIdMap[Number(id)]], uniqueID: 'm' + '-' + altIdMap[Number(id)] + '-' + Date.now() + Math.random() + index };
        if (card.name !== undefined) {
          if (card.type.includes('Spell')) mDeckCounts.Spell += 1;
          else if (card.type.includes('Trap')) mDeckCounts.Trap += 1;
          else mDeckCounts.Monster += 1;
          newMain.push(card);
        }
      });
      newExtraIds.forEach((id: any, index: number) => {
        const card: DropCard = { ...cards[altIdMap[Number(id)]], uniqueID: 'e' + '-' + altIdMap[Number(id)] + '-' + Date.now() + Math.random() + index };
        if (card.name !== undefined) {
          if (card.subtype.includes('Fusion')) eDeckCounts.Fusion += 1;
          else if (card.subtype.includes('Synchro')) eDeckCounts.Synchro += 1;
          else if (card.subtype.includes('Xyz')) eDeckCounts.Xyz += 1;
          else eDeckCounts.Link += 1;
          newExtra.push(card);
        }
      });
      newSideIds.forEach((id: any, index: number) => {
        const card: DropCard = { // Prefix the uniqueID with the card's deck zone type
          ...cards[altIdMap[Number(id)]],
          uniqueID: '-' + altIdMap[Number(id)] + '-' + Date.now() + Math.random() + index,
        };
        if (card.name !== undefined) {
          card.uniqueID = (card.subtype.some((st: string) => ExtraDeckSubtypes.includes(st)) ? 'e' : 'm') + card.uniqueID; // Have to add this after undefined check
          if (card.type.includes('Spell')) sDeckCounts.Spell += 1;
          else if (card.type.includes('Trap')) sDeckCounts.Trap += 1;
          else sDeckCounts.Monster += 1;
          newSide.push(card);
        }
      });

      setMainDeck(newMain);
      setExtraDeck(newExtra);
      setSideDeck(newSide);
      setMainDeckCounts(mDeckCounts);
      setExtraDeckCounts(eDeckCounts);
      setSideDeckCounts(sDeckCounts);
      setDeckLoading(false);
    }
    catch (err) {
      setDeckLoading(false);
      openErrorModal('Issue populating deck.<br />' + contactString);
      return;
    }
  }

  async function exportToYdk() {
    setDeckLoading(true);
    await exportYdk(currentDeckName != '' ? currentDeckName : 'Untitled Deck', mainDeck, extraDeck, sideDeck);
    setDeckLoading(false);
  }

  function exportToYdke() {
    setExportUrl({ type: 'YDKe', exportString: ydke.toURL({
      main: Uint32Array.from(mainDeck.map((c: DropCard) => {
        return c.id;
      })),
      extra: Uint32Array.from(extraDeck.map((c: DropCard) => {
        return c.id;
      })),
      side: Uint32Array.from(sideDeck.map((c: DropCard) => {
        return c.id;
      })),
    }) });
  }

  function urlExportSuccess() {
    if (exportUrl.type == 'YDKe') setDeckSavedOrExported(true);
    setExportUrl({ type: 'ShareableLink', exportString: '' });
  }

  function exportToUrl() {
    if (currentDeck != '' && deckIsPublic) {
      setExportUrl({ type: 'Shareable Link', exportString: frontEndPath + 'DeckBuilder?d=' + currentDeck });
    }
  }

  async function addAllResultsToDecks() {
    setDeckLoading(true);

    setTimeout(() => {
      const newMain = [...mainDeck];
      const newMainCounts = { ...mainDeckCounts };
      const newExtra = [...extraDeck];
      const newExtraCounts = { ...extraDeckCounts };

      for (let i: number = 0; i < searchResults.length; i++) {
        const count = currentBinder != '' ?
          (rarityFilter.length > 0 ? rarityFilter.reduce((ac: number, r: string) => {
            return ac + ((searchResults[i] as BinderCard).all_rarities.includes(r) ? (searchResults[i] as BinderCard).all_counts[r] : 0);
          }, 0) :
            (searchResults[i] as BinderCard).count) :
          1;

        const cardInfo = searchResults[i];
        for (let j: number = 0; j < count; j++) {
          const uniqueID = (cardInfo.subtype.some((st: string) => ExtraDeckSubtypes.includes(st)) ? 'e' : 'm') +
            '-' + cardInfo.id + '-' + Date.now() + Math.random() + i + j;
          if (uniqueID[0] == 'e') { // Extra
            newExtra.push({ ...cardInfo, uniqueID: uniqueID });

            if (cardInfo.subtype.includes('Fusion')) newExtraCounts.Fusion += 1;
            else if (cardInfo.subtype.includes('Synchro')) newExtraCounts.Synchro += 1;
            else if (cardInfo.subtype.includes('Xyz')) newExtraCounts.Xyz += 1;
            else newExtraCounts.Link += 1;
          }
          else { // Main
            newMain.push({ ...cardInfo, uniqueID: uniqueID });

            if (cardInfo.type.includes('Spell')) newMainCounts.Spell += 1;
            else if (cardInfo.type.includes('Trap')) newMainCounts.Trap += 1;
            else newMainCounts.Monster += 1;
          }
        }
      }
      setMainDeck(newMain);
      setMainDeckCounts(newMainCounts);
      setExtraDeck(newExtra);
      setExtraDeckCounts(newExtraCounts);
      setDeckSavedOrExported(false);
      setDeckLoading(false);
    }, 500);
  }

  function sortSearch() {
    setSearchResults(builderSearchSort([...searchResults], sortField as keyof (BinderCard | Card), sortOrder == 'asc'));
  }

  function updateSearchString(newSearchTerm: string) {
    if (newSearchTerm != searchString) setSearchString(newSearchTerm);
    else {
      if (searchTask != null) clearTimeout(searchTask); // Force search refresh on click
      searchTask = setTimeout(searchCards, 300);
    }
  }

  function sortDeck() {
    const sortedMain = [...mainDeck].sort(zoneSortFunc);
    setMainDeck(sortedMain);

    const sortedExtra = [...extraDeck].sort(zoneSortFunc);
    setExtraDeck(sortedExtra);

    const sortedSide = [...sideDeck].sort(zoneSortFunc);
    setSideDeck(sortedSide);
    setDeckSavedOrExported(false);
  }

  function shuffleDeck() {
    setMainDeck(shuffle([...mainDeck])); // Doesn't make sense to shuffle extra or side
    setDeckSavedOrExported(false); // We puth these everywhere instead of a useEffect because we don't want to trigger on getDeck
  }

  function clearDeck() {
    setMainDeck([]);
    setExtraDeck([]);
    setSideDeck([]);

    setMainDeckCounts({ Monster: 0, Spell: 0, Trap: 0 });
    setExtraDeckCounts({ Fusion: 0, Synchro: 0, Xyz: 0, Link: 0 });
    setSideDeckCounts({ Monster: 0, Spell: 0, Trap: 0 });
    setDeckSavedOrExported(false);
  }

  function resetDeck() {
    if (currentDeck != '') getCurrentDeck();
    else clearDeck();
    setDeckSavedOrExported(true);
  }

  function setCurrentDragCard(card: DropCard, deckZone: string, dropCallback: any) {
    currentDragCard.zone = deckZone;
    currentDragCard.card = card;
    currentDragCard.dropCallback = dropCallback;
    currentDragCard.index = findCard(deckZone, card.uniqueID).index;
  }

  function setCurrentDragCardPos(deckZone: string, index: number) {
    currentDragCard.zone = deckZone;
    currentDragCard.index = index;
  }

  function setCurrentDragCardDropCallback(dropCallback: any) {
    currentDragCard.dropCallback = dropCallback;
  }

  function clearCurrentDragCard() {
    if (currentDragCard.dropCallback != null) currentDragCard.dropCallback();
    currentDragCard.zone = '';
    currentDragCard.card = null;
    currentDragCard.dropCallback = null;
    currentDragCard.index = -1;
  }

  function checkCardAllowed(deckZone: string, uniqueID: string, oldDeckZone: string) {
    if (oldDeckZone != deckZone && ((deckZone == 'm' && mainDeckRef.current.length >= MAIN_DECK_MAX) ||
      (deckZone == 'e' && extraDeckRef.current.length >= EXTRA_DECK_MAX) ||
      (deckZone == 's' && sideDeckRef.current.length >= SIDE_DECK_MAX))) return false; // Limit to max but allow sorting within

    if (deckZone != 's' && uniqueID[0] != deckZone) return false; // No extra monsters in main and vice versa

    if (oldDeckZone == 'b') { // Limit 3 (main in main/side, extra in extra/side)
      const cardId: number = Number(uniqueID.split('-')[1]);
      const banlistExists = Object.keys(banlistRef.current).length > 0;
      if (enforceLimitsRef.current && banlistExists &&
        !cards[cardId].all_prints.some((d: Date) => isNaN(d.valueOf()) || (d > banlistDateRange[0] && d < banlistDateRange[1]))) {
        return false;
      }

      if (uniqueID[0] != 'e' && ((mainDeckRef.current.reduce((count: number, card: DropCard) => {
        if (card.id == cardId && card.uniqueID != uniqueID) count += 1;
        return count;
      }, 0) +
        sideDeckRef.current.reduce((count: number, card: DropCard) => {
          if (card.id == cardId && card.uniqueID != uniqueID) count += 1;
          return count;
        }, 0)) >= (enforceLimitsRef.current ?
        (banlistExists && banlistRef.current[cardId] !== undefined ? banlistRef.current[cardId] : 3) :
        3))) return false;
      else if ((extraDeckRef.current.reduce((count: number, card: DropCard) => {
        if (card.id == cardId && card.uniqueID != uniqueID) count += 1;
        return count;
      }, 0) +
        sideDeckRef.current.reduce((count: number, card: DropCard) => {
          if (card.id == cardId && card.uniqueID != uniqueID) count += 1;
          return count;
        }, 0)) >= (enforceLimitsRef.current ?
        (banlistExists && banlistRef.current[cardId] !== undefined ? banlistRef.current[cardId] : 3) :
        3)) return false;
    }

    return true;
  }

  function findCard(deckZone: string, uniqueID: string) {
    let card: DropCard;
    let index: number;
    if (deckZone == 'm') { // Main
      card = mainDeckRef.current.filter((c: DropCard) => c.uniqueID == uniqueID)[0];
      index = mainDeckRef.current.indexOf(card);
    }
    else if (deckZone == 'e') { // Extra
      card = extraDeckRef.current.filter((c: DropCard) => c.uniqueID == uniqueID)[0];
      index = extraDeckRef.current.indexOf(card);
    }
    else if (deckZone == 's') { // Side
      card = sideDeckRef.current.filter((c: DropCard) => c.uniqueID == uniqueID)[0];
      index = sideDeckRef.current.indexOf(card);
    }
    else { // Search. We don't ever actually use the index on this so we can do a less expensive op
      card = { ...cards[Number(uniqueID.split('-')[1])], uniqueID: uniqueID };
      index = -1;
    }

    return { card: card, index: index };
  }

  function removeCardUtility(deckZone: string, index: number, cardType: string, cardSubtype: string[]) {
    if (deckZone == 'm') { // Main
      const newMain = [...mainDeckRef.current];
      newMain.splice(index, 1);
      setMainDeck(newMain);

      const deckCounts = { ...mainDeckCountsRef.current };
      if (cardType.includes('Spell')) deckCounts.Spell -= 1;
      else if (cardType.includes('Trap')) deckCounts.Trap -= 1;
      else deckCounts.Monster -= 1;
      setMainDeckCounts(deckCounts);
    }
    else if (deckZone == 'e') { // Extra
      const newExtra = [...extraDeckRef.current];
      newExtra.splice(index, 1);
      setExtraDeck(newExtra);

      const deckCounts = { ...extraDeckCountsRef.current };
      if (cardSubtype.includes('Fusion')) deckCounts.Fusion -= 1;
      else if (cardSubtype.includes('Synchro')) deckCounts.Synchro -= 1;
      else if (cardSubtype.includes('Xyz')) deckCounts.Xyz -= 1;
      else deckCounts.Link -= 1;
      setExtraDeckCounts(deckCounts);
    }
    else if (deckZone == 's') { // Side
      const newSide = [...sideDeckRef.current];
      newSide.splice(index, 1);
      setSideDeck(newSide);

      const deckCounts = { ...sideDeckCountsRef.current };
      if (cardType.includes('Spell')) deckCounts.Spell -= 1;
      else if (cardType.includes('Trap')) deckCounts.Trap -= 1;
      else deckCounts.Monster -= 1;
      setSideDeckCounts(deckCounts);
    }
  }

  function moveCard(deckZone: string, uniqueID: string, newDeckZone: string, newIndex: number, isCurrentDragCard: boolean, dropOp: boolean) {
    if (isCurrentDragCard) {
      dragLock.current = true; // An attempt at ensuring nothing fires while we're in the middle of this function and gets locked in with an old index
      deckZone = currentDragCard.zone;
    }
    const cardInfo = isCurrentDragCard ? { card: currentDragCard.card!, index: currentDragCard.index } : findCard(deckZone, uniqueID);

    if (cardInfo.card == null || (cardInfo.index == newIndex && newDeckZone == deckZone)) {
      dragLock.current = false;
      if (deckZone == 'b' && isScreen950 && !isCurrentDragCard) {
        setCardAddedPopupText(cardInfo.card.name + ' could not be added- already at maximum copies or deck is at maximum size!');
        showCardAddedPopup();
      }
      return; // Restrict to max or limit 3
    }
    if (deckZone == newDeckZone) { // There's probably a better way to optimize/condense this later
      if (deckZone == 'm') { // Main
        const newMain = [...mainDeckRef.current];
        newMain.splice(newIndex, 0, newMain.splice(cardInfo.index, 1)[0]);
        setMainDeck(newMain);
        if (newIndex >= newMain.length) newIndex = newMain.length - 1;
      }
      else if (deckZone == 'e') { // Extra
        const newExtra = [...extraDeckRef.current];
        newExtra.splice(newIndex, 0, newExtra.splice(cardInfo.index, 1)[0]);
        setExtraDeck(newExtra);
        if (newIndex >= newExtra.length) newIndex = newExtra.length - 1;
      }
      else if (deckZone == 's') { // Side
        const newSide = [...sideDeckRef.current];
        newSide.splice(newIndex, 0, newSide.splice(cardInfo.index, 1)[0]);
        setSideDeck(newSide);
        if (newIndex >= newSide.length) newIndex = newSide.length - 1;
      }
    }
    else {
      if (!checkCardAllowed(newDeckZone, cardInfo.card.uniqueID, deckZone)) {
        dragLock.current = false;
        if (deckZone == 'b' && isScreen950 && !isCurrentDragCard) {
          setCardAddedPopupText(cardInfo.card.name + ' could not be added- already at maximum copies or deck is at maximum size!');
          showCardAddedPopup();
        }
        return; // Restrict to max or limit 3
      }

      removeCardUtility(deckZone, cardInfo.index, cardInfo.card.type, cardInfo.card.subtype);

      if (newDeckZone == 'm') { // Main
        const newMain = [...mainDeckRef.current];
        newIndex = newIndex > -1 ? newIndex : newMain.length;
        newMain.splice(newIndex, 0, cardInfo.card);
        setMainDeck(newMain);
        if (newIndex >= newMain.length) newIndex = newMain.length - 1;

        const deckCounts = { ...mainDeckCountsRef.current };
        if (cardInfo.card.type.includes('Spell')) deckCounts.Spell += 1;
        else if (cardInfo.card.type.includes('Trap')) deckCounts.Trap += 1;
        else deckCounts.Monster += 1;
        setMainDeckCounts(deckCounts);
      }
      else if (newDeckZone == 'e') { // Extra
        const newExtra = [...extraDeckRef.current];
        newIndex = newIndex > -1 ? newIndex : newExtra.length;
        newExtra.splice(newIndex, 0, cardInfo.card);
        setExtraDeck(newExtra);
        if (newIndex >= newExtra.length) newIndex = newExtra.length - 1;

        const deckCounts = { ...extraDeckCountsRef.current };
        if (cardInfo.card.subtype.includes('Fusion')) deckCounts.Fusion += 1;
        else if (cardInfo.card.subtype.includes('Synchro')) deckCounts.Synchro += 1;
        else if (cardInfo.card.subtype.includes('Xyz')) deckCounts.Xyz += 1;
        else deckCounts.Link += 1;
        setExtraDeckCounts(deckCounts);
      }
      else if (newDeckZone == 's') { // Side
        const newSide = [...sideDeckRef.current];
        newIndex = newIndex > -1 ? newIndex : newSide.length;
        newSide.splice(newIndex, 0, cardInfo.card);
        setSideDeck(newSide);
        if (newIndex >= newSide.length) newIndex = newSide.length - 1;

        const deckCounts = { ...sideDeckCountsRef.current };
        if (cardInfo.card.type.includes('Spell')) deckCounts.Spell += 1;
        else if (cardInfo.card.type.includes('Trap')) deckCounts.Trap += 1;
        else deckCounts.Monster += 1;
        setSideDeckCounts(deckCounts);
      }

      if (deckZone == 'b' && isScreen950 && !isCurrentDragCard) {
        setCardAddedPopupText(cardInfo.card.name + ' added to ' + (newDeckZone == 'e' ? 'Extra' : 'Main') + ' Deck!');
        showCardAddedPopup();
      }
    }

    if (isCurrentDragCard) {
      setCurrentDragCardPos(newDeckZone, newIndex);
    }

    if (dropOp) {
      clearCurrentDragCard();
    }
    setDeckSavedOrExported(false);
  }

  function removeCard(deckZone: string, uniqueID: string, isCurrentDragCard: boolean) {
    if (isCurrentDragCard) deckZone = currentDragCard.zone;
    if (deckZone != 'b') {
      dragLock.current = true;
      const cardInfo = isCurrentDragCard ? { card: currentDragCard.card!, index: currentDragCard.index } : findCard(deckZone, uniqueID);

      removeCardUtility(deckZone, cardInfo.index, cardInfo.card.type, cardInfo.card.subtype);
    }

    if (isCurrentDragCard) {
      clearCurrentDragCard();
    }
    setDeckSavedOrExported(false);
  }

  const resultsRow = useCallback(({ index, style }: { index: number, style: CSSProperties }) => {
    const card = searchResults[index];
    return (
      <div style={style} key={card.id+'decksearchcardcont'}>
        <div className='builder-search-card'>
          <div className='builder-search-card-left'>
            <BuilderSearchCard
              {...{
                card: card,
                limit: (currentBanlist != '' ?
                  (card.all_prints.some((d: Date) => isNaN(d.valueOf()) || (d > banlistDateRange[0] && d < banlistDateRange[1])) ?
                    (banlistData[card.id] ?? undefined) : 0) : undefined),
                limitSize: '30px',
              }}
              key={card.id + 'decksearchcard'}
            />
          </div>
          <div className='builder-search-card-right'>
            <h3><a href={cardLink(card.id)} target='_blank' rel='noreferrer'>{card.name}</a></h3>
            <span>
              {(card.type == 'Monster' ? ((card.subtype.includes('Xyz') ? 'Rank ' : (card.subtype.includes('Link') ? 'Link ' : 'Level ')) + card.level +
                ' / ' + card.subtype.slice(1).join(' ')) : card.subtype.join(' ')) + ' ' + card.type}
            </span>
            {card.type == 'Monster' ? <span>{card.attribute + ' / ' + card.subtype[0]}</span> : ''}
            {'count' in card && card.count !== undefined ?
              (rarityFilter.length > 0 ? <span>
                {rarityFilter.reduce((ac: string, r: string) => {
                  return ac + (card.all_rarities.includes(r) ? (ac != '' ? ', ' : '') + card.all_counts[r] + ' ' + RarityCode[r] : '');
                }, '') + ' (' + card.count + ' Total) in Binder'}
              </span> :
                <span>{card.count + ' in Binder'}</span>) :
              ''}
          </div>
        </div>
      </div>
    );
  }, [searchResults, banlistData, banlistDateRange]);

  function openDeckRenameModal() {
    document.getElementById('deck-rename-modal')!.style.display = 'flex';
  }

  function closeDeckRenameModal() {
    document.getElementById('deck-rename-modal')!.style.display = 'none';
    (document.getElementById('deck-rename-name')! as HTMLInputElement).value = '';
  }

  function openDeckSaveAsModal() {
    document.getElementById('deck-saveas-modal')!.style.display = 'flex';
  }

  function closeDeckSaveAsModal() {
    document.getElementById('deck-saveas-modal')!.style.display = 'none';
    (document.getElementById('deck-saveas-name')! as HTMLInputElement).value = '';
  }

  function openYdkeImportModal() {
    document.getElementById('ydke-import-modal')!.style.display = 'flex';
  }

  function closeYdkeImportModal() {
    document.getElementById('ydke-import-modal')!.style.display = 'none';
  }

  function getPublicDeck(id: any) {
    if (id == '') return;
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    };
    setDeckLoading(true);
    makeApiCall(cookies.get('refresh'), cookies.get('userId'), apiPath + 'deck/public/' + id, requestOptions)
      .then(async (response) => {
        if (!response.ok) {
          const error = response.status;
          if (error == 404 || error == 501) openErrorModal('Issue retrieving public deck.<br />This deck may not be public or may not exist.');
          else {
            openErrorModal('Issue retrieving public deck.<br />' +
            (error == 401 ? '<br />Try logging out and logging back in.<br />If this persists, ' + lowercaseContactString : '') +
            (error == 400 ? '<br />You have been inactive for a long time. Please log out and back in.<br /><br />If this persists, ' + lowercaseContactString : '') +
            (error != 401 && error != 400 ? '<br />' + contactString : ''));
          }
          setDeckLoading(false);
          return Promise.reject(error);
        }

        const data = await response.json();

        setDeckIsPublic(true);
        setCurrentDeckName(data.name);
        populateDecksFromIds(data.main, data.extra, data.side);
        setDeckLoading(false);
        setDeckSavedOrExported(true);
      });
  }

  function getCurrentDeck() {
    if (currentDeck == '') return;
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + cookies.get('token') },
    };
    setDeckLoading(true);
    makeApiCall(cookies.get('refresh'), cookies.get('userId'), apiPath + 'deck/' + (currentDeckGroup != '' ? (currentDeckGroup + '/') : '') + currentDeck, requestOptions)
      .then(async (response) => {
        if (!response.ok) {
          const error = response.status;
          openErrorModal('Issue retrieving deck.<br />' +
            (error == 401 ? '<br />Try logging out and logging back in.<br />If this persists, ' + lowercaseContactString : '') +
            (error == 400 ? '<br />You have been inactive for a long time. Please log out and back in.<br /><br />If this persists, ' + lowercaseContactString : '') +
            (error != 401 && error != 400 ? '<br />' + contactString : ''));
          setDeckLoading(false);
          return Promise.reject(error);
        }

        const data = await response.json();

        setCurrentDeckName(data.name);
        populateDecksFromIds(data.main, data.extra, data.side);
        setDeckIsPublic(data.public);
        setDeckLoading(false);
        setDeckSavedOrExported(true);
      });
  }

  function selectCurrentBanlist(id: string, group: string) {
    setCurrentBanlist(id);
    setCurrentBanlistGroup(group);
  }

  function selectCurrentDeck(id: string, group: string) {
    setCurrentDeck(id);
    setCurrentDeckGroup(group);
  }

  function onDeckNameInput(e: any) {
    checkDeckNameInvalid(e.target.value);
  }

  function checkDeckNameInvalid(name: string) {
    if (name == '' || name.length > 80) { // Limit name
      setSaveAsDisabled(true);
      return true;
    }
    else {
      setSaveAsDisabled(false);
      return false;
    }
  }

  function saveAsNewDeck() {
    const newName = (document.getElementById('deck-saveas-name')! as HTMLInputElement).value;
    if (checkDeckNameInvalid(newName)) return;
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + cookies.get('token') },
      body: JSON.stringify({
        name: newName,
        main: mainDeck.map((c: DropCard) => c.id),
        extra: extraDeck.map((c: DropCard) => c.id),
        side: sideDeck.map((c: DropCard) => c.id),
        public: deckIsPublic,
        group: selectedDeckGroup,
      }),
    };
    setSavingDeck(true);
    makeApiCall(cookies.get('refresh'), cookies.get('userId'), apiPath + 'deck', requestOptions)
      .then(async (response) => {
        setSaveAsDisabled(true);

        if (!response.ok) {
          const error = response.status;
          openErrorModal('Issue creating deck.<br />' +
            (error == 401 ? '<br />Try logging out and logging back in.<br />If this persists, ' + lowercaseContactString : '') +
            (error == 400 ? '<br />You have been inactive for a long time. Please log out and back in.<br /><br />If this persists, ' + lowercaseContactString : '') +
            (error != 401 && error != 400 ? '<br />' + contactString : ''));
          setSavingDeck(false);
          return Promise.reject(error);
        }

        const data = await response.json();

        const newDecksList = [...decksList];
        newDecksList.find((deckGroup: any) => deckGroup.text == selectedDeckGroup)!.subItems!.push({ value: data._id, text: newName } as DropdownOption); // We'll always have created the group first

        localforage.setItem('decks', newDecksList).then(() => {
          updateDecksList(data._id);
          closeDeckSaveAsModal();
          setCurrentDeckName(newName);
          setCurrentDeckGroup(selectedDeckGroup);
          setSavingDeck(false);
          setDeckSavedOrExported(true);
        })
          .catch(() => {
            updateDecksList();
            openErrorModal('Issue saving decks after creation.<br />' + contactString);
            setSavingDeck(false);
          });
      });
  }

  function renameCurrentDeck() {
    const newName = (document.getElementById('deck-rename-name')! as HTMLInputElement).value;
    if (checkDeckNameInvalid(newName)) return;
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + cookies.get('token') },
      body: JSON.stringify({
        name: newName,
        main: mainDeck.map((c: DropCard) => c.id),
        extra: extraDeck.map((c: DropCard) => c.id),
        side: sideDeck.map((c: DropCard) => c.id),
        public: deckIsPublic,
      }),
    };
    setSavingDeck(true);
    makeApiCall(cookies.get('refresh'), cookies.get('userId'), apiPath + 'deck/' + (currentDeckGroup != '' ? (currentDeckGroup + '/') : '') + currentDeck, requestOptions)
      .then(async (response) => {
        if (!response.ok) {
          const error = response.status;
          openErrorModal('Issue renaming deck.<br />' +
            (error == 401 ? '<br />Try logging out and logging back in.<br />If this persists, ' + lowercaseContactString : '') +
            (error == 400 ? '<br />You have been inactive for a long time. Please log out and back in.<br /><br />If this persists, ' + lowercaseContactString : '') +
            (error != 401 && error != 400 ? '<br />' + contactString : ''));
          setSavingDeck(false);
          return Promise.reject(error);
        }

        const newDecksList = [...decksList];
        newDecksList.find((deckGroup: any) => deckGroup.text == currentDeckGroup)!.subItems!.find((deck: DropdownOption) => deck.value == currentDeck)!.text = newName;

        localforage.setItem('decks', newDecksList).then(() => {
          updateDecksList();
          closeDeckRenameModal();
          setSaveAsDisabled(false);
          setCurrentDeckName(newName);
          setSavingDeck(false);
          setDeckSavedOrExported(true);
        })
          .catch(() => {
            updateDecksList();
            openErrorModal('Issue saving decks after rename.<br />' + contactString);
            setSaveAsDisabled(false);
            setCurrentDeckName(newName);
            setSavingDeck(false);
          });
      });
  }

  function saveCurrentDeck() {
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + cookies.get('token') },
      body: JSON.stringify({
        name: currentDeckName,
        main: mainDeck.map((c: DropCard) => c.id),
        extra: extraDeck.map((c: DropCard) => c.id),
        side: sideDeck.map((c: DropCard) => c.id),
        public: deckIsPublic,
      }),
    };
    setSavingDeck(true);
    makeApiCall(cookies.get('refresh'), cookies.get('userId'), apiPath + 'deck/' + (currentDeckGroup != '' ? (currentDeckGroup + '/') : '') + currentDeck, requestOptions)
      .then(async (response) => {
        setSavingDeck(false);

        if (!response.ok) {
          const error = response.status;
          openErrorModal('Issue saving deck.<br />' +
            (error == 401 ? '<br />Try logging out and logging back in.<br />If this persists, ' + lowercaseContactString : '') +
            (error == 400 ? '<br />You have been inactive for a long time. Please log out and back in.<br /><br />If this persists, ' + lowercaseContactString : '') +
            (error != 401 && error != 400 ? '<br />' + contactString : ''));
          return Promise.reject(error);
        }
        setDeckSavedOrExported(true);
      });
  }

  function togglePublic() {
    const newPublic = !deckIsPublic;
    if (currentDeck != '') {
      const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + cookies.get('token') },
        body: JSON.stringify({
          public: newPublic,
        }),
      };
      setSavingDeck(true);
      makeApiCall(cookies.get('refresh'), cookies.get('userId'), apiPath + 'deck/' + (currentDeckGroup != '' ? (currentDeckGroup + '/') : '') + currentDeck + '/public', requestOptions)
        .then(async (response) => {
          setSavingDeck(false);

          if (!response.ok) {
            const error = response.status;
            openErrorModal('Issue toggling deck permission.<br />' +
              (error == 401 ? '<br />Try logging out and logging back in.<br />If this persists, ' + lowercaseContactString : '') +
              (error == 400 ? '<br />You have been inactive for a long time. Please log out and back in.<br /><br />If this persists, ' + lowercaseContactString : '') +
              (error != 401 && error != 400 ? '<br />' + contactString : ''));
            return Promise.reject(error);
          }
        });
    }
    setDeckIsPublic(newPublic);
  }

  function saveNewDecksList(newList: DropdownOption[], newGroup: string) {
    localforage.setItem('decks', newList).then(() => {
      updateDecksList();
    })
      .catch(() => {
        openErrorModal('Issue saving groups.<br />' + contactString);
      });
  }

  function deleteCurrentDeck() {
    const requestOptions = {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + cookies.get('token') },
    };
    setSavingDeck(true);
    setSaveAsDisabled(true);
    makeApiCall(cookies.get('refresh'), cookies.get('userId'), apiPath + 'deck/' + (currentDeckGroup != '' ? (currentDeckGroup + '/') : '') + currentDeck, requestOptions)
      .then(async (response) => {
        if (!response.ok) {
          const error = response.status;
          openErrorModal('Issue deleting deck.<br />' +
            (error == 401 ? '<br />Try logging out and logging back in.<br />If this persists, ' + lowercaseContactString : '') +
            (error == 400 ? '<br />You have been inactive for a long time. Please log out and back in.<br /><br />If this persists, ' + lowercaseContactString : '') +
            (error != 401 && error != 400 ? '<br />' + contactString : ''));
          setSavingDeck(false);
          return Promise.reject(error);
        }

        const newDecksList = [...decksList];
        const group = newDecksList.find((deckGroup: any) => deckGroup.text == currentDeckGroup)!;
        group.subItems = group.subItems!.filter((deck: DropdownOption) => deck.value != currentDeck);

        setCurrentDeck('');
        setCurrentDeckName('');
        setCurrentDeckGroup('');
        setDeckIsPublic(false);
        setDecksList(newDecksList);
        setConfirmMatchString('');

        localforage.setItem('decks', newDecksList).then(() => {
          updateDecksList();
          setSavingDeck(false);
          setDeckSavedOrExported(true);
        })
          .catch(() => {
            updateDecksList();
            openErrorModal('Issue saving after deleting deck.<br />' + contactString);
            setSavingDeck(false);
          });
      });
  }

  function openDeleteDeckConfirm() {
    setConfirmMatchString('DELETE'); // after the set finishes the useffect opens modal
  }

  function showNavCheckModal(location: any) {
    if (!confirmedNavigation) { // Need this gate, otherwise when they confirm they'll just infinitely keep popping the dialog up
      setNavToLocation(location);
      document.getElementById('navCheckModal')!.style.display = 'flex';
      return false;
    }

    return true;
  }

  function closeNavCheckModal() {
    setNavToLocation(null);
    document.getElementById('navCheckModal')!.style.display = 'none';
  }

  function acceptNavCheckModal() {
    setConfirmedNavigation(true); // Will then trigger nav on callback from setState
  }

  function openPerformanceWarningModal() {
    document.getElementById('performanceWarningModal')!.style.display = 'flex';
  }

  function checkPerformanceWarning() {
    if (cookies.get('ignorePerformanceWarnings') == 'false' && // Only one max here to fudgily account for multiple counts in results without going through and counting them
      searchResults.length + mainDeck.length + extraDeck.length > MAIN_DECK_MAX) openPerformanceWarningModal();
    else addAllResultsToDecks();
  }

  function openNewGroupModal() {
    document.getElementById('create-group-modal')!.style.display = 'flex';
  }

  function getGridWidth() {
    // if (isScreen950) return 5;
    if (isScreen1150) return 6;
    else if (isScreen1300) return 8;
    else return 10;
  }

  const checkCardAllowedRef = useRef<any>();
  checkCardAllowedRef.current = checkCardAllowed;
  const moveCardRef = useRef<any>();
  moveCardRef.current = moveCard;
  const removeCardRef = useRef<any>();
  removeCardRef.current = removeCard;

  // const dndBackend: BackendFactory = isTouchDevice() ? TouchBackend : HTML5Backend;

  if (cardsAreLoaded) {
    return ( // I should use useReducer here like I do in PackOpener, but I think this is honestly cleaner since I need the Refs
      <BuilderContext.Provider value={{ moveCard: moveCardRef, removeCard: removeCardRef, checkCardAllowed: checkCardAllowedRef, dragTask: dragTask,
        setCurrentDragCard: setCurrentDragCard, clearCurrentDragCard: clearCurrentDragCard, setCurrentDragCardDropCallback: setCurrentDragCardDropCallback,
        currentDragCard: currentDragCard, dragLock: dragLock, zoneCalcShifts: zoneCalcShifts,
        currentBanlist: currentBanlist, banlistData: banlistData, banlistDates: banlistDateRange, builderType: 'd' }}>
        <Helmet>
          <meta content="YGO Prog - Deck Builder" property="og:title" />
          <meta
            content="YGO Prog's Deck Builder lets you create and export Yu-Gi-Oh! TCG decks as files for EDOPRO, YGO Omega, and Dueling Book, or as images and links for sharing!"
            property="og:description"
          />
          <meta content="https://www.ygoprog.com/DeckBuilder" property="og:url" />
          <meta
            name="description"
            content="YGO Prog's Deck Builder lets you create and export Yu-Gi-Oh! TCG decks as files for EDOPRO, YGO Omega, and Dueling Book, or as images and links for sharing!"
          />
          <title>YGO Prog - Deck Builder</title>
        </Helmet>
        <main>
          <DndProvider options={HTML5toTouch as any}>
            <DeleteConfirmModal {...{
              id: 'delete-confirm',
              title: 'Confirm Deck Delete',
              matchString: confirmMatchString,
              callback: deleteCurrentDeck,
              clearMatch: setConfirmMatchString,
              preConfirmStatement: '',
            }} />
            <div id={'navCheckModal'} className='modal'>
              <button className='modal-close' title='Close modal' onClick={closeNavCheckModal} />
              <div className='content-box modal-content'>
                <h5 style={{ marginBottom: '0px' }}>Leaving Without Saving</h5>
                <p className='largetext' style={{ paddingTop: '5px', marginBottom: '0px' }}>You have not saved or exported changes to your deck,<br />if you leave this page they will be lost.</p>
                <button className='black-button white-button' style={{ marginRight: isScreen1150 ? '5px' : '7px' }} title='Leave page' onClick={acceptNavCheckModal}><span>Leave</span></button>
                <button className='black-button' style={{ marginRight: isScreen1150 ? '5px' : '7px' }} title='Stay on page' onClick={closeNavCheckModal}><span>Stay</span></button>
              </div>
            </div>

            <PerformanceWarningModal {...{
              text: 'This action will add a large number of cards to the deck,<br />and may cause performance issues on some machines.',
              callback: addAllResultsToDecks,
            }} />

            {exportUrl.exportString != '' ? <StringExportModal {...{
              id: 'url-export-modal',
              exportStringObj: exportUrl,
              dispatch: urlExportSuccess,
            }} /> : ''}

            <Prompt
              when={!deckSavedOrExported && search == ''}
              message={showNavCheckModal}
            />

            <SaveAsGroupModal {...{ id: 'create-group-modal', isRename: false, groupName: currentDeckGroup, storageName: 'decks', list: decksList, updateListDispatch: saveNewDecksList }} />
            {groupManagementOpen ? <GroupManagementWindow {...{
              id: 'group-management',
              titleCaseSingularType: 'Deck',
              visibleStateDispatch: setGroupManagementOpen,
              storageName: 'decks',
              list: decksList,
              updateListDispatch: updateDecksList,
              currentItem: currentDeck,
              updateCurrentItemDispatch: setCurrentDeck,
              currentGroup: currentDeckGroup,
              updateCurrentGroupDispatch: setCurrentDeckGroup,
              openNewGroupModal: openNewGroupModal,
              isScreen950: isScreen950,
            }} /> : ''}

            {exportingImage ? <ZonesImageExport {...{
              id: 'img-export',
              listName: currentDeckName != '' ? currentDeckName :
                'Custom Deck',
              zoneNames: ['Main Deck', 'Extra Deck', 'Side Deck'],
              zoneStyleClassNames: ['maindeck-box', 'extradeck-box', 'sidedeck-box'],
              allowedPrintings: [],
              zoneLists: [mainDeck, extraDeck, sideDeck],
              finishedDispatch: setExportingImage,
              builderType: 'Deck Builder',
            }} /> : ''}

            <div id='deck-rename-modal' className='modal'>
              <button className='modal-close' title='Close modal' onClick={closeDeckRenameModal} />
              <div className='content-box modal-content'>
                <h5 style={{ marginBottom: '0px' }}>Rename and Save Deck</h5>
                <div className='vertical-form-text-input'>
                  <label htmlFor='deck-rename-name'>Name:</label>
                  <div className='text-input'><input id='deck-rename-name' type='text' onInput={onDeckNameInput} onKeyDown={(e) => {if (e.code === 'Enter') renameCurrentDeck();}} /></div>
                </div>
                {savingDeck ?
                  <img src={'/images/LoadingAnim.png'} width='30px' height='30px' style={{ margin: 'auto', verticalAlign: 'middle', marginRight: isScreen1150 ? '5px' : '7px' }} /> :
                  <div>
                    <button className='black-button' style={{ marginRight: isScreen1150 ? '5px' : '7px' }} title='Rename deck and save (overwrites current deck)'
                      onClick={renameCurrentDeck} disabled={currentDeck == '' || saveAsDisabled || typeof(username) == 'undefined'}><span>Rename and Save</span></button>
                  </div>}
              </div>
            </div>

            <div id='ydke-import-modal' className='modal'>
              <button className='modal-close' title='Close modal' onClick={closeYdkeImportModal} />
              <div className='content-box modal-content'>
                <h5 style={{ marginBottom: '0px' }}>Import from YDKe</h5>
                <div className='vertical-form-text-input'>
                  <label htmlFor='ydke-input-field'>YDKe URL:</label>
                  <div className='text-input'><input id='ydke-input-field' type='text' onKeyDown={(e) => {if (e.code === 'Enter') importFromYdke();}} /></div>
                </div>
                {deckLoading ?
                  <img src={'/images/LoadingAnim.png'} width='30px' height='30px' style={{ margin: 'auto', verticalAlign: 'middle', marginRight: isScreen1150 ? '5px' : '7px' }} /> :
                  <div>
                    <button className='black-button' style={{ marginRight: isScreen1150 ? '5px' : '7px' }} title='Import from YDKe'
                      onClick={importFromYdke}><span>Import</span></button>
                  </div>}
              </div>
            </div>

            <div id='deck-saveas-modal' className='modal'>
              <button className='modal-close' title='Close modal' onClick={closeDeckSaveAsModal} />
              <div className='content-box modal-content'>
                <h5 style={{ marginBottom: '0px' }}>Save Deck As New</h5>
                <div className='vertical-form-text-input' style={{ textAlign: 'center' }}>
                  <label htmlFor='deck-saveas-name'>Name:</label>
                  <div className='text-input'><input id='deck-saveas-name' type='text' onInput={onDeckNameInput} onKeyDown={(e) => {if (e.code === 'Enter') saveAsNewDeck();}} /></div>
                </div>
                <div className='vertical-form-text-input'>
                  <label>Group:</label>
                  <BlackDropdown {...{
                    uniqueID: 'saveas-group-select',
                    isSelector: true,
                    disabledVar: false,
                    optionsList: deckGroupsList,
                    startingOption: selectedDeckGroup != '' ? {
                      value: selectedDeckGroup,
                      text: selectedDeckGroup,
                    } as DropdownOption : null,
                    defaultValue: '',
                    defaultDisplay: 'None',
                    nonSelectorDisplay: '',
                    width: '170px',
                    stateVar: selectedDeckGroup,
                    dispatch: setSelectedDeckGroup,
                    title: 'Select group to create deck in',
                    style: { marginRight: '5px' },
                    firstOptionItalic: true,
                  }} />
                  <button className='black-button text-icon-button' title='Add new deck group' onClick={openNewGroupModal} style={{ marginRight: '0px' }}>
                    <img src={imagePath + 'BigPlus.png'} width='18px' height='20px' style={{ margin: 'auto', marginLeft: '4px' }} />
                    <span style={{ marginLeft: '5px', marginRight: '5px' }}>New</span>
                  </button>
                </div>
                {savingDeck ?
                  <img src={'/images/LoadingAnim.png'} width='30px' height='30px' style={{ margin: 'auto', verticalAlign: 'middle', marginRight: isScreen1150 ? '5px' : '7px' }} /> :
                  <div>
                    <button className='black-button' style={{ marginRight: isScreen1150 ? '5px' : '7px' }} title='Create new deck'
                      onClick={saveAsNewDeck} disabled={saveAsDisabled || typeof(username) == 'undefined'}><span>Save As New</span></button>
                  </div>}
              </div>
            </div>

            <Ad styleName='bar_ad' />
            <Ad styleName='left_sidebar_ad_1400' />
            <Ad styleName='right_sidebar_ad_1400' />

            <div style={{ display: 'flex', margin: '0px auto 0px auto', alignItems: 'center' }}>
              <div style={{ margin: '0px auto 0px auto' }}>
                <h1 style={{ display: 'inline-flex', marginRight: isScreen1150 ? '5px' : '7px', padding: '0px' }}>Deck Builder</h1>
              </div>
            </div>

            <div className='builder-wrapper'>
              <div className='builder-left'>
                <div style={{ marginBottom: '10px' }}>
                  <span className='button-bar-label'>Deck:</span>
                  <BlackDropdown {...{
                    uniqueID: 'deck-select',
                    isSelector: true,
                    disabledVar: typeof(username) == 'undefined',
                    optionsList: decksList,
                    startingOption: null,
                    defaultValue: '',
                    defaultDisplay: 'Select...',
                    nonSelectorDisplay: '',
                    width: '200px',
                    stateVar: currentDeck,
                    dispatch: selectCurrentDeck,
                    title: 'Select deck to view/edit',
                    style: { marginRight: isScreen1150 ? '5px' : '7px' },
                    ulStyle: { width: '100%' },
                    stateGroupVar: currentDeckGroup,
                  }} />
                  {savingDeck ?
                    <img src={'/images/LoadingAnim.png'} width='30px' height='30px' style={{ margin: 'auto', verticalAlign: 'middle', marginRight: isScreen1150 ? '5px' : '7px' }} /> :
                    <button className='black-button text-icon-button' title='Manage decks & groups' disabled={deckLoading || typeof(username) == 'undefined'}
                      onClick={() => setGroupManagementOpen(true)} style={{ marginRight: isScreen1150 ? '5px' : '7px' }}>
                      <img src={imagePath + 'Tools.png'} width='18px' height='20px' style={{ margin: 'auto', marginLeft: '4px' }} />
                      <span style={{ marginLeft: '5px', marginRight: '5px' }}>Manage</span>
                    </button>}

                  {isScreen1300 ? <div style={{ marginBottom: '5px' }} /> : ''}

                  {savingDeck ?
                    <img src={'/images/LoadingAnim.png'} width='30px' height='30px' style={{ margin: 'auto', verticalAlign: 'middle', marginRight: isScreen1150 ? '5px' : '7px' }} /> :
                    <button className='black-button text-icon-button' title='Save deck (overwrites current deck)' disabled={currentDeck == '' || deckLoading || typeof(username) == 'undefined'}
                      onClick={saveCurrentDeck} style={{ marginRight: isScreen1150 ? '5px' : '7px' }}>
                      <img src={imagePath + 'Save.png'} width='18px' height='20px' style={{ margin: 'auto', marginLeft: '4px' }} />
                      <span style={{ marginLeft: '5px', marginRight: '5px' }}>Save</span>
                    </button>}

                  {savingDeck ?
                    <img src={'/images/LoadingAnim.png'} width='30px' height='30px' style={{ margin: 'auto', verticalAlign: 'middle', marginRight: isScreen1150 ? '5px' : '7px' }} /> :
                    <button className='black-button text-icon-button' title='Rename and save deck (overwrites current deck)' onClick={openDeckRenameModal}
                      style={{ marginRight: isScreen1150 ? '5px' : '7px' }} disabled={currentDeck == '' || saveAsDisabled || typeof(username) == 'undefined'}>
                      <img src={imagePath + 'Edit.png'} width='18px' height='20px' style={{ margin: 'auto', marginLeft: '4px' }} />
                      <span style={{ marginLeft: '5px', marginRight: '5px' }}>Rename</span>
                    </button>}

                  {savingDeck ?
                    <img src={'/images/LoadingAnim.png'} width='30px' height='30px' style={{ margin: 'auto', verticalAlign: 'middle', marginRight: isScreen1150 ? '5px' : '7px' }} /> :
                    <button className='black-button text-icon-button' title='Save as new deck' onClick={openDeckSaveAsModal}
                      style={{ marginRight: isScreen1150 ? '5px' : '7px' }} disabled={deckLoading || typeof(username) == 'undefined'}>
                      <img src={imagePath + 'BigPlus.png'} width='18px' height='20px' style={{ margin: 'auto', marginLeft: '4px' }} />
                      <span style={{ marginLeft: '5px', marginRight: '5px' }}>Save As New</span>
                    </button>}

                  {savingDeck ?
                    <img src={'/images/LoadingAnim.png'} width='30px' height='30px' style={{ margin: 'auto', verticalAlign: 'middle', marginRight: isScreen1150 ? '5px' : '7px' }} /> :
                    <button className='black-button text-icon-button' title='Delete current deck' disabled={currentDeck == '' || deckLoading || typeof(username) == 'undefined'} onClick={openDeleteDeckConfirm}
                      style={{ marginRight: isScreen1150 ? '5px' : '7px' }}>
                      <img src={imagePath + 'Trashcan.png'} width='18px' height='20px' style={{ margin: 'auto', marginLeft: '4px' }} />
                      <span style={{ marginLeft: '5px', marginRight: '5px' }}>Delete</span>
                    </button>}

                  {isScreen950 ? <div style={{ marginBottom: '5px' }} /> : ''}
                  <div className={'checkbox' + (savingDeck || deckLoading || typeof(username) == 'undefined' ? ' checkbox-disabled' : '')} title='Allow deck to be shared via link'
                    onClick={togglePublic} style={{ marginRight: '5px' }} >
                    <img className='checkbox-checkmark' src={imagePath + 'CheckmarkBlack.png'} width='20px' height='20px' style={{ display: (deckIsPublic ? 'inline-block' : 'none') }} />
                  </div>
                  <span className={'button-bar-label' + (savingDeck || deckLoading || typeof(username) == 'undefined' ? ' label-disabled' : '')}>Public</span>
                  <span className='save-warning' style={{ display: typeof (username) == 'undefined' ? 'inline-flex' : 'none' }}>Must be logged in to save decks and filter binders.</span>
                </div>

                <div style={{ marginBottom: '10px' }}>
                  <span className='button-bar-label'>Banlist:</span>
                  <BlackDropdown {...{
                    uniqueID: 'banlist-select',
                    isSelector: true,
                    disabledVar: false,
                    optionsList: banlistsList,
                    startingOption: null,
                    defaultValue: '',
                    defaultDisplay: 'No Banlist',
                    nonSelectorDisplay: '',
                    width: '200px',
                    stateVar: currentBanlist,
                    dispatch: selectCurrentBanlist,
                    title: 'Select banlist to apply',
                    style: { marginRight: isScreen1150 ? '5px' : '7px' },
                    ulStyle: { width: '100%' },
                    firstOptionItalic: true,
                    stateGroupVar: currentBanlistGroup,
                  }} />
                  {isScreen950 ? <div style={{ marginBottom: '5px' }} /> : ''}
                  <div className='checkbox' title='Enforce banlist limits on Deck Builder controls' onClick={toggleEnforceLimits} style={{ marginRight: '5px' }}>
                    <img className='checkbox-checkmark' src={imagePath + 'CheckmarkBlack.png'} width='20px' height='20px' style={{ display: (enforceLimits ? 'inline-block' : 'none') }} />
                  </div>
                  <span className='button-bar-label'>Enforce Limits</span>
                </div>

                <h5 style={{ margin: '0px 10px -5px 0px', textAlign: 'left', display: 'inline-block', fontSize: '43px' }}>
                  {currentDeckName != '' ? currentDeckName : 'New Deck'}
                </h5>
                <div style={{ display: 'flex', width: '100%' }}>
                  <h5 style={{ margin: '0px 10px -5px 0px', textAlign: 'left', display: 'inline-block' }}>Main Deck</h5>
                  {deckLoading ?
                    <img src={'/images/LoadingAnim.png'} width='40px' height='40px' style={{ margin: 'auto 0px auto 0px', verticalAlign: 'middle', display: 'inline-block' }} /> : ''}

                  {isScreen950 ? '' : <div className='tooltip' style={{ display: 'inline-block', alignSelf: 'center', marginLeft: 'auto' }}>
                    <img src={imagePath + 'Info.png'} width='30px' height='30px' style={{ verticalAlign: 'middle' }} />
                    <span className='button-bar-label' style={{ marginRight: '0px', paddingTop: '1px' }}>Hotkeys</span>
                    <ul className='tooltip-text' style={{ width: '250px', listStylePosition: 'inside', textIndent: '-18px', paddingLeft: '25px' }}>
                      <span style={{ display: 'block', textIndent: '0px', marginLeft: '-18px' }}>Hotkeys in search results:</span>
                      <li><span>Right-Click: Add card to Main/Extra</span></li>
                      <li><span>Shift + Right-Click: Add card to Side Deck</span></li>
                      <br />
                      <span style={{ display: 'block', textIndent: '0px', marginLeft: '-18px' }}>Hotkeys in deck zones:</span>
                      <li><span>Right-Click: Remove card</span></li>
                      <li><span>Shift + Right-Click: Swap card between Main/Extra and Side Deck</span></li>
                    </ul>
                  </div>}
                </div>
                <div className='drop-zone-controls deck-builder-controls'>
                  <div className='grid-left'>
                    <span className='floating-text'>
                      {mainDeck.length} Cards &#40;{mainDeckCounts.Monster} Monster | {mainDeckCounts.Spell} Spell | {mainDeckCounts.Trap} Trap&#41;
                    </span>
                  </div>
                  {isScreen1300 ? '' : <div className='grid-middle' />}
                  <div className={(isScreen1300 ? 'grid-left grid-row2' : 'grid-right')} style={{ marginTop: '0px', zIndex: 3 }}>
                    <BlackDropdown {...{
                      uniqueID: 'import-deck',
                      isSelector: false,
                      disabledVar: deckLoading,
                      optionsList: [{ value: 'ydk', text: 'Import from .ydk', callback: uploadDeck },
                        { value: 'ydke', text: 'Import from YDKe', callback: openYdkeImportModal }],
                      startingOption: null,
                      defaultValue: '',
                      defaultDisplay: '',
                      nonSelectorDisplay: <div className='text-icon-button'>
                        <img src={imagePath + 'Import.png'} width='18px' height='20px' style={{ margin: 'auto', marginLeft: '4px' }} />
                        <span style={{ marginLeft: '5px', marginRight: '5px' }}>Import</span>
                      </div>,
                      width: 'initial',
                      stateVar: '',
                      dispatch: null,
                      title: 'Import deck',
                      style: { marginRight: isScreen1150 ? '5px' : '7px' },
                    }}/>
                    <input type='file' id='deck-import-field' accept='.ydk' onChange={importFromYdk} style={{ display: 'none' }} />
                    <BlackDropdown {...{
                      uniqueID: 'export-deck',
                      isSelector: false,
                      disabledVar: deckLoading,
                      optionsList: [{ value: 'ydk', text: 'Export to .ydk', callback: exportToYdk },
                        { value: 'ydke', text: 'Export to YDKe', callback: exportToYdke },
                        ...(currentDeck != '' && deckIsPublic ? [{ value: 'url', text: 'Export to Shareable Link', callback: exportToUrl }] : []),
                        { value: 'png', text: 'Export to .png', callback: () => setExportingImage(true) }],
                      startingOption: null,
                      defaultValue: '',
                      defaultDisplay: '',
                      nonSelectorDisplay: <div className='text-icon-button'>
                        <img src={imagePath + 'Export.png'} width='18px' height='20px' style={{ margin: 'auto', marginLeft: '4px' }} />
                        <span style={{ marginLeft: '5px', marginRight: '5px' }}>Export</span>
                      </div>,
                      width: 'initial',
                      stateVar: '',
                      dispatch: null,
                      title: 'Export deck',
                      style: { marginRight: isScreen1150 ? '5px' : '7px' },
                    }}/>
                    {isScreen950 ? <div style={{ marginBottom: '5px' }} /> : ''}
                    <div className='right-controls'>
                      <button className='black-button text-icon-button' title='Sort deck by type' disabled={deckLoading} onClick={sortDeck}
                        style={{ marginRight: isScreen1150 ? '5px' : (isScreen1300 ? '5px' : '7px') }}>
                        <img src={imagePath + 'Sort.png'} width='18px' height='18px' style={{ margin: 'auto', marginLeft: '4px' }} />
                        <span style={{ marginLeft: '5px', marginRight: '5px' }}>Sort</span>
                      </button>
                      <button className='black-button text-icon-button' title='Shuffle main deck' disabled={deckLoading} onClick={shuffleDeck}
                        style={{ marginRight: isScreen1150 ? '5px' : '7px' }}>
                        <img src={imagePath + 'Shuffle.png'} width='18px' height='18px' style={{ margin: 'auto', marginLeft: '4px' }} />
                        <span style={{ marginLeft: '5px', marginRight: '5px' }}>Shuffle</span>
                      </button>
                      <button className='black-button text-icon-button' title='Clear deck zones' disabled={deckLoading} onClick={clearDeck}
                        style={{ marginRight: isScreen1150 ? '5px' : '7px' }}>
                        <img src={imagePath + 'Broom.png'} width='18px' height='18px' style={{ margin: 'auto', marginLeft: '4px' }} />
                        <span style={{ marginLeft: '5px', marginRight: '5px' }}>Clear</span>
                      </button>
                      <button className='black-button text-icon-button' title='Reset to last saved state' disabled={deckLoading} onClick={resetDeck}
                        style={{ marginRight: '0px' }}>
                        <img src={imagePath + 'Reset.png'} width='18px' height='18px' style={{ margin: 'auto', marginLeft: '4px' }} />
                        <span style={{ marginLeft: '5px', marginRight: '5px' }}>Reset</span>
                      </button>
                    </div>
                  </div>
                </div>
                <DropZone
                  {...{
                    zone: 'm',
                    zoneListRef: mainDeckRef,
                    zoneList: mainDeck,
                    zoneMax: MAIN_DECK_MAX,
                    gridWidth: getGridWidth(),
                    minimize: minimizeMainDeck,
                    styleClassName: 'maindeck-box',
                  }}
                />
                <img className='dropzone-arrow' src={imagePath + 'DownArrow.png'} width='30px' height='30px' style={{ transform: minimizeMainDeck ? 'none' : 'scaleY(-1)', marginBottom: '-5px' }}
                  title='Collapse/Expand Main Deck zone' onClick={() => setMinimizeMainDeck((prev: boolean) => !prev)} />

                <h5 style={{ marginTop: '0px', marginBottom: '0px', textAlign: 'left', clear: 'both' }}>Extra Deck</h5>
                <span className='floating-text' style={{ marginBottom: isScreen1150 ? '5px' : '12px' }}>
                  {extraDeck.length} Cards &#40;{extraDeckCounts.Fusion} Fusion | {extraDeckCounts.Synchro} Synchro | {extraDeckCounts.Xyz} Xyz | {extraDeckCounts.Link} Link&#41;
                </span>
                <DropZone
                  {...{
                    zone: 'e',
                    zoneListRef: extraDeckRef,
                    zoneList: extraDeck,
                    zoneMax: EXTRA_DECK_MAX,
                    gridWidth: getGridWidth(),
                    minimize: minimizeExtraDeck,
                    styleClassName: 'extradeck-box',
                  }}
                />
                <img className='dropzone-arrow' src={imagePath + 'DownArrow.png'} width='30px' height='30px' style={{ transform: minimizeExtraDeck ? 'none' : 'scaleY(-1)', marginBottom: '-5px' }}
                  title='Collapse/Expand Extra Deck zone' onClick={() => setMinimizeExtraDeck((prev: boolean) => !prev)} />

                <h5 style={{ marginTop: '0px', marginBottom: '0px', textAlign: 'left', clear: 'both' }}>Side Deck</h5>
                <span className='floating-text' style={{ marginBottom: isScreen1150 ? '5px' : '12px' }}>
                  {sideDeck.length} Cards &#40;{sideDeckCounts.Monster} Monster | {sideDeckCounts.Spell} Spell | {sideDeckCounts.Trap} Trap&#41;
                </span>
                <DropZone
                  {...{
                    zone: 's',
                    zoneListRef: sideDeckRef,
                    zoneList: sideDeck,
                    zoneMax: SIDE_DECK_MAX,
                    gridWidth: getGridWidth(),
                    minimize: minimizeSideDeck,
                    styleClassName: 'sidedeck-box',
                  }}
                />
                <img className='dropzone-arrow' src={imagePath + 'DownArrow.png'} width='30px' height='30px' style={{ transform: minimizeSideDeck ? 'none' : 'scaleY(-1)', marginBottom: '-5px' }}
                  title='Collapse/Expand Side Deck zone' onClick={() => setMinimizeSideDeck((prev: boolean) => !prev)} />
              </div>

              <div id='deckbuilder-results' className={isScreen950 ? 'small-screen-builder-results' : 'builder-right sticky-panel'}>
                <div id='card-added-popup' className='card-added-popup'><span>{cardAddedPopupText}</span></div>
                {isScreen950 ? <button className='black-button icon-button left-tab' title='Show/hide search results' onClick={toggleResultsBox}
                  style={{ width: '34px', marginRight: '0px' }}>
                  <img src={imagePath + 'SearchIcon.png'} width='20px' height='20px' style={{ margin: 'auto' }} />
                </button> : ''}
                <div className='filter-field'>
                  {isScreen950 ? <img src={imagePath + 'Close.png'} width='15px' height='15px' className='close-button' title='Hide search results' onClick={toggleResultsBox}
                    style={{ margin: '0px 10px auto 0px', display: 'inline-block', cursor: 'pointer' }} /> : ''}
                  <span className='button-bar-label'>Sort By:</span>
                  <BlackDropdown {...{
                    uniqueID: 'search-sort',
                    isSelector: true,
                    disabledVar: searchActionsDisabled,
                    optionsList: [...filterSearchSortOptions, ...(currentBinder != '' ? [{ value: 'count', text: 'Count' } as DropdownOption] : [])],
                    startingOption: null,
                    defaultValue: 'name',
                    defaultDisplay: 'Name',
                    nonSelectorDisplay: '',
                    width: '100px',
                    stateVar: sortField,
                    dispatch: setSortField,
                    title: 'Select field to sort search results by',
                    style: { marginRight: isScreen1150 ? '5px' : '7px' },
                  }} />
                  <BlackDropdown {...{
                    uniqueID: 'sort-order',
                    isSelector: true,
                    disabledVar: searchActionsDisabled,
                    optionsList: sortOrderOptions,
                    startingOption: null,
                    defaultValue: 'asc',
                    defaultDisplay: 'Asc',
                    nonSelectorDisplay: '',
                    width: '70px',
                    stateVar: sortOrder,
                    dispatch: setSortOrder,
                    title: 'Select order to sort search results by',
                    style: { marginRight: isScreen1150 ? (isScreen950 ? '0px' : '5px') : '7px' },
                  }} />
                  {isScreen950 ? '' : <button className='black-button icon-button' title='Show/hide filters' onClick={toggleFilterBox}
                    style={{ width: '30px', marginRight: isScreen1150 ? '5px' : '7px' }}>
                    <img src={imagePath + 'Filter.png'} width='20px' height='20px' style={{ margin: 'auto' }} />
                  </button>}
                </div>
                <div className={'small-screen-builder-results-box' + (isScreen950 ? '' : ' content-box')}>
                  {searchLoading ? <img src={'/images/LoadingAnim.png'} width='100px' height='100px' style={{ margin: '5px auto auto auto' }} /> :
                    searchResults.length > 0 ?
                      <FixedSizeList
                        height={isScreen950 ? Math.min((window.innerHeight / 100) * 70, 790) : 790}
                        width={isScreen950 ? 250 : 390}
                        style={{ marginRight: '0px' }}
                        itemSize={95}
                        itemCount={searchResults.length}>
                        {resultsRow}
                      </FixedSizeList> :
                      <span className='empty-search' style={{ marginTop: '5px' }}>No Matching Cards Found</span>}
                </div>
                <button className='black-button text-icon-button' title='Add all results to deck' onClick={checkPerformanceWarning}
                  style={{ margin: '0px 0px 0px auto', display: 'flex', alignSelf: 'end' }} disabled={deckLoading || searchLoading}>
                  <img src={imagePath + 'BigPlus.png'} width='18px' height='20px' style={{ margin: 'auto', marginLeft: '4px' }} />
                  <span style={{ marginLeft: '5px', marginRight: '5px' }}>Add All</span>
                </button>
              </div>
            </div>

            <div id='deckbuilder-filters' className='filters-box'>
              {isScreen950 ? <button className='black-button icon-button right-tab' title='Show/hide filters' onClick={toggleFilterBox}
                style={{ width: '34px', marginRight: '0px' }}>
                <img src={imagePath + 'Filter.png'} width='20px' height='20px' style={{ margin: 'auto' }} />
              </button> : ''}
              <div style={{ marginTop: '0px', marginBottom: '5px', textAlign: 'left' }}>
                <h5 style={{ margin: '0px', textAlign: 'left', display: 'inline-block', width: 'fit-content' }}>Filters</h5>
                <span id='clear-all-button' className='forgot-pass-button' title='Clear all filters' onClick={clearAllFilters} style={{ marginLeft: '3px', marginRight: 'auto', display: 'none' }}>
                  Clear All
                </span>
                <img src={imagePath + 'Close.png'} width='15px' height='15px' className='close-button' title='Hide filters' onClick={toggleFilterBox}
                  style={{ margin: 'auto', display: 'inline-block', position: 'absolute', right: '5px', top: '5px', cursor: 'pointer' }} />
              </div>
              <div className='filter-field'>
                <span className='button-bar-label'>Binder:</span>
                <BlackDropdown {...{
                  uniqueID: 'binder-select',
                  isSelector: true,
                  disabledVar: searchActionsDisabled || typeof(username) == 'undefined',
                  optionsList: binderList,
                  startingOption: {
                    value: sessionMemory.lastBinderSelected,
                    text: binderList.find((b: DropdownOption) => b.value == sessionMemory.lastBinderSelected)?.text,
                  } as DropdownOption,
                  defaultValue: '',
                  defaultDisplay: 'All Cards',
                  nonSelectorDisplay: '',
                  width: '200px',
                  stateVar: currentBinder,
                  dispatch: setCurrentBinder,
                  title: 'Select binder to limit search results by',
                  style: { marginRight: '0px' },
                  firstOptionItalic: true,
                }} />
              </div>

              <SearchBox {...{
                uniqueID: 'search',
                emptyDisplay: 'Search cards...',
                width: '100%',
                searchFunc: updateSearchString,
                style: { marginBottom: '5px', width: '100%', boxShadow: 'none' },
                searchOnValueChange: true,
              }}/>

              <WindowedSelect windowThreshold={100} ref={setsFilterField}
                options={Object.keys(cardSetDates).map((cs: string) => ({ value: cs, label: cs }))}
                onChange={(newValue: any, actionMeta: any) => setFilter(newValue, setSetsFilter)}
                isDisabled={searchActionsDisabled} isMulti={true} styles={multiSelectStyles} placeholder='Set' noOptionsMessage={() => 'No More Options'} />

              <Select options={rarityOptions} isMulti={true} styles={multiSelectStyles} onChange={(newValue: any, actionMeta: any) => setFilter(newValue, setRarityFilter)}
                isDisabled={searchActionsDisabled} placeholder='Rarity' noOptionsMessage={() => 'No More Options'} ref={rarityFilterField} />

              <Select options={limitOptions} isMulti={true} styles={multiSelectStyles} onChange={(newValue: any, actionMeta: any) => setFilter(newValue, setLimitFilter)}
                isDisabled={searchActionsDisabled} placeholder='Banlist Limit' noOptionsMessage={() => 'No More Options'} ref={limitFilterField} />

              <Select options={cardCategoryOptions} isMulti={true} styles={multiSelectStyles} onChange={(newValue: any, actionMeta: any) => setFilter(newValue, setCategoryFilter)}
                isDisabled={searchActionsDisabled} placeholder='Card Category' noOptionsMessage={() => 'No More Options'} ref={categoryFilterField} />

              <Select options={cardTypeOptions} isMulti={true} styles={multiSelectStyles} onChange={(newValue: any, actionMeta: any) => setFilter(newValue, setTypeFilter)}
                isDisabled={searchActionsDisabled} placeholder='Type' noOptionsMessage={() => 'No More Options'} ref={typeFilterField} />

              <Select options={subtypeOptions} isMulti={true} styles={multiSelectStyles} onChange={(newValue: any, actionMeta: any) => setFilter(newValue, setSubtypeFilter)}
                isDisabled={searchActionsDisabled} placeholder='Subtype' noOptionsMessage={() => 'No More Options'} ref={subtypeFilterField} />

              <Select options={attributeOptions} isMulti={true} styles={multiSelectStyles} onChange={(newValue: any, actionMeta: any) => setFilter(newValue, setAttributeFilter)}
                isDisabled={searchActionsDisabled} placeholder='Attribute' noOptionsMessage={() => 'No More Options'} ref={attributeFilterField} />

              <Select options={levelOptions} isMulti={true} styles={multiSelectStyles} onChange={(newValue: any, actionMeta: any) => setFilter(newValue, setLevelFilter)}
                isDisabled={searchActionsDisabled} placeholder='Level/Rank/Link' noOptionsMessage={() => 'No More Options'} ref={levelFilterField} />

              <Select options={linkMarkerOptions} isMulti={true} styles={multiSelectStyles} onChange={(newValue: any, actionMeta: any) => setFilter(newValue, setLinkMarkersFilter)}
                isDisabled={searchActionsDisabled} placeholder='Link Markers' noOptionsMessage={() => 'No More Options'} ref={linkMarkersFilterField} />

              <Select options={levelOptions} isMulti={true} styles={multiSelectStyles} onChange={(newValue: any, actionMeta: any) => setFilter(newValue, setPendScaleFilter)}
                isDisabled={searchActionsDisabled} placeholder='Pendulum Scale' noOptionsMessage={() => 'No More Options'} ref={pendScaleFilterField} />

              <div className='filter-field filter-range'>
                <div className='input-box grid-left' style={{ boxShadow: 'none' }}><input type='number' min='0' max='9999' pattern='[0-9]{4}' name='MinAtk' ref={atkFilterFieldLeft}
                  disabled={searchActionsDisabled} onInput={rangeFilterOnChange} placeholder={'Min ATK'} style={{ width: '70px' }} /></div>
                <b className='middle-margin grid-middle'>-</b>
                <div className='input-box grid-right' style={{ boxShadow: 'none' }}><input type='number' min='0' max='9999' pattern='[0-9]{4}' name='MaxAtk' ref={atkFilterFieldRight}
                  disabled={searchActionsDisabled} onInput={rangeFilterOnChange} placeholder={'Max ATK'} style={{ width: '70px' }} /></div>
              </div>
              <div className='filter-field filter-range'>
                <div className='input-box grid-left' style={{ boxShadow: 'none' }}><input type='number' min='0' max='9999' pattern='[0-9]{4}' name='MinDef' ref={defFilterFieldLeft}
                  disabled={searchActionsDisabled} onInput={rangeFilterOnChange} placeholder={'Min DEF'} style={{ width: '70px' }} /></div>
                <b className='middle-margin grid-middle'>-</b>
                <div className='input-box grid-right' style={{ boxShadow: 'none' }}><input type='number' min='0' max='9999' pattern='[0-9]{4}' name='MaxDef' ref={defFilterFieldRight}
                  disabled={searchActionsDisabled} onInput={rangeFilterOnChange} placeholder={'Max DEF'} style={{ width: '70px' }} /></div>
              </div>
              <div className='filter-field filter-range'>
                <div className='input-box grid-left' style={{ boxShadow: 'none' }}>
                  <input type='date' disabled={searchActionsDisabled} placeholder={'Min Date'} name='MinDate' onInput={rangeFilterOnChange} ref={dateFilterFieldLeft}
                    style={{ width: '100px', color: dateFilter.length > 0 && dateFilter[0] > DATE_MIN ? '#1E1E1E' : '#777' }} />
                </div>
                <b className='middle-margin grid-middle'>-</b>
                <div className='input-box grid-right' style={{ boxShadow: 'none' }}>
                  <input type='date' disabled={searchActionsDisabled} placeholder={'Max Date'} name='MaxDate' onInput={rangeFilterOnChange} ref={dateFilterFieldRight}
                    style={{ width: '100px', color: dateFilter.length > 0 && dateFilter[1] < DATE_MAX ? '#1E1E1E' : '#777' }} />
                </div>
              </div>
            </div>

            <Ad styleName='bar_ad_footer' />
          </DndProvider>
        </main>
      </BuilderContext.Provider>
    );
  }
  else {
    return (
      <div style={{ margin: 'auto', textAlign: 'center' }}>
        <Helmet>
          <meta content="YGO Prog - Deck Builder" property="og:title" />
          <meta
            content="YGO Prog's Deck Builder lets you create and export Yu-Gi-Oh! TCG decks as files for EDOPRO, YGO Omega, and Dueling Book, or as images and links for sharing!"
            property="og:description"
          />
          <meta content="https://www.ygoprog.com/DeckBuilder" property="og:url" />
          <meta
            name="description"
            content="YGO Prog's Deck Builder lets you create and export Yu-Gi-Oh! TCG decks as files for EDOPRO, YGO Omega, and Dueling Book, or as images and links for sharing!"
          />
          <title>YGO Prog - Deck Builder</title>
        </Helmet>
        <h1 style={{ paddingBottom: '0px', marginBottom: '0px' }}>Loading</h1>
        <img src={'/images/LoadingAnim.png'} width='150px' height='150px' style={{ margin: 'auto', marginTop: '5px', padding: '0' }} />
      </div>
    );
  }
}
