import { useState, useEffect, useContext, useRef } from 'react';
import { cookies, onCookieChangeListeners, apiPath,
  addCardsToBinder, sessionMemory, imagePath, cardLink, productLink, TypeGroup, contactString, lowercaseContactString, RarityLevel } from '../utils/Constants';
import BlackDropdown from './BlackDropdown';
import SearchBox from './SearchBox';
import SaveAsBinderModal from './SaveAsBinderModal';
import { saveAs } from 'file-saver';
import csvToJson from 'csvtojson';
import HoverableCard from './HoverableCard';
import makeApiCall from '../utils/ApiMiddleware';
import { openErrorModal } from '../utils/BasicModalFuncs';
import { encode, decode } from 'iconv-lite';
import * as CardsData from '../utils/CardDataCache';
import { AppContext } from '../utils/Contexts';
import { useHistory } from 'react-router-dom';
import DeleteConfirmModal from './DeleteConfirmModal';
import localforage from 'localforage';
import { Helmet } from 'react-helmet';
import Ad from './Ad';

interface AllSearchCard extends Card {
  setrarities: DropdownOption[],
}

const allCardSearchFilterOptions: DropdownOption[] = [
  { value: 'all', text: 'Card Name & Text' } as DropdownOption,
  { value: 'name', text: 'Card Name' } as DropdownOption,
  { value: 'set_name', text: 'Set Name' } as DropdownOption,
  { value: 'desc', text: 'Card Text' } as DropdownOption,
];

const binderSearchFilterOptions: DropdownOption[] = [
  { value: 'all', text: 'Card Name & Text' } as DropdownOption,
  { value: 'name', text: 'Card Name' } as DropdownOption,
  { value: 'set', text: 'Set Name' } as DropdownOption,
  { value: 'rarity', text: 'Card Rarity' } as DropdownOption,
  // { value: 'desc', text: 'Card Text' } as DropdownOption,
];

let allCardSearchTask: any;
let binderSearchTask: any;
let checkForCardsTask: any;
let queuedCurrentBinder: string|null = null;

export default function BinderManagement(props: { refresh: any, openModalFunc: any }) {

  const [allCardSearchField, setAllCardSearchField] = useState('all');
  const [allCardSearchSort, setAllCardSearchSort] = useState('name');
  const [allCardSearchSortOrder, setAllCardSearchSortOrder] = useState('asc');
  const [allCardsPerPage, setAllCardsPerPage] = useState<number>(5);
  const [allCardSearchResults, setAllCardSearchResults] = useState<AllSearchCard[]>([]); // Page array of results, filled as we go
  const [addingAllCard, setAddingAllCard] = useState<boolean[]>(new Array(30).fill(false));
  const [adding, setAdding] = useState(false); // Need secondary to force update
  const [allCardAdded, setAllCardAdded] = useState<boolean[]>(new Array(30).fill(false));
  const [allCardSearchLoaded, setAllCardSearchLoaded] = useState(true);
  const [currentAllCardPage, setCurrentAllCardPage] = useState(0); // Page array of results, filled as we go
  const [allCardSearchString, setAllCardSearchString] = useState('');
  const [allCardSelectedSetRarities, setAllCardSelectedSetRarities] = useState<{[key: number]: string}>({});
  const allCardPageField = useRef<any>();

  const [currentBinder, setCurrentBinder] = useState('');
  const [currentBinderCards, setCurrentBinderCards] = useState<BinderCard[]>([]);
  const [binderActionsDisabled, setBinderActionsDisabled] = useState(true);
  const [binderCardsPerPage, setBinderCardsPerPage] = useState<number>(20);
  const [binderSearchField, setBinderSearchField] = useState('all');
  const [binderSearchSort, setBinderSearchSort] = useState('name');
  const [binderSearchSortOrder, setBinderSearchSortOrder] = useState('asc');
  const [binderSearchString, setBinderSearchString] = useState('');
  const [binderSearchResults, setBinderSearchResults] = useState<BinderCard[]>([]);
  const [binderSearchLoaded, setBinderSearchLoaded] = useState(true);
  const [collapsePrints, setCollapsePrints] = useState(false);
  const [currentBinderPage, setCurrentBinderPage] = useState(0);
  const [binderSearchCardCount, setBinderSearchCardCount] = useState(0);
  const [binderSearchResultsDirty, setBinderSearchResultsDirty] = useState(false);
  const [selectedCards, setSelectedCards] = useState<string[]>([]); // Selected card is just the set-card code for the entry
  const [allBinderCardsSelected, setAllBinderCardsSelected] = useState(false);
  const [selectedCardCount, setSelectedCardCount] = useState(0);
  const [binderList, setBinderList] = useState<DropdownOption[]>([]);
  const [username, setUsername] = useState(cookies.get('user'));
  const allCardsMounted = useRef(false);
  const binderMounted = useRef(false);
  const [cardsAreLoaded, setCardsAreLoaded] = useState(CardsData.cardsLoaded.flag);
  const history = useHistory();
  const { refreshRouteFunc, refresh, isScreen1150Query, isScreen950Query } = useContext(AppContext);
  const [isScreen950, setIsScreen950] = useState(isScreen950Query.matches);
  const [isScreen1150, setIsScreen1150] = useState(isScreen1150Query.matches);
  const [confirmMatchString, setConfirmMatchString] = useState('');
  const binderPageFieldTop = useRef<any>();
  const binderPageFieldBottom = useRef<any>();

  useEffect(() => { // Mount
    isScreen950Query.addEventListener('change', checkScreen950); // To avoid re-rendering the whole page and losing data, we have to listen and state change on the sublevel
    isScreen1150Query.addEventListener('change', checkScreen1150);
    updateBinderList();
    onCookieChangeListeners.push(cookieRefresh);
    if (!cardsAreLoaded) {
      checkForCardsTask = checkForCardsLoop();
    }

    return () => { // Unmount
      isScreen950Query.removeEventListener('change', checkScreen950);
      isScreen1150Query.removeEventListener('change', checkScreen1150);
      onCookieChangeListeners.splice(onCookieChangeListeners.indexOf(cookieRefresh), 1);
      if (allCardSearchTask != null) {
        clearTimeout(allCardSearchTask);
        allCardSearchTask = null;
      }
      if (binderSearchTask != null) {
        clearTimeout(binderSearchTask);
        binderSearchTask = null;
      }
      if (checkForCardsTask != null) {
        clearTimeout(checkForCardsTask);
        checkForCardsTask = null;
      }
    };
  }, []);

  useEffect(() => {
    const setrarities: { [key: number]: string } = {};
    if (allCardSearchResults.length > 0) {
      for (let i: number = 0; i < allCardSearchResults.length; i++) {
        const card = allCardSearchResults[i];
        setrarities[card.id] = String(card.setrarities[0].value);
      }
    }
    setAllCardSelectedSetRarities(setrarities);
  }, [allCardSearchResults]);

  useEffect(() => {
    sessionMemory.lastBinderSelected = currentBinder;
    queuedCurrentBinder = null;
    if (currentBinder != '') {
      setCurrentBinderPage(1);
      setBinderActionsDisabled(true);
      setBinderSearchLoaded(false);

      CardsData.getBinderCards(currentBinder, false)
        .then(async (results) => {
          if (results != null) {
            setCurrentBinderCards(results);
            binderSearch(binderSearchSort, binderSearchSortOrder, binderSearchString, binderSearchField, collapsePrints, results);
          }
          else {
            setBinderActionsDisabled(false);
            setBinderSearchLoaded(true);
          }
        });
    }
    else {
      setCurrentBinderPage(0);
      setBinderActionsDisabled(true);
      setBinderSearchLoaded(true);
      setBinderSearchResults([]);
      setBinderSearchCardCount(0);
    }
  }, [currentBinder]);

  useEffect(() => {
    if (queuedCurrentBinder != null) setCurrentBinder(queuedCurrentBinder);
  }, [binderList]);

  useEffect(() => {
    setSelectedCards([]);
    setSelectedCardCount(0);
    setAllBinderCardsSelected(false);
  }, [binderSearchResults]);

  useEffect(() => {
    const maxPages = Math.ceil(binderSearchResults.length / binderCardsPerPage);
    if (currentBinderPage > maxPages) setCurrentBinderPage(maxPages);
  }, [binderCardsPerPage]);

  useEffect(() => {
    const maxPages = Math.ceil(allCardSearchResults.length / allCardsPerPage);
    if (currentAllCardPage > maxPages) setCurrentAllCardPage(maxPages);
  }, [allCardsPerPage]);

  useEffect(() => {
    if (binderPageFieldTop.current) binderPageFieldTop.current.value = currentBinderPage;
    if (binderPageFieldBottom.current) binderPageFieldBottom.current.value = currentBinderPage;
  }, [currentBinderPage]);

  useEffect(() => {
    if (allCardPageField.current) allCardPageField.current.value = currentAllCardPage;
  }, [currentAllCardPage]);

  useEffect(() => {
    if (allCardsMounted.current) {
      if (allCardSearchTask != null) clearTimeout(allCardSearchTask);
      allCardSearchTask = setTimeout(() => {
        setCurrentAllCardPage(1);
        allCardSearch(allCardSearchSort, allCardSearchSortOrder, allCardSearchString, allCardSearchField);
      }, 300);
    }
    else allCardsMounted.current = true;
  }, [allCardSearchString]);

  useEffect(() => {
    if (binderMounted.current) {
      if (binderSearchTask != null) clearTimeout(binderSearchTask);
      binderSearchTask = setTimeout(() => {
        setCurrentBinderPage(1);
        binderSearch(binderSearchSort, binderSearchSortOrder, binderSearchString, binderSearchField, collapsePrints, null);
      }, 300);
    }
    else binderMounted.current = true;
  }, [binderSearchString]);

  useEffect(() => {
    if (confirmMatchString != '') {
      document.getElementById('delete-confirm-modal')!.style.display = 'flex';
    }
  }, [confirmMatchString]);

  function checkScreen950() {
    setIsScreen950(isScreen950Query.matches);
  }

  function checkScreen1150() {
    setIsScreen1150(isScreen1150Query.matches);
  }

  function cookieRefresh() {
    setUsername(cookies.get('user'));
    updateBinderList();
  }

  function checkForCardsLoop() {
    return setTimeout(() => {
      checkForCardsTask = null;
      if (CardsData.cardsLoaded.flag) setCardsAreLoaded(true);
      else {
        checkForCardsTask = checkForCardsLoop();
      }
    }, 200);
  }

  function allCardSearch(sortField: string, sortOrder: string, searchString: string, searchField: string) {
    allCardSearchTask = null;
    setAllCardSearchLoaded(false);
    setAddingAllCard(new Array(30).fill(false));
    setAllCardAdded(new Array(30).fill(false));

    const results = CardsData.allCardSearch(searchField, searchString, sortField, sortOrder == 'asc');
    if (results.length > 0) {
      const cardsData: AllSearchCard[] = results.map((card: Card) => {
        return {
          ...card,
          setrarities: card.card_sets.map((set: SetCardInfo) => {
            return {
              value: set.set_name + ',' + set.set_rarity,
              text: set.set_name + ' - ' + set.set_rarity,
            } as DropdownOption;
          })
            .filter((setrarity: DropdownOption, index: number, self: DropdownOption[]) => { // Remove any duplicates
              return index === self.findIndex((sr) => sr.value === setrarity.value);
            }),
        };
      });
      setCurrentAllCardPage(1);
      setAllCardSearchResults(cardsData);
    }
    else {
      setCurrentAllCardPage(0);
      setAllCardSearchResults([]);
    }

    setAllCardSearchLoaded(true);
  }

  function allCardPageInput(e: any) {
    if (e.currentTarget.value !== '') {
      if (parseFloat(e.currentTarget.value as string) > parseFloat(e.currentTarget.max as string)) e.currentTarget.value = e.currentTarget.max;
      else if (parseFloat(e.currentTarget.value as string) < parseFloat(e.currentTarget.min as string)) e.currentTarget.value = e.currentTarget.min;
    }

    changeAllCardPage(e.currentTarget.value - currentAllCardPage);
  }

  function changeAllCardPage(increment: number) {
    const newPage = currentAllCardPage + increment;

    if (increment == 0 || newPage < 1 || newPage > Math.ceil(allCardSearchResults.length / allCardsPerPage)) return;
    else {
      setAddingAllCard(new Array(30).fill(false));
      setAllCardAdded(new Array(30).fill(false));
      setCurrentAllCardPage(newPage);
    }
  }

  function updateAllCardSearchSort(sortField: string) {
    setAllCardSearchSort(sortField);
    sortAllCardSearch(allCardSearchSortOrder, sortField);
  }

  function updateAllCardSearchSortOrder(sortOrderField: string) {
    setAllCardSearchSortOrder(sortOrderField);
    sortAllCardSearch(sortOrderField, allCardSearchSort);
  }

  function addAllSearchCardToBinder(e: any) {
    const cardIndex = allCardSearchResults.findIndex((c: AllSearchCard) => c.id == e.currentTarget.dataset.cardid);
    let card: AllSearchCard | undefined;
    if (cardIndex !== -1) card = allCardSearchResults[cardIndex];

    if (card !== undefined) {
      const setrarity = allCardSelectedSetRarities[card.id];
      const set = card.card_sets.find((s: SetCardInfo) => s.set_name+','+s.set_rarity == setrarity)!;
      const addCard: BinderCardInfo = {
        id: card.id,
        name: card.name,
        set: set.set_name,
        code: set.set_code,
        rarity: set.set_rarity,
        count: Number(1),
      };

      if (currentBinder != '') {
        let acAdding: boolean[] = addingAllCard;
        acAdding[cardIndex] = true;
        setAddingAllCard(acAdding);
        setAdding(true);
        addCardsToBinder(cookies.get('token'), cookies.get('refresh'), cookies.get('userId'), currentBinder, [addCard], () => {
          const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + cookies.get('token') },
          };

          makeApiCall(cookies.get('refresh'), cookies.get('userId'), apiPath + 'binder/' + currentBinder + '/cards/?pageLimit=30&page=0&searchField=code&searchText=' + addCard.code +
            '&sortField=code&sortOrder=asc', requestOptions)
            .then(async (response) => {
              acAdding = addingAllCard;
              acAdding[cardIndex] = false;
              setAddingAllCard(acAdding);
              setAdding(false);

              if (!response.ok) {
                const error = response.status;
                openErrorModal('Issue searching binder after card add.<br />' +
                  (error == 401 ? '<br />Try logging out and logging back in.<br />If this persists, ' + lowercaseContactString : '') +
                  (error == 400 ? '<br />You have been inactive for a long time. Please log out and back in.<br /><br />If this persists, ' + lowercaseContactString : '') +
                  (error != 401 && error != 400 ? '<br />' + contactString : ''));
                return Promise.reject(error);
              }

              const data = await response.json();

              if (data.results.length > 0 && currentBinder != '') {
                const binderSR = [...binderSearchResults];
                const resultCard: BinderCard = {
                  ...CardsData.cards[data.results[0].cardId],
                  set: data.results[0].set,
                  code: data.results[0].code,
                  rarity: data.results[0].rarity,
                  count: Number(data.results[0].count),
                  all_counts: {},
                };

                const adjustedBinderCards = [...currentBinderCards];
                const existingInd = adjustedBinderCards.findIndex((c: BinderCard) => c.code + c.rarity == resultCard.code + resultCard.rarity);
                if (existingInd !== -1) adjustedBinderCards[existingInd].count += Number(1);
                else adjustedBinderCards.push(resultCard);
                setCurrentBinderCards(adjustedBinderCards);

                if (currentBinderPage > 0) { // Check if it's on this page so we don't make a duplicate key
                  const pageInd = binderSR.findIndex((c: BinderCard) => c.code + c.rarity == resultCard.code + resultCard.rarity);
                  if (pageInd == -1) binderSR.splice((currentBinderPage-1) * binderCardsPerPage, 0, resultCard);
                }
                else {
                  setCurrentBinderPage(1);
                  binderSR[0] = resultCard;
                }

                const acAdded = allCardAdded;
                acAdded[cardIndex] = true;
                setAllCardAdded(acAdded);
                setBinderSearchResults(binderSR);
                setBinderSearchResultsDirty(true);
                const newCount = binderSearchCardCount + Number(data.results[0].count);
                setBinderSearchCardCount(newCount);
              }
            });
        });
      }
    }
  }

  function binderSearch(sortField: string, sortOrder: string, searchString: string, searchField: string, collapseBinderPrints: boolean, binder: BinderCard[] | null) {
    binderSearchTask = null;
    if (currentBinder == '' && binder == null) return;

    setBinderSearchResultsDirty(false);
    setBinderSearchLoaded(false);

    const results = CardsData.binderCardSearch(binder != null ? binder : currentBinderCards, searchField, searchString, sortField, sortOrder == 'asc', collapseBinderPrints);

    if (results.cards.length > 0) {
      setBinderSearchCardCount(results.totalCardCount);
      setBinderSearchResults(results.cards);
      if (currentBinderPage > Math.ceil(results.totalCardCount / binderCardsPerPage)) setCurrentBinderPage(1);
    }
    else {
      setBinderSearchCardCount(0);
      setCurrentBinderPage(0);
      setBinderSearchResults([]);
    }

    setBinderActionsDisabled(false);
    setBinderSearchLoaded(true);
  }

  function binderPageInput(e: any) {
    if (e.currentTarget.value !== '') {
      if (parseFloat(e.currentTarget.value as string) > parseFloat(e.currentTarget.max as string)) e.currentTarget.value = e.currentTarget.max;
      else if (parseFloat(e.currentTarget.value as string) < parseFloat(e.currentTarget.min as string)) e.currentTarget.value = e.currentTarget.min;
    }

    changeBinderPage(e.currentTarget.value - currentBinderPage);
  }

  function changeBinderPage(increment: number) {
    const newPage = currentBinderPage + increment;

    if (increment == 0 || newPage < 1 || newPage > Math.ceil(binderSearchResults.length / binderCardsPerPage)) return;
    else {
      setSelectedCards([]);
      setSelectedCardCount(0);
      setAllBinderCardsSelected(false);
      setCurrentBinderPage(newPage);
      if (binderSearchResultsDirty) {
        binderSearch(binderSearchSort, binderSearchSortOrder, binderSearchString, binderSearchField, collapsePrints, null);
      }
    }
  }

  function updateBinderSearchSort(sortField: string) {
    setBinderSearchSort(sortField);
    sortBinderSearch(binderSearchSortOrder, sortField);
  }

  function updateBinderSearchSortOrder(sortOrderField: string) {
    setBinderSearchSortOrder(sortOrderField);
    sortBinderSearch(sortOrderField, binderSearchSort);
  }

  function toggleCollapsePrints() {
    const collapseBinderPrints = !collapsePrints;
    setCollapsePrints(collapseBinderPrints);
    binderSearch(binderSearchSort, binderSearchSortOrder, binderSearchString, binderSearchField, collapseBinderPrints, null);
  }

  function sortAllCardSearch(sortOrder: string, sortField: string) {
    const sortAsc = sortOrder == 'asc';
    let result = [...allCardSearchResults];
    result = result.sort((a: AllSearchCard, b: AllSearchCard) => {
      if (a.type > b.type) return 1;
      else if (a.type < b.type) return -1;

      if (a.type == 'Monster') { // We know both are the same type at this point
        if (a.subtype.length > 1 && b.subtype.length > 1) {
          if (TypeGroup[a.subtype.slice(1).join()] > TypeGroup[b.subtype.slice(1).join()]) return 1;
          else if (TypeGroup[a.subtype.slice(1).join()] < TypeGroup[b.subtype.slice(1).join()]) return -1;
        }
        else if (a.subtype.length < 2 && b.subtype.length > 1) return -1;
        else if (a.subtype.length > 1 && b.subtype.length < 1) return 1;
      }
      else {
        if (a.subtype[0] > b.subtype[0]) return 1;
        else if (a.subtype[0] < b.subtype[0]) return -1;
      }

      const fieldKey = sortField as keyof AllSearchCard;
      if (a[fieldKey] != null && b[fieldKey] != null) {
        if (a[fieldKey]! > b[fieldKey]!) return sortAsc ? 1 : -1;
        else if (a[fieldKey]! < b[fieldKey]!) return sortAsc ? -1 : 1;
      }
      else if (a[fieldKey] == null && b[fieldKey] != null) return sortAsc ? -1 : 1;
      else if (a[fieldKey] != null && b[fieldKey] == null) return sortAsc ? 1 : -1;

      if (a.name > b.name) return 1;
      else if (a.name < b.name) return -1;

      return 0;
    });
    setAllCardSearchResults(result as AllSearchCard[]);
  }

  function updateAllCardSearchString(newSearchTerm: string) {
    if (newSearchTerm != allCardSearchString) setAllCardSearchString(newSearchTerm);
    else {
      if (allCardSearchTask != null) clearTimeout(allCardSearchTask); // Force search refresh on click
      allCardSearchTask = setTimeout(() => {
        setCurrentAllCardPage(1);
        allCardSearch(allCardSearchSort, allCardSearchSortOrder, allCardSearchString, allCardSearchField);
      }, 300);
    }
  }

  function sortBinderSearch(sortOrder: string, sortField: string) {
    const sortAsc = sortOrder == 'asc';
    let result = [...binderSearchResults];
    result = result.sort((a: BinderCard, b: BinderCard) => {
      if (sortField == 'rarity') {
        if (a.rarity != null && b.rarity != null) {
          if (RarityLevel[a.rarity!] > RarityLevel[b.rarity!]) return sortAsc ? 1 : -1;
          else if (RarityLevel[a.rarity!] < RarityLevel[b.rarity]) return sortAsc ? -1 : 1;
        }
        else if (a.rarity == null && b.rarity != null) return sortAsc ? -1 : 1;
        else if (a.rarity != null && b.rarity == null) return sortAsc ? 1 : -1;
      }
      else if (sortField != 'name') {
        const fieldKey = sortField as keyof BinderCard;
        if (a[fieldKey] != null && b[fieldKey] != null) {
          if (a[fieldKey]! > b[fieldKey]!) return sortAsc ? 1 : -1;
          else if (a[fieldKey]! < b[fieldKey]!) return sortAsc ? -1 : 1;
        }
        else if (a[fieldKey] == null && b[fieldKey] != null) return sortAsc ? -1 : 1;
        else if (a[fieldKey] != null && b[fieldKey] == null) return sortAsc ? 1 : -1;
      }

      if (sortField != 'name') {
        if (a.type > b.type) return 1;
        else if (a.type < b.type) return -1;

        if (a.type == 'Monster') { // We know both are the same type at this point
          if (a.subtype.length > 1 && b.subtype.length > 1) {
            if (TypeGroup[a.subtype.slice(1).join()] > TypeGroup[b.subtype.slice(1).join()]) return 1;
            else if (TypeGroup[a.subtype.slice(1).join()] < TypeGroup[b.subtype.slice(1).join()]) return -1;
          }
          else if (a.subtype.length < 2 && b.subtype.length > 1) return -1;
          else if (a.subtype.length > 1 && b.subtype.length < 1) return 1;
        }
        else {
          if (a.subtype[0] > b.subtype[0]) return 1;
          else if (a.subtype[0] < b.subtype[0]) return -1;
        }
      }

      if (a.name > b.name) return sortField == 'name' && !sortAsc ? -1 : 1; // If sorting name and descending then flip these
      else if (a.name < b.name) return sortField == 'name' && !sortAsc ? 1 : -1;

      return 0;
    });
    setBinderSearchResults(result as BinderCard[]);
  }

  function updateBinderSearchString(newSearchTerm: string) {
    if (newSearchTerm != binderSearchString) setBinderSearchString(newSearchTerm);
    else {
      if (binderSearchTask != null) clearTimeout(binderSearchTask);
      binderSearchTask = setTimeout(() => {
        setCurrentBinderPage(1);
        binderSearch(binderSearchSort, binderSearchSortOrder, binderSearchString, binderSearchField, collapsePrints, null);
      }, 300);
    }
  }

  function incrementBinderCard(e: any) {
    if (currentBinder != '') {
      const binderSR = [...binderSearchResults];
      const binderCards = [...currentBinderCards];
      const card = binderSR.find((c: BinderCard) => c.code + c.rarity == e.currentTarget.dataset.setrarity);

      if (card !== undefined) {
        card.count += Number(e.currentTarget.dataset.increment);

        if (card.count < Number(1)) {
          binderSR.splice(binderSR.indexOf(card), 1);
          binderCards.splice(binderCards.indexOf(card), 1);
        }

        setBinderSearchResults(binderSR);
        setCurrentBinderCards(binderCards);
        setBinderSearchCardCount(binderSearchCardCount+Number(e.currentTarget.dataset.increment));
        if (selectedCards.includes(card.code)) setSelectedCardCount(selectedCardCount+Number(e.currentTarget.dataset.increment));

        // addCardsToBinder(cookies.get('token'), cookies.get('refresh'), cookies.get('userId'), currentBinder, [{ ...card, count: Number(e.currentTarget.dataset.increment) }]);

        const requestOptions = {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + cookies.get('token') },
          body: JSON.stringify({ code: card.code, rarity: card.rarity, count: Number(e.currentTarget.dataset.increment) }),
        };
        makeApiCall(refresh, cookies.get('userId'), apiPath + 'binder/' + currentBinder + '/card/count', requestOptions)
          .then(async (response) => {
            if (!response.ok) {
              const error = response.status;
              openErrorModal('Issue incrementing card in binder.<br />' +
                (error == 401 ? '<br />Try logging out and logging back in.<br />If this persists, ' + lowercaseContactString : '') +
                (error == 400 ? '<br />You have been inactive for a long time. Please log out and back in.<br /><br />If this persists, ' + lowercaseContactString : '') +
                (error != 401 && error != 400 ? '<br />' + contactString : ''));
              return Promise.reject(error);
            }
          });
      }
    }
  }

  function selectBinder(newBinderId: string) {
    if (newBinderId != '') {
      setCurrentBinder(newBinderId);
    }
  }

  function exportBinderYdk() {
    if (currentBinder != '') {
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + cookies.get('token') },
      };

      makeApiCall(cookies.get('refresh'), cookies.get('userId'), apiPath + 'export/binder/ydk/' + currentBinder, requestOptions)
        .then(async (response) => {
          const blob = await response.blob();

          if (!response.ok) {
            const error = response.status;
            openErrorModal('Issue retrieving binder.<br />' +
              (error == 401 ? '<br />Try logging out and logging back in.<br />If this persists, ' + lowercaseContactString : '') +
              (error == 400 ? '<br />You have been inactive for a long time. Please log out and back in.<br /><br />If this persists, ' + lowercaseContactString : '') +
              (error != 401 && error != 400 ? '<br />' + contactString : ''));
            return Promise.reject(error);
          }

          saveAs(blob, binderList.find((binder: DropdownOption) => binder.value == currentBinder)?.text + '.ydk');
        });
    }
  }

  function exportBinderCsv() {
    if (currentBinder != '') {
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + cookies.get('token') },
      };

      makeApiCall(cookies.get('refresh'), cookies.get('userId'), apiPath + 'export/binder/csv/' + currentBinder, requestOptions)
        .then(async (response) => {
          const blob = await response.blob();

          if (!response.ok) {
            const error = response.status;
            openErrorModal('Issue retrieving binder.<br />' +
              (error == 401 ? '<br />Try logging out and logging back in.<br />If this persists, ' + lowercaseContactString : '') +
              (error == 400 ? '<br />You have been inactive for a long time. Please log out and back in.<br /><br />If this persists, ' + lowercaseContactString : '') +
              (error != 401 && error != 400 ? '<br />' + contactString : ''));
            return Promise.reject(error);
          }

          saveAs(blob, binderList.find((binder: DropdownOption) => binder.value == currentBinder)?.text + '.csv');
        });
    }
  }

  function exportBinderLflist() {
    if (currentBinder != '') {
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + cookies.get('token') },
      };

      makeApiCall(cookies.get('refresh'), cookies.get('userId'), apiPath + 'export/binder/lflist/' + currentBinder, requestOptions)
        .then(async (response) => {
          const blob = await response.blob();

          if (!response.ok) {
            const error = response.status;
            openErrorModal('Issue retrieving binder.<br />' +
              (error == 401 ? '<br />Try logging out and logging back in.<br />If this persists, ' + lowercaseContactString : '') +
              (error == 400 ? '<br />You have been inactive for a long time. Please log out and back in.<br /><br />If this persists, ' + lowercaseContactString : '') +
              (error != 401 && error != 400 ? '<br />' + contactString : ''));
            return Promise.reject(error);
          }

          saveAs(blob, binderList.find((binder: DropdownOption) => binder.value == currentBinder)?.text + '.lflist.conf');
        });
    }
  }

  function uploadBinderCsv() {
    document.getElementById('binder-csv-import-field')?.click();
  }

  function uploadBinderYdk() {
    document.getElementById('binder-ydk-import-field')?.click();
  }

  async function sendBinderImport(cards: any[]) {
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + cookies.get('token') },
      body: JSON.stringify({ cards: cards }),
    };

    makeApiCall(cookies.get('refresh'), cookies.get('userId'), apiPath + 'binder/' + currentBinder + '/cards', requestOptions)
      .then(async (response) => {
        if (!response.ok) {
          const error = response.status;
          openErrorModal('Issue adding cards to binder.<br />' +
            (error == 401 ? '<br />Try logging out and logging back in.<br />If this persists, ' + lowercaseContactString : '') +
            (error == 400 ? '<br />You have been inactive for a long time. Please log out and back in.<br /><br />If this persists, ' + lowercaseContactString : '') +
            (error != 401 && error != 400 ? '<br />' + contactString : ''));
          setBinderSearchLoaded(true);
          setBinderActionsDisabled(false);
          return Promise.reject(error);
        }

        const data = await response.json();

        let binderCards: BinderCard[];
        if (data != null) {
          binderCards = data.cards.map((card: any) => {
            return {
              ...CardsData.cards[card.cardId],
              set: card.set,
              code: card.code,
              rarity: card.rarity,
              count: Number(card.count),
            };
          });
        }
        else {
          binderCards = [];
        }

        setCurrentBinderCards(binderCards);
        binderSearch(binderSearchSort, binderSearchSortOrder, binderSearchString, binderSearchField, collapsePrints, binderCards);

        setSelectedCardCount(0);
        setSelectedCards([]);
        setCurrentBinderPage(1);
        setBinderSearchLoaded(true);
        setBinderActionsDisabled(false);
      });
  }

  async function importBinderCsv() {
    setBinderSearchLoaded(false);
    setBinderActionsDisabled(true);
    const inputField = document.getElementById('binder-csv-import-field')!;

    const csvData = await ((inputField as HTMLInputElement).files as FileList).item(0)?.text()!;
    (inputField as HTMLInputElement).value = '';
    csvToJson().fromString(csvData).then((jsonData: any) => {
      const importCards: any[] = [];
      jsonData.forEach((cardData: any) => {
        cardData.cardname = decode(encode(cardData.cardname, 'windows-1252'), 'utf-8');
        let baseCard = CardsData.cards[cardData.cardid];
        if (typeof(baseCard) == 'undefined') { // Try and find a name match if id is hinky, otherwise toss it
          const matches = CardsData.allCardSearch('name', cardData.cardname, 'name', true);
          if (matches.length > 0) {
            cardData.cardid = matches[0].id;
            baseCard = matches[0];
          }
          else return;
        }

        let set = baseCard.card_sets.filter((setInfo: SetCardInfo) => setInfo.set_code == cardData.cardcode);
        let cardrarity = cardData.cardrarity.includes('Short Print') ? 'Common' : cardData.cardrarity;
        if (set.length <= 0) set = baseCard.card_sets.filter((setInfo: SetCardInfo) => setInfo.set_name == cardData.setname); // If code is incorrect, try set name
        if (set.length > 0) {
          if (!set.some((setInfo: SetCardInfo) => cardrarity == setInfo.set_rarity)) { // Check if card set+rarity match
            cardrarity = set[0].set_rarity; // If they don't match then fix the rarity
          }
        }
        else { // Set code and name are incorrect
          const rarityset = baseCard.card_sets.find((setInfo: SetCardInfo) => setInfo.set_rarity == cardrarity); // Find a card matching the rarity and fix the set
          if (rarityset !== undefined) {
            cardData.cardset = rarityset.set_name;
            cardData.cardcode = rarityset.set_code;
          }
          else { // If no matching rarities or sets then just take the first print
            cardData.cardset = baseCard.card_sets[0].set_name;
            cardData.cardcode = baseCard.card_sets[0].set_code;
            cardrarity = baseCard.card_sets[0].set_rarity;
          }
        }

        importCards.push({
          cardId: Number(cardData.cardid),
          name: baseCard.name,
          set: cardData.cardset,
          code: cardData.cardcode,
          rarity: cardrarity,
          count: Number(cardData.cardq),
        });
      });

      sendBinderImport(importCards);
    });

  }

  async function importBinderYdk() {
    setBinderSearchLoaded(false);
    setBinderActionsDisabled(true);
    const inputField = document.getElementById('binder-ydk-import-field')!;

    const ydkData = (await ((inputField as HTMLInputElement).files as FileList).item(0)!.text()!).split(new RegExp('\\r?\\n'));
    (inputField as HTMLInputElement).value = '';

    const importCards: any[] = [];
    for (let i: number = 0; i < ydkData.length; i++) {
      const line = ydkData[i].trim();
      if (line == '' || line[0] == '#' || line[0] == '!') continue;

      const cardData = CardsData.cards[CardsData.altIdMap[Number(line)]];
      if (typeof(cardData) == 'undefined') continue; // Toss ids we don't have
      const existingIndex = importCards.indexOf((c: any) => c.cardId == CardsData.altIdMap[Number(line)]);
      if (existingIndex == null || existingIndex < 0) {
        importCards.push({
          cardId: cardData.id,
          name: cardData.name,
          set: cardData.card_sets[0].set_name,
          code: cardData.card_sets[0].set_code,
          rarity: cardData.card_sets[0].set_rarity,
          count: 1,
        });
      }
      else {
        importCards[existingIndex].count += 1;
      }
    }

    sendBinderImport(importCards);
  }

  function deleteBinder() {
    const requestOptions = {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + cookies.get('token') },
    };
    makeApiCall(cookies.get('refresh'), cookies.get('userId'), apiPath + 'binder/' + currentBinder, requestOptions)
      .then(async (response) => {
        if (!response.ok) {
          const error = response.status;
          openErrorModal('Issue deleting binder.<br />' +
            (error == 401 ? '<br />Try logging out and logging back in.<br />If this persists, ' + lowercaseContactString : '') +
            (error == 400 ? '<br />You have been inactive for a long time. Please log out and back in.<br /><br />If this persists, ' + lowercaseContactString : '') +
            (error != 401 && error != 400 ? '<br />' + contactString : ''));
          return Promise.reject(error);
        }

        const data = await response.json();

        setCurrentBinder('');
        localforage.getItem('binders').then((binders: any) => {
          if (binders != null) {
            binders.splice(binders.findIndex((binder: any) => binder.id == currentBinder), 1);
            localforage.setItem('binders', binders).then(() => updateBinderList())
              .catch(() => {
                updateBinderList();
                openErrorModal('Issue saving binders after delete.<br />' + contactString);
              });
          } // If it's null there's nothing to delete...somehow
        })
          .catch(() => {
            updateBinderList();
            openErrorModal('Issue getting binders for delete.<br />' + contactString);
          });
      });
  }

  function deleteSelected() {
    if (selectedCards.length > 0) {
      const deleteCards: BinderCard[] = [];

      const updatedResults = [...binderSearchResults];
      const updatedCards = [...currentBinderCards];
      let updatedCount: number = Number(binderSearchCardCount);
      for (let i: number = updatedResults.length - 1; i >= 0; i--) {
        if (selectedCards.includes(updatedResults[i].code + ',' + updatedResults[i].rarity)) {
          deleteCards.push(updatedResults[i]);
          updatedCount -= Number(updatedResults[i].count);
          deleteCards[deleteCards.length - 1].count *= Number(-1);

          updatedCards.splice(updatedCards.indexOf(updatedResults[i]), 1);
          updatedResults.splice(i, 1);
        }
      }

      setCurrentBinderCards(updatedCards);
      setBinderSearchResults(updatedResults);
      setBinderSearchCardCount(Number(updatedCount));
      setBinderSearchLoaded(false);
      setBinderActionsDisabled(true);

      addCardsToBinder(cookies.get('token'), cookies.get('refresh'), cookies.get('userId'), currentBinder, deleteCards, null, () => {
        setBinderSearchLoaded(true);
        setBinderActionsDisabled(false);
      });
    }
  }

  function toggleSelectAll() {
    const allSelect = !allBinderCardsSelected;

    if (allSelect) {
      let selectedCount = 0;
      const sCards: string[] = [];
      binderSearchResults.forEach((card: BinderCard, index: number) => {
        if (index >= (currentBinderPage - 1) * binderCardsPerPage && index < ((currentBinderPage - 1) * binderCardsPerPage) + binderCardsPerPage) {
          selectedCount += Number(card.count);
          sCards.push(card.code+','+card.rarity);
        }
      });
      setSelectedCards(sCards);
      setSelectedCardCount(Number(selectedCount));
    }
    else {
      setSelectedCards([]);
      setSelectedCardCount(0);
    }

    setAllBinderCardsSelected(allSelect);
  }

  function toggleSelectCard(e: any) {
    const selectedIndex = selectedCards.indexOf(e.currentTarget.dataset.selectstring);

    if (selectedIndex >= 0) {
      const sCards = [...selectedCards];
      sCards.splice(selectedIndex, 1);
      setSelectedCards(sCards);
      setSelectedCardCount(selectedCardCount-Number(e.currentTarget.dataset.count));
    }
    else {
      setSelectedCards([...selectedCards, e.currentTarget.dataset.selectstring]);
      setSelectedCardCount(selectedCardCount+Number(e.currentTarget.dataset.count));
    }
  }

  function updateBinderList(newCurrent: string|null = null) {
    localforage.getItem('binders').then((binders: any) => {
      if (binders != null && binders.length > 0) {
        const binderOpts: DropdownOption[] = binders.map((binder: any) => {
          return { value: binder.id, text: binder.name } as DropdownOption;
        });
        queuedCurrentBinder = newCurrent;
        setBinderList(binderOpts);
      }
      else {
        setBinderList([]);
        setCurrentBinder('');
        sessionMemory.lastBinderSelected = '';
        setBinderActionsDisabled(true);
      }
    })
      .catch(() => {
        openErrorModal('Issue getting binders for Management.<br />' + contactString);
      });
  }

  function openNewBinderModal() {
    document.getElementById('create-binder-modal')!.style.display = 'flex';
  }

  function openRenameBinderModal() {
    document.getElementById('rename-binder-modal')!.style.display = 'flex';
  }

  function openDeleteBinderConfirm() {
    setConfirmMatchString(binderList.find((b: DropdownOption) => b.value == currentBinder)!.text.toLowerCase()); // after the set finishes the useffect opens modal
  }

  function openDeckBuilder() {
    sessionMemory.lastBinderSelected = currentBinder;
    history.push('/DeckBuilder');
    refreshRouteFunc(!refresh);
  }

  if (cardsAreLoaded) {
    if (username !== undefined) {
      return (
        <main>
          <Helmet>
            <meta content="YGO Prog - Binder Management" property="og:title" />
            <meta
              content="YGO Prog's Binder Manager lets you manage your different collections of Yu-Gi-Oh! TCG cards."
              property="og:description"
            />
            <meta content="https://www.ygoprog.com/BinderManagement" property="og:url" />
            <meta
              name="description"
              content="YGO Prog's Binder Manager lets you manage your different collections of Yu-Gi-Oh! TCG cards."
            />
            <title>YGO Prog - Binder Management</title>
          </Helmet>
          <DeleteConfirmModal {...{
            id: 'delete-confirm',
            title: 'Confirm Binder Delete',
            matchString: confirmMatchString,
            callback: deleteBinder,
            clearMatch: setConfirmMatchString,
            preConfirmStatement: '',
          }} />

          <SaveAsBinderModal {...{ id: 'create-binder-modal', isRename: false, binderId: '', updateListDispatch: updateBinderList }} />
          <SaveAsBinderModal {...{ id: 'rename-binder-modal', isRename: true, binderId: currentBinder, updateListDispatch: updateBinderList }} />

          <Ad styleName='bar_ad' />
          <Ad styleName='left_sidebar_ad_1400' />
          <Ad styleName='right_sidebar_ad_1400' />

          <h1>Card Binder Management</h1>
          <h2 style={{ marginTop: '10px' }}>All Cards</h2>

          <div className='all-cards-search-controls'>
            <div className='grid-left'>
              <span className='button-bar-label'>Search:</span>
              <BlackDropdown {...{
                uniqueID: 'all-search',
                isSelector: true,
                disabledVar: false,
                optionsList: allCardSearchFilterOptions,
                startingOption: { value: 'all', text: 'All Fields' } as DropdownOption,
                defaultValue: '',
                defaultDisplay: '',
                nonSelectorDisplay: '',
                width: '140px',
                stateVar: allCardSearchField,
                dispatch: setAllCardSearchField,
                title: 'Select field to search',
                style: { marginRight: isScreen950 ? '5px' : '7px' },
              }} />

              {isScreen950 ? <div style={{ marginBottom: '3px' }} /> : ''}

              <SearchBox {...{
                uniqueID: 'all-search',
                emptyDisplay: 'Search all cards...',
                width: '160px',
                searchFunc: updateAllCardSearchString,
                style: { marginRight: isScreen950 ? '5px' : '7px' },
                searchOnValueChange: true,
              }}/>

              {isScreen950 ? <div style={{ marginBottom: '3px' }} /> : ''}

              <span className='button-bar-label'>Sort:</span>
              <BlackDropdown {...{
                uniqueID: 'allcard-sort',
                isSelector: true,
                disabledVar: false,
                optionsList: [{ value: 'name', text: 'Card Name' } as DropdownOption,
                { value: 'first_print', text: 'First Print' } as DropdownOption],
                startingOption: null,
                defaultValue: 'name',
                defaultDisplay: 'Name',
                nonSelectorDisplay: '',
                width: '100px',
                stateVar: allCardSearchSort,
                dispatch: updateAllCardSearchSort,
                title: 'Select field to sort search results by',
                style: { marginRight: isScreen950 ? '5px' : '7px' },
              }}/>
              <BlackDropdown {...{
                uniqueID: 'allcard-order',
                isSelector: true,
                disabledVar: false,
                optionsList: [{ value: 'asc', text: 'Asc' } as DropdownOption,
                { value: 'desc', text: 'Desc' } as DropdownOption],
                startingOption: null,
                defaultValue: 'asc',
                defaultDisplay: 'Asc',
                nonSelectorDisplay: '',
                width: '60px',
                stateVar: allCardSearchSortOrder,
                dispatch: updateAllCardSearchSortOrder,
                title: 'Select order to sort search results by',
                style: { marginRight: isScreen950 ? '5px' : '7px' },
              }}/>
            </div>
            <div className='grid-middle' />
            <div className='grid-right pagination-top'>
              <div className='results-per-top' style={{ display: 'inline-block', textAlign: 'right', marginBottom: '3px' }}>
                <span className='button-bar-label'>Results Per Page:</span>
                <BlackDropdown {...{
                  uniqueID: 'all-results-per',
                  isSelector: true,
                  disabledVar: false,
                  optionsList: [{ value: 5, text: '5' } as DropdownOption,
                  { value: 10, text: '10' } as DropdownOption,
                  { value: 15, text: '15' } as DropdownOption,
                  { value: 20, text: '20' } as DropdownOption,
                  { value: 30, text: '30' } as DropdownOption],
                  startingOption: null,
                  defaultValue: 5,
                  defaultDisplay: '5',
                  nonSelectorDisplay: '',
                  width: '60px',
                  stateVar: allCardsPerPage,
                  dispatch: (value: string) => {setAllCardsPerPage(Number(value));},
                  title: 'Select number of cards to show per results page',
                  style: { marginRight: '0px' },
                }}/>
              </div>
              <div className='pagination-controls' style={{ display: 'inline-block', textAlign: 'right' }}>
                <label className='page-entry-field'>Page&nbsp;
                  <input className='page-entry' type='number' min='1' max={Math.ceil(allCardSearchResults.length / allCardsPerPage)}
                    onInput={allCardPageInput} defaultValue={currentAllCardPage} ref={allCardPageField} />
                </label>
                <span>
                  /{Math.ceil(allCardSearchResults.length / allCardsPerPage)}
                </span>
                {isScreen950 ? <div style={{ marginBottom: '3px' }} /> : ''}
                <button title='Go to previous page' onClick={() => changeAllCardPage(-1)} disabled={currentAllCardPage <= 1}>
                  <img src={imagePath + 'PageLeft.png'} alt='<' width={isScreen950 ? '15px' : '18px'} height={isScreen950 ? '15px' : '18px'} />
                </button>
                <button title='Go to next page' onClick={() => changeAllCardPage(1)} disabled={currentAllCardPage == Math.ceil(allCardSearchResults.length / allCardsPerPage)}>
                  <img src={imagePath + 'PageRight.png'} alt='>' width={isScreen950 ? '15px' : '18px'} height={isScreen950 ? '15px' : '18px'} />
                </button>
              </div>
            </div>
          </div>

          <div className='all-cards-search-container content-box' style={{ marginBottom: '35px' }}>
            {allCardSearchLoaded ? ((Math.ceil(allCardSearchResults.length / allCardsPerPage) > 0 && currentAllCardPage > 0) ?
              <div>
                {allCardSearchResults.map((card: AllSearchCard, index: number) => {
                  if (index >= (currentAllCardPage - 1) * allCardsPerPage && index < ((currentAllCardPage - 1) * allCardsPerPage) + allCardsPerPage) {
                    return <div className='all-search-card' key={card.id}>
                      <div className='grid-left'>
                        {adding && addingAllCard[index] ?
                          <img src={'/images/LoadingAnim.png'} width={isScreen950 ? '25px' : '30px'} height={isScreen950 ? '25px' : '30px'}
                            style={{ margin: isScreen950 ? '20px 0px 20px 0px' : '20px 0px 20px 0px', verticalAlign: 'middle' }} /> :
                          <button className='all-search-add-button' title='Add card to current binder' data-cardid={card.id}
                            onClick={addAllSearchCardToBinder} disabled={binderActionsDisabled || allCardAdded[index]} style={{ marginLeft: '0px' }}>
                            <img src={imagePath + 'BigPlus.png'} alt='+' width={isScreen950 ? '15px' : '18px'} height={isScreen950 ? '15px' : '18px'} />
                          </button>}
                        <HoverableCard {...{
                          cardID: card.id,
                          cardName: card.name,
                          classNames: 'binder-management-card',
                          width: 47.73,
                          height: 70,
                          beforeElement: null,
                          afterElement: null,
                          style: {},
                          autosize: false,
                          contextMenu: null,
                          disableHover: false,
                        }} />
                        {isScreen950 ? '' : <h3><a href={cardLink(card.id)} target='_blank' rel='noreferrer'>{card.name}</a></h3>}
                        {isScreen950 ?
                          <div className='all-search-card-right-small'>
                            <h3><a href={cardLink(card.id)} target='_blank' rel='noreferrer'>{card.name}</a></h3>
                            <div style={{ display: 'block', marginBottom: '1px' }} />
                            <BlackDropdown {...{
                              uniqueID: 'allcard-setrarity-' + card.id,
                              isSelector: true,
                              disabledVar: false,
                              optionsList: card.setrarities,
                              startingOption: null,
                              defaultValue: card.setrarities[0].value as string,
                              defaultDisplay: card.setrarities[0].text,
                              nonSelectorDisplay: '',
                              width: '100%',
                              stateVar: allCardSelectedSetRarities[card.id],
                              dispatch: (setrarity: string) => {
                                const acAdded = allCardAdded;
                                acAdded[index] = false;
                                setAllCardAdded(acAdded);
                                setAllCardSelectedSetRarities({ ...allCardSelectedSetRarities, [card.id]: setrarity });
                              },
                              title: 'Select set and rarity of card to add',
                              style: { marginRight: isScreen950 ? '5px' : '7px' },
                              ulStyle: { width: '100%' },
                            }} />
                          </div> : ''}
                      </div>
                      {isScreen950 ? '' : <div className='grid-right'>
                        <BlackDropdown {...{
                          uniqueID: 'allcard-setrarity-' + card.id,
                          isSelector: true,
                          disabledVar: false,
                          optionsList: card.setrarities,
                          startingOption: null,
                          defaultValue: card.setrarities[0].value as string,
                          defaultDisplay: card.setrarities[0].text,
                          nonSelectorDisplay: '',
                          width: '400px',
                          stateVar: allCardSelectedSetRarities[card.id],
                          dispatch: (setrarity: string) => {
                            const acAdded = allCardAdded;
                            acAdded[index] = false;
                            setAllCardAdded(acAdded);
                            setAllCardSelectedSetRarities({ ...allCardSelectedSetRarities, [card.id]: setrarity });
                          },
                          title: 'Select set and rarity of card to add',
                          style: { marginRight: isScreen950 ? '5px' : '7px' },
                          ulStyle: { width: '100%' },
                        }} />
                      </div>}
                    </div>;
                  }
                })}
              </div> :
              <span className='empty-search'>No cards found.</span>) :
              <img src={'/images/LoadingAnim.png'} width='100px' height='100px' style={{ margin: 'auto' }} />}
          </div>

          <h2 style={{ marginTop: '10px' }}>Your Binders</h2>
          <div className='binder-controls' style={{ marginBottom: '5px', gridTemplateColumns: '1fr' }}>
            <div className='grid-left'>
              <span className='button-bar-label'>Binder:</span>
              <BlackDropdown {...{
                uniqueID: 'binder-select',
                isSelector: true,
                disabledVar: false,
                optionsList: binderList,
                startingOption: {
                  value: sessionMemory.lastBinderSelected,
                  text: binderList.find((b: DropdownOption) => b.value == sessionMemory.lastBinderSelected)?.text } as DropdownOption,
                defaultValue: '',
                defaultDisplay: 'Select Binder...',
                nonSelectorDisplay: '',
                width: '150px',
                stateVar: currentBinder,
                dispatch: selectBinder,
                title: 'Select binder to view/edit',
                style: { marginRight: isScreen950 ? '5px' : '7px' },
                ulStyle: { width: '100%' },
                firstOptionItalic: false,
              }} />

              <button className='black-button text-icon-button' title='Rename binder' onClick={openRenameBinderModal}
                disabled={binderActionsDisabled} style={{ marginRight: isScreen950 ? '5px' : '7px' }}>
                <img src={imagePath + 'Edit.png'} width='18px' height='20px' style={{ margin: 'auto', marginLeft: '4px' }}/>
                <span style={{ marginLeft: '5px', marginRight: '5px' }}>Rename</span>
              </button>

              {isScreen950 ? <div style={{ marginBottom: '5px' }} /> : ''}

              <button className='black-button text-icon-button' title='Add new binder' onClick={openNewBinderModal}
                style={{ marginRight: isScreen950 ? '5px' : '7px' }}>
                <img src={imagePath + 'BigPlus.png'} width='18px' height='20px' style={{ margin: 'auto', marginLeft: '4px' }}/>
                <span style={{ marginLeft: '5px', marginRight: '5px' }}>New</span>
              </button>

              <BlackDropdown {...{
                uniqueID: 'import-binder',
                isSelector: false,
                disabledVar: binderActionsDisabled,
                optionsList: [{ value: 'csv', text: 'Import from .csv', callback: uploadBinderCsv }, { value: 'ydk', text: 'Import from .ydk', callback: uploadBinderYdk }],
                startingOption: null,
                defaultValue: '',
                defaultDisplay: '',
                nonSelectorDisplay: <div className='text-icon-button'>
                  <img src={imagePath + 'Import.png'} width='18px' height='20px' style={{ margin: 'auto', marginLeft: '4px' }} />
                  <span style={{ marginLeft: '5px', marginRight: '5px' }}>Import</span>
                </div>,
                width: 'initial',
                stateVar: '',
                dispatch: null,
                title: 'Import binder',
                style: { marginRight: isScreen950 ? '5px' : '7px' },
              }}/>
              <input type='file' id='binder-csv-import-field' accept='.csv' onChange={importBinderCsv} style={{ display: 'none' }} />
              <input type='file' id='binder-ydk-import-field' accept='.ydk' onChange={importBinderYdk} style={{ display: 'none' }} />

              <BlackDropdown {...{
                uniqueID: 'export-binder',
                isSelector: false,
                disabledVar: binderActionsDisabled,
                optionsList: [{ value: 'csv', text: 'Export to .csv', callback: exportBinderCsv },
                  { value: 'ydk', text: 'Export to .ydk', callback: exportBinderYdk },
                  { value: 'lflist', text: 'Export as banlist to .lflist.conf', callback: exportBinderLflist }],
                startingOption: null,
                defaultValue: '',
                defaultDisplay: '',
                nonSelectorDisplay: <div className='text-icon-button'>
                  <img src={imagePath + 'Export.png'} width='18px' height='20px' style={{ margin: 'auto', marginLeft: '4px' }} />
                  <span style={{ marginLeft: '5px', marginRight: '5px' }}>Export</span>
                </div>,
                width: 'initial',
                stateVar: '',
                dispatch: null,
                title: 'Export binder',
                style: { marginRight: isScreen950 ? '5px' : '7px' },
              }}/>

              <button className='black-button text-icon-button' title='Delete current binder' disabled={binderActionsDisabled} onClick={openDeleteBinderConfirm}
                style={{ marginRight: isScreen950 ? '5px' : '7px' }}>
                <img src={imagePath + 'Trashcan.png'} width='18px' height='20px' style={{ margin: 'auto', marginLeft: '4px' }} />
                <span style={{ marginLeft: '5px', marginRight: '5px' }}>Delete</span>
              </button>

              {isScreen950 ? <div style={{ marginBottom: '5px' }} /> : ''}

              <button className='black-button' style={{ marginRight: isScreen950 ? '5px' : '7px' }} disabled={binderActionsDisabled}
                title='Open binder in Deck Builder' onClick={openDeckBuilder}><span>Open In Deck Builder</span></button>

              <div className='checkbox' title='Collapse sets and rarities of the same card' onClick={toggleCollapsePrints} style={{ marginRight: '5px' }}>
                <img className='checkbox-checkmark' src={imagePath + 'CheckmarkBlack.png'} width='20px' height='20px' style={{ display: (collapsePrints ? 'inline-block' : 'none') }} />
              </div>
              <span className='button-bar-label'>Collapse Prints</span>

              <span className='floating-text'>{binderSearchCardCount} Cards&nbsp;&nbsp;{selectedCardCount} Selected</span>
            </div>
          </div>

          <div className='binder-controls'>
            <div className='grid-left'>
              <span className='button-bar-label'>Search:</span>
              <BlackDropdown {...{
                uniqueID: 'binder-search',
                isSelector: true,
                disabledVar: false,
                optionsList: binderSearchFilterOptions,
                startingOption: null,
                defaultValue: 'all',
                defaultDisplay: 'All Fields',
                nonSelectorDisplay: '',
                width: '140px',
                stateVar: binderSearchField,
                dispatch: setBinderSearchField,
                title: 'Select field to search',
                style: { marginRight: isScreen950 ? '5px' : '7px' },
              }} />

              {isScreen950 ? <div style={{ marginBottom: '5px' }} /> : ''}

              <SearchBox {...{
                uniqueID: 'binder-search',
                emptyDisplay: 'Search binder...',
                width: '160px',
                searchFunc: updateBinderSearchString,
                style: { marginRight: isScreen950 ? '5px' : '7px' },
                searchOnValueChange: true,
              }}/>

              {isScreen1150 ? <div style={{ marginBottom: '5px' }} /> : ''}

              <span className='button-bar-label'>Sort:</span>
              <BlackDropdown {...{
                uniqueID: 'binder-sort',
                isSelector: true,
                disabledVar: false,
                optionsList: [{ value: 'name', text: 'Card Name' } as DropdownOption,
                { value: 'set', text: 'Set Name' } as DropdownOption,
                { value: 'rarity', text: 'Card Rarity' } as DropdownOption,
                { value: 'first_print', text: 'First Print' } as DropdownOption,
                { value: 'count', text: 'Quantity' } as DropdownOption],
                startingOption: null,
                defaultValue: 'name',
                defaultDisplay: 'Card Name',
                nonSelectorDisplay: '',
                width: '100px',
                stateVar: binderSearchSort,
                dispatch: updateBinderSearchSort,
                title: 'Select field to sort search results by',
                style: { marginRight: isScreen950 ? '5px' : '7px' },
              }}/>
              <BlackDropdown {...{
                uniqueID: 'binder-order',
                isSelector: true,
                disabledVar: false,
                optionsList: [{ value: 'asc', text: 'Asc' } as DropdownOption,
                { value: 'desc', text: 'Desc' } as DropdownOption],
                startingOption: null,
                defaultValue: 'asc',
                defaultDisplay: 'Asc',
                nonSelectorDisplay: '',
                width: '60px',
                stateVar: binderSearchSortOrder,
                dispatch: updateBinderSearchSortOrder,
                title: 'Select order to sort search results by',
                style: { marginRight: isScreen950 ? '5px' : '7px' },
              }}/>

              {isScreen950 ? <div style={{ marginBottom: '5px' }} /> : ''}

              {/* TODO: This needs a confirmation pop-up */}
              <button className='black-button' style={{ marginRight: '7px' }}
                disabled={binderActionsDisabled || selectedCards.length < 1}
                title='Delete selected cards from this binder' onClick={deleteSelected}><span>Delete Selected</span></button>

              <div className='checkbox' title='Select all visible cards' onClick={toggleSelectAll} style={{ marginRight: '5px' }}>
                <img className='checkbox-checkmark' src={imagePath + 'CheckmarkBlack.png'} width='20px' height='20px' style={{ display: (allBinderCardsSelected ? 'inline-block' : 'none') }} />
              </div>
              <span className='button-bar-label'>Select All</span>
            </div>
            <div className='grid-middle' />
            <div className='grid-right pagination-top'>
              <div className='results-per-top' style={{ display: 'inline-block', textAlign: 'right', marginBottom: '3px' }}>
                <span className='button-bar-label'>Results Per Page:</span>
                <BlackDropdown {...{
                  uniqueID: 'binder-results-per',
                  isSelector: true,
                  disabledVar: false,
                  optionsList: [{ value: 20, text: '20' } as DropdownOption,
                  { value: 30, text: '30' } as DropdownOption,
                  { value: 40, text: '40' } as DropdownOption,
                  { value: 50, text: '50' } as DropdownOption,
                  { value: 100, text: '100' } as DropdownOption],
                  startingOption: null,
                  defaultValue: 20,
                  defaultDisplay: '20',
                  nonSelectorDisplay: '',
                  width: '60px',
                  stateVar: binderCardsPerPage,
                  dispatch: (value: string) => {setBinderCardsPerPage(Number(value));},
                  title: 'Select number of cards to show per results page',
                  style: { marginRight: '0px' },
                }}/>
              </div>
              <div className='pagination-controls' style={{ display: 'inline-block', textAlign: 'right' }}>
                <label className='page-entry-field'>Page&nbsp;
                  <input className='page-entry' type='number' min='1' max={Math.ceil(binderSearchResults.length / binderCardsPerPage)}
                    onInput={binderPageInput} defaultValue={currentBinderPage} ref={binderPageFieldTop} />
                </label>
                <span>
                  /{Math.ceil(binderSearchResults.length / binderCardsPerPage)}
                </span>
                {isScreen950 ? <div style={{ marginBottom: '3px' }} /> : ''}
                <button title='Go to previous page' onClick={() => changeBinderPage(-1)} disabled={currentBinderPage <= 1}>
                  <img src={imagePath + 'PageLeft.png'} alt='<' width={isScreen950 ? '15px' : '18px'} height={isScreen950 ? '15px' : '18px'} />
                </button>
                <button title='Go to next page' onClick={() => changeBinderPage(1)} disabled={currentBinderPage == Math.ceil(binderSearchResults.length / binderCardsPerPage)}>
                  <img src={imagePath + 'PageRight.png'} alt='>' width={isScreen950 ? '15px' : '18px'} height={isScreen950 ? '15px' : '18px'} />
                </button>
              </div>
            </div>
          </div>

          <div className='binder-search-container content-box'>
            {binderSearchLoaded ? ((Math.ceil(binderSearchResults.length / binderCardsPerPage) > 0 && currentBinderPage > 0) ?
              <div>
                {binderSearchResults.map((card: BinderCardInfo, index: number) => {
                  if (index >= (currentBinderPage - 1) * binderCardsPerPage && index < ((currentBinderPage - 1) * binderCardsPerPage) + binderCardsPerPage) {
                    return <div className='binder-search-card' key={card.code + card.rarity + card.set}>
                      <div className='grid-left'>
                        <div className='checkbox' title='Select card' onClick={toggleSelectCard} data-selectstring={card.code + ',' + card.rarity} data-count={card.count}
                          style={{ marginRight: isScreen950 ? '0px' : '5px', minWidth: isScreen950 ? '15px' : '20px',
                            width: isScreen950 ? '15px' : '20px', height: isScreen950 ? '15px' : '20px', marginLeft: '0px' }}>
                          <img className='checkbox-checkmark' src={imagePath + 'CheckmarkBlack.png'} width={isScreen950 ? '15px' : '20px'} height={isScreen950 ? '15px' : '20px'}
                            style={{ display: (selectedCards.includes(card.code + ',' + card.rarity) ? 'inline-block' : 'none') }} />
                        </div>
                        <HoverableCard {...{
                          cardID: card.id,
                          cardName: card.name,
                          classNames: 'binder-management-card',
                          width: 47.73,
                          height: 70,
                          beforeElement: null,
                          afterElement: null,
                          style: {},
                          autosize: false,
                          contextMenu: null,
                          disableHover: false,
                        }} />
                        {isScreen950 ? '' : <h3><a href={cardLink(card.id)} target='_blank' rel='noreferrer'>{card.name}</a></h3>}
                        {isScreen950 ? <div className='binder-search-card-middle-small'>
                          <h3><a href={cardLink(card.id)} target='_blank' rel='noreferrer'>{card.name}</a></h3>
                          <h4><a href={productLink(card.set)} target='_blank' rel='noreferrer'>{card.set}</a>
                            <span className='binder-rarity-text'>&nbsp;{'- ' + card.rarity}</span>
                          </h4>
                        </div> : ''}
                      </div>
                      {isScreen950 ? '' : <div className='grid-middle' style={{ margin: 'auto' }}>
                        <h4 style={{ textAlign: 'center' }}><a href={productLink(card.set)} target='_blank' rel='noreferrer'>{card.set}</a></h4>
                      </div>}
                      {isScreen950 ? '' : <div className='grid-middle-right' style={{ margin: 'auto' }}>
                        <span className='binder-rarity-text' style={{ textAlign: 'center' }}>{card.rarity}</span>
                      </div>}
                      <div className='grid-right'>
                        <button className='binder-search-quantity-button' title='Subtract one' data-increment='-1' data-setrarity={card.code + card.rarity}
                          onClick={incrementBinderCard} disabled={binderActionsDisabled} style={{ display: collapsePrints ? 'none' : 'inherit' }}>
                          <img src={imagePath + 'BigMinus.png'} alt='-' width={isScreen950 ? '15px' : '16px'} height={isScreen950 ? '15px' : '16px'} />
                        </button>
                        <span className='binder-card-count'>x{card.count}</span>
                        <button className='binder-search-quantity-button' title='Add one' data-increment='1' data-setrarity={card.code + card.rarity}
                          onClick={incrementBinderCard} disabled={binderActionsDisabled} style={{ display: collapsePrints ? 'none' : 'inherit' }}>
                          <img src={imagePath + 'BigPlus.png'} alt='+' width={isScreen950 ? '15px' : '16px'} height={isScreen950 ? '15px' : '16px'} />
                        </button>
                      </div>
                    </div>;
                  }
                })}
              </div> :
              <span className='empty-search'>No cards found.</span>) :
              <img src={'/images/LoadingAnim.png'} width='100px' height='100px' style={{ margin: 'auto' }} />}
          </div>

          <div className='binder-controls binder-controls-bottom'>
            <div className='grid-left'>
              <div>
                <button className='black-button' style={{ marginRight: isScreen950 ? '5px' : '7px' }}
                  disabled={binderActionsDisabled || selectedCards.length < 1}
                  title='Delete selected cards from this binder' onClick={deleteSelected}><span>Delete Selected</span></button>
                <div className='checkbox' title='Select all visible cards' onClick={toggleSelectAll} style={{ marginRight: '5px' }}>
                  <img className='checkbox-checkmark' src={imagePath + 'CheckmarkBlack.png'} width='20px' height='20px' style={{ display: (allBinderCardsSelected ? 'inline-block' : 'none') }} />
                </div>
                <span className='button-bar-label'>Select All</span>
              </div>
            </div>
            <div className='grid-middle' />
            <div className='grid-right'>
              <div style={{ display: 'inline-block', textAlign: 'right', marginBottom: '3px' }}>
                <span className='button-bar-label'>Results Per Page:</span>
                <BlackDropdown {...{
                  uniqueID: 'binder-results-per2',
                  isSelector: true,
                  disabledVar: false,
                  optionsList: [{ value: 20, text: '20' } as DropdownOption,
                  { value: 30, text: '30' } as DropdownOption,
                  { value: 40, text: '40' } as DropdownOption,
                  { value: 50, text: '50' } as DropdownOption,
                  { value: 100, text: '100' } as DropdownOption],
                  startingOption: null,
                  defaultValue: 20,
                  defaultDisplay: '20',
                  nonSelectorDisplay: '',
                  width: '60px',
                  stateVar: binderCardsPerPage,
                  dispatch: (value: string) => {setBinderCardsPerPage(Number(value));},
                  title: 'Select number of cards to show per results page',
                  style: { marginRight: isScreen950 ? '0px' : '7px' },
                }}/>
              </div>
              <div className='pagination-controls' style={{ display: 'inline-block', textAlign: 'right' }}>
                {isScreen950 ? '' : <div style={{ display: 'inline-block' }}>
                  <label className='page-entry-field'>Page&nbsp;
                    <input className='page-entry' type='number' min='1' max={Math.ceil(binderSearchResults.length / binderCardsPerPage)}
                      onInput={binderPageInput} defaultValue={currentBinderPage} ref={binderPageFieldBottom} />
                  </label>
                  <span>
                    /{Math.ceil(binderSearchResults.length / binderCardsPerPage)}
                  </span></div>}
                <button title='Go to previous page' onClick={() => changeBinderPage(-1)} disabled={currentBinderPage <= 1}>
                  <img src={imagePath + 'PageLeft.png'} alt='<' width={isScreen950 ? '15px' : '18px'} height={isScreen950 ? '15px' : '18px'} />
                </button>
                <button title='Go to next page' onClick={() => changeBinderPage(1)} disabled={currentBinderPage == Math.ceil(binderSearchResults.length / binderCardsPerPage)}>
                  <img src={imagePath + 'PageRight.png'} alt='>' width={isScreen950 ? '15px' : '18px'} height={isScreen950 ? '15px' : '18px'} />
                </button>
                {isScreen950 ? <div style={{ marginBottom: '3px' }} /> : ''}
                {isScreen950 ?
                  <div style={{ display: 'inline-block' }}><label className='page-entry-field'>Page&nbsp;
                    <input className='page-entry' type='number' min='1' max={Math.ceil(binderSearchResults.length / binderCardsPerPage)}
                      onInput={binderPageInput} defaultValue={currentBinderPage} ref={binderPageFieldBottom} />
                  </label>
                  <span>
                    /{Math.ceil(binderSearchResults.length / binderCardsPerPage)}
                  </span></div> : ''}
              </div>
            </div>
          </div>

          <Ad styleName='bar_ad_footer' />
        </main>
      );
    }
    else {
      return (
        <main>
          <Helmet>
            <meta content="YGO Prog - Binder Management" property="og:title" />
            <meta
              content="YGO Prog's Binder Manager lets you manage your different collections of Yu-Gi-Oh! TCG cards."
              property="og:description"
            />
            <meta content="https://www.ygoprog.com/BinderManagement" property="og:url" />
            <meta
              name="description"
              content="YGO Prog's Binder Manager lets you manage your different collections of Yu-Gi-Oh! TCG cards."
            />
            <title>YGO Prog - Binder Management</title>
          </Helmet>

          <Ad styleName='bar_ad' />

          <h1 style={{ marginBottom: '10px' }}>Binder Management</h1>
          <p className='content-box'>
            You must <u className='in-text-link' title='Open login modal' onClick={() => {props.openModalFunc('Duelist Login');}}>Log In</u> to view your binders.
          </p>
        </main>
      );
    }
  }
  else {
    return (
      <div style={{ margin: 'auto', textAlign: 'center' }}>
        <Helmet>
          <meta content="YGO Prog - Binder Management" property="og:title" />
          <meta
            content="YGO Prog is a tool for simulating Yu-Gi-Oh! pack opening, collection management, deck building, banlist building, and more!"
            property="og:description"
          />
          <meta content="https://www.ygoprog.com/BinderManagement" property="og:url" />
          <meta
            name="description"
            content="YGO Prog is a tool for simulating Yu-Gi-Oh! pack opening, collection management, deck building, banlist building, and more!"
          />
          <title>YGO Prog - Binder Management</title>
        </Helmet>
        <h1 style={{ paddingBottom: '0px', marginBottom: '0px' }}>Loading</h1>
        <img src={'/images/LoadingAnim.png'} width='150px' height='150px' style={{ margin: 'auto', marginTop: '5px', padding: '0' }} />
      </div>
    );
  }
}
