import { useState } from 'react';
import * as Constants from '../utils/Constants';
import {
  emailEntryValidator,
  nameValidator,
  passwordValidator,
  confirmPasswordValidator,
} from '../utils/validators/LoginAndRegistrationValidator';
import makeApiCall from '../utils/ApiMiddleware';
import { openErrorModal } from '../utils/BasicModalFuncs';
import { contactString } from '../utils/Constants';

export default function Registration(props: { modal: boolean, page: string, setPage: any }) {
  const [error, setError] = useState({
    emailMessage: '',
    usernameMessage: '',
    passwordMessage: '',
    confirmPasswordMessage: '',
  });
  const [registrationEmail, setRegistrationEmail] = useState('');
  const [registrationPassword, setRegistrationPassword] = useState('');
  const [registrationUsername, setRegistrationUsername] = useState('');
  const [registrationConfirmPassword, setRegistrationConfirmPassword] = useState('');
  const [errorList, setErrorList] = useState<string[]>([]);
  const [registrationComplete, setRegistrationComplete] = useState(false);
  const [registering, setRegistering] = useState(false);

  function handleRegistration() {
    if (registrationEmail != '' && registrationUsername != '' && registrationPassword != '' && registrationConfirmPassword != '' &&
      (error.emailMessage + error.usernameMessage + error.passwordMessage + error.confirmPasswordMessage) == '') {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: registrationEmail, username: registrationUsername, password: registrationPassword }),
      };
      setRegistering(true);
      makeApiCall(Constants.cookies.get('refresh'), Constants.cookies.get('userId'), Constants.apiPath.concat('register'), requestOptions)
        .then(async (response) => {
          setRegistering(false);

          if (!response.ok) {
            const resError = response.status;
            openErrorModal('Issue registering user.<br />Error Code: ' + resError + '<br />' + contactString);
            return Promise.reject(resError);
          }

          const data = await response.json();

          const newError = error;
          if (data.message == 'DuplicateUsername') {
            newError.usernameMessage = 'A duelist with this name already exists';
            setError(newError);
            setErrorList([error.emailMessage, 'A duelist with this name already exists', error.passwordMessage, error.confirmPasswordMessage]);
            setRegistrationComplete(false);
          }
          else if (data.message == 'DuplicateEmail') {
            newError.emailMessage = 'There is already a duelist associated with this email';
            setError(newError);
            setErrorList(['There is already a duelist associated with this email', error.usernameMessage, error.passwordMessage, error.confirmPasswordMessage]);
            setRegistrationComplete(false);
          }
          else if (data.message.includes('Verification Email Not Sent')) {
            openErrorModal('Registration succeeded, but verification email did not send.' +
              '<br />The email send can be re-attempted on the <a class="in-text-link" href="' + Constants.frontEndPath + 'Account">Account Page</a> after logging in.');
            setErrorList([]);
            setRegistrationComplete(true);
          }
          else if (data.success) {
            setErrorList([]);
            setRegistrationComplete(true);
          }
          else {
            openErrorModal('Registration failed, try double checking all your fields are properly filled.' +
              '<br />If this persists, ' + contactString);
            setRegistrationComplete(false);
          }
        });
    }
    else if ((error.emailMessage + error.usernameMessage + error.passwordMessage + error.confirmPasswordMessage) == '') {
      setErrorList(['All fields are required', '', '', '']);
    }
    else {
      setErrorList([error.emailMessage, error.usernameMessage, error.passwordMessage, error.confirmPasswordMessage]);
    }
  }

  function handleRegistrationEmail(event: React.ChangeEvent<any>) {
    const email = `${event.target.value}`;
    const newError = error;

    newError.emailMessage = emailEntryValidator(email);

    setError(newError);
    setRegistrationEmail(email);
  }

  function handleRegistrationUsername(event: React.ChangeEvent<any>) {
    const username = `${event.target.value}`;
    const newError = error;

    newError.usernameMessage = nameValidator(username);

    setError(newError);
    setRegistrationUsername(username);
  }

  function handleRegistrationPassword(event: React.ChangeEvent<any>) {
    const password = `${event.target.value}`;
    const newError = error;

    newError.passwordMessage = passwordValidator(password);

    newError.confirmPasswordMessage = confirmPasswordValidator(
      password,
      registrationConfirmPassword,
    );

    setError(newError);
    setRegistrationPassword(password);
  }


  function handleRegistrationConfirmPassword(event: React.ChangeEvent<any>) {
    const confirmPassword = `${event.target.value}`;
    const newError = error;

    newError.confirmPasswordMessage = confirmPasswordValidator(
      registrationPassword,
      confirmPassword,
    );

    setError(newError);
    setRegistrationConfirmPassword(confirmPassword);
  }

  return (
    <div>

      {props.modal &&
        <h5 style={{ marginBottom: '10px' }}>New Duelist Registration</h5>}
      {!registrationComplete ?
        <div>
          <div className='vertical-form-text-input'>
            <label htmlFor='email-input'>Email:</label>
            <div className='text-input'>
              <input id='email-input' type="email" title='Email for validation password recovery' required
                onChange={handleRegistrationEmail}
                onKeyDown={(e) => {if (e.code === 'Enter') handleRegistration();}} />
            </div>
            <div className='tooltip' style={{ display: 'inline-block' }}>
              <img src={Constants.imagePath + 'Info.png'} width='30px' height='30px' style={{ verticalAlign: 'middle' }} />
              <p className='tooltip-text' style={{ width: '180px', paddingLeft: '7px' }}>
                Email is used for verification and password recovery, we do not send unsolicited emails except in the event of a security issue.
                <br /><a className='tooltip-link' href={Constants.frontEndPath + 'PrivacyPolicy'} target='_blank' rel='noreferrer'>Privacy Policy</a>
              </p>
            </div>
          </div>
          <div className='vertical-form-text-input'>
            <label htmlFor='username-input'>Username:</label>
            <div className='text-input'>
              <input id='username-input' type="text" title='May only contain letters and/or numbers, and must be between 1 and 255 characters long' required
                onChange={handleRegistrationUsername}
                onKeyDown={(e) => {if (e.code === 'Enter') handleRegistration();}} />
            </div>
            <div className='tooltip' style={{ display: 'inline-block' }}>
              <img src={Constants.imagePath + 'Info.png'} width='30px' height='30px' style={{ verticalAlign: 'middle' }} />
              <ul className='tooltip-text' style={{ width: '180px', listStylePosition: 'inside', textIndent: '-18px', paddingLeft: '25px' }}>
                Username:
                <li><span>Must contain only lowercase or capital letters A-Z or numbers 0-9</span></li>
                <li><span>Must be at least 1 character long</span></li>
                <li><span>Should not contain any sensitive personal information</span></li>
              </ul>
            </div>
          </div>

          <div className='vertical-form-text-input'>
            <label htmlFor='password-input'>Password:</label>
            <div className='text-input'>
              <input id='password-input' required type="password" title='Must be between 8 and 255 characters long'
                onChange={handleRegistrationPassword}
                onKeyDown={(e) => {if (e.code === 'Enter') handleRegistration();}} />
            </div>
            <div className='tooltip' style={{ display: 'inline-block' }}>
              <img src={Constants.imagePath + 'Info.png'} width='30px' height='30px' style={{ verticalAlign: 'middle' }} />
              <ul className='tooltip-text' style={{ width: '180px', listStylePosition: 'inside', textIndent: '-18px', paddingLeft: '25px' }}>
                Password:
                <li><span>May contain any letters, numbers, or special characters</span></li>
                <li><span>Must be at least 8 characters long</span></li>
                <li><span>Should not contain username or any personal information</span></li>
              </ul>
            </div>
          </div>

          <div className='vertical-form-text-input'>
            <label htmlFor='confirm-password-input'>Confirm Password:</label>
            <div className='text-input' style={{ marginRight: '30px' }}>
              <input id='confirm-password-input' required type="password" title='Must match password'
                onChange={handleRegistrationConfirmPassword}
                onKeyDown={(e) => {if (e.code === 'Enter') handleRegistration();}} />
            </div>
          </div>

          {errorList.map((value: string, index: number) => <span className='validation-error' key={index}>{value}</span>)}

          {registering ?
            <img src={'/images/LoadingAnim.png'} width='30px' height='30px' style={{ margin: 'auto', verticalAlign: 'middle', marginRight: '0px' }} /> :
            <button className='black-button' type='button' title='Register' onClick={handleRegistration} style={{ padding: '0px 25px', marginTop: '7px' }}><span>Register</span></button>}

          <div className='middletext-divider'>
            <div className='grid-left gray-line' />
            <span className='grid-middle gray-middle-text'>or</span>
            <div className='grid-right gray-line' />
          </div>
        </div> :
        <p style={{ paddingTop: '0' }}>
          Your registration is complete. A verification email has been sent to <b>{registrationEmail}</b>.<br />
          If you do not verify your email, we have no way to help you reset your password if you forget it.<br />
          You can resend the verification email from your <a className='in-text-link' href={Constants.frontEndPath + 'Account'}>Account Page</a>.
        </p>}

      <span className='black-underline-link' title='Open login modal' onClick={() => {props.setPage('Duelist Login');}}>Return to Login</span>
    </div>
  );
}
