import { useContext, useEffect, useState } from 'react';
import * as Constants from '../utils/Constants';
import {
  loginPasswordValidator,
  emailEntryValidator,
  nameValidator,
  passwordValidator,
  confirmPasswordValidator,
} from '../utils/validators/LoginAndRegistrationValidator';
import makeApiCall from '../utils/ApiMiddleware';
import { openBasicModal, openErrorModal, openResetPassModal } from '../utils/BasicModalFuncs';
import { Helmet } from 'react-helmet';
import { contactString } from '../utils/Constants';
import { AppContext } from '../utils/Contexts';
import localforage from 'localforage';

export default function Account(props: { refresh: any, openModalFunc: any }) {
  const [error, setError] = useState({
    emailMessage: '',
    usernameMessage: '',
    passwordMessage: '',
    confirmPasswordMessage: '',
  });
  const [newEmail, setNewEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newUsername, setNewUsername] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [errorList, setErrorList] = useState<string[]>([]);
  const [changeInProgress, setChangeinProgess] = useState(false);
  const [userVerified, setUserVerified] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [resendingEmail, setResendingEmail] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [unsavedEmail, setUnsavedEmail] = useState(false);
  const [username, setUsername] = useState(Constants.cookies.get('user'));
  const { resetCache, isScreen950Query } = useContext(AppContext);
  const [isScreen950, setIsScreen950] = useState(isScreen950Query.matches);

  useEffect(() => { // Mount
    isScreen950Query.addEventListener('change', checkScreen950); // To avoid re-rendering the whole page and losing data, we have to listen and state change on the sublevel
    if (Constants.cookies.get('user') !== undefined) getUser();
    Constants.onCookieChangeListeners.push(cookieRefresh);

    return () => { // Unmount
      isScreen950Query.removeEventListener('change', checkScreen950);
      Constants.onCookieChangeListeners.splice(Constants.onCookieChangeListeners.indexOf(cookieRefresh), 1);
    };
  }, []);

  useEffect(() => {
    if ((newUsername != '' && newUsername != username) ||
    (newEmail != '' && newEmail != userEmail) ||
    (newPassword != '')) setUnsavedChanges(true);
    else setUnsavedChanges(false);
  }, [newUsername, newEmail, newPassword]);

  useEffect(() => {
    if (newEmail != '' && newEmail != userEmail) setUnsavedEmail(true);
    else setUnsavedEmail(false);
  }, [newEmail]);

  function checkScreen950() {
    setIsScreen950(isScreen950Query.matches);
  }

  function cookieRefresh() {
    setUsername(Constants.cookies.get('user'));
    if (Constants.cookies.get('user') !== undefined) getUser();
  }

  function getUser() {
    const userReqOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + Constants.cookies.get('token') },
    };

    makeApiCall(Constants.cookies.get('refresh'), Constants.cookies.get('userId'), Constants.apiPath + 'user/' + Constants.cookies.get('userId'), userReqOptions)
      .then(async (response) => {
        if (!response.ok) {
          const err = response.status;
          openErrorModal('Issue retrieving duelist account settings.<br />Error Code: ' + err + '<br />' + contactString);
          return Promise.reject(err);
        }

        const data = await response.json();

        if (data.success && data?.email != '') {
          setUserVerified(data.userVerified);
          setUserEmail(data.email);
        }
        else if (data.message == 'UserNotFound') {
          openErrorModal('Issue retrieving duelist account settings - could not find duelist account.<br />' + contactString);
        }
        else {
          openErrorModal('Issue retrieving duelist account settings.<br />' + contactString);
        }
      });
  }

  function handleChanges() {
    if (currentPassword != '' && (error.emailMessage + error.usernameMessage + error.passwordMessage + error.confirmPasswordMessage) == '') {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + Constants.cookies.get('token') },
        body: JSON.stringify({ username: username, password: currentPassword, newEmail: newEmail, newUsername: newUsername, newPassword: newPassword }),
      };
      setChangeinProgess(true);
      makeApiCall(Constants.cookies.get('refresh'), Constants.cookies.get('userId'), Constants.apiPath.concat('user/update/' + Constants.cookies.get('userId')), requestOptions)
        .then(async (response) => {
          setChangeinProgess(false);

          if (!response.ok) {
            const resError = response.status;
            openErrorModal('Issue saving account changes.<br />Error Code: ' + resError + '<br />' + contactString);
            return Promise.reject(resError);
          }

          const data = await response.json();

          const newError = error;
          if (data.message == 'DuplicateUsername') {
            newError.usernameMessage = 'A duelist with this name already exists';
            setError(newError);
            setErrorList([error.emailMessage, 'A duelist with this name already exists', error.passwordMessage, error.confirmPasswordMessage]);
          }
          else if (data.message == 'DuplicateEmail') {
            newError.emailMessage = 'There is already a duelist associated with this email';
            setError(newError);
            setErrorList(['There is already a duelist associated with this email', error.usernameMessage, error.passwordMessage, error.confirmPasswordMessage]);
          }
          else if (data.message == 'UserNotFound') {
            openErrorModal('Issue saving account changes - could not find duelist account.<br />' + contactString);
          }
          else if (data.message.includes('Auth')) {
            newError.passwordMessage = 'Password is incorrect';
            setError(newError);
            setErrorList([error.emailMessage, error.usernameMessage, error.passwordMessage, error.confirmPasswordMessage]);
          }
          else if (data.message.includes('Verification Email Not Sent')) {
            openErrorModal('Changes to duelist profile succeeded, but verification email for new email did not send.' +
              '<br />The email send can be re-attempted by pressing the "Resend Verification Email" button on this page.');
            setErrorList([]);
            Constants.setCookie('user', data.username);
            setUserEmail(data.email);
            setUserVerified(data.userVerified);
            setUnsavedChanges(false);
            setUnsavedEmail(false);
          }
          else if (data.success) {
            setErrorList([]);
            if (data.message.includes('Email Sent')) {
              openBasicModal('Changes Saved', 'Changes to duelist profile successfully completed.<br />Verification email has been sent to <b>' + userEmail + '</b>.', 'Ok');
            }
            else openBasicModal('Changes Saved', 'Changes to duelist profile successfully completed.', 'Ok');
            Constants.setCookie('user', data.username);
            setUserEmail(data.email);
            setUserVerified(data.userVerified);
            setUnsavedChanges(false);
            setUnsavedEmail(false);
          }
          else {
            openErrorModal('Saving changes failed, try double checking all your fields are properly filled.' +
              '<br />If this persists, ' + contactString);
          }
        });
    }
    else if ((error.emailMessage + error.usernameMessage + error.passwordMessage + error.confirmPasswordMessage) == '') {
      setErrorList(['Current Password is required', '', '', '']);
    }
    else {
      setErrorList([error.emailMessage, error.usernameMessage, error.passwordMessage, error.confirmPasswordMessage]);
    }
  }

  function handleNewEmail(event: React.ChangeEvent<any>) {
    const email = `${event.target.value}`;
    const newError = error;

    if (email != '') newError.emailMessage = emailEntryValidator(email);
    else newError.emailMessage = '';

    setError(newError);
    setNewEmail(email);
  }

  function handleNewUsername(event: React.ChangeEvent<any>) {
    const username = `${event.target.value}`;
    const newError = error;

    if (username != '') newError.usernameMessage = nameValidator(username);
    else newError.usernameMessage = '';

    setError(newError);
    setNewUsername(username);
  }

  function handleNewPassword(event: React.ChangeEvent<any>) {
    const password = `${event.target.value}`;
    const newError = error;

    if (password != '') {
      newError.passwordMessage = passwordValidator(password);

      newError.confirmPasswordMessage = confirmPasswordValidator(
        password,
        confirmPassword,
      );
    }
    else {
      newError.passwordMessage = '';
      newError.confirmPasswordMessage = '';
    }

    setError(newError);
    setNewPassword(password);
  }

  function handleNewPasswordConfirm(event: React.ChangeEvent<any>) {
    const confirmPassword = `${event.target.value}`;
    const newError = error;

    if (newPassword != '') {
      newError.confirmPasswordMessage = confirmPasswordValidator(
        newPassword,
        confirmPassword,
      );
    }
    else newError.confirmPasswordMessage = '';

    setError(newError);
    setConfirmPassword(confirmPassword);
  }

  function handleCurrentPassword(event: React.ChangeEvent<any>) {
    const password = `${event.target.value}`;
    const newError = error;

    newError.passwordMessage = loginPasswordValidator(password);

    setError(error);
    setCurrentPassword(password);
  }

  function resendVerificationEmail() {
    if ((error.emailMessage + error.usernameMessage + error.passwordMessage + error.confirmPasswordMessage) == '') {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + Constants.cookies.get('token') },
        body: '',
      };
      setResendingEmail(true);
      makeApiCall(Constants.cookies.get('refresh'), Constants.cookies.get('userId'), Constants.apiPath.concat('user/verify/resend/' + Constants.cookies.get('userId')), requestOptions)
        .then(async (response) => {
          setResendingEmail(false);

          if (!response.ok) {
            const resError = response.status;
            openErrorModal('Issue resending verification email.<br />Error Code: ' + resError + '<br />' + contactString);
            return Promise.reject(resError);
          }

          const data = await response.json();

          if (!data.success) {
            if (data.message == 'UserNotFound') {
              openErrorModal('Issue resending verification email - could not find duelist account.' +
                '<br />' + contactString);
            }
            else openErrorModal('Issue resending verification email.<br />' + contactString);
          }
          else {
            openBasicModal('Verification Sent', 'Verification email has been resent to <b>' + userEmail + '</b>.', 'Ok');
          }
        });
    }
  }

  function logOut() {
    // call logout with userId and refresh token
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ userId: Constants.cookies.get('userId'), refreshToken: Constants.cookies.get('refresh') }),
    };
    makeApiCall(Constants.cookies.get('refresh'), Constants.cookies.get('userId'), Constants.apiPath + 'logout', requestOptions);
    Constants.removeCookie('user');
    Constants.removeCookie('userId');
    Constants.removeCookie('token');
    Constants.removeCookie('refresh');
    Constants.sessionMemory.lastBinderSelected = '';
    setUsername(undefined);
    localforage.removeItem('decks')
      .catch(() => {
        openErrorModal('Issue clearing decks on logout.<br />' + contactString);
      });
    localforage.removeItem('banlists')
      .catch(() => {
        openErrorModal('Issue clearing banlists on logout.<br />' + contactString);
      });
    localforage.removeItem('binders')
      .catch(() => {
        openErrorModal('Issue clearing binders on logout.<br />' + contactString);
      });
  }

  if (username !== undefined) {
    return (
      <main>
        <Helmet>
          <meta content="YGO Prog - Account" property="og:title" />
          <meta
            content="YGO Prog is a tool for simulating Yu-Gi-Oh! pack opening, collection management, deck building, banlist building, and more!"
            property="og:description"
          />
          <meta content="https://www.ygoprog.com/Account" property="og:url" />
          <meta
            name="description"
            content="YGO Prog is a tool for simulating Yu-Gi-Oh! pack opening, collection management, deck building, banlist building, and more!"
          />
          <title>YGO Prog - Account</title>
        </Helmet>
        <h1 style={{ marginBottom: '10px' }}>Account Settings</h1>
        <div className='content-box' style={{ padding: isScreen950 ? '5px' : '12px 20px' }}>
          <h5 style={{ fontSize: '28px' }}>Change Duelist Info?</h5>
          <div className='vertical-form-text-input'>
            <label htmlFor='username-input'>Username:</label>
            <div className='text-input'>
              <input id='username-input' type="text" title='May only contain letters and/or numbers, and must be between 1 and 255 characters long'
                defaultValue={Constants.cookies.get('user')}
                onChange={handleNewUsername}
                onKeyDown={(e) => {if (e.code === 'Enter') handleChanges();}} />
            </div>
            <div className='tooltip' style={{ display: 'inline-block' }}>
              <img src={Constants.imagePath + 'Info.png'} width='30px' height='30px' style={{ verticalAlign: 'middle' }} />
              <ul className='tooltip-text' style={{ width: '180px', listStylePosition: 'inside', textIndent: '-18px', paddingLeft: '25px' }}>
                Username:
                <li><span>Must contain only lowercase or capital letters A-Z or numbers 0-9</span></li>
                <li><span>Must be at least 1 character long</span></li>
                <li><span>Should not contain any sensitive personal information</span></li>
              </ul>
            </div>
          </div>

          <div className='vertical-form-text-input'>
            <label htmlFor='email-input'>Email:</label>
            <div className='text-input'>
              <input id='email-input' type="email" title='Email for validation password recovery'
                defaultValue={userEmail}
                onChange={handleNewEmail}
                onKeyDown={(e) => {if (e.code === 'Enter') handleChanges();}} />
            </div>
            <div className='tooltip' style={{ display: 'inline-block' }}>
              <img src={Constants.imagePath + 'Info.png'} width='30px' height='30px' style={{ verticalAlign: 'middle' }} />
              <p className='tooltip-text' style={{ width: '180px', paddingLeft: '7px' }}>
                Email is used for verification and password recovery, we do not send unsolicited emails except in the event of a security issue.
                <br /><a className='tooltip-link' href={Constants.frontEndPath + 'PrivacyPolicy'} target='_blank' rel='noreferrer'>Privacy Policy</a>
              </p>
            </div>
            {!unsavedEmail ?
              (!userVerified ?
                (resendingEmail ?
                  <img src={'/images/LoadingAnim.png'} width='20px' height='20px'
                    style={{ display: 'block', padding: '0px 5px', marginTop: '5px', marginLeft: 'auto', marginRight: '25%' }} /> :
                  <span className='forgot-pass-button' title='Resend verification email' onClick={resendVerificationEmail} style={{ marginRight: '30px' }}>Resend verification?</span>) :
                <div style={{ marginLeft: 'auto', display: 'flex', width: 'fit-content', marginRight: '30px', alignItems: 'center' }}>
                  <img className='verified-checkmark' src={Constants.imagePath + 'CheckmarkGreen.png'} title='Verified' alt='Checkmark' width='18px' height='20px' />
                  <span className='verified'>Verified</span>
                </div>) :
              <p style={{ fontSize: '12px', fontStyle: 'italic', textAlign: 'right', padding: '0px', margin: '0px', marginRight: '30px' }}>Email change not saved</p>}
          </div>

          <div className='account-divider' />

          <h5 style={{ fontSize: '28px' }}>Change Password?</h5>
          <div className='vertical-form-text-input'>
            <label htmlFor='password-input'>New Password:</label>
            <div className='text-input'>
              <input id='password-input' type="password" title='Must be between 8 and 255 characters long'
                onChange={handleNewPassword}
                onKeyDown={(e) => {if (e.code === 'Enter') handleChanges();}} />
            </div>
            <div className='tooltip' style={{ display: 'inline-block' }}>
              <img src={Constants.imagePath + 'Info.png'} width='30px' height='30px' style={{ verticalAlign: 'middle' }} />
              <ul className='tooltip-text' style={{ width: '180px', listStylePosition: 'inside', textIndent: '-18px', paddingLeft: '25px' }}>
                Password:
                <li><span>May contain any letters, numbers, or special characters</span></li>
                <li><span>Must be at least 8 characters long</span></li>
                <li><span>Should not contain username or any personal information</span></li>
              </ul>
            </div>
          </div>

          <div className='vertical-form-text-input'>
            <label htmlFor='confirm-password-input'>Confirm Password:</label>
            <div className='text-input' style={{ marginRight: '30px' }}>
              <input id='confirm-password-input' type="password" title='Must match password'
                onChange={handleNewPasswordConfirm}
                onKeyDown={(e) => {if (e.code === 'Enter') handleChanges();}} />
            </div>
          </div>

          <div className='account-divider' />

          <p style={{ padding: '0px', margin: '0px', marginBottom: '7px' }}>You must confirm your current password to save changes</p>
          <div className='vertical-form-text-input'>
            <label htmlFor='current-password-input'>Current Password:</label>
            <div className='text-input' style={{ marginRight: '30px' }}>
              <input id='current-password-input' required type="password" title='Required to change account settings'
                onChange={handleCurrentPassword}
                onKeyDown={(e) => {if (e.code === 'Enter') handleChanges();}} />
            </div>
            <span className='forgot-pass-button' title='Open password reset modal' onClick={openResetPassModal} style={{ marginRight: '30px' }}>Forgot password?</span>
          </div>

          {errorList.map((value: string, index: number) => <span className='validation-error' key={index}>{value}</span>)}

          {unsavedChanges ? <p style={{ fontWeight: 'bold', padding: '0px', margin: '0px' }}>You have unsaved changes</p> : ''}

          {changeInProgress ?
            <img src={'/images/LoadingAnim.png'} width='30px' height='30px' style={{ margin: 'auto', verticalAlign: 'middle', marginRight: '7px' }} /> :
            <button className='black-button' type='button' title='Register' onClick={handleChanges} style={{ padding: '0px 25px', marginTop: '7px' }}><span>Save Changes</span></button>}
        </div>
        <button className='black-button' type='button' title='Log out' onClick={logOut} style={{ padding: '0px', margin: '7px auto 5px auto', display: 'block' }}>
          <span>Log Out</span>
        </button>
        <button className='black-button' type='button' title='Reset local cache (does not clear any user data)' onClick={resetCache} style={{ padding: '0px', margin: '7px auto 0px auto', display: 'block' }}>
          <span>Reset Local Cards &amp; Sets Cache</span>
        </button>
      </main>
    );
  }
  else {
    return (
      <main>
        <h1 style={{ marginBottom: '10px' }}>Account Settings</h1>
        <p className='content-box'>
          You must <u className='in-text-link' title='Open login modal' onClick={() => {props.openModalFunc('Duelist Login');}}>Log In</u> to edit your account.
        </p>
        <button className='black-button' type='button' title='Reset local cache (does not clear any user data)' onClick={resetCache} style={{ padding: '0px', margin: '7px auto 0px auto', display: 'block' }}>
          <span>Reset Local Cards &amp; Sets Cache</span>
        </button>
      </main>
    );
  }
}
