import * as Constants from './Constants';
import makeApiCall from './ApiMiddleware';
import { openErrorModal } from './BasicModalFuncs';
import localforage from 'localforage';
import { extendPrototype as extendGet } from 'localforage-getitems';
import { extendPrototype as extendSet } from 'localforage-setitems';
import { contactString, lowercaseContactString } from './Constants';

extendGet(localforage);
extendSet(localforage);

export const cards: { [key: number]: Card } = {};
export const altIdMap: { [key: number]: number } = {}; // Map of all alt card ids -> key ids
export const cardSets: { [key: string]: CardProduct } = {}; // Pullable sets, keyed on set prefix
export const cardSetDates: { [key: string]: Date } = {}; // All set print dates, keyed on set name
export const bundleLinks: { [key: string]: string } = {}; // Map of bundle set names to yugipedia urls
export const cardsLoaded = { flag: false };

export async function validateCache(setsLoadedDispatch: any, forceFlush: boolean) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };

  makeApiCall(Constants.cookies.get('refresh'), Constants.cookies.get('userId'), Constants.apiPath, requestOptions)
    .then(async (response) => {
      if (response == null) {
        openErrorModal('Could not reach the API.<br />' + contactString);
        return;
      }
      else if (!response.ok) {
        openErrorModal('Error reaching the API.<br />' + contactString);
        return;
      }

      const data = await response.json();

      if (data.status != 'API Online') {
        openErrorModal('API is offline.<br />' + contactString);
        return;
      }

      checkCacheUpToDate(data.dataLastUpdated, forceFlush)
        .then(async (updateResponse) => {
          getAllSetsData() // Error modals are handled inside the get
            .then(async (setsResponse) => {
              if (setsResponse && setsLoadedDispatch != null) {
                setsLoadedDispatch(true);
              }

              getAllCardsData() // Error modals are handled inside the get
                .then(async (cardsResponse) => {
                  if (cardsResponse) {
                    cardsLoaded.flag = true;
                  }
                });
            });
        });
    })
    .catch((err) => {
      openErrorModal('Issue reaching the API.<br />' + contactString);
      return Promise.resolve(null);
    });
}

export async function refreshUserDataLists() {
  const refresh = Constants.cookies.get('refresh');
  const userId = Constants.cookies.get('userId');
  const getReqOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + Constants.cookies.get('token') },
  };
  makeApiCall(refresh, userId, Constants.apiPath + 'user/' + userId + '/decks', getReqOptions)
    .then(async (deckResponse: any) => {
      if (!deckResponse.ok) {
        const err = deckResponse.status;
        openErrorModal('Issue retrieving decks on site open.<br />' +
          (err == 401 ? '<br />Try logging out and logging back in.<br />If this persists, ' + lowercaseContactString : '') +
          (err == 400 ? '<br />You have been inactive for a long time. Please log out and back in.<br /><br />If this persists, ' + lowercaseContactString : '') +
          (err != 401 && err != 400 ? '<br />' + contactString : ''));
        return Promise.reject(err);
      }

      const deckData = await deckResponse.json();

      makeApiCall(refresh, userId, Constants.apiPath + 'user/' + userId + '/banlists', getReqOptions)
        .then(async (banlistResponse: any) => {
          if (!banlistResponse.ok) {
            const err = banlistResponse.status;
            openErrorModal('Issue retrieving banlists on site open.<br />' +
              (err == 401 ? '<br />Try logging out and logging back in.<br />If this persists, ' + lowercaseContactString : '') +
              (err == 400 ? '<br />You have been inactive for a long time. Please log out and back in.<br /><br />If this persists, ' + lowercaseContactString : '') +
              (err != 401 && err != 400 ? '<br />' + contactString : ''));
            return Promise.reject(err);
          }

          const banlistData = await banlistResponse.json();

          makeApiCall(refresh, userId, Constants.apiPath + 'user/' + userId + '/binders', getReqOptions)
            .then(async (binderResponse: any) => {
              if (!binderResponse.ok) {
                const err = binderResponse.status;
                openErrorModal('Issue retrieving binders on site open.<br />' +
                  (err == 401 ? '<br />Try logging out and logging back in.<br />If this persists, ' + lowercaseContactString : '') +
                  (err == 400 ? '<br />You have been inactive for a long time. Please log out and back in.<br /><br />If this persists, ' + lowercaseContactString : '') +
                  (err != 401 && err != 400 ? '<br />' + contactString : ''));
                return Promise.reject(err);
              }

              const binderData = await binderResponse.json();

              await localforage.setItem('decks', dropdownOptsFromGroups(deckData, 'decks'));
              await localforage.setItem('banlists', dropdownOptsFromGroups(banlistData, 'banlists'));
              await localforage.setItem('binders', binderData.map((binder: any) => ({ id: binder._id, name: binder.name })));
            });
        });
    });
}

export function dropdownOptsFromGroups(groups: any, objectArrayName: string) {
  const opts: DropdownOption[] = [];
  for (let i = 0; i < groups.length; i++) {
    const group = groups[i];
    opts.push({
      value: 'group',
      text: group.name,
      subItems: group[objectArrayName].map((entry: any) => {return { value: entry._id, text: entry.name } as DropdownOption;}),
    } as DropdownOption);
  }

  return opts;
}

export async function clearUserDataLists() {
  await localforage.removeItem('decks');
  await localforage.removeItem('banlists');
  await localforage.removeItem('binders');
}

export async function checkCacheUpToDate(serverLastUpdated: number, forceFlush: boolean): Promise<void> {
  const clientLastUpdated: number | null = await localforage.getItem('DataLastUpdated');

  if (forceFlush || (clientLastUpdated != null && (clientLastUpdated <= serverLastUpdated ||
    Math.round((Date.now() - clientLastUpdated) / (7 * 24 * 60 * 60 * 1000)) > 2))) { // If we're mismatched or expired (>2 weeks)

    await localforage.removeItem('DataLastUpdated')
      .catch((err) => {
        openErrorModal('Issue clearing cache date.<br />' + contactString);
        return Promise.resolve();
      });
    await localforage.removeItem('Cards')
      .catch((err) => {
        openErrorModal('Issue clearing Cards cache.<br />' + contactString);
        return Promise.resolve();
      });
    await localforage.removeItem('DbCardSets')
      .catch((err) => {
        openErrorModal('Issue clearing Card Sets cache.<br />' + contactString);
        return Promise.resolve();
      });
    await localforage.removeItem('DbBundles')
      .catch((err) => {
        openErrorModal('Issue clearing Bundles cache.<br />' + contactString);
        return Promise.resolve();
      });
  }

  await localforage.setItem('DataLastUpdated', Date.now())
    .catch((err) => {
      openErrorModal('Issue updating cache.<br />' + contactString);
      return Promise.resolve();
    });

  return Promise.resolve();
}

export async function getAllCardsData(): Promise<boolean> {
  const storedCards: any = await localforage.getItem('Cards');

  if (storedCards == null) {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    };

    const response = await makeApiCall(Constants.cookies.get('refresh'), Constants.cookies.get('userId'), Constants.apiPath + 'cards', requestOptions)
      .catch((err) => {
        openErrorModal('Issue retrieving card data.<br />' + contactString);
        return Promise.resolve(null);
      });

    if (response == null) {
      return Promise.resolve(false);
    }
    else if (!response.ok) {
      const error = response?.status;
      openErrorModal('Issue retrieving card data.<br />' +
        (error == 401 ? '<br />Try logging out and logging back in.<br />If this persists, ' + lowercaseContactString : '') +
        (error == 400 ? '<br />You have been inactive for a long time. Please log out and back in.<br /><br />If this persists, ' + lowercaseContactString : '') +
        (error != 401 && error != 400 ? '<br />' + contactString : ''));
      return Promise.resolve(false);
    }

    const cardsData = await response.json();

    for (const card of cardsData) {
      let firstprint = Constants.DATE_MAX;
      const allprints: Set<Date> = new Set<Date>();
      const allrarities: Set<string> = new Set<string>();
      Object.assign(altIdMap, { [card.id]: card.id });
      Object.assign(cards, { [card.id]: {
        id: card.id,
        name: card.name,
        desc: card.desc,
        type: card.type,
        subtype: card.subtype,
        attribute: card.attribute,
        level: card.level,
        atk: card.atk,
        def: card.def,
        linkmarkers: card.linkmarkers,
        scale: card.scale,
        card_sets: card.card_sets.map((cardset: any) => {
          const releaseDate = new Date(cardSetDates[cardset.set_name]);
          allprints.add(releaseDate);
          allrarities.add(cardset.set_rarity);
          if (releaseDate < firstprint) firstprint = releaseDate;
          return {
            set_name: cardset.set_name,
            set_code: cardset.set_code,
            set_rarity: cardset.set_rarity,
            set_date: releaseDate,
          };
        })
          .sort((a: SetCardInfo, b: SetCardInfo) => {
            if (a.set_date > b.set_date) return 1;
            else if (a.set_date < b.set_date) return -1;
            else if (a.set_name > b.set_name) return 1;
            else if (a.set_name < b.set_name) return -1;
            else if (Constants.RarityLevel[a.set_rarity] > Constants.RarityLevel[b.set_rarity]) return 1;
            else if (Constants.RarityLevel[a.set_rarity] < Constants.RarityLevel[b.set_rarity]) return -1;
            return 0;
          }),
        alt_ids: card.alt_ids?.map((altid: any) => {
          Object.assign(altIdMap, { [Number(altid)]: card.id });
          return Number(altid);
        }),
        first_print: firstprint,
        all_prints: Array.from(allprints),
        all_rarities: Array.from(allrarities),
      } });
    }

    const res = await localforage.setItem('Cards', cards)
      .catch((err) => {
        openErrorModal('Issue caching card data.<br />' + contactString);
        return Promise.resolve(null);
      });

    return Promise.resolve(true); // We still got the cards so we can carry on even if the store failed
  }
  else {
    Object.entries(storedCards).forEach((entry: [string, any]) => {
      Object.assign(altIdMap, { [entry[1].id]: entry[1].id });
      if (entry[1].alt_ids != null) {
        entry[1].alt_ids.forEach((id: any) => {
          Object.assign(altIdMap, { [Number(id)]: entry[1].id });
        });
      }
      Object.assign(cards, { [Number(entry[0])]: entry[1] });
    });
    return Promise.resolve(true);
  }
}

export async function getAllSetsData(): Promise<boolean> {
  const storedCardSets = await localforage.getItem('DbCardSets');
  const storedBundles = await localforage.getItem('DbBundles');

  if (storedCardSets == null || storedBundles == null) {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    };

    const setsResponse = await makeApiCall(Constants.cookies.get('refresh'), Constants.cookies.get('userId'), Constants.apiPath + 'card-set', requestOptions) // GET the packs, check for errors
      .catch((err) => {
        openErrorModal('Issue retrieving sets.<br />' + contactString);
        return Promise.resolve(null);
      });

    if (setsResponse == null) {
      return Promise.resolve(false);
    }
    else if (!setsResponse.ok) {
      const error = setsResponse?.status;
      openErrorModal('Issue retrieving sets.<br />' +
        (error == 401 ? '<br />Try logging out and logging back in.<br />If this persists, ' + lowercaseContactString : '') +
        (error == 400 ? '<br />You have been inactive for a long time. Please log out and back in.<br /><br />If this persists, ' + lowercaseContactString : '') +
        (error != 401 && error != 400 ? '<br />' + contactString : ''));
      return Promise.resolve(false);
    }

    const setsData = await setsResponse.json();

    setsData.forEach((cs: DbCardSet) => {
      if (cs.pack_pulling_allowed) { // Each duplicate prefix only has 0 or 1 pullable entry
        Object.assign(cardSets, {
          [cs.prefix]: {
            name: cs.name,
            prefix: cs.prefix,
            product_category: 'set',
            type: cs.type,
            release_date: new Date(cs.release_date),
            box_odds_enabled: cs.box_odds_enabled,
            pack_count: cs.pack_count,
          },
        });
      }

      Object.assign(cardSetDates, { [cs.name]: new Date(cs.release_date) });
    });

    const bundlesResponse = await makeApiCall(Constants.cookies.get('refresh'), Constants.cookies.get('userId'), Constants.apiPath + 'bundles', requestOptions) // GET the bundles, check for errors
      .catch((err) => {
        openErrorModal('Issue retrieving bundles.<br />' + contactString);
        return Promise.resolve(null);
      });

    if (bundlesResponse == null) {
      return Promise.resolve(false);
    }
    else if (!bundlesResponse.ok) {
      const error = bundlesResponse?.status;
      openErrorModal('Issue retrieving bundles.<br />' +
        (error == 401 ? '<br />Try logging out and logging back in.<br />If this persists, ' + lowercaseContactString : '') +
        (error == 400 ? '<br />You have been inactive for a long time. Please log out and back in.<br /><br />If this persists, ' + lowercaseContactString : '') +
        (error != 401 && error != 400 ? '<br />' + contactString : ''));
      return Promise.resolve(false);
    }

    const bundlesData = await bundlesResponse.json();

    bundlesData.forEach((cs: any) => {
      Object.assign(cardSets, {
        [cs.prefix]: {
          name: cs.name,
          prefix: cs.prefix,
          product_category: cs.product_category,
          type: cs.type,
          release_date: new Date(cs.release_date),
        },
      });

      if (cs.product_category == 'tin') {
        Object.assign(bundleLinks, { [cs.name]: 'https://yugipedia.com/wiki/' +
        ((cs.type == 'Modern Collectible Tins' || cs.type == 'MiscB') ? encodeURI(cs.name) : encodeURI(cs.type)) });
      }
      Object.assign(cardSetDates, { [cs.name]: new Date(cs.release_date) });
    });

    const csres = await localforage.setItem('DbCardSets', setsData as DbCardSet[])
      .catch((err) => {
        openErrorModal('Issue caching sets.<br />' + contactString);
        return Promise.resolve(null);
      });

    if (csres == null) {
      return Promise.resolve(false);
    }

    const bres = await localforage.setItem('DbBundles', bundlesData as CardProduct[])
      .catch((err) => {
        openErrorModal('Issue caching sets.<br />' + contactString);
        return Promise.resolve(null);
      });

    if (bres == null) {
      return Promise.resolve(false);
    }
    return Promise.resolve(true);
  }
  else {
    (storedCardSets as DbCardSet[]).forEach((cs: DbCardSet) => {
      if (cs.pack_pulling_allowed) { // Each duplicate prefix only has 0 or 1 pullable entry
        Object.assign(cardSets, {
          [cs.prefix]: {
            name: cs.name,
            prefix: cs.prefix,
            product_category: 'set',
            type: cs.type,
            release_date: new Date(cs.release_date),
            box_odds_enabled: cs.box_odds_enabled,
            pack_count: cs.pack_count,
          },
        });
      }
      Object.assign(cardSetDates, { [cs.name]: new Date(cs.release_date) });
    });

    (storedBundles as CardProduct[]).forEach((cs: CardProduct) => {
      Object.assign(cardSets, {
        [cs.prefix]: {
          name: cs.name,
          prefix: cs.prefix,
          product_category: cs.product_category,
          type: cs.type,
          release_date: new Date(cs.release_date),
        },
      });
      if (cs.product_category == 'tin') {
        Object.assign(bundleLinks, { [cs.name]: 'https://yugipedia.com/wiki/' +
        ((cs.type == 'Modern Collectible Tins' || cs.type == 'MiscB') ? encodeURI(cs.name) : encodeURI(cs.type)) });
      }
      Object.assign(cardSetDates, { [cs.name]: new Date(cs.release_date) });
    });
    return Promise.resolve(true);
  }
}

export function filteredCardSearch(searchField: string, searchTerm: string, filters: { fieldName: string, type: string, value: any[] }[],
  sortField: string, sortAsc: boolean, binder: BinderCard[] | null, banlist: { [id: number]: number } | null, banlistDates: Date[] | null) {
  searchTerm = searchTerm.toLowerCase();

  const result = (binder != null ? binder : Object.values(cards))
    .filter((card: BinderCard | Card) => {
      if (filters.length > 0) {
        for (let i: number = 0; i < filters.length; i++) {
          const filter = filters[i];
          const fieldKey = filter.fieldName as keyof (BinderCard | Card);

          if (!(((filter.type == '1:N' && filter.value.includes(card[fieldKey])) ||
            (filter.type == 'N:N' && (card[fieldKey]! as any[]).some((f: string) => filter.value.includes(f))) ||
            (filter.type == 'Range' && filter.value[0] <= (card[fieldKey]! as Date | number) &&
              (card[fieldKey]! as Date | number) <= filter.value[1]) ||
            (filter.type == 'RangeArray' && (card[fieldKey]! as Date[] | number[]).some((v: Date | number) =>
              filter.value[0] <= v && v <= filter.value[1])) ||
            (filter.type == 'Banlist' && (banlist == null ||
              (banlistDates != null && card.all_prints.some((d: Date) => d > banlistDates[0] && d < banlistDates[1])) &&
              ((banlist[card.id] !== undefined && filter.value.includes(banlist[card.id])) ||
                (banlist[card.id] === undefined && filter.value.includes(3))))) ||
            (filter.type == 'N:Nx' && filter.value.every((f: string) => card[fieldKey] !== undefined &&
              (card[fieldKey]! as any[]).includes(f))) ||
            (card.card_sets.some((set: SetCardInfo) => filter.value.includes(set.set_name)))) &&
            (searchTerm == '' ||
              ((searchField == 'name' || searchField == 'all') && card.name.toLowerCase().includes(searchTerm)) ||
              ((searchField == 'desc' || searchField == 'all') && card.desc.toLowerCase().includes(searchTerm))))) {
            return false;
          }
        }

        return true;
      }
      else if (searchTerm == '' ||
        ((searchField == 'name' || searchField == 'all') && card.name.toLowerCase().includes(searchTerm)) ||
        ((searchField == 'desc' || searchField == 'all') && card.desc.toLowerCase().includes(searchTerm))) {
        return true;
      }
    })
    .sort((a: BinderCard | Card, b: BinderCard | Card) => {
      if (searchTerm != '' && searchField == 'all') { // We only need to float name matches in the 'all' case, as anything else will be restricted to expected matches anyways
        const aMatch = a.name.toLowerCase().includes(searchTerm);
        const bMatch = b.name.toLowerCase().includes(searchTerm);
        if (aMatch && !bMatch) return -1;
        else if (!aMatch && bMatch) return 1;
        else {
          const aExact = a.name.toLowerCase() == searchTerm;
          const bExact = b.name.toLowerCase() == searchTerm;
          if (aExact && !bExact) return -1;
          else if (!aExact && bExact) return 1;
        }
      }

      if (a.type > b.type) return 1;
      else if (a.type < b.type) return -1;

      if (sortField != 'name') {
        const fieldKey = sortField as keyof (BinderCard | Card);
        if (a[fieldKey] != null && b[fieldKey] != null) {
          if (a[fieldKey]! > b[fieldKey]!) return sortAsc ? 1 : -1;
          else if (a[fieldKey]! < b[fieldKey]!) return sortAsc ? -1 : 1;
        }
        else if (a[fieldKey] == null && b[fieldKey] != null) return sortAsc ? -1 : 1;
        else if (a[fieldKey] != null && b[fieldKey] == null) return sortAsc ? 1 : -1;
      }

      if (a.type == 'Monster') { // We know both are the same type at this point
        if (a.subtype.length > 1 && b.subtype.length > 1) {
          if (Constants.TypeGroup[a.subtype.slice(1).join()] > Constants.TypeGroup[b.subtype.slice(1).join()]) return 1;
          else if (Constants.TypeGroup[a.subtype.slice(1).join()] < Constants.TypeGroup[b.subtype.slice(1).join()]) return -1;
        }
        else if (a.subtype.length < 2 && b.subtype.length > 1) return -1;
        else if (a.subtype.length > 1 && b.subtype.length < 1) return 1;
      }
      else {
        if (a.subtype[0] > b.subtype[0]) return 1;
        else if (a.subtype[0] < b.subtype[0]) return -1;
      }

      if (a.name > b.name) return sortField == 'name' && !sortAsc ? -1 : 1; // If sorting name and descending then flip these
      else if (a.name < b.name) return sortField == 'name' && !sortAsc ? 1 : -1;

      return 0;
    });

  return result;
}

export function allCardSearch(searchField: string, searchTerm: string, sortField: string, sortAsc: boolean) {
  searchTerm = searchTerm.toLowerCase();
  const results = Object.values(cards)
    .filter((card: Card) => {
      if (searchTerm == '') return true;
      if ((searchField == 'name' && card.name.toLowerCase().includes(searchTerm)) ||
        (searchField == 'set_name' && card.card_sets.some((set) => set.set_name.toLowerCase().includes(searchTerm))) ||
        (searchField == 'desc' && card.desc.toLowerCase().includes(searchTerm))) {
        return true;
      }
      else if (searchField == 'all' && (card.name.toLowerCase().includes(searchTerm) || card.desc.toLowerCase().includes(searchTerm))) {
        return true;
      }

      return false;
    })
    .sort((a: Card, b: Card) => {
      if (searchTerm != '' && searchField == 'all') { // We only need to float name matches in the 'all' case, as anything else will be restricted to expected matches anyways
        const aMatch = a.name.toLowerCase().includes(searchTerm);
        const bMatch = b.name.toLowerCase().includes(searchTerm);
        if (aMatch && !bMatch) return -1;
        else if (!aMatch && bMatch) return 1;
        else {
          const aExact = a.name.toLowerCase() == searchTerm;
          const bExact = b.name.toLowerCase() == searchTerm;
          if (aExact && !bExact) return -1;
          else if (!aExact && bExact) return 1;
        }
      }

      if (a.type > b.type) return 1;
      else if (a.type < b.type) return -1;

      if (sortField != 'name') {
        const fieldKey = sortField as keyof Card;
        if (a[fieldKey] != null && b[fieldKey] != null) {
          if (a[fieldKey]! > b[fieldKey]!) return sortAsc ? 1 : -1;
          else if (a[fieldKey]! < b[fieldKey]!) return sortAsc ? -1 : 1;
        }
        else if (a[fieldKey] == null && b[fieldKey] != null) return sortAsc ? -1 : 1;
        else if (a[fieldKey] != null && b[fieldKey] == null) return sortAsc ? 1 : -1;
      }

      if (a.type == 'Monster') { // We know both are the same type at this point
        if (a.subtype.length > 1 && b.subtype.length > 1) {
          if (Constants.TypeGroup[a.subtype.slice(1).join()] > Constants.TypeGroup[b.subtype.slice(1).join()]) return 1;
          else if (Constants.TypeGroup[a.subtype.slice(1).join()] < Constants.TypeGroup[b.subtype.slice(1).join()]) return -1;
        }
        else if (a.subtype.length < 2 && b.subtype.length > 1) return -1;
        else if (a.subtype.length > 1 && b.subtype.length < 1) return 1;
      }
      else {
        if (a.subtype[0] > b.subtype[0]) return 1;
        else if (a.subtype[0] < b.subtype[0]) return -1;
      }

      if (a.name > b.name) return sortField == 'name' && !sortAsc ? -1 : 1; // If sorting name and descending then flip these
      else if (a.name < b.name) return sortField == 'name' && !sortAsc ? 1 : -1;

      return 0;
    });

  return results;
}

export function binderCardSearch(binder: BinderCard[], searchField: string, searchTerm: string, sortField: string, sortAsc: boolean, collapsePrints: boolean) {
  searchTerm = searchTerm.toLowerCase();
  let totalCardCount: number = 0;
  if (collapsePrints) {
    binder = Object.values(binder.reduce((prints: any, card) => {
      if (typeof(prints[card.id]) == 'undefined') prints[card.id] = card; // This should already be sorted as it takes the first instance of each
      else {
        const collapsedCard = { ...prints[card.id] };
        collapsedCard.count += card.count;
        if (collapsedCard.set != card.set) collapsedCard.set = 'Multiple Sets';
        if (collapsedCard.rarity != card.rarity) collapsedCard.rarity = 'Multiple Rarities';
        prints[card.id] = collapsedCard;
      }
      return prints;
    }, {}) as BinderCard[]);
  }
  const results = binder
    .filter((card: BinderCard) => { // This is separate from the Card one because we have other fields to consider
      if (searchTerm == '') {
        totalCardCount += card.count;
        return true;
      }
      if ((searchField == 'name' && card.name.toLowerCase().includes(searchTerm)) ||
        (searchField == 'set' && card.set.toLowerCase().includes(searchTerm)) ||
        (searchField == 'rarity' && card.rarity.toLowerCase().includes(searchTerm))) {
        totalCardCount += card.count;
        return true;
      }
      else if (searchField == 'all' && (card.name.toLowerCase().includes(searchTerm) || card.desc.toLowerCase().includes(searchTerm))) {
        totalCardCount += card.count;
        return true;
      }

      return false;
    })
    .sort((a: BinderCard, b: BinderCard) => {
      if (searchTerm != '' && searchField == 'all') { // We only need to float name matches in the 'all' case, as anything else will be restricted to expected matches anyways
        const aMatch = a.name.toLowerCase().includes(searchTerm);
        const bMatch = b.name.toLowerCase().includes(searchTerm);
        if (aMatch && !bMatch) return -1;
        else if (!aMatch && bMatch) return 1;
        else {
          const aExact = a.name.toLowerCase() == searchTerm;
          const bExact = b.name.toLowerCase() == searchTerm;
          if (aExact && !bExact) return -1;
          else if (!aExact && bExact) return 1;
        }
      }

      if (sortField == 'rarity') {
        if (a.rarity != null && b.rarity != null) {
          if (Constants.RarityLevel[a.rarity!] > Constants.RarityLevel[b.rarity!]) return sortAsc ? 1 : -1;
          else if (Constants.RarityLevel[a.rarity!] < Constants.RarityLevel[b.rarity]) return sortAsc ? -1 : 1;
        }
        else if (a.rarity == null && b.rarity != null) return sortAsc ? -1 : 1;
        else if (a.rarity != null && b.rarity == null) return sortAsc ? 1 : -1;
      }
      else if (sortField != 'name') {
        const fieldKey = sortField as keyof BinderCard;
        if (a[fieldKey] != null && b[fieldKey] != null) {
          if (a[fieldKey]! > b[fieldKey]!) return sortAsc ? 1 : -1;
          else if (a[fieldKey]! < b[fieldKey]!) return sortAsc ? -1 : 1;
        }
        else if (a[fieldKey] == null && b[fieldKey] != null) return sortAsc ? -1 : 1;
        else if (a[fieldKey] != null && b[fieldKey] == null) return sortAsc ? 1 : -1;
      }

      if (sortField != 'name') {
        if (a.type > b.type) return 1;
        else if (a.type < b.type) return -1;

        if (a.type == 'Monster') { // We know both are the same type at this point
          if (a.subtype.length > 1 && b.subtype.length > 1) {
            if (Constants.TypeGroup[a.subtype.slice(1).join()] > Constants.TypeGroup[b.subtype.slice(1).join()]) return 1;
            else if (Constants.TypeGroup[a.subtype.slice(1).join()] < Constants.TypeGroup[b.subtype.slice(1).join()]) return -1;
          }
          else if (a.subtype.length < 2 && b.subtype.length > 1) return -1;
          else if (a.subtype.length > 1 && b.subtype.length < 1) return 1;
        }
        else {
          if (a.subtype[0] > b.subtype[0]) return 1;
          else if (a.subtype[0] < b.subtype[0]) return -1;
        }
      }

      if (a.name > b.name) return sortField == 'name' && !sortAsc ? -1 : 1; // If sorting name and descending then flip these
      else if (a.name < b.name) return sortField == 'name' && !sortAsc ? 1 : -1;

      return 0;
    });

  return { cards: results, totalCardCount: totalCardCount };
}

export async function getBinderCards(binderId: string, collapsePrints: boolean) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + Constants.cookies.get('token') },
  };

  return makeApiCall(Constants.cookies.get('refresh'), Constants.cookies.get('userId'), Constants.apiPath + 'binder/' + binderId, requestOptions)
    .then(async (response) => {
      if (!response.ok) {
        const error = response.status;
        openErrorModal('Issue retrieving binder.<br />' +
          (error == 401 ? '<br />Try logging out and logging back in.<br />If this persists, ' + lowercaseContactString : '') +
          (error == 400 ? '<br />You have been inactive for a long time. Please log out and back in.<br /><br />If this persists, ' + lowercaseContactString : '') +
          (error != 401 && error != 400 ? '<br />' + contactString : ''));
        return Promise.reject(error);
      }

      const data = await response.json();

      if (data != null) {
        if (collapsePrints) {
          const resultObj: { [key: number]: BinderCard } = {};
          for (let i: number = 0; i < data.cards.length; i++) {
            const card = data.cards[i];
            if (Number(card.count) < 1) continue; // Toss 0/neg counts
            const printdate = new Date(cardSetDates[card.set]);
            if (resultObj[card.cardId] !== undefined) {
              resultObj[card.cardId] = {
                ...resultObj[card.cardId],
                count: resultObj[card.cardId].count + Number(card.count),
                all_prints: (resultObj[card.cardId].all_prints.includes(printdate) ?
                  resultObj[card.cardId].all_prints :
                  [...resultObj[card.cardId].all_prints, printdate]),
                all_rarities: (resultObj[card.cardId].all_rarities.includes(card.rarity) ?
                  resultObj[card.cardId].all_rarities :
                  [...resultObj[card.cardId].all_rarities, card.rarity]),
                all_counts: { ...resultObj[card.cardId].all_counts, [card.rarity]: resultObj[card.cardId].all_counts[card.rarity]??0 + Number(card.count) },
              } as BinderCard;
            }
            else {
              resultObj[card.cardId] = {
                ...cards[card.cardId],
                set: card.set,
                code: card.code,
                rarity: card.rarity,
                count: Number(card.count),
                all_prints: [new Date(cardSetDates[card.set])],
                all_rarities: [card.rarity],
                all_counts: { [card.rarity]: Number(card.count) },
              } as BinderCard;
            }
          }
          return Object.values(resultObj);
        }
        else {
          return data.cards.map((card: any) => {
            return {
              ...cards[card.cardId],
              set: card.set,
              code: card.code,
              rarity: card.rarity,
              count: Number(card.count),
            };
          });
        }
      }
      else {
        return [];
      }
    });
}
