import { useState, useEffect, useContext } from 'react';
import { AppContext } from '../utils/Contexts';
import { imagePath } from '../utils/Constants';
import ReactDOM from 'react-dom';

interface DropdownProps {
  uniqueID: string,
  isSelector: boolean,
  disabledVar: boolean,
  optionsList: DropdownOption[],
  startingOption: DropdownOption | null,
  defaultValue: string | number,
  defaultDisplay: string,
  nonSelectorDisplay: any, // This is so we can take in custom button displays but still reuse everything else. This only applies to non-selector dropdowns
  width: string,
  stateVar: string | number,
  dispatch: any,
  style: any,
  title: string,
  ulStyle?: any,
  firstOptionItalic?: boolean,
  stateGroupVar?: string,
}

const currentOpenSubdropdownId: { value: string } = { value: '' }; // Shared amongst all dropdowns, but you physically can't have multiple open

export default function BlackDropdown(props: DropdownProps) {

  const clickClass = props.uniqueID + '-click';
  const listID = props.uniqueID + '-dropdown-list';
  const labelID = props.uniqueID + '-dropdown-label';
  const buttonID = props.uniqueID + '-dropdown-button';
  const arrowID = props.uniqueID + '-dropdown-arrow';

  const [labelText, setLabelText] = useState(props.defaultDisplay);
  const { isScreen950Query, isScreen1300Query } = useContext(AppContext);
  const [isScreen950, setIsScreen950] = useState(isScreen950Query.matches);

  useEffect(() => { // Mount
    isScreen950Query.addEventListener('change', checkScreen950); // To avoid re-rendering the whole page and losing data, we have to listen and state change on the sublevel
    window.addEventListener('click', closeDropdown, true);
    if (props.startingOption != null && props.startingOption.value != '') {
      props.dispatch(props.startingOption.value);
      setLabelText(props.startingOption.text);
      document.getElementById(buttonID)!.dataset.value = String(props.startingOption.value);
    }
    else {
      document.getElementById(buttonID)!.dataset.value = String(props.defaultValue);
    }

    return () => { // Unmount
      isScreen950Query.removeEventListener('change', checkScreen950);
      window.removeEventListener('click', closeDropdown, true);
    };
  }, []);

  useEffect(() => {
    if (props.isSelector) setLabelText(findOptionText(props.stateVar));
  }, [props.stateVar]);

  useEffect(() => {
    if (props.isSelector) setLabelText(findOptionText(props.stateVar));
  }, [props.optionsList]);

  function findOptionText(value: string | number) {
    for (let i = 0; i < props.optionsList.length; i++) {
      const option = props.optionsList[i];
      if (option.subItems !== undefined) {
        for (let j = 0; j < option.subItems.length; j++) {
          if (option.subItems[j].value == value) return option.subItems[j].text??props.defaultDisplay;
        }
      }
      else if (option.value == value) return option.text??props.defaultDisplay;
    }
    return props.defaultDisplay;
  }

  function checkScreen950() {
    setIsScreen950(isScreen950Query.matches);
  }

  function openDropdown(event: any) {
    event.stopPropagation();
    document.getElementById(listID)?.classList.toggle('show');
    if (props.isSelector) document.getElementById(arrowID)?.classList.toggle('flip');
    document.getElementById(buttonID)?.classList.toggle('open-bottom-corners');
  }

  function closeDropdown(event: MouseEvent) {
    if (event !== null && event.target !== null && !((event.target as Element).classList.contains(clickClass))) {
      const dropdown = document.getElementById(listID)!;
      if (dropdown.classList.contains('show')) {
        dropdown.classList.remove('show');
        document.getElementById(buttonID)?.classList.remove('open-bottom-corners');
        if (props.isSelector) document.getElementById(arrowID)?.classList.remove('flip');
        closeSubdropdown(null);
      }
    }
  }

  function openSubdropdown(event: any) {
    if (event !== null && event.currentTarget !== null && ((event.currentTarget as Element).classList.contains('subdropdown')) && !((event.currentTarget as Element).classList.contains('show-subdropdown'))) {
      event.stopPropagation();
      (event.currentTarget as Element).classList.toggle('show-subdropdown');
      const subdropdown = document.getElementById(event.currentTarget.id + '-sub')!;
      const rect = event.currentTarget.getBoundingClientRect();
      const leftPos = (Number(rect.x) + (Number(event.currentTarget.parentNode.clientWidth) - 1));
      subdropdown.style.top = (Number(rect.top) - (isScreen1300Query.matches ? 45 : 75)) + 'px';
      subdropdown.style.left = leftPos + 'px';
      subdropdown.style.maxWidth = (document.body.clientWidth - leftPos - 3) + 'px';
      subdropdown.style.display = 'block';
      closeSubdropdown(null);
      currentOpenSubdropdownId.value = event.currentTarget.id;
    }
  }

  function closeSubdropdown(event: any) {
    if (currentOpenSubdropdownId.value != '') {
      const currentOpenSubdropdown = document.getElementById(currentOpenSubdropdownId.value);
      if (currentOpenSubdropdown !== undefined && currentOpenSubdropdown != null && currentOpenSubdropdown.classList.contains('show-subdropdown')) {
        currentOpenSubdropdown.classList.remove('show-subdropdown');
        const subdropdown = document.getElementById(currentOpenSubdropdownId.value + '-sub')!;
        subdropdown.style.display = 'none';
        currentOpenSubdropdownId.value = '';
      }
    }
  }

  function updateValue(e: any) {
    if (e.currentTarget.dataset.value == 'group') return;

    if (props.stateGroupVar === undefined) props.dispatch(e.currentTarget.dataset.value);
    else props.dispatch(e.currentTarget.dataset.value, e.currentTarget.dataset.group);
    document.getElementById(buttonID)?.classList.remove('open-bottom-corners');
    document.getElementById(listID)!.classList.remove('show');
    if (props.isSelector) {
      document.getElementById(arrowID)?.classList.remove('flip');
      setLabelText(e.currentTarget.innerText);
    }
    closeSubdropdown(null);
  }

  function triggerCallback(e: any) {
    if (e.currentTarget.dataset.value == 'group'/* || e.currentTarget.dataset.value == ''*/) return; // Not 100% sure on any consequences or removing the empty check, but 'Ungrouped' has to pass this

    props.optionsList[e.currentTarget.dataset.index].callback(e.currentTarget.dataset.value);
    document.getElementById(listID)!.classList.remove('show');
    document.getElementById(buttonID)?.classList.remove('open-bottom-corners');
    if (props.isSelector) document.getElementById(arrowID)?.classList.remove('flip');
    closeSubdropdown(null);
  }

  if (props.isSelector) {
    return (
      <div className={'black-dropdown ' + clickClass} style={props.style}>
        <button id={buttonID} className={'black-dropdown-button ' + clickClass} title={props.title} onClick={openDropdown} disabled={props.disabledVar}
          style={{ width: props.width, height: isScreen950 ? 'fit-content' : '30px', minHeight: isScreen950 ? '26px' : 'initial' }}>
          <span id={labelID} className={clickClass}
            style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', fontStyle: props.firstOptionItalic && props.stateVar == props.optionsList[0]?.value ? 'italic' : 'initial' }}>
            {labelText}
          </span>
          <img id={arrowID} className={'black-dropdown-arrow ' + clickClass} src={imagePath + 'DownArrowWhite.png'} width='14px' height='14px' />
        </button>
        <ul id={listID} className={clickClass} style={props.ulStyle ? props.ulStyle : { minWidth: props.width }}>
          <div onScroll={closeSubdropdown} style={{ overflowY: 'auto', overflowX: props.stateGroupVar !== undefined ? 'hidden' : 'initial', maxHeight: '33vh' }}>
            {props.optionsList.sort((a: DropdownOption, b: DropdownOption) => {
              if (a.text == '') return 1;
              else if (b.text == '') return -1;
              else return 0;
            }).map((option: DropdownOption, index: number) => {
              if (option.value != 'group') {
                return <li className={clickClass} onClick={updateValue} data-value={option.value} data-group={''} key={listID + index + option.value} title={option.text}
                  style={{ whiteSpace: 'pre-wrap', fontStyle: props.firstOptionItalic && option.value == props.optionsList[0]?.value ? 'italic' : 'initial' }}>
                  <div className={clickClass} style={{ display: 'flex', width: props.stateGroupVar !== undefined ? `calc(${props.width} - 35px)` : 'initial' }}>
                    <span className={clickClass} style={props.stateGroupVar !== undefined ? { whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' } : {}}>{option.text}</span>
                  </div>
                </li>;
              }
              else if (option.text == '') {
                return option.subItems?.map((suboption: DropdownOption) => {
                  return <li className={clickClass} onClick={updateValue} onPointerEnter={closeSubdropdown}
                    data-value={suboption.value} data-group={''} key={listID + index + suboption.value} title={suboption.text}
                    style={{ whiteSpace: 'pre-wrap', fontStyle: props.firstOptionItalic && suboption.value == props.optionsList[0]?.value ? 'italic' : 'initial' }}>
                    <div className={clickClass} style={{ display: 'flex', width: props.stateGroupVar !== undefined ? `calc(${props.width} - 35px)` : 'initial' }}>
                      <span className={clickClass} style={props.stateGroupVar !== undefined ? { whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' } : {}}>{suboption.text}</span>
                    </div>
                  </li>;
                });
              }
              else {
                return <li id={listID + '-' + index} className={clickClass + ' subdropdown'} onPointerEnter={openSubdropdown} data-value={option.value}
                  data-group={''} data-index={index} key={listID + '-' + index}
                  style={{ cursor: 'default', display: 'flex', alignItems: 'center', minWidth: `calc(${props.width} - 36px)` }} title={option.text}>
                  <div className={clickClass} style={{ display: 'flex', width: 'calc(100% - 21px)' }}>
                    <span className={clickClass} style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>{option.text}</span>
                  </div>
                  <img className={'black-dropdown-arrow ' + clickClass} src={imagePath + 'DownArrowWhite.png'} width='14px' height='14px' style={{ transform: 'rotate(-90deg)' }} />

                  {option.subItems !== undefined && option.subItems.length > 0 ?
                    ReactDOM.createPortal(<ul id={listID + '-' + index + '-sub'} className={clickClass} style={{ minWidth: props.width, overflowY: 'auto' }}>
                      {option.subItems.map((suboption: DropdownOption) => {
                        return <li className={clickClass} onClick={updateValue} data-value={suboption.value} data-group={option.text} key={listID + index + suboption.value}
                          style={{ whiteSpace: 'pre-wrap', fontStyle: 'initial' }} title={suboption.text}>{suboption.text}</li>;
                      })}
                    </ul>,
                      document.getElementById('submenu-div')!,
                    ) : ''}
                </li>;
              }
            })}
          </div>
        </ul>
      </div>
    );
  }
  else {
    return (
      <div className={'black-dropdown ' + clickClass} style={props.style}>
        <button id={buttonID} className={'black-dropdown-button ' + clickClass} title={props.title} onClick={openDropdown} disabled={props.disabledVar}
          style={{ width: props.width, height: isScreen950 ? 'fit-content' : '30px', minHeight: isScreen950 ? '26px' : 'initial', padding: '0px' }}>
          {props.nonSelectorDisplay}
        </button>
        <ul id={listID} className={clickClass} style={props.ulStyle ? props.ulStyle : { minWidth: props.width }}>
          <div style={{ overflowY: 'auto', maxHeight: '33vh' }}>
            {props.optionsList.map((option: DropdownOption, index: number) => {
              return <li className={clickClass} onClick={triggerCallback} data-value={option.value} data-index={index} key={listID + index + option.value}
                style={{ whiteSpace: 'pre-wrap', fontStyle: props.firstOptionItalic && option.value == props.optionsList[0]?.value ? 'italic' : 'initial' }}>{option.text}</li>;
            })}
          </div>
        </ul>
      </div>
    );
  }
}
