import { useEffect } from 'react';

const listenerCallbacks = new WeakMap(); // See https://dev.to/shubhamreacts/progressively-loading-images-in-react-40lg

let observer: any;

function handleIntersections(entries: any) {
  entries.forEach((entry: any) => {
    if (listenerCallbacks.has(entry.target)) {
      const cb = listenerCallbacks.get(entry.target);

      if (entry.isIntersecting || entry.intersectionRatio > 0) {
        observer.unobserve(entry.target);
        listenerCallbacks.delete(entry.target);
        cb();
      }
    }
  });
}

function getIntersectionObserver() {
  if (observer === undefined) {
    observer = new IntersectionObserver(handleIntersections, {
      rootMargin: '100px',
      threshold: 0.15,
    });
  }
  return observer;
}

export function useIntersection(elem: any, callback: any) {
  useEffect(() => {
    const target = elem.current;
    const observer = getIntersectionObserver();
    listenerCallbacks.set(target, callback);
    observer.observe(target);

    return () => {
      listenerCallbacks.delete(target);
      observer.unobserve(target);
    };
  }, []);
}
