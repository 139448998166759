import './css/App.css';
import './css/Header.css';
import './css/Footer.css';
import './css/PackSelection.css';
import './css/OpenPack.css';
import './css/BinderManagement.css';
import './css/Builders.css';
import './css/Ads.css';
import PackOpener from './components/PackOpener';
import Header from './components/Header';
import Footer from './components/Footer';
import { useState, useEffect, useRef } from 'react';
import { cookies, mouseProps, setCookie, contactString } from './utils/Constants';
import { Router, Switch, Route, Redirect } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import Account from './components/Account';
import LoginAndRegistration from './components/LoginAndRegistration';
import BinderManagement from './components/BinderManagement';
import DeckBuilder from './components/DeckBuilder';
import PagePlaceholder from './components/PagePlaceholder';
import About from './components/About';
import PrivacyPolicy from './components/PrivacyPolicy';
import { createRoot } from 'react-dom/client';
import { AppContext } from './utils/Contexts';
import { openErrorModal, closeBasicModal } from './utils/BasicModalFuncs';
import ResetPassModal from './components/ResetPassModal';
import ResetPassLanding from './components/ResetPassLanding';
import VerifyLanding from './components/VerifyLanding';
import { clearUserDataLists, refreshUserDataLists, validateCache } from './utils/CardDataCache';
import BanlistBuilder from './components/BanlistBuilder';
import { Helmet } from 'react-helmet';
import Ad from './components/Ad';

export const history = createBrowserHistory();
export const reactPlugin = new ReactPlugin();
if (process.env.REACT_APP_YGOPROG_INSIGHTS_CONN != '') {
  const appInsights = new ApplicationInsights({
    config: {
      connectionString: process.env.REACT_APP_YGOPROG_INSIGHTS_CONN,
      extensions: [reactPlugin],
      extensionConfig: {
        [reactPlugin.identifier]: { history: history },
      },
    },
  });
  appInsights.loadAppInsights();
}

function App() {

  const [setsLoaded, setSetsLoaded] = useState(false);
  const [refreshRoute, setRefreshRoute] = useState(false);
  const [isScreen1300Query, setIsScreen1300Query] = useState(window.matchMedia('(max-width: 1300px)')); // Is screen 1300 or less
  const [isScreen1150Query, setIsScreen1150Query] = useState(window.matchMedia('(max-width: 1150px)')); // Is screen 1150 or less
  const [isScreen950Query, setIsScreen950Query] = useState(window.matchMedia('(max-width: 950px)')); // Is screen 950 or less
  const [isMainInputTouchQuery, setIsMainInputTouchQuery] = useState(window.matchMedia('(hover: none)'));
  const isMainInputTouchRef = useRef<boolean>(false);
  isMainInputTouchRef.current = isMainInputTouchQuery.matches;
  const isScreen950Ref = useRef<boolean>(false);
  isScreen950Ref.current = isScreen950Query.matches;
  let globalModalRoot: any = null;

  useEffect(() => { // Grab the card sets and populate the ambiguity list
    window.onerror = function(message, source, lineno, colno, error) {
      openErrorModal('Hmmm, we&apos;re not quite sure what happened here.<br />' + contactString);
    };

    isScreen950Query.addEventListener('change', () => {isScreen950Ref.current = isScreen950Query.matches;}); // Set the refs for callback enclosure
    isMainInputTouchQuery.addEventListener('change', () => {isMainInputTouchRef.current = isMainInputTouchQuery.matches;});

    document.addEventListener('mousemove', (event) => {
      mouseProps.mouseX = event.clientX;
      mouseProps.mouseY = event.clientY;

      if (!(isMainInputTouchRef.current || isScreen950Ref.current)) hover(event.target);
    });

    document.addEventListener('scroll', () => {
      const hoverTarget = document.elementFromPoint(mouseProps.mouseX, mouseProps.mouseY);
      if (hoverTarget && !(isMainInputTouchRef.current || isScreen950Ref.current)) {
        hover(hoverTarget);
      }
    });

    if (typeof (cookies.get('ignorePerformanceWarnings')) == 'undefined') {
      setCookie('ignorePerformanceWarnings', false);
    }

    if (cookies.get('user') !== undefined) refreshUserDataLists(); // If they're logged in, update their lists
    else clearUserDataLists();
    validateCache(setSetsLoaded, false);

    history.listen(() => {
      window.scrollTo({ top: 0, behavior: 'auto' });
    });

    if (cookies.get('darkModeOn') == 'true' && !document.body.classList.contains('dark-theme-bg')) {
      document.body.classList.add('dark-theme-bg');
      document.querySelector(':root')!.classList.add('dark-theme');
    }

    setCookie('darkModeOn', cookies.get('darkModeOn') == 'true');
    setCookie('ignorePerformanceWarnings', cookies.get('ignorePerformanceWarnings') == 'true');
    setCookie('autoflip', cookies.get('autoflip') == 'true');

    const wrapper = document.getElementById('App')!;
    wrapper.style.height = '100%';
    wrapper.style.minHeight = '100vh';
    const observer = new MutationObserver(function(mutations, observer) {
      wrapper.style.height = '100%';
      wrapper.style.minHeight = '100vh';
    });
    observer.observe(wrapper, {
      attributes: true,
      attributeFilter: ['style'],
    });
  }, []);

  function hover(targetElement: any) {

    let hoverElement = null as any;

    if (targetElement != null) {
      let testElement = targetElement;
      const main = document.body;
      while (testElement != null && hoverElement == null) {
        if (testElement.classList?.contains('hoverable')) {
          hoverElement = testElement;
        }
        else {
          testElement = testElement.parentElement;
        }
      }
    }

    // If the target and stored element are the same, return early
    // because setting it again is unnecessary.
    if (mouseProps.hoveredElement === hoverElement) {
      return;
    }

    // On first run, `hoveredElement` is undefined.
    if (mouseProps.hoveredElement) {
      mouseProps.hoveredElement.classList.remove('hover');
    }

    mouseProps.hoveredElement = hoverElement;
    if (hoverElement) {
      mouseProps.hoveredElement.classList.add('hover');
    }
  }

  function openModal(modalName: string) { // This must be done through direct react dom renders if we don't want to refresh the entire app
    globalModalRoot = createRoot(document.getElementById('global-modal-content')!);
    globalModalRoot.render(<LoginAndRegistration {...{ modal: true, firstPage: modalName, closeModal: closeGlobalModal }} />);

    const modalElement = document.getElementById('global-modal');
    if (modalElement != null) modalElement.style.display = 'flex';
  }

  function closeGlobalModal() {
    globalModalRoot?.unmount();

    const modal = document.getElementById('global-modal');
    if (modal != null) modal.style.display = 'none';
  }

  function resetCache() {
    setSetsLoaded(false);
    validateCache(setSetsLoaded, true);
  }

  return (
    <Router history={history}>
      <Helmet>
        <meta content="YGO Prog" property="og:title" />
        <meta
          content="YGO Prog is a tool for simulating Yu-Gi-Oh! pack opening, collection management, deck building, banlist building, and more!"
          property="og:description"
        />
        <meta content="https://www.ygoprog.com" property="og:url" />
        <meta
          name="description"
          content="YGO Prog is a tool for simulating Yu-Gi-Oh! pack opening, collection management, deck building, banlist building, and more!"
        />
        <title>YGO Prog</title>
      </Helmet>
      <AppContext.Provider value = {{ refreshRouteFunc: setRefreshRoute, refresh: refreshRoute, resetCache: resetCache,
        isScreen1300Query: isScreen1300Query, isScreen1150Query: isScreen1150Query, isScreen950Query: isScreen950Query, isMainInputTouchQuery: isMainInputTouchQuery }}>
        <div id='App' className='App'>
          <Header {...{ triggerModal: openModal, refreshRouteFunc: setRefreshRoute, refresh: refreshRoute }} />

          <div id='basic-modal' className='modal' style={{ zIndex: 9019 }}>
            <button className='modal-close' title='Close modal' onClick={closeBasicModal} />
            <div id='basic-modal-content' style={{ display: 'flex', justifyContent: 'center' }}>
              <div className='content-box modal-content' style={{ zIndex: 9020 }}>
                <h5 id='basic-modal-title' style={{ marginBottom: '0px' }}></h5>
                <p id='basic-modal-text' style={{ textAlign: 'center' }}></p>
                <button className='black-button' style={{ marginRight: '7px' }} title='Close modal' onClick={closeBasicModal}><span id='basic-modal-button'>Ok</span></button>
              </div>
            </div>
          </div>

          <ResetPassModal {...{ id: 'reset-pass-modal' }} />

          <div id='global-modal' className='modal'>
            <button className='modal-close' title='Close modal' onClick={closeGlobalModal} />
            <div id='global-modal-content' style={{ display: 'flex', justifyContent: 'center' }}></div>
          </div>

          <div id='card-hover-div'>
          </div>

          <div id='submenu-div' className='black-dropdown' style={{ zIndex: 7 }}>
          </div>

          {/* We generate unique keys so that when the user clicks the same page they're on (or do so from a subpage) they get dumped back at that page */}
          {setsLoaded ?
            <Switch>
              <Route path='/ProgSeries' component={() => <PagePlaceholder {...{ refresh: refreshRoute }} />} />
              <Route path='/PackOpener' component={() => <PackOpener {...{ refresh: refreshRoute }} />} />
              <Route path='/BinderManagement' component={() => <BinderManagement {...{ refresh: refreshRoute, openModalFunc: openModal }} />} />
              <Route path='/DeckBuilder' component={() => <DeckBuilder {...{ refresh: refreshRoute }} />} />
              <Route path='/BanlistBuilder' component={() => <BanlistBuilder {...{ refresh: refreshRoute }} />} />
              <Route path='/About' component={() => <About {...{ refresh: refreshRoute }} />} />
              <Route path='/Account' component={() => <Account {...{ refresh: refreshRoute, openModalFunc: openModal }} />} />
              <Route path='/PrivacyPolicy' component={() => <PrivacyPolicy {...{ refresh: refreshRoute }} />} />
              <Route path='/VerifyAccount/:token' component={() => <VerifyLanding {...{ refresh: refreshRoute }} />} />
              <Route path='/ResetPassword/:token' component={() => <ResetPassLanding {...{ refresh: refreshRoute }} />} />
              <Route path='/' component={() => <Redirect to='/PackOpener'/>} />
            </Switch> :
            <div style={{ margin: 'auto', textAlign: 'center' }}>
              <h1 style={{ paddingBottom: '0px', marginBottom: '0px' }}>Loading</h1>
              <img src={'/images/LoadingAnim.png'} width='150px' height='150px' style={{ margin: 'auto', marginTop: '5px', padding: '0' }} />
            </div>
          }

          <div id='footer-gap' style={{ flexGrow: 1 }}></div>

          <Footer />
        </div>
      </AppContext.Provider>
    </Router>
  );
}

export default (process.env.REACT_APP_YGOPROG_INSIGHTS_CONN != '') ? withAITracking(reactPlugin, App) : App;
