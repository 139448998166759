import { useState, useEffect, CSSProperties, useRef } from 'react';
import { useIntersection } from '../utils/IntersectionObserver';

interface LazyImageProps {
  src: string,
  width: number,
  height: number,
  alt: string,
  className: string,
  style: CSSProperties,
  onError: any,
  onLoad: any,
}

export default function LazyImage(props: LazyImageProps) {
  const [src, setSrc] = useState(props.src);
  const [isInView, setIsInView] = useState(false);
  const imgRef = useRef<any>();

  useIntersection(imgRef, () => {
    setIsInView(true);
  });

  useEffect(() => {
    setSrc(props.src);
  }, [props.src]);

  return (
    <div
      className={'' + props.className}
      ref={imgRef}>
      {isInView ? <img
        className='lazy-image'
        src={src}
        alt={props.alt}
        width={props.width > 0 ? props.width + 'px' : 'auto'}
        height={props.height > 0 ? props.height + 'px' : 'auto'}
        style={props.style}
        onLoad={props.onLoad}
        onError={props.onError}
      /> :
        <div
          className='lazy-image-container'
          style={{
            paddingBottom: props.height + 'px',
            width: '100%',
          }} />
      }
    </div>
    // <img className={props.className} src={src} alt={props.alt} width={props.width} height={props.height}
    //   onError={props.onError} onLoad={props.onLoad} style={{...props.style}} />
  );
}
