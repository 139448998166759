import { useState } from 'react';
import { cookies, apiPath, lowercaseContactString, contactString } from '../utils/Constants';
import makeApiCall from '../utils/ApiMiddleware';
import { openErrorModal } from '../utils/BasicModalFuncs';
import localforage from 'localforage';


export default function SaveAsBinderModal(props: { id: string, isRename: boolean, binderId: string, updateListDispatch: any }) {

  const [saveAsDisabled, setSaveAsDisabled] = useState(true);
  const [saving, setSaving] = useState(false);

  function closeSaveAsBinderModal() {
    document.getElementById(props.id)!.style.display = 'none';
    (document.getElementById(props.id + '-name')! as HTMLInputElement).value = '';
  }

  function saveBinder() {
    const newName = (document.getElementById(props.id + '-name')! as HTMLInputElement).value;
    if (newName == '') return;
    const requestOptions = props.isRename ? {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + cookies.get('token') },
      body: JSON.stringify({ name: newName }),
    } : {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + cookies.get('token') },
    };
    setSaving(true);
    makeApiCall(cookies.get('refresh'), cookies.get('userId'), apiPath + 'binder/' + (props.isRename ? props.binderId : newName), requestOptions)
      .then(async (response) => {
        setSaving(false);

        if (!response.ok) {
          const error = response.status;
          if (props.isRename) {
            openErrorModal('Issue renaming binder.<br />' +
            (error == 401 ? '<br />Try logging out and logging back in.<br />If this persists, ' + lowercaseContactString : '') +
            (error == 400 ? '<br />You have been inactive for a long time. Please log out and back in.<br /><br />If this persists, ' + lowercaseContactString : '') +
            (error != 401 && error != 400 ? '<br />' + contactString : ''));
          }
          else {
            openErrorModal('Issue creating binder.<br />' +
            (error == 401 ? '<br />Try logging out and logging back in.<br />If this persists, ' + lowercaseContactString : '') +
            (error == 400 ? '<br />You have been inactive for a long time. Please log out and back in.<br /><br />If this persists, ' + lowercaseContactString : '') +
            (error != 401 && error != 400 ? '<br />' + contactString : ''));
          }
          return Promise.reject(error);
        }

        const data = await response.json();

        localforage.getItem('binders').then((binders: any) => {
          if (props.isRename) {
            if (binders != null) {
              for (let i: number = 0; i < binders.length; i++) {
                if (binders[i].id == props.binderId) {
                  binders[i].name = newName;
                  break;
                }
              }
              localforage.setItem('binders', binders).then(() => {
                props.updateListDispatch();
                closeSaveAsBinderModal();
              })
                .catch(() => {
                  props.updateListDispatch();
                  openErrorModal('Issue saving binders after rename.<br />' + contactString);
                });
            }
            else {
              props.updateListDispatch();
              openErrorModal('Issue getting binders for rename.<br />' + contactString);
            }
          }
          else {
            if (binders != null) {
              localforage.setItem('binders', [...binders, { id: data._id, name: newName }]).then(() => {
                props.updateListDispatch(data._id);
                closeSaveAsBinderModal();
              })
                .catch(() => {
                  props.updateListDispatch();
                  openErrorModal('Issue saving binders after creation.<br />' + contactString);
                });
            }
            else {
              localforage.setItem('binders', [{ id: data._id, name: newName }]).then(() => {
                props.updateListDispatch(data._id);
                closeSaveAsBinderModal();
              })
                .catch(() => {
                  props.updateListDispatch();
                  openErrorModal('Issue saving binder after creation.<br />' + contactString);
                });
            }
          }
        })
          .catch(() => {
            props.updateListDispatch();
            openErrorModal('Issue getting binders for creation/rename.<br />' + contactString);
          });
      });
  }

  function checkBinderName(e: any) {
    if (e.target.value != '') setSaveAsDisabled(false);
    else setSaveAsDisabled(true);
  }

  return (
    <div id={props.id} className='modal'>
      <button className='modal-close' title='Close modal' onClick={closeSaveAsBinderModal} />
      <div className='content-box modal-content'>
        <h5 style={{ marginBottom: '0px' }}>{props.isRename ? 'Rename Binder' : 'New Binder'}</h5>
        <div className='vertical-form-text-input'>
          <label htmlFor={props.id + '-name'}>Name:</label>
          <div className='text-input'><input id={props.id + '-name'} type='text' onInput={checkBinderName} onKeyDown={(e) => {if (e.code === 'Enter') saveBinder();}} /></div>
        </div>
        {saving ?
          <img src={'/images/LoadingAnim.png'} width='30px' height='30px' style={{ margin: 'auto', verticalAlign: 'middle' }} /> :
          <button className='black-button' title={props.isRename ? 'Rename binder' : 'Create new binder'} onClick={saveBinder} disabled={saveAsDisabled}>
            <span>{props.isRename ? 'Rename' : 'Create'}</span>
          </button>}
      </div>
    </div>
  );
}
