import { memo } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { imagePath } from '../utils/Constants';

export const GroupManagementItem = memo(
  function GroupManagementItem(props: { groupName: string, indexInGroup: number, item: DropdownOption, dragTask: any, moveDraggedItem: any
    commitDraggedItem: any, setCurrentDragItem: any, toggleSelectItem: any, isSelected: boolean, titleCaseSingularType: string, isScreen950: boolean }) {
    const [{ isDragging }, drag] = useDrag(
      () => ({
        type: 'groupitem',
        item: () => {
          props.setCurrentDragItem(props.item.value.toString(), props.groupName, props.indexInGroup);
          return { id: props.item.value.toString() };
        },
        collect: (monitor) => ({
          isDragging: monitor.isDragging(),
        }),
        end: (item, monitor) => {
          const didDrop = monitor.didDrop();
          if (props.dragTask.flag != null) {
            clearTimeout(props.dragTask.flag);
            props.dragTask.flag = null;
          }
          if (!didDrop) {
            props.moveDraggedItem('', -1);
          }
          else props.commitDraggedItem();
        },
      }),
      [props.item, props.groupName, props.indexInGroup],
    );

    const [, drop] = useDrop(
      () => ({
        accept: 'groupitem',
        hover(item: { id: string }, monitor) {
          if (item.id !== props.item.value.toString()) {
            if (props.dragTask.flag != null) clearTimeout(props.dragTask.flag);
            props.dragTask.flag = setTimeout(() => {
              props.dragTask.flag = null;
              props.moveDraggedItem(props.groupName, props.indexInGroup);
            }, 0);
          }
        },
      }),
      [props.item, props.groupName, props.indexInGroup],
    );

    return (
      <div id={'mgmtitem' + props.item.value.toString()} className={'group-search-item' + (props.groupName != '' ? ' grouped-item' : '')} ref={(node) => drag(drop(node))}>
        <div className='checkbox' title={'Select ' + props.titleCaseSingularType} onClick={props.toggleSelectItem} data-group={props.groupName} data-value={props.item.value.toString()}
          style={{
            marginRight: '5px', minWidth: props.isScreen950 ? '15px' : '20px',
            width: props.isScreen950 ? '15px' : '20px', height: props.isScreen950 ? '15px' : '20px', marginLeft: '0px',
          }}>
          <img className='checkbox-checkmark' src={imagePath + 'CheckmarkBlack.png'} width={props.isScreen950 ? '15px' : '20px'} height={props.isScreen950 ? '15px' : '20px'}
            style={{ display: props.isSelected ? 'inline-block' : 'none' }} />
        </div>
        <h4>{props.item.text}</h4>
      </div>
    );
  });
