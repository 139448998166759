import { useReducer, useEffect, useState } from 'react';
import PackSelect from './PackSelect';
import PickNextPack from './PickNextPack';
import OpenPack from './OpenPack';
import { PackOpenerContext } from '../utils/Contexts';
import { cardsLoaded } from '../utils/CardDataCache';
import { Helmet } from 'react-helmet';
import Ad from './Ad';

function reducer(state: any, action: any) {
  switch (action.type) {
    case 'packOpenMode':
      return { ...state, packOpenMode: action.payload };
    case 'packsToOpen':
      return { ...state, packsToOpen: action.payload };
    case 'currentSetToOpenPrefix':
      return { ...state, currentSetToOpenPrefix: action.payload };
    case 'OpenPack':
      return { ...state, currentSetToOpenPrefix: action.payload, page: 'OpenPack' };
    case 'DraftPacks':
      return { ...state, packsToOpen: action.payload, currentSetToOpenPrefix: 'Draft', page: 'OpenPack' };
    default:
      return { ...state, page: action.payload };
  }
}

let checkForCardsTask: any;

export default function PackOpener(props: { refresh: any }) {
  const [state, dispatch] = useReducer(reducer, { packsToOpen: {}, page: 'PackSelect', currentSetToOpenPrefix: '', packOpenMode: 'Sealed' });
  const [cardsAreLoaded, setCardsAreLoaded] = useState(cardsLoaded.flag);

  useEffect(() => { // Mount
    if (!cardsAreLoaded) {
      checkForCardsTask = checkForCardsLoop();
    }

    return () => { // Unmount
      if (checkForCardsTask != null) {
        clearTimeout(checkForCardsTask);
        checkForCardsTask = null;
      }
    };
  }, []);

  function checkForCardsLoop() {
    return setTimeout(() => {
      checkForCardsTask = null;
      if (cardsLoaded.flag) setCardsAreLoaded(true);
      else {
        checkForCardsTask = checkForCardsLoop();
      }
    }, 200);
  }

  function getPage() {
    let component: any;
    switch (state.page) {
      case 'PickNextPack':
        component = <PickNextPack />;
        break;
      case 'OpenPack':
        component = <div>{
          cardsAreLoaded ? <OpenPack /> :
            <div style={{ margin: 'auto', textAlign: 'center' }}>
              <h1 style={{ paddingBottom: '0px', marginBottom: '0px' }}>Loading</h1>
              <img src={'/images/LoadingAnim.png'} width='150px' height='150px' style={{ margin: 'auto', marginTop: '5px', padding: '0' }} />
            </div>
        }</div>;
        break;
      default:
        component = <PackSelect />;
        break;
    }

    return component;
  }

  return (
    <PackOpenerContext.Provider value = {{ state, dispatch }}>
      <Helmet>
        <meta content="YGO Prog - Pack Opener" property="og:title" />
        <meta
          content="YGO Prog's Pack Opener simulates opening Yu-Gi-Oh! TCG packs and boxes with odds based on real-world data!"
          property="og:description"
        />
        <meta content="https://www.ygoprog.com/PackOpener" property="og:url" />
        <meta
          name="description"
          content="YGO Prog's Pack Opener simulates opening Yu-Gi-Oh! TCG packs and boxes with odds based on real-world data!"
        />
        <title>YGO Prog - Pack Opener</title>
      </Helmet>

      {getPage()}
    </PackOpenerContext.Provider>
  );
}
