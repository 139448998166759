import { Helmet } from 'react-helmet';
import { frontEndPath } from '../utils/Constants';
import Ad from './Ad';

export default function PrivacyPolicy(props: { refresh: any }) {
  return (
    <main>
      <Helmet>
        <meta content="YGO Prog - Privacy Policy" property="og:title" />
        <meta
          content="YGO Prog is a tool for simulating Yu-Gi-Oh! pack opening, collection management, deck building, banlist building, and more!"
          property="og:description"
        />
        <meta content="https://www.ygoprog.com/PrivacyPolicy" property="og:url" />
        <meta
          name="description"
          content="YGO Prog is a tool for simulating Yu-Gi-Oh! pack opening, collection management, deck building, banlist building, and more!"
        />
        <title>YGO Prog - Privacy Policy</title>
      </Helmet>

      <Ad styleName='bar_ad' />

      <h1>Privacy Policy</h1>
      <Ad styleName='left_sidebar_ad_1200' />
      <Ad styleName='right_sidebar_ad_1200' />
      <p className='content-box largetext about-content' style={{ textAlign: 'left' }}>
        Your privacy is important to us. It is YGO Prog policy to respect your privacy and comply with
        any applicable law and regulation regarding any personal information we may collect about you,
        including across our website, <a className='in-text-link' href={frontEndPath}>https://www.ygoprog.com</a>, as well as on our socials and email.
        <br /><br />
        This policy is effective as of 6 March 2022 and was last updated on 6 March 2022.

        <h5 className='about-h3' style={{ textAlign: 'left' }}>Information We Collect</h5>
        Information we collect includes both information you knowingly and actively provide us when using or participating in any of our services and promotions,
        and any information automatically sent by your devices in the course of accessing our services or communicating with us.

        <h3 className='about-h3'>Log Data</h3>
        When you visit our website, our servers may automatically log the standard data provided by your web browser.
        It may include your device&apos;s Internet Protocol (IP) address, your browser type and version,
        the pages you visit, the time and date of your visit, the time spent on each page, other details about your visit,
        and technical details that occur in conjunction with any errors you may encounter.
        <br />
        Please be aware that while this information may not be personally identifying by itself, it may be possible to combine
        it with other data to personally identify individual persons.

        <h3 className='about-h3'>Personal Information</h3>
        We may ask for personal information which may include one or more of the following: email, username
        (for this site or yourpublic username for communications via Twitter or Discord).

        <h3 className='about-h3'>Use of Personal Information</h3>
        We only collect and use your personal information when we have a legitimate reason for doing so.
        In which instance, we only collect personal information that is reasonably necessary to provide our services to you.

        <h3 className='about-h3'>Collection and Use of Information</h3>
        We may collect personal information from you when you do any of the following:

        <ul>
          <li>Access our content using a mobile device, web browser, or other method</li>
          <li>Contact us via email, social media, or any similar technologies</li>
          <li>Mention us on social media</li>
        </ul>

        We may collect, hold, use, and disclose information for analytics, market research, and business development, including to operate and improve our website,
        associated applications, and associated social media platforms. Personal information will not be further processed in a manner that is incompatible with this purpose.
        <br />
        Please be aware that we may combine information we collect about you with general information or research data we receive from other trusted sources.

        <h3 className='about-h3'>Security of Your Personal Information</h3>
        When we collect and process personal information, and while we retain this information, we will protect it within commercially acceptable means to prevent loss and theft,
        as well as unauthorized access, disclosure, copying, use, or modification.
        <br />
        Although we will do our best to protect the personal information you provide to us,
        we advise that no method of electronic transmission or storage is 100% secure, and no one can guarantee absolute data security.
        In the event of an issue such as a data breach, we will comply with any and all laws applicable to us.
        <br />
        You are responsible for selecting any password and its overall security strength, ensuring the security of your own information within the bounds of our services.

        <h3 className='about-h3'>How Long We Keep Your Personal Information</h3>
        We keep your personal information only for as long as we need to. This time period may depend on what we are using your information for, in accordance with this privacy policy.
        If your personal information is no longer required, we will delete it or make it anonymous by removing all details that identify you.
        <br />
        However, if necessary, we may retain your personal information for our compliance with a legal, accounting, or reporting obligation or for archiving purposes in the public interest, scientific,
        or historical research purposes or statistical purposes.
        <br /><br />
        If you wish to have your data deleted, please contact us at <a className='in-text-link' href='mailto: admin@ygoprog.com'>admin@ygoprog.com</a>.

        <h5 className='about-h3' style={{ textAlign: 'left' }}>Children&apos;s Privacy</h5>
        We do not aim any of our products or services directly at children under the age of 13, and we do not knowingly collect personal information about children under 13.

        <h5 className='about-h3' style={{ textAlign: 'left' }}>Disclosure of Personal Information to Third Parties</h5>
        We may disclose personal information to:

        <ul>
          <li>A branch or affiliate of our site</li>
          <li>
            Third party service providers for the purpose of enabling them to provide their services, for example,
            IT service providers, data storage, hosting and server providers, advertisers, or analytics platforms
          </li>
          <li>Our team members, contractors, and/or related entities</li>
          <li>Our existing or potential agents or business partners</li>
          <li>Sponsors or promoters of any competition, sweepstakes, or promotion we run</li>
          <li>
            Courts, tribunals, regulatory authorities, and law enforcement officers, as required by law,
            in connection with any actual or prospective legal proceedings, or in order to establish, exercise, or defend our legal rights
          </li>
          <li>Third parties, including agents or sub-contractors, who assist us in providing information, products, services, or direct marketing to you</li>
        </ul>

        <h5 className='about-h3' style={{ textAlign: 'left' }}>International Transfers of Personal Information</h5>
        The personal information we collect is stored and/or processed where we or our partners, affiliates, and third-party providers maintain facilities.
        Please be aware that the locations to which we store, process, or transfer your personal information may not have the same data protection laws as
        the country in which you initially provided the information. If we transfer your personal information to third parties in other countries:
        (i) we will perform those transfers in accordance with the requirements of applicable law; and (ii) we will protect the transferred personal information in accordance with this privacy policy.


        <h5 className='about-h3' style={{ textAlign: 'left' }}>Your Rights and Controlling Your Personal Information</h5>
        You always retain the right to withhold personal information from us, with the understanding that your experience of our website may be affected. We will not discriminate against you
        for exercising any of your rights over your personal information. If you do provide us with personal information you understand that we will collect, hold, use and disclose it in accordance
        with this privacy policy. You retain the right to request details of any personal information we hold about you.
        <br /><br />
        If we receive personal information about you from a third party, we will protect it as set out in this privacy policy. If you are a third party providing personal information about somebody else,
        you represent and warrant that you have such person&apos;s consent to provide the personal information to us.
        <br /><br />
        If you believe that any information we hold about you is inaccurate, out of date, incomplete, irrelevant, or misleading, please contact us using the details provided in this privacy policy.
        We will take reasonable steps to correct any information found to be inaccurate, incomplete, misleading, or out of date.
        <br /><br />
        If you believe that we have breached a relevant data protection law and wish to make a complaint, please contact us using the details below and provide us with full details of the alleged breach.
        We will promptly investigate your complaint and respond to you, in writing, setting out the outcome of our investigation and the steps we will take to deal with your complaint.
        You also have the right to contact a regulatory body or data protection authority in relation to your complaint.

        <h5 className='about-h3' style={{ textAlign: 'left' }}>Use of Cookies</h5>
        We use “cookies” to collect information about you and your activity across our site. A cookie is a small piece of data that our website stores on your computer, and accesses each time you visit,
        so we can serve you content based on preferences you have specified.

        <h5 className='about-h3' style={{ textAlign: 'left' }}>Limits of Our Policy</h5>
        Our website may link to external sites that are not operated by us. Please be aware that we have no control over the content and policies of those sites,
        and cannot accept responsibility or liability for their respective privacy practices.

        <h5 className='about-h3' style={{ textAlign: 'left' }}>Changes to This Policy</h5>
        At our discretion, we may change our privacy policy to reflect updates to our business processes, current acceptable practices, or legislative or regulatory changes.
        If we decide to change this privacy policy, we will post the changes here at the same link by which you are accessing this privacy policy.
        <br /><br />
        If required by law, we will get your permission or give you the opportunity to opt in to or opt out of, as applicable, any new uses of your personal information.
      </p>

      <Ad styleName='bar_ad_footer' />
    </main>
  );
}
