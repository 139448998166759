import { useState } from 'react';
import { cookies, apiPath, lowercaseContactString, contactString } from '../utils/Constants';
import makeApiCall from '../utils/ApiMiddleware';
import { openErrorModal } from '../utils/BasicModalFuncs';
import { emailEntryValidator } from '../utils/validators/LoginAndRegistrationValidator';


export default function ResetPassModal(props: { id: string }) {

  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [sendingEmail, setSendingEmail] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  function closeResetPassModal() {
    document.getElementById(props.id)!.style.display = 'none';
  }

  function sendPasswordResetLink() {
    const emailError = emailEntryValidator(email);
    if (emailError == '') {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ resetEmail: email }),
      };
      setSendingEmail(true);
      makeApiCall(cookies.get('refresh'), cookies.get('userId'), apiPath + 'user/forgot', requestOptions)
        .then(async (response) => {
          setSendingEmail(false);

          if (!response.ok) {
            const error = response.status;
            openErrorModal('Issue sending password reset.<br />' +
              (error == 401 ? '<br />Try logging out and logging back in.<br />If this persists, ' + lowercaseContactString : '') +
              (error == 400 ? '<br />You have been inactive for a long time. Please log out and back in.<br /><br />If this persists, ' + lowercaseContactString : '') +
              (error != 401 && error != 400 ? '<br />' + contactString : ''));
            return Promise.reject(error);
          }

          const data = await response.json();
          if (!data.success) {
            if (data.message == 'UserNotFound') {
              openErrorModal('No account associated with this email, please check that you entered it correctly.' +
                '<br />If this persists, ' + contactString);
            }
            else openErrorModal('Issue sending password reset.<br />If this persists, ' + contactString);
          }
          else {
            setEmailSent(true);
          }
        });
    }
    else {
      setError(emailError);
    }
  }

  function handleResetEmail(event: React.ChangeEvent<any>) {
    const email = `${event.target.value}`;
    setEmail(email);
  }

  return (
    <div id={props.id} className='modal' style={{ zIndex: 9009 }}>
      <button className='modal-close' title='Close modal' onClick={closeResetPassModal} />
      <div className='content-box modal-content' style={{ zIndex: 9010 }}>
        <h5 style={{ marginBottom: '0px' }}>Reset Password</h5>
        {emailSent ?
          <p style={{ textAlign: 'center' }}>
            Password reset email sent to <b>{email}</b>.<br />
            <span className='in-text-link' title='Resend password reset email' onClick={() => setEmailSent(false)}>Resend Email</span>
          </p> :
          <div>
            <div className='vertical-form-text-input'>
              <label htmlFor='reset-email'>Email:</label>
              <div className='text-input'>
                <input id='reset-email' type="email" title='Email for validation password recovery' required
                  onChange={handleResetEmail}
                  onKeyDown={(e) => {if (e.code === 'Enter') sendPasswordResetLink();}} /></div>
            </div>
            {error != '' ? <span className='validation-error'>{error}</span> : ''}
            {sendingEmail ?
              <img src={'/images/LoadingAnim.png'} width='30px' height='30px' style={{ margin: 'auto', verticalAlign: 'middle', marginRight: '0px', marginTop: '7px' }} /> :
              <button className='black-button' style={{ marginRight: '7px', marginTop: '7px' }} title='Send password reset to account email' onClick={sendPasswordResetLink}>
                <span>Send Reset Email</span>
              </button>}
          </div>}
      </div>
    </div>
  );
}
