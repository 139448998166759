
import { useState } from 'react';
import { cookies, apiPath, setCookie, contactString } from '../utils/Constants';
import {
  loginUsernameValidator,
  loginPasswordValidator,
} from '../utils/validators/LoginAndRegistrationValidator';
import makeApiCall from '../utils/ApiMiddleware';
import { openBasicModal, openErrorModal, openResetPassModal } from '../utils/BasicModalFuncs';
import { frontEndPath } from '../utils/Constants';
import localforage from 'localforage';
import { dropdownOptsFromGroups } from '../utils/CardDataCache';

export default function Login(props: { modal: boolean, page: string, setPage: any }) {

  const [error, setError] = useState({
    usernameMessage: '',
    passwordMessage: '',
    emailMessage: '',
  });
  const [loginUsername, setLoginUsername] = useState('');
  const [loginPassword, setLoginPassword] = useState('');
  const [errorList, setErrorList] = useState<string[]>([]);
  const [loginComplete, setLoginComplete] = useState(false);
  const [loggingIn, setLoggingIn] = useState(false);

  function handleLogin() {
    let token = '';
    let refresh = '';
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ username: loginUsername, password: loginPassword }),
    };
    setLoggingIn(true);
    makeApiCall(cookies.get('refresh'), cookies.get('userId'), apiPath + 'login', requestOptions)
      .then(async (response) => {
        if (!response.ok) {
          const err = response.status;
          openErrorModal('Issue logging in.<br />Error Code: ' + err + '<br />' + contactString);
          return Promise.reject(err);
        }

        const data = await response.json();
        token = data.token;
        refresh = data.refresh_token;
        if (token !== undefined) {
          if (data.userVerified != true) {
            openBasicModal('Duelist Not Verified', 'You have not verified your account email, please do so.<br />' +
              'If you do not verify your email, we have no way to help you reset your password if you forget it.<br />' +
              'You can resend the verification email from your <a class="in-text-link" href="' + frontEndPath + 'Account">Account Page</a>.', 'Ok');
          }

          const getReqOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
          };
          makeApiCall(refresh, data.userId, apiPath + 'user/' + data.userId + '/decks', getReqOptions)
            .then(async (deckResponse: any) => {
              setLoggingIn(false);
              if (!deckResponse.ok) {
                const err = deckResponse.status;
                openErrorModal('Issue retrieving decks after login.<br />Error Code: ' + err + '<br />' + contactString);
                return Promise.reject(err);
              }

              const deckData = await deckResponse.json();

              makeApiCall(refresh, data.userId, apiPath + 'user/' + data.userId + '/banlists', getReqOptions)
                .then(async (banlistResponse: any) => {
                  setLoggingIn(false);
                  if (!banlistResponse.ok) {
                    const err = banlistResponse.status;
                    openErrorModal('Issue retrieving banlists after login.<br />Error Code: ' + err + '<br />' + contactString);
                    return Promise.reject(err);
                  }

                  const banlistData = await banlistResponse.json();

                  makeApiCall(refresh, data.userId, apiPath + 'user/' + data.userId + '/binders', getReqOptions)
                    .then(async (binderResponse: any) => {
                      setLoggingIn(false);
                      if (!binderResponse.ok) {
                        const err = binderResponse.status;
                        openErrorModal('Issue retrieving binders after login.<br />Error Code: ' + err + '<br />' + contactString);
                        return Promise.reject(err);
                      }

                      const binderData = await binderResponse.json();

                      await localforage.setItem('decks', dropdownOptsFromGroups(deckData, 'decks'));
                      await localforage.setItem('banlists', dropdownOptsFromGroups(banlistData, 'banlists'));
                      await localforage.setItem('binders', binderData.map((binder: any) => ({ id: binder._id, name: binder.name })));
                      setCookie('refresh', refresh);
                      setCookie('token', token);
                      setCookie('userId', data.userId);
                      setCookie('user', loginUsername);
                    });
                });
            });

          // setLoginComplete(true);
          // if (props.modal) props.setPage('Login Complete');
        }
        else {
          setLoggingIn(false);
          setErrorList(['Username and/or Password do not match any recorded Duelist']);
        }
      });
  }

  function handleLoginUsername(event: React.ChangeEvent<any>) {
    const username = `${event.target.value}`;
    const newError = error;

    newError.usernameMessage = loginUsernameValidator(username);

    setError(newError);
    setLoginUsername(username);
  }

  function handleLoginPassword(event: React.ChangeEvent<any>) {
    const password = `${event.target.value}`;
    const newError = error;

    newError.passwordMessage = loginPasswordValidator(password);

    setError(newError);
    setLoginPassword(password);
  }

  return (
    <div>
      {props.modal &&
        <h5 style={{ marginBottom: '10px' }}>Duelist Login</h5>}
      <div>
        <div className='vertical-form-text-input'>
          <label htmlFor='username-input'>Username:</label>
          <div className='text-input'><input id='username-input' type="text" required
            onChange={handleLoginUsername}
            onKeyDown={(e) => {if (e.code === 'Enter') handleLogin();}} /></div>
        </div>

        <div className='vertical-form-text-input'>
          <label htmlFor='password-input'>Password:</label>
          <div className='text-input'><input id='password-input' required type="password"
            onChange={handleLoginPassword}
            onKeyDown={(e) => {if (e.code === 'Enter') handleLogin();}} /></div>
          <span className='forgot-pass-button' title='Open password reset modal' onClick={openResetPassModal} style={{ marginRight: '0px' }}>Forgot password?</span>
        </div>

        {errorList.map((value: string, index: number) => <span className='validation-error' key={index}>{value}</span>)}

        {loggingIn ?
          <img src={'/images/LoadingAnim.png'} width='30px' height='30px' style={{ margin: 'auto', verticalAlign: 'middle', marginRight: '0px' }} /> :
          <button className='black-button' type='button' title='Log In' onClick={handleLogin} style={{ padding: '0px 25px', marginTop: '7px' }}><span>Log In</span></button>}

        <div className='middletext-divider'>
          <div className='grid-left gray-line' />
          <span className='grid-middle gray-middle-text'>or</span>
          <div className='grid-right gray-line' />
        </div>

        <span className='black-underline-link' title='Open registration modal' onClick={() => {props.setPage('New Duelist Registration');}}>Register New Duelist</span>
      </div>
      {/* {!loginComplete ?
         :
        <p style={{ paddingTop: '0' }}>
          Login complete.
        </p>} */}
    </div>
  );
}
