import { useState, useEffect, useRef, memo, useContext } from 'react';
import * as Constants from '../utils/Constants';
import { usePopper } from 'react-popper';
import ReactDOM from 'react-dom';
import { cards } from '../utils/CardDataCache';
import LazyImage from './LazyImage';
import { AppContext } from '../utils/Contexts';

interface CardProps {
  cardID: number,
  cardName: string,
  classNames: string,
  width: number,
  height: number,
  beforeElement: JSX.Element | null,
  afterElement: JSX.Element | null,
  style: any,
  autosize: boolean,
  contextMenu: any,
  disableHover: boolean,
}

function HoverableCardComp(props: CardProps) {
  const [internalCardData, setInternalCardData] = useState<Card | null>(cards[props.cardID]);
  const [classWatcher, setClassWatcher] = useState<Constants.ClassWatcher | null>(null);
  const { isScreen950Query, isMainInputTouchQuery } = useContext(AppContext);
  const [isScreen950, setIsScreen950] = useState(isScreen950Query.matches);
  const [isMainInputTouch, setIsMainInputTouch] = useState(isMainInputTouchQuery.matches);
  const [hoverContentVisibility, setHoverContentVisibility] = useState(false);
  const [id] = useState('hc' + (props.cardID.toString() + Date.now().toString() + Math.random().toString()).replaceAll('.', '_'));
  const [hover, setHover] = useState(false);
  const [imageFailedToLoad, setImageFailedToLoad] = useState(false);
  const [referenceElement, setReferenceElement] = useState<any>(null);
  const [popperElement, setPopperElement] = useState<any>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'auto',
    modifiers: [{ name: 'offset', options: { offset: [0, 20] } }, { name: 'arrow', enabled: false }],
  });
  const hoverTask = useRef<NodeJS.Timeout | null>(null); // Timeout for the time before hover pops up
  const isMounted = useRef<boolean>(false); // Is this component mounted (avoid setting state if the card gets removed while the hover was going on)
  const disableHover = useRef<boolean>(props.disableHover);
  disableHover.current = props.disableHover;
  const abandonHover = useRef<boolean>(false); // Used to call off the hover if close is called before the open finishes

  useEffect(() => { // Mount
    isScreen950Query.addEventListener('change', checkScreen950); // To avoid re-rendering the whole page and losing data, we have to listen and state change on the sublevel
    isMainInputTouchQuery.addEventListener('change', checkMainInputTouch);
    hoverTask.current = null;
    abandonHover.current = false;
    isMounted.current = true;

    return () => { // Unmount
      isScreen950Query.removeEventListener('change', checkScreen950);
      isMainInputTouchQuery.removeEventListener('change', checkMainInputTouch);
      isMounted.current = false;
      abandonHover.current = true;
      if (classWatcher != null) classWatcher.disconnect();
      if (hoverTask.current != null) clearTimeout(hoverTask.current);
    };

  }, []);

  useEffect(() => {
    disableHover.current = props.disableHover;
    if (props.disableHover) closeHover();
  }, [props.disableHover]);

  useEffect(() => {
    updateClassWatcher();
  }, [internalCardData]);

  useEffect(() => {
    setInternalCardData(cards[props.cardID]);
  }, [props.cardID]);

  useEffect(() => {
    if (hover && !disableHover.current && !abandonHover.current) { // If not disabled, adjust the text width to avoid weird hanging lines, otherwise turn it back off
      adjustWidth(); // Doing this as a useeffect means the component has already mounted and calculated width so we can mess with it
    }
    else if (hover) {
      setHover(false);
    }
  }, [hover]);

  function checkScreen950() {
    setIsScreen950(isScreen950Query.matches);
  }

  function checkMainInputTouch() {
    setIsMainInputTouch(isMainInputTouchQuery.matches);
  }

  function updateClassWatcher() {
    classWatcher?.disconnect();
    setClassWatcher(new Constants.ClassWatcher(document.getElementById(id.toString())!, 'hover', () => {
      if (document.getElementById(id.toString()+'hover') == null && !disableHover.current) { // If hover is not true and we're in focus
        openHover(internalCardData, props.cardID, id.toString());
      }
    }, closeHover));
  }

  function openHover(pCard: Card | null, newID: number, divID: string) { // Set a timeout, then set hover true to be handled in useEffect
    abandonHover.current = false; // Reset the abandon flag
    if (hoverTask.current != null) clearTimeout(hoverTask.current);
    if (!disableHover.current) {
      hoverTask.current = setTimeout(function() {
        if (isMounted.current) setHover(true);
        hoverTask.current = null;
      }, isMainInputTouch || isScreen950 ? 0 : 450);
    }
    else hoverTask.current = null;
  }

  function adjustWidth() {
    const d = document.getElementById(id.toString() + 'desc');
    if (d != null) {
      let w: number; // Adjust the width of the description to avoid weird spaces and hanging words
      const width = d.offsetWidth;
      const height = d.offsetHeight;

      for (w = width; w; w--) {
        d.style.width = w + 'px';
        if (d.offsetHeight !== height) break;
      }

      if (w < d.scrollWidth) {
        d.style.width = d.style.maxWidth = d.scrollWidth + 'px';
      }
      else {
        d.style.width = (w + 1) + 'px';
      }

      if (isMounted.current && !disableHover.current && !abandonHover.current) setHoverContentVisibility(true); // If we're still good to go, turn the visibility on
      else if (isMounted.current) setHover(false);
    }
    else setHover(false);
  }

  function closeHover() {
    window.removeEventListener('click', hoverClickClose, true);
    document.getElementById(id.toString())?.children[0]?.classList.remove('hover');
    if (hoverTask.current != null) {
      clearTimeout(hoverTask.current);
      hoverTask.current = null;
    }
    abandonHover.current = true;
    if (isMounted.current) {
      setHoverContentVisibility(false);
      setHover(false);
    }
  }

  function iconSize() {
    return isScreen950 ? '15px' : '25px';
  }

  function getLvlDisplay() {
    if (internalCardData?.subtype.includes('Xyz')) {
      return <div className='sameline-div'>
        <img src={Constants.imagePath + 'RankIcon.png'} width={iconSize()} height={iconSize()} />
        <span>Rank: {internalCardData?.level}</span>
      </div>;
    }
    else {
      return <div className='sameline-div'>
        <img src={Constants.imagePath + 'LevelIcon.png'} width={iconSize()} height={iconSize()} />
        <span>Level: {internalCardData?.level}</span>
      </div>;
    }
  }

  function getStatsDisplay() {
    if (internalCardData?.subtype.includes('Link')) {
      return <div className='line-div'>
        <img src={Constants.imagePath + 'SwordsIcon.png'} width={iconSize()} height={iconSize()} />
        <span>ATK/ {internalCardData?.atk}&nbsp;&nbsp;LINK-{internalCardData?.level}</span>
      </div>;
    }
    else {
      return <div className='line-div'>
        <img src={Constants.imagePath + 'SwordsIcon.png'} width={iconSize()} height={iconSize()} />
        <span>ATK/ {internalCardData?.atk}&nbsp;&nbsp;DEF/ {internalCardData?.def}</span>
      </div>;
    }
  }

  function getLinkDisplay() {
    if (internalCardData?.subtype.includes('Link') && internalCardData?.linkmarkers != null) {
      return <div className='line-div'>
        <img src={Constants.imagePath + 'RightLinkMarker.png'} width={iconSize()} height={iconSize()} />
        <span>Link Markers: {internalCardData?.linkmarkers?.join(', ')}</span>
      </div>;
    }
    else return null;
  }

  function getPendulumDisplay() {
    if (internalCardData?.subtype.includes('Pendulum')) {
      return <div className='line-div'>
        <img src={Constants.imagePath + 'PendulumLeftIcon.png'} width={isScreen950 ? '21px' : '35px'} height={iconSize()} />
        <span style={{ marginRight: '5px' }}>Pendulum Scale: {internalCardData?.scale}</span>
        <img src={Constants.imagePath + 'PendulumRightIcon.png'} width={isScreen950 ? '21px' : '35px'} height={iconSize()} />
      </div>;
    }
    else return null;
  }

  function replaceWithDefaultImg(event: any) {
    setImageFailedToLoad(true);
  }

  function imageProperlyLoaded(event: any) {
    setImageFailedToLoad(false);
  }

  function cardClick(e: any) {
    if (!(isMainInputTouch || isScreen950)) {
      window.open(Constants.cardLink(props.cardID), '_blank', 'noreferrer');
    }
    else {
      if (!e.currentTarget.classList.contains('hover')) {
        if (document.getElementById(id.toString()+'hover') == null && !disableHover.current) { // If hover is not true and we're in focus
          e.currentTarget.classList.add('hover');
          window.addEventListener('click', hoverClickClose, true);
          openHover(internalCardData, props.cardID, id.toString());
        }
      }
      else {
        closeHover();
      }
    }
  }

  function onCardHoverClick(e: any) {
    if (isMainInputTouch || isScreen950) {
      window.open(Constants.cardLink(props.cardID), '_blank', 'noreferrer');
    }
    return true;
  }

  function hoverClickClose(e: any) { // Need to check if we're clicking the card again and ignore if so
    if (!((e.target as Element).closest('#' + id.toString()) || (e.target as Element).closest('#' + id.toString() + 'hoverimg'))) closeHover();
  }

  return (
    <div id ={id.toString()} className={'hover-card-div hoverable '+props.classNames} style={props.style} data-cardname={props.cardName}
      onContextMenu={(e: any) => {
        if (props.contextMenu != null) props.contextMenu(e);
      }}>
      <div className='card-link' onClick={cardClick} ref={setReferenceElement}>
        {props.beforeElement}
        {imageFailedToLoad ?
          <div className='card-image' style={props.autosize ? { width: '100%', height: 'auto', position: 'relative' } : { width: props.width + 'px', height: props.height + 'px', position: 'relative' }}>
            <img className='card-image' src={Constants.imagePath + 'FailedCardLoad.png'} alt={props.cardName} width={props.width + 'px'} height={props.height + 'px'}
              style={props.autosize ? { width: '100%', height: 'auto' } : {}} />
            <span className='replacement-card-title' style={{ display: props.width < 120 ? 'none' : 'inline' }}>{props.cardName}</span>
          </div> :
          <LazyImage {...{
            src: Constants.cardImagePath + props.cardID + '.jpg',
            width: props.width,
            height: props.height,
            alt: props.cardName,
            className: 'card-image',
            style: props.autosize ? { width: '100%', height: 'auto' } : {},
            onError: replaceWithDefaultImg,
            onLoad: imageProperlyLoaded,
          }}/>}
        {props.afterElement}
      </div>

      {ReactDOM.createPortal(
        hover && !disableHover.current ? <div ref={setPopperElement} style={{ ...styles.popper, zIndex: 9001 }} {...attributes.popper}>
          <div id={id.toString() + 'hover'} className={'card-hover'} style={{ visibility: (hoverContentVisibility ? 'visible' : 'hidden') }}>
            <div className='card-hover-left'>
              {imageFailedToLoad ?
                <div className='card-image' style={{ width: 'min(30vw, 200px)', height: 'auto', position: 'relative' }}>
                  <img id={id.toString() + 'hoverimg'} className='card-image' src={Constants.imagePath + 'FailedCardLoad.png'} alt={props.cardName} width='200px' height='291.68px'
                    style={{ width: 'min(30vw, 200px)', height: 'auto' }} onClick={onCardHoverClick} />
                  {isScreen950 ? '' : <span className='replacement-card-title' style={{ fontSize: '13pt' }}>{props.cardName}</span>}
                </div> :
                <img id={id.toString() + 'hoverimg'} className='card-image' src={Constants.cardImagePath + props.cardID + '.jpg'} width='200px' height='291.68px'
                  style={{ width: 'min(30vw, 200px)', height: 'auto' }} onError={replaceWithDefaultImg} onLoad={imageProperlyLoaded} onClick={onCardHoverClick} />}
            </div>
            <div className='card-hover-right' style={{ marginRight: 'initial', marginLeft: '10px' }}>
              <div className='line-div'>
                <h4>{internalCardData?.name}</h4>
              </div>
              {internalCardData?.type == 'Monster' && <div className='line-div'>
                {getLvlDisplay()}
                <div>
                  <img src={internalCardData != null ? Constants.imagePath + 'AttributeIcons/' + internalCardData?.attribute + '.png' : undefined} width={iconSize()} height={iconSize()} />
                  <span>Attribute: {internalCardData?.attribute}</span>
                </div>
              </div>}
              <div className='line-div'>
                {internalCardData != null && internalCardData?.subtype[0] != 'Token' ?
                  <img src={internalCardData != null ? Constants.imagePath + 'TypeIcons/' + internalCardData?.subtype[0] + '.png' : undefined} width={iconSize()} height={iconSize()} /> : ''}
                <span>{internalCardData?.subtype.join(' / ')}</span>
              </div>
              {internalCardData?.type == 'Monster' && getStatsDisplay()}
              {getLinkDisplay()}
              {getPendulumDisplay()}
              <div className='line-div' style={{ marginBottom: '0px' }}>
                <span id={id.toString() + 'desc'} style={{ whiteSpace: 'pre-wrap' }}>
                  {internalCardData?.desc.replaceAll('.\n[ ', '.\n----------------------------------------\n[ ')}
                </span>
              </div>
            </div>
          </div>
        </div> : null,
        document.getElementById('card-hover-div')!,
      )}
    </div>
  );
}

export const HoverableCard = memo(HoverableCardComp);
export default HoverableCard;
