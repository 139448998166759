import HoverableCard from './HoverableCard';
import { useDrag } from 'react-dnd';
import { useContext, useEffect, useRef } from 'react';
import { BuilderContext } from '../utils/Contexts';
import { ExtraDeckSubtypes, imagePath } from '../utils/Constants';

interface BuilderSearchCardProps {
  card: Card,
  limit?: number,
  limitSize?: string
}

export default function BuilderSearchCard(props: BuilderSearchCardProps) {

  const context = useContext(BuilderContext);
  const uniqueID = useRef<string>();

  useEffect(() => {
    uniqueID.current = (props.card.subtype.some((st: string) => ExtraDeckSubtypes.includes(st)) ? 'e' : 'm') + '-' + props.card.id + '-' + Date.now() + Math.random();
  }, []);

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: 'dropcard',
      item: () => {
        context.setCurrentDragCard({ ...props.card, uniqueID: uniqueID.current }, 'b', dropDrag);
        return { uniqueID: uniqueID.current, originalZone: 'b' } as DragCard;
      },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
      end: (item: DragCard, monitor) => {
        const didDrop = monitor.didDrop();
        if (!didDrop) {
          if (context.dragTask.flag != null) {
            clearTimeout(context.dragTask.flag);
            context.dragTask.flag = null;
          }
          const itemOffset = monitor.getClientOffset();
          if (itemOffset != null) {
            const zones = Object.keys(context.zoneCalcShifts);
            for (let i: number = 0; i < 3; i++) {
              const zone = zones[i];
              const boundRect = document.getElementById(zone + 'Drop')!.getBoundingClientRect();
              if ((itemOffset.x >= Math.floor(boundRect.left) && itemOffset.x <= Math.ceil(boundRect.right)) &&
              (itemOffset.y >= Math.floor(boundRect.top) && itemOffset.y <= Math.ceil(boundRect.bottom))) {
                context.zoneCalcShifts[zone](item, monitor, true);
                return;
              }
            }
          }
          context.removeCard.current('', '', true); // Was particularly nasty to figure this out- when we swap zones this becomes uncoupled and can't update, so we have to use refs here
        }
      },
    }),
    [uniqueID],
  );

  function dropDrag() {
    uniqueID.current = (props.card.subtype.some((st: string) => ExtraDeckSubtypes.includes(st)) ? 'e' : 'm') + '-' + props.card.id + '-' + Date.now() + Math.random();
  }

  function rightClickAdd(e: any) {
    e.preventDefault();
    let newZone: string = uniqueID.current![0];
    if (context.builderType == 'd' && e.shiftKey) newZone = 's'; // Move to side deck in deckbuilder
    else if (context.builderType == 'b') { // In banlist builder
      if (e.shiftKey && e.ctrlKey) { // Remove
        let removeZone: string = 'su';
        switch (props.limit) {
          case 0:
            removeZone = 'sf';
            break;
          case 1:
            removeZone = 'sl';
            break;
          case 2:
            removeZone = 'ss';
            break;
          case 3:
            removeZone = 'su';
            break;
          default:
            return;
        }

        context.removeCard.current(removeZone, 'm-' + props.card.id, false);
        return;
      }
      else if (e.shiftKey) newZone = 'sl'; // Move to limited
      else if (e.ctrlKey) newZone = 'ss'; // Move to semilimited
      else if (e.altKey) newZone = 'su'; // move to unlimited
    }

    context.moveCard.current(
      'b',
      (props.card.subtype.some((st: string) => ExtraDeckSubtypes.includes(st)) ? 'e' : 'm') + '-' + props.card.id + '-' + Date.now() + Math.random(),
      newZone,
      -1,
      false,
      false);

  }

  return (
    <div id={uniqueID.current+'searchcard'} className='drop-card' ref={drag}>
      <HoverableCard {...{
        cardID: props.card.id,
        cardName: props.card.name,
        classNames: 'drop-card',
        width: 58.28,
        height: 80,
        beforeElement: props.limit !== undefined ? <img className='lf-icon' src={imagePath + 'Limit' + props.limit + '.png'}
          alt={props.limit.toString()} width={props.limitSize} height={props.limitSize} /> : null,
        afterElement: null,
        style: { margin: '5px 5px 5px 0px', width: '58.28px' },
        autosize: true,
        contextMenu: rightClickAdd,
      }} disableHover={isDragging} key={uniqueID.current}/>
    </div>
  );
}
