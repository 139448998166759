import { useEffect, memo, useState } from 'react';
import { env } from '../utils/Constants';

export default function Ad(props: { styleName: string }) {
  const [isScreen1700Query, setIsScreen1700Query] = useState(window.matchMedia('(max-width: 1700px)')); // Is screen 1700 or less
  const [isScreen1510Query, setIsScreen1510Query] = useState(window.matchMedia('(max-width: 1510px)')); // Is screen 1510 or less
  const [isScreen1300Query, setIsScreen1300Query] = useState(window.matchMedia('(max-width: 1300px)')); // Is screen 1300 or less

  useEffect(() => {
    try {
      if (env != 'dev' && !(props.styleName.includes('1400') && isScreen1700Query.matches) && !(props.styleName.includes('1400') && isScreen1700Query.matches)) {
        ((window as any).adsbygoogle = (window as any).adsbygoogle || []).push({});
      }
    }
    catch (e: any) {
      console.log(e.message);
    }
  }, []);

  function getAdSlot() { // Split the slots for better analytics (and pricing)
    if (props.styleName.startsWith('left') || props.styleName.startsWith('right')) return '5010372229';
    else if (props.styleName.endsWith('footer')) return '1140761042';
    else return '1262698908';
  }

  return (
    props.styleName.includes('1400') && isScreen1700Query.matches ? <div style={{ display: 'none' }} /> :
      props.styleName.includes('1200') && isScreen1510Query.matches ? <div style={{ display: 'none' }} /> :
        <div className={props.styleName + '_div'}>
          <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9842964564050008"
            crossOrigin="anonymous"></script>
          <ins className={'adsbygoogle ' + props.styleName}
            style={{ }}
            data-ad-client="ca-pub-9842964564050008"
            data-full-width-responsive={isScreen1300Query.matches && props.styleName.startsWith('bar') ? 'true' : ''}
            data-ad-slot={getAdSlot()}
            data-ad-format={props.styleName == 'bar_ad' ? '' : props.styleName == 'bar_ad_footer' ? 'horizontal' : 'vertical'}></ins>
        </div>
  );
}
