import { memo } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { imagePath } from '../utils/Constants';

export const GroupManagementGroupDrop = memo(
  function GroupManagementGroupDrop(props: { className: string, groupName: string, dragTask: any, moveDraggedItem: any, children: any }) {
    const [, drop] = useDrop(
      () => ({
        accept: 'groupitem',
        hover(item: { id: string }, monitor) {
          if (props.dragTask.flag != null) clearTimeout(props.dragTask.flag);
          props.dragTask.flag = setTimeout(() => {
            props.dragTask.flag = null;
            props.moveDraggedItem(props.groupName, 0);
          }, 0);
        },
      }),
      [],
    );

    return (
      <div className={props.className} ref={drop}>
        {props.children}
      </div>
    );
  });
