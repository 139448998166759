import { useState, useEffect, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { cookies, onCookieChangeListeners, shouldPagesRefresh, setCookie } from '../utils/Constants';
import { AppContext } from '../utils/Contexts';

export default function Header(props: { triggerModal: any }) {

  const [username, setUsername] = useState(cookies.get('user'));
  const { refreshRouteFunc, refresh, isScreen950Query, isMainInputTouchQuery } = useContext(AppContext);
  const [isScreen950, setIsScreen950] = useState(isScreen950Query.matches);
  const [isMainInputTouch, setIsMainInputTouch] = useState(isMainInputTouchQuery.matches);
  const scrollDirection = useScrollDirection();

  useEffect(() => { // Mount
    isScreen950Query.addEventListener('change', checkScreen950); // To avoid re-rendering the whole page and losing data, we have to listen and state change on the sublevel
    isMainInputTouchQuery.addEventListener('change', checkMainInputTouch);
    onCookieChangeListeners.push(cookieRefresh);

    return () => { // Unmnount
      isScreen950Query.removeEventListener('change', checkScreen950);
      isMainInputTouchQuery.removeEventListener('change', checkMainInputTouch);
      window.removeEventListener('click', closeDropdown, false);
      onCookieChangeListeners.splice(onCookieChangeListeners.indexOf(cookieRefresh), 1);
    };
  }, []);

  function checkScreen950() {
    setIsScreen950(isScreen950Query.matches);
  }

  function checkMainInputTouch() {
    setIsMainInputTouch(isMainInputTouchQuery.matches);
  }

  function cookieRefresh() {
    setUsername(cookies.get('user'));
  }

  function useScrollDirection() { // From https://www.codemzy.com/blog/react-sticky-header-disappear-scroll
    const [scrollDirection, setScrollDirection] = useState('');
    const delta: number = 5;

    useEffect(() => {
      let lastScrollY = window.pageYOffset;

      const updateScrollDirection = () => {
        const scrollY = window.pageYOffset;
        const direction = scrollY > lastScrollY ? 'down' : 'up';
        if (direction !== scrollDirection && (scrollY - lastScrollY > delta || scrollY - lastScrollY < -delta)) {
          setScrollDirection(direction);
        }
        lastScrollY = scrollY > 0 ? scrollY : 0;
      };
      window.addEventListener('scroll', updateScrollDirection);
      return () => {
        window.removeEventListener('scroll', updateScrollDirection); // Unmount
      };
    }, [scrollDirection]);

    return scrollDirection;
  }

  function openDropdown() {
    document.getElementById('account-nav-dropdown-list')?.classList.toggle('show');
    window.addEventListener('click', closeDropdown, true);
  }

  function openSimDropdown() {
    document.getElementById('packsim-nav-dropdown-list')?.classList.toggle('show');
    window.addEventListener('click', closeSimDropdown, true);
  }

  function toggleNavDropdown() {
    document.getElementById('nav-ul')!.classList!.toggle('show');
    window.addEventListener('click', closeNavDropdown, true);
  }

  function toggleNav2Dropdown() {
    document.getElementById('nav2-ul')!.classList.toggle('show');
    window.addEventListener('click', closeNav2Dropdown, true);
  }

  function closeDropdown(e: any) {
    const dropdown = document.getElementById('account-nav-dropdown-list');
    if (dropdown != null && dropdown.classList.contains('show')) {
      dropdown.classList.remove('show');
    }
    window.removeEventListener('click', closeDropdown, false);
  }

  function closeSimDropdown(e: any) {
    const dropdown = document.getElementById('packsim-nav-dropdown-list');
    if (dropdown != null && dropdown.classList.contains('show')) {
      dropdown.classList.remove('show');
    }
    window.removeEventListener('click', closeSimDropdown, false);
  }

  function closeNavDropdown(e: any) {
    if (!(e?.target?.parentElement?.id == 'nav')) {
      const dropdown = document.getElementById('nav-ul');
      if (dropdown != null && dropdown.classList.contains('show')) {
        dropdown.classList.remove('show');
      }
    }
    window.removeEventListener('click', closeNavDropdown, false);
  }

  function closeNav2Dropdown(e: any) {
    if (e?.target.parentElement.id != 'nav2') {
      const dropdown = document.getElementById('nav2-ul');
      if (dropdown != null && dropdown.classList.contains('show')) {
        dropdown.classList.remove('show');
      }
    }
    window.removeEventListener('click', closeNav2Dropdown, false);
  }

  function refreshFunc() {
    if (shouldPagesRefresh.val) refreshRouteFunc(!refresh);
  }

  function toggleDarkMode() {
    if (document.body.classList.contains('dark-theme-bg')) {
      document.body.classList.remove('dark-theme-bg');
      document.querySelector(':root')!.classList.remove('dark-theme');
      setCookie('darkModeOn', false);
    }
    else {
      document.body.classList.add('dark-theme-bg');
      document.querySelector(':root')!.classList.add('dark-theme');
      setCookie('darkModeOn', true);
    }
  }

  return (
    <header id='header' className={scrollDirection === 'down' ? 'hide-header' : ''}>
      {isScreen950 ? '' : <strong className='logo' />}
      <nav id='nav' className='nav'>
        {isScreen950 ? <img src="/images/Menu.png" width='30px' height='24px' onClick={toggleNavDropdown} style={{ margin: '10px 0px 0px 7px' }}/> : ''}
        <ul id='nav-ul' style={isScreen950 ? { left: 0 } : {}}>
          {/* <li><NavLink to='/ProgSeries' className='topbar-link' activeClassName='active-page' onClick={refreshFunc}><span>Prog Series</span></NavLink></li> */}
          {isScreen950 ? <li><NavLink to='/PackOpener' className='topbar-link' activeClassName='active-page' onClick={refreshFunc}><span>Pack Opener</span></NavLink></li> :
            <div id='packsim-nav-dropdown-box' className='nav-dropdown-box packsim-nav-dropdown' onMouseEnter={openSimDropdown} onMouseLeave={closeSimDropdown}>
              <li><NavLink to={isMainInputTouch ? '' : '/PackOpener'} className='topbar-link' activeClassName='active-page' onClick={isMainInputTouch ? openSimDropdown : refreshFunc}>
                <span>Pack Simulator</span></NavLink>
              </li>
              <ul id='packsim-nav-dropdown-list'>
                <li><NavLink to='/PackOpener' onClick={refreshFunc}><span>Pack Opener</span></NavLink></li>
              </ul>
            </div>}
          <li><NavLink to='/BinderManagement' className='topbar-link' activeClassName='active-page' onClick={refreshFunc}><span>Binders</span></NavLink></li>
          <li><NavLink to='/DeckBuilder' className='topbar-link' activeClassName='active-page' onClick={refreshFunc}><span>Deck Builder</span></NavLink></li>
          <li><NavLink to='/BanlistBuilder' className='topbar-link' activeClassName='active-page' onClick={refreshFunc}><span>Banlist Builder</span></NavLink></li>
          <li id='final-nav-box'><NavLink to='/About' className='topbar-link' activeClassName='active-page' onClick={refreshFunc}><span>About</span></NavLink></li>
          {isScreen950 ? '' :
            <div id="large-header-right">
              <li title='Toggle Dark Mode' onClick={toggleDarkMode}>
                <div className='topbar-link' style={{ width: '52px' }}>
                  <span><img src={'/images/Moon.png'} width='40px' height='40px' style={{ margin: 'auto' }} /></span>
                </div>
              </li>
              {(username !== undefined ?
                <div id='account-nav-dropdown-box' className='nav-dropdown-box account-nav-dropdown' onMouseEnter={openDropdown} onMouseLeave={closeDropdown}>
                  <li><NavLink to={isMainInputTouch ? '' : '/Account'} className='topbar-link' activeClassName='active-page' onClick={isMainInputTouch ? openDropdown : refreshFunc}>
                    <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', lineHeight: '.9' }}>Duelist<br />{username}</span></NavLink>
                  </li>
                  <ul id='account-nav-dropdown-list'>
                    <li><NavLink to='/Account' onClick={refreshFunc}><span>Settings</span></NavLink></li>
                    <li onClick={() => {props.triggerModal('Log Out');}}>Log Out</li>
                  </ul>
                </div> :
                <div id='account-nav-dropdown-box' className='nav-dropdown-box account-nav-dropdown' onMouseEnter={openDropdown} onMouseLeave={closeDropdown}>
                  <li><div className='topbar-link' onClick={isMainInputTouch ? openDropdown : () => {props.triggerModal('Duelist Login');}}><span>Log In</span></div></li>
                  <ul id='account-nav-dropdown-list'>
                    {isMainInputTouch ? <li onClick={() => {props.triggerModal('Duelist Login');}}>Duelist Login</li> : ''}
                    <li onClick={() => {props.triggerModal('New Duelist Registration');}}>Register</li>
                  </ul>
                </div>
              )}
            </div>}
        </ul>
      </nav>
      {isScreen950 ? <strong className='logo' /> : ''}
      {isScreen950 ?
        <nav id='nav2' className='nav'>
          <img src="/images/Account.png" width='30px' height='30px' onClick={toggleNav2Dropdown} style={{ margin: '7px 7px 0px 0px' }}/>
          {username !== undefined ?
            <ul id='nav2-ul' style={{ right: 0 }}>
              <li style={{ background: '#626262', height: 'max-content', textAlign: 'left', padding: '5px 5px 5px 7px', marginRight: 'auto', lineHeight: '.9',
                whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                <span style={{ color: '#f1f1f1' }}>
                  Duelist<br />{username}
                </span>
              </li>
              <li title='Toggle Dark Mode' onClick={toggleDarkMode}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <img src={'/images/Moon.png'} width='20px' height='20px' style={{ margin: 'auto', alignSelf: 'baseline', marginRight: '5px' }} />
                  <span>Dark Mode</span>
                </div>
              </li>
              <li><NavLink to='/Account' onClick={refreshFunc}><span>Settings</span></NavLink></li>
              <li onClick={() => {props.triggerModal('Log Out');}}>Log Out</li>
            </ul> :
            <ul id='nav2-ul' style={{ right: 0 }}>
              <li title='Toggle Dark Mode' onClick={toggleDarkMode}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <img src={'/images/Moon.png'} width='20px' height='20px' style={{ margin: 'auto', alignSelf: 'baseline', marginRight: '5px' }} />
                  <span>Dark Mode</span>
                </div>
              </li>
              <li onClick={() => {props.triggerModal('Duelist Login');}}>Duelist Login</li>
              <li onClick={() => {props.triggerModal('New Duelist Registration');}}>Register</li>
            </ul>
          }
        </nav> : ''}
    </header>
  );
}
