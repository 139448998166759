import { useState, useEffect, useContext, useRef } from 'react';
import { exportComponentAsPNG } from '../react-component-export-image/index';
import { openErrorModal } from '../utils/BasicModalFuncs';
import { imagePath, cardImagePath, RarityLevel, contactString } from '../utils/Constants';
import { AppContext } from '../utils/Contexts';
import { cards } from '../utils/CardDataCache';

interface PullsExportProps {
  id: string,
  setName: string,
  totalPulls: any,
  totalsGroupField: string,
  finishedDispatch: any,
}

export default function PullsImageExport(props: PullsExportProps) {

  const { isScreen1150Query } = useContext(AppContext);
  const [isScreen1150, setIsScreen1150] = useState(isScreen1150Query.matches);
  const [imageFailedToLoad, setImageFailedToLoad] = useState(false);
  const [imageResult, setImageResult] = useState<any>(null);
  const [clipboardResult, setClipboardResult] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const imageExportZone = useRef<any>();

  useEffect(() => {
    isScreen1150Query.addEventListener('change', checkScreen1150); // To avoid re-rendering the whole page and losing data, we have to listen and state change on the sublevel
    exportComponentAsPNG(imageExportZone, {
      fileName: props.setName + ' Pulls',
      html2CanvasOptions: {
        scrollX: -window.scrollX,
        scrollY: -window.scrollY,
        windowWidth: document.documentElement.offsetWidth,
      },
    }).then(async (data: any) => {
      const result = await data;
      setImageResult(result.dataURL);
      if (result.blob != null) {
        try {
          const clipItem = [new ClipboardItem({ 'image/png': result.blob })];
          setClipboardResult(clipItem);
        }
        catch (err) {
          console.log('Clipboard not supported');
          setLoading(false);
        }
      }
      else setLoading(false);
    });

    return () => { // Unmount
      isScreen1150Query.removeEventListener('change', checkScreen1150);
    };
  }, []);

  useEffect(() => {
    if (clipboardResult != null) {
      setLoading(false);
    }
  }, [clipboardResult]);

  function checkScreen1150() {
    setIsScreen1150(isScreen1150Query.matches);
  }

  function closeZonesImageExportModal() {
    document.getElementById(props.id)!.style.display = 'none';
    props.finishedDispatch(false);
  }

  function replaceWithDefaultImg(event: any) {
    setImageFailedToLoad(true);
  }

  function imageProperlyLoaded(event: any) {
    setImageFailedToLoad(false);
  }

  function copyToClipboard() {
    if (clipboardResult != null) {
      navigator.clipboard.write(clipboardResult)
        .catch((err: any) => {
          openErrorModal('Issue copying image to clipboard.<br />If this persists, ' + contactString);
          return;
        });
      const popup = document.getElementById('img-copied-popup')!;
      popup.style.transition = '0s';
      popup.style.opacity = '100';
      popup.style.visibility = 'visible';

      setTimeout(function() {
        popup.style.transition = '.8s';
        popup.style.opacity = '0';
        popup.style.visibility = 'hidden';
      }, 400);
    }
  }

  function renderTotalPulls() {
    const binCounts: any = {};
    const groupedPulls = props.totalPulls.reduce((grouped: any, card: any) => {
      let group: string = '';
      switch (props.totalsGroupField) {
        case 'type':
          group = cards[card.id].type;
          break;
        default: // Rarity
          group = card.rarity;
          break;
      }
      if (!Object.prototype.hasOwnProperty.call(grouped, group)) {
        grouped[group] = [card];
        binCounts[group] = card.count;
      }
      else {
        grouped[group].push(card);
        binCounts[group] += card.count;
      }

      return grouped;
    }, {});
    return <div className='total-pulls-container content-box'>
      {Object.keys(groupedPulls)
        .sort((a: string, b: string) => {
          switch (props.totalsGroupField) {
            case 'type':
              return (a > b) ? 1 : -1;
            default: // Rarity
              return (RarityLevel[a] > RarityLevel[b]) ? -1 : 1;
          }
        }) // Sort by rarity, highest first
        .map((group: string) => {
          const groupedBin = groupedPulls[group];
          if (groupedBin.length > 0) {
            return (
              <div key={group + 'totals'}>
                <h3>{group + ' - ' + binCounts[group]}</h3>
                <div className='total-pulls-group-bin'>
                  {(() => {
                    // const groupedBin: {[key: string]: PackCard[]} = {}; // Group by card type
                    return groupedBin
                      .sort((a: any, b: any) => { // Sort by quantity then name
                        if (a.count > b.count) return -1;
                        else if (a.count == b.count) return a.name.localeCompare(b.name);
                        else return 1;
                      })
                      .map((card: any) => {
                        return (
                          <div className='total-pulls-card' key={card.rarity + card.name}>
                            <div className='card-link'>
                              <div className='total-pulls-card-count'>
                                <span>{card.count}</span>
                              </div>
                              {imageFailedToLoad ?
                                <div className='card-image' style={{ width: '100%', height: 'auto', position: 'relative' }}>
                                  <img className='card-image' src={imagePath + 'FailedCardLoad.png' + '?' + Date.now()} alt={card.name} width='120px' height='175px'
                                    style={{ width: isScreen1150 ? '68.57px' : '137.14px', height: isScreen1150 ? '100px' : '200px' }} />
                                  <span className='replacement-card-title' style={{ display: 'inline', fontSize: '9px' }}>{card.name}</span>
                                </div> :
                                <div className='card-image'>
                                  <img
                                    src={cardImagePath + card.id + '.jpg'}
                                    alt={card.name}
                                    width={isScreen1150 ? '68.57px' : '137.14px'}
                                    height={isScreen1150 ? '100px' : '200px'}
                                    style={{}}
                                    onLoad={imageProperlyLoaded}
                                    onError={replaceWithDefaultImg}
                                  />
                                </div>}
                            </div>
                          </div>
                        );
                      });
                  })()}
                </div>
              </div>
            );
          }
        })}
    </div>;
  }

  return (
    <div id={props.id} className='modal' style={{ display: 'block' }}>
      <button className='modal-close' title='Close modal' onClick={closeZonesImageExportModal} />
      <div className='content-box modal-content' style={{ top: '5%', padding: '0px', paddingTop: '10px', paddingBottom: '10px' }}>
        <div id='img-copied-popup' className='clipboard-popup'><span>Image copied to clipboard!</span></div>
        <h5 style={{ marginBottom: '0px' }}>Export as Image</h5>
        {loading ? <img src={'/images/LoadingAnim.png'} width='50px' height='50px' style={{ margin: '5px auto auto auto' }} /> :
          (clipboardResult != null ? <button className='black-button' style={{ margin: '5px auto auto auto', display: 'block' }} disabled={imageResult == null}
            title='Copy image to clipboard' onClick={copyToClipboard}><span>Copy to Clipboard</span></button> :
            <span className='largetext-label'>Image complete, right click to copy</span>)}
        {clipboardResult == null ? <div style={{ background: 'var(--contentBoxColor)', padding: '5px 20px 10px 17px', margin: '0px' }} ref={imageExportZone}>
          <div style={{
            display: 'grid',
            gridTemplateColumns: '6fr repeat(1, auto) 6fr',
            justifyItems: 'center',
            margin: 'auto',
            height: 'fit-content',
            alignItems: 'center',
            textAlign: 'left',
            verticalAlign: 'middle',
            marginTop: '10px',
            marginBottom: '0px',
          }}>
            <div className='grid-left'>
              <h5 style={{ marginTop: '0px', marginBottom: '0px', textAlign: 'left', fontSize: isScreen1150 ? '32px' : '46px' }}>{props.setName + ' Pulls'}</h5>
            </div>
            <div className='grid-middle' />
            <div className='grid-right' style={{ height: '54px' }}>
              <h5 className='largetext-label' style={{ fontWeight: 900, fontSize: isScreen1150 ? '18px' : '22px', margin: '0px', display: 'block', textAlign: 'right' }}>
                YGOProg.com
              </h5>
              <span className='largetext-label' style={{ fontWeight: 500, fontSize: isScreen1150 ? '13px' : '16px', margin: '0px', display: 'block' }}>
                Made with the Pack Opener
              </span>
            </div>
          </div>
          {renderTotalPulls()}
        </div> :
          <img
            src={imageResult}
            alt={props.setName + ' Pulls Image'}
            style={{ maxWidth: 'min(1315px, 90vw)' }}
          />}
      </div>
    </div>
  );
}
