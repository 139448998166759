import * as Constants from '../utils/Constants';

interface SearchBoxProps {
  uniqueID: string,
  emptyDisplay: string,
  width: string,
  searchFunc: any,
  style: any,
  searchOnValueChange: boolean
}

export default function SearchBox(props: SearchBoxProps) {

  function search() {
    props.searchFunc((document.getElementById(props.uniqueID+'-search-input') as HTMLInputElement).value);
  }

  if (props.searchOnValueChange) {
    return (
      <div className='input-box' style={props.style}>
        <input id={props.uniqueID + '-search-input'} type="text" placeholder={props.emptyDisplay}
          onKeyDown={(e) => {if (e.code === 'Enter') search();}}
          onInput={search}
          style={{ width: props.width }} />
        <img src={Constants.imagePath + 'SearchIcon.png'} width='18px' height='18px' title='Search' onClick={search} />
      </div>
    );
  }
  else {
    return (
      <div className='input-box' style={props.style}>
        <input id={props.uniqueID + '-search-input'} type="text" placeholder={props.emptyDisplay}
          onKeyDown={(e) => {if (e.code === 'Enter') search();}}
          style={{ width: props.width }} />
        <img src={Constants.imagePath + 'SearchIcon.png'} width='18px' height='18px' title='Search' onClick={search} />
      </div>
    );
  }
}
