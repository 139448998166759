import React, { useState, useEffect } from 'react';
import Login from './Login';
import Registration from './Registration';
import { cookies, sessionMemory, onCookieChangeListeners, removeCookie, contactString, apiPath } from '../utils/Constants';
import localforage from 'localforage';
import { openErrorModal } from '../utils/BasicModalFuncs';
import makeApiCall from '../utils/ApiMiddleware';

export default function LoginAndRegistration(props: { modal: boolean, firstPage: string, closeModal: any }) {

  const [currentPage, setCurrentPage] = useState(props.firstPage);
  const [username, setUsername] = useState(cookies.get('user'));

  useEffect(() => { // Mount
    changePage(props.firstPage);
    onCookieChangeListeners.push(cookieRefresh);

    return () => { // Unmount
      onCookieChangeListeners.splice(onCookieChangeListeners.indexOf(cookieRefresh), 1);
    };
  }, []);

  function cookieRefresh() {
    setUsername(cookies.get('user'));
  }

  function changePage(newPage: string) {
    setCurrentPage(newPage);
    if (newPage == 'Log Out') {
      // call logout with userId and refresh token
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ userId: cookies.get('userId'), refreshToken: cookies.get('refresh') }),
      };
      makeApiCall(cookies.get('refresh'), cookies.get('userId'), apiPath + 'logout', requestOptions);
      removeCookie('user');
      removeCookie('userId');
      removeCookie('token');
      removeCookie('refresh');
      sessionMemory.lastBinderSelected = '';
      setUsername(undefined);
      localforage.removeItem('decks')
        .catch(() => {
          openErrorModal('Issue clearing decks on logout.<br />' + contactString);
        });
      localforage.removeItem('banlists')
        .catch(() => {
          openErrorModal('Issue clearing banlists on logout.<br />' + contactString);
        });
      localforage.removeItem('binders')
        .catch(() => {
          openErrorModal('Issue clearing binders on logout.<br />' + contactString);
        });
    }
  }

  function getPage() { // I went ahead and made this a switch because I'm not sure yet how we'll be handling email verification and other auths verification
    let component: any;
    if (username !== undefined) {
      component = <div>
        <p>You are logged in, click outside this box to close.</p>
      </div>;
    }
    else {
      switch (currentPage) {
        case 'Log Out':
          component = <div>
            <p>You have been logged out.</p>
            <span className='black-underline-link' title='Open login modal' onClick={() => {changePage('Duelist Login');}}>Return to Login</span>
          </div>;
          break;
        case 'Login Complete':
          if (props.closeModal != null) props.closeModal();
          component = <span>Login and Registration complete.</span>;
          break;
        case 'New Duelist Registration':
          component = <Registration {...{ modal: props.modal, page: currentPage, setPage: changePage }} />;
          break;
        default:
          component = <Login {...{ modal: props.modal, page: currentPage, setPage: changePage }} />;
          break;
      }
    }

    return component;
  }

  return (
    <div data-page={currentPage}>
      {props.modal ?
        <div className='content-box modal-content'>
          {getPage()}
        </div> :
        <div>
          <h1>{currentPage}</h1>
          {getPage()}
        </div>
      }
    </div>
  );
}
