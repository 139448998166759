import { useEffect, useState, useRef } from 'react';
import { cookies, apiPath, lowercaseContactString, contactString, imagePath } from '../utils/Constants';
import makeApiCall from '../utils/ApiMiddleware';
import { openErrorModal } from '../utils/BasicModalFuncs';
import localforage from 'localforage';
import SearchBox from './SearchBox';
import BlackDropdown from './BlackDropdown';
import SaveAsGroupModal from './SaveAsGroupModal';
import DeleteConfirmModal from './DeleteConfirmModal';
import { dropdownOptsFromGroups } from '../utils/CardDataCache';
import { useDrag, useDrop } from 'react-dnd';
import { GroupManagementItem } from './GroupManagementItem';
import { GroupManagementGroupDrop } from './GroupManagementGroupDrop';


const currentDragItem = { id: '', groupName: '', index: -1, originalGroupName: '', originalIndex: -1 }; // I don't have these as state because they need to be updated immediately for processing so setState doesn't cut it
const dragTask: { flag: NodeJS.Timeout | null } = { flag: null };
let searchTask: any;

interface GroupManagementProps {
  id: string,
  titleCaseSingularType: string,
  visibleStateDispatch: any,
  storageName: string,
  list: DropdownOption[],
  updateListDispatch: any,
  currentItem: string,
  updateCurrentItemDispatch: any,
  currentGroup: string,
  updateCurrentGroupDispatch: any,
  openNewGroupModal: any,
  isScreen950: any
}

export default function GroupManagementWindow(props: GroupManagementProps) {

  const mounted = useRef(false);
  const [groupedItemsList, setGroupedItemsList] = useState<DropdownOption[]>(props.list);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [saving, setSaving] = useState(false);
  const [searchString, setSearchString] = useState<string>('');
  const [searchResults, setSearchResults] = useState<DropdownOption[]>(props.list);
  const [searchedItemCount, setSearchedItemCount] = useState(0);
  const [selectedItems, setSelectedItems] = useState<DropdownOption[]>([]);
  const [allSearchItemsSelected, setAllSearchItemsSelected] = useState(false);
  const [deletedItems, setDeletedItems] = useState<DropdownOption[]>([]);
  const [confirmMatchString, setConfirmMatchString] = useState('');
  const [renameGroupName, setRenameGroupName] = useState('');
  const [groupIsExpanded, setGroupIsExpanded] = useState<{ [key: string]: boolean }>({});

  const groupedItemsListRef = useRef<DropdownOption[]>([]);
  const searchResultsRef = useRef<DropdownOption[]>([]);
  groupedItemsListRef.current = groupedItemsList;
  searchResultsRef.current = searchResults;

  useEffect(() => { // Mount
    return () => { // Unmount
      if (searchTask != null) {
        clearTimeout(searchTask);
        searchTask = null;
      }
    };
  }, []);

  useEffect(() => {
    setGroupedItemsList(props.list);
  }, [props.list]);

  useEffect(() => {
    search(searchString);
  }, [groupedItemsList]);

  useEffect(() => {
    setSelectedItems([]);
    setAllSearchItemsSelected(false);
  }, [searchResults]);

  useEffect(() => {
    if (mounted.current) {
      if (searchTask != null) clearTimeout(searchTask);
      searchTask = setTimeout(() => {
        search(searchString);
      }, 300);
    }
    else mounted.current = true;
  }, [searchString]);

  useEffect(() => {
    if (confirmMatchString != '') {
      document.getElementById('group-delete-confirm-modal')!.style.display = 'flex';
    }
  }, [confirmMatchString]);

  useEffect(() => {
    if (renameGroupName != '') document.getElementById('rename-group-modal')!.style.display = 'flex';
  }, [renameGroupName]);

  function search(searchTerm: string) {
    const updatedSearchGroups = structuredClone(groupedItemsList);
    let updatedCount = 0;
    for (let i: number = updatedSearchGroups.length - 1; i >= 0; i--) {
      if (updatedSearchGroups[i].subItems !== undefined) {
        updatedSearchGroups[i].subItems = updatedSearchGroups[i].subItems!.filter((item: DropdownOption) => item.text.toLowerCase().includes(searchTerm.toLowerCase()));
        updatedCount += updatedSearchGroups[i].subItems!.length;
        if (searchTerm != '' && updatedSearchGroups[i].subItems!.length < 1) updatedSearchGroups.splice(i, 1); // Remove empty groups
      }
    }

    setSearchedItemCount(updatedCount);
    setSearchResults(updatedSearchGroups);
  }

  function closeGroupManagementWindow() {
    if (unsavedChanges) document.getElementById('saveCheckModal')!.style.display = 'block';
    else props.visibleStateDispatch(false);
  }

  function discardChanges() {
    setGroupedItemsList(props.list);
    setDeletedItems([]);
    setUnsavedChanges(false);
  }

  function confirmSaveChanges() {
    if (deletedItems.length > 0) setConfirmMatchString('DELETE');
    else saveChanges();
  }

  async function saveChanges() {
    setSaving(true);
    const refresh = cookies.get('refresh');
    const userId = cookies.get('userId');
    const token = cookies.get('token');

    if (deletedItems.length > 0) { // Do deletion first
      const deleteRequestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
        body: JSON.stringify({ ['delete_' + props.titleCaseSingularType.toLowerCase() + '_ids']: deletedItems.map((item: DropdownOption) => item.value) }),
      };

      const deleteResponse = await makeApiCall(refresh, userId, apiPath + 'user/' + userId + '/' + props.storageName + '/delete', deleteRequestOptions);
      if (!deleteResponse.ok) {
        const error = deleteResponse.status;
        openErrorModal('Issue deleting ' + props.titleCaseSingularType + 's. Changes could not be saved.<br />' +
          (error == 401 ? '<br />Try logging out and logging back in.<br />If this persists, ' + lowercaseContactString : '') +
          (error == 400 ? '<br />You have been inactive for a long time. Please log out and back in.<br /><br />If this persists, ' + lowercaseContactString : '') +
          (error != 401 && error != 400 ? '<br />' + contactString : ''));
        setSaving(false);
        return Promise.reject(error);
      }
    }

    const updateRequestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
      body: JSON.stringify({
        [props.titleCaseSingularType.toLowerCase() + '_groups']:
          groupedItemsList.map((group: DropdownOption) => {
            return {
              name: group.text,
              [props.titleCaseSingularType.toLowerCase() + 's']: (group.subItems??[]).map((item: DropdownOption) => {return { _id: item.value, name: item.text };}),
            };
          }),
      }),
    };

    makeApiCall(refresh, userId, apiPath + 'user/' + userId + '/' + props.storageName, updateRequestOptions)
      .then(async (response) => {
        if (!response.ok) {
          const error = response.status;
          openErrorModal('Issue updating ' + props.titleCaseSingularType + 's list. Changes may not have been saved<br />' +
            (error == 401 ? '<br />Try logging out and logging back in.<br />If this persists, ' + lowercaseContactString : '') +
            (error == 400 ? '<br />You have been inactive for a long time. Please log out and back in.<br /><br />If this persists, ' + lowercaseContactString : '') +
            (error != 401 && error != 400 ? '<br />' + contactString : ''));
          setSaving(false);
          return Promise.reject(error);
        }

        const data = await response.json();

        await localforage.setItem(props.storageName, dropdownOptsFromGroups(data, props.storageName))
          .then(() => {
            setUnsavedChanges(false);
            setDeletedItems([]);
            props.updateListDispatch();
            if (props.currentItem != '') { // Relocate the currently viewed item for when we go back to it, or clear if it was deleted
              let foundCurrent = false;
              for (const group of groupedItemsListRef.current) {
                if (group.subItems !== undefined) {
                  if (group.subItems.findIndex((item: DropdownOption) => item.value == props.currentItem) != -1) {
                    props.updateCurrentGroupDispatch(group.text);
                    foundCurrent = true;
                  }
                }
              }
              if (!foundCurrent) {
                props.updateCurrentGroupDispatch('');
                props.updateCurrentItemDispatch('');
              }
            }
            setSaving(false);
          });
      });

  }

  function updateSearchString(newSearchTerm: string) {
    if (newSearchTerm != searchString) setSearchString(newSearchTerm);
    else {
      if (searchTask != null) clearTimeout(searchTask);
      searchTask = setTimeout(() => {
        search(searchString);
      }, 300);
    }
  }

  function ungroupGroup(groupName: string) {
    const updatedGroupedItems = structuredClone(groupedItemsList);
    const deleteGroupIndex = updatedGroupedItems.findIndex((group: DropdownOption) => group.text == groupName);
    const baseGroupIndex = updatedGroupedItems.findIndex((group: DropdownOption) => group.text == '');

    if (deleteGroupIndex > -1) {
      if (baseGroupIndex > -1) { // Dump the items back into the base group before deleting
        if (updatedGroupedItems[baseGroupIndex].subItems !== undefined) updatedGroupedItems[baseGroupIndex].subItems!.push(...updatedGroupedItems[deleteGroupIndex].subItems??[]);
        else updatedGroupedItems[baseGroupIndex].subItems = [...updatedGroupedItems[deleteGroupIndex].subItems??[]];
      }
      else updatedGroupedItems.push({ text: '', value: 'group', subItems: [...updatedGroupedItems[deleteGroupIndex].subItems??[]] } as DropdownOption);

      updatedGroupedItems[deleteGroupIndex].subItems = [];
      if (!unsavedChanges) setUnsavedChanges(true);
      setGroupedItemsList(updatedGroupedItems);
    }
  }

  function deleteGroup(groupName: string) {
    const updatedGroupedItems = structuredClone(groupedItemsList);
    const deleteGroupIndex = updatedGroupedItems.findIndex((group: DropdownOption) => group.text == groupName);

    if (deleteGroupIndex > -1) {
      setDeletedItems([...deletedItems, ...updatedGroupedItems[deleteGroupIndex].subItems??[]]);
      updatedGroupedItems.splice(deleteGroupIndex, 1);
      if (!unsavedChanges) setUnsavedChanges(true);
      setGroupedItemsList(updatedGroupedItems);
    }
  }

  function renameGroup(updatedGroupedItems: DropdownOption[], groupName: string) {
    setGroupedItemsList(updatedGroupedItems);
    if (!unsavedChanges) setUnsavedChanges(true);
  }

  function addSelectedToNewGroup(updatedGroupedItems: DropdownOption[], groupName: string) {
    if (selectedItems.length > 0) {
      const addGroup = updatedGroupedItems.find((group: DropdownOption) => group.text == groupName);
      if (addGroup !== undefined) {
        for (let i: number = updatedGroupedItems.length - 1; i >= 0; i--) {
          if (updatedGroupedItems[i].subItems !== undefined) {
            updatedGroupedItems[i].subItems = updatedGroupedItems[i].subItems!.filter((item: DropdownOption) => selectedItems.findIndex((sItem: DropdownOption) => sItem.value == item.value) == -1);
            if (updatedGroupedItems[i].subItems!.length < 1 && updatedGroupedItems[i].text != groupName && updatedGroupedItems[i].text != '') updatedGroupedItems.splice(i, 1); // Remove empty groups
          }
        }

        addGroup.subItems!.push(...selectedItems);
        if (!unsavedChanges) setUnsavedChanges(true);
      }
    }
    setGroupedItemsList(updatedGroupedItems);
  }

  function addSelectedToGroup(groupName: string) {
    if (selectedItems.length > 0) {
      const updatedGroupedItems = structuredClone(groupedItemsList);
      const addGroup = updatedGroupedItems.find((group: DropdownOption) => group.text == groupName);
      if (addGroup !== undefined) {
        for (let i: number = updatedGroupedItems.length - 1; i >= 0; i--) {
          if (updatedGroupedItems[i].subItems !== undefined) {
            updatedGroupedItems[i].subItems = updatedGroupedItems[i].subItems!.filter((item: DropdownOption) => selectedItems.findIndex((sItem: DropdownOption) => sItem.value == item.value) == -1);
            if (updatedGroupedItems[i].subItems!.length < 1 && updatedGroupedItems[i].text != groupName && updatedGroupedItems[i].text != '') updatedGroupedItems.splice(i, 1); // Remove empty groups
          }
        }

        addGroup.subItems!.push(...selectedItems);
        if (!unsavedChanges) setUnsavedChanges(true);
        setGroupedItemsList(updatedGroupedItems);
      }
    }
  }

  function deleteSelected() {
    if (selectedItems.length > 0) {
      const updatedGroupedItems = structuredClone(groupedItemsList);
      for (let i: number = updatedGroupedItems.length - 1; i >= 0; i--) {
        if (updatedGroupedItems[i].subItems !== undefined) {
          updatedGroupedItems[i].subItems = updatedGroupedItems[i].subItems!.filter((item: DropdownOption) => selectedItems.findIndex((sItem: DropdownOption) => sItem.value == item.value) == -1);
          if (updatedGroupedItems[i].subItems!.length < 1 && updatedGroupedItems[i].text != '') updatedGroupedItems.splice(i, 1); // Remove empty groups
        }
      }

      setDeletedItems([...deletedItems, ...selectedItems]);
      if (!unsavedChanges) setUnsavedChanges(true);
      setGroupedItemsList(updatedGroupedItems);
    }
  }

  function toggleSelectItem(e: any) {
    const selectedIndex = selectedItems.findIndex((sI: DropdownOption) => sI.value == e.currentTarget.dataset.value);

    if (selectedIndex >= 0) {
      const sItems = [...selectedItems];
      sItems.splice(selectedIndex, 1);
      setSelectedItems(sItems);
    }
    else {
      setSelectedItems([...selectedItems,
        groupedItemsList.find((group: DropdownOption) => group.text == e.currentTarget.dataset.group)!.subItems!.find((item: DropdownOption) => item.value == e.currentTarget.dataset.value)!]);
    }
  }

  function toggleSelectAll() {
    const allSelect = !allSearchItemsSelected;

    if (allSelect) {
      const sItems: DropdownOption[] = [];
      searchResults.forEach((group: DropdownOption) => {
        if (group.subItems !== undefined) sItems.push(...group.subItems);
      });
      setSelectedItems(sItems);
    }
    else {
      setSelectedItems([]);
    }

    setAllSearchItemsSelected(allSelect);
  }

  function openNewGroupModal() {
    document.getElementById('new-group-modal')!.style.display = 'flex';
  }

  function toggleGroupExpand(groupName: string) {
    const updatedGrpExpand = { ...groupIsExpanded, [groupName]: !(groupIsExpanded[groupName]??true) };
    setGroupIsExpanded(updatedGrpExpand);
  }

  function setCurrentDragItem(id: string, groupName: string, index: number) {
    currentDragItem.id = id;
    currentDragItem.groupName = groupName;
    currentDragItem.originalGroupName = groupName;
    currentDragItem.index = index;
    currentDragItem.originalIndex = index;
  }

  function clearCurrentDragItem() {
    currentDragItem.id = '';
    currentDragItem.groupName = '';
    currentDragItem.originalGroupName = '';
    currentDragItem.index = -1;
    currentDragItem.originalIndex = -1;
  }

  function moveDraggedItem(targetGroupName: string, targetIndex: number) {
    const updatedSearchResults = structuredClone(searchResultsRef.current); // Technically since subItems is an array inside objects we could just mutate the state directly (and have accidentally before), but that's very bad hat
    const draggedItemGroup = updatedSearchResults.find((group: DropdownOption) => group.text == currentDragItem.groupName)!;
    const draggedItem = draggedItemGroup.subItems!.splice(currentDragItem.index, 1)[0];

    if (targetIndex == -1) { // Resetting to original position
      updatedSearchResults.find((group: DropdownOption) => group.text == currentDragItem.originalGroupName)!.subItems!.splice(currentDragItem.originalIndex, 0, draggedItem);
      clearCurrentDragItem();
    }
    else {
      updatedSearchResults.find((group: DropdownOption) => group.text == targetGroupName)!.subItems!.splice(targetIndex, 0, draggedItem);
      currentDragItem.groupName = targetGroupName;
      currentDragItem.index = targetIndex;
    }

    setSearchResults(updatedSearchResults);
  }

  function commitDraggedItem() { // The item is already previewed in position in the search results, just have to reflect that in grouped items
    const updatedGroupedItems = structuredClone(groupedItemsListRef.current);
    const draggedItemGroup = searchResultsRef.current.find((group: DropdownOption) => group.text == currentDragItem.groupName)!;

    const originalGroup = updatedGroupedItems.find((group: DropdownOption) => group.text == currentDragItem.originalGroupName)!;
    const draggedItem = originalGroup.subItems!.splice(originalGroup.subItems!.findIndex((item: DropdownOption) => item.value == currentDragItem.id), 1)[0];

    const newGroup = updatedGroupedItems.find((group: DropdownOption) => group.text == currentDragItem.groupName)!;

    if (currentDragItem.index >= draggedItemGroup.subItems!.length - 1) { // Bottom item in group, didn't displace any visible items
      newGroup.subItems!.push(draggedItem);
    }
    else {
      const displacedItem = draggedItemGroup.subItems![currentDragItem.index + 1]; // Search results may have been missing items, so just put it next to the last thing it displaced
      newGroup.subItems!.splice(newGroup.subItems!.findIndex((item: DropdownOption) => item.value == displacedItem.value), 0, draggedItem);
    }

    clearCurrentDragItem();
    setUnsavedChanges(true);
    setGroupedItemsList(updatedGroupedItems);
  }

  function closeSaveCheckModal() {
    document.getElementById('saveCheckModal')!.style.display = 'none';
  }

  function acceptSaveCheckModal() {
    props.visibleStateDispatch(false);
  }

  return (
    <div>
      <div id={'saveCheckModal'} className='modal' style={{ zIndex: 9017 }}>
        <button className='modal-close' title='Close modal' onClick={closeSaveCheckModal} />
        <div className='content-box modal-content' style={{ zIndex: 9018 }}>
          <h5 style={{ marginBottom: '0px' }}>Leaving Without Saving</h5>
          <p className='largetext' style={{ paddingTop: '5px', marginBottom: '0px' }}>
            {'You have unsaved changes to your ' + props.titleCaseSingularType + 's,'}<br />if you close this window they will be discarded.
          </p>
          <button className='black-button white-button' style={{ marginRight: props.isScreen950 ? '5px' : '7px' }} title='Discard Changes & Close' onClick={acceptSaveCheckModal}>
            <span>Discard & Close</span>
          </button>
          <button className='black-button' style={{ marginRight: props.isScreen950 ? '5px' : '7px' }} title='Stay in Window' onClick={closeSaveCheckModal}><span>Stay</span></button>
        </div>
      </div>

      <DeleteConfirmModal {...{
        id: 'group-delete-confirm',
        title: 'Confirm Deletion of ' + deletedItems.length + ' ' + props.titleCaseSingularType + 's',
        matchString: confirmMatchString,
        callback: saveChanges,
        clearMatch: setConfirmMatchString,
        preConfirmStatement: 'This will delete ' + props.titleCaseSingularType + 's:\n' +
          deletedItems.reduce((list: string, item: DropdownOption, index: number) => list + (index > 0 ? ', ' : '') + item.text, '') + '\n\n',
      }} />
      <SaveAsGroupModal {...{ id: 'new-group-modal', isRename: false, groupName: '', storageName: props.storageName, list: groupedItemsList, updateListDispatch: addSelectedToNewGroup }} />
      <SaveAsGroupModal {...{ id: 'rename-group-modal', isRename: true, groupName: renameGroupName, storageName: props.storageName, list: groupedItemsList, updateListDispatch: renameGroup }} />

      <div id={props.id} className='modal' style={{ display: 'block' }}>
        <button className='modal-close' title='Close modal' onClick={closeGroupManagementWindow} />
        <div className='content-box modal-content' style={{ top: '10%', width: props.isScreen950 ? 'calc(99% - 12px)' : 'max(40vw, 800px)', padding: props.isScreen950 ? '5px' : '10px 21px' }}>
          <img src={imagePath + 'Close.png'} width='20px' height='20px' className='close-button' title='Close Management Window' onClick={closeGroupManagementWindow}
            style={{ margin: 'auto', display: 'inline-block', position: 'absolute', right: '7px', top: '7px', cursor: 'pointer' }} />

          <h5 style={{ marginBottom: '0px' }}>{'Manage ' + props.titleCaseSingularType + 's'}</h5>

          <div className='group-management-controls'>
            <span className='button-bar-label'>Search:</span>
            <SearchBox {...{
              uniqueID: props.id + '-search',
              emptyDisplay: 'Search...',
              width: '160px',
              searchFunc: updateSearchString,
              style: { marginRight: props.isScreen950 ? '5px' : '7px' },
              searchOnValueChange: true,
            }} />
            <span className='floating-text'>{searchedItemCount + ' ' + props.titleCaseSingularType + 's   ' + selectedItems.length + ' Selected'}</span>

            <div style={{ marginBottom: '5px' }} />

            <span className='button-bar-label'>Selected:</span>
            <button className='black-button text-icon-button' title={'Create a new group and add any selected ' + props.titleCaseSingularType + 's'}
              onClick={openNewGroupModal} style={{ marginRight: props.isScreen950 ? '5px' : '7px' }}>
              <img src={imagePath + 'BigPlus.png'} width='18px' height='20px' style={{ margin: 'auto', marginLeft: '4px' }} />
              <span style={{ marginLeft: '5px', marginRight: '5px' }}>New Group</span>
            </button>

            <BlackDropdown {...{
              uniqueID: 'add-to-group',
              isSelector: false,
              disabledVar: selectedItems.length < 1,
              optionsList: [{ text: 'Ungrouped', value: '', subItems: undefined, callback: addSelectedToGroup } as DropdownOption,
                ...groupedItemsList.filter((group) => group.text != '')
                  .map((group) => {return { text: group.text, value: group.text, subItems: undefined, callback: addSelectedToGroup } as DropdownOption;})],
              startingOption: null,
              defaultValue: '',
              defaultDisplay: '',
              nonSelectorDisplay: <div className='text-icon-button'>
                <img src={imagePath + 'Group.png'} width='18px' height='20px' style={{ margin: 'auto', marginLeft: '4px' }} />
                <span style={{ marginLeft: '5px', marginRight: '5px' }}>Add to Group</span>
              </div>,
              width: 'initial',
              stateVar: '',
              dispatch: null,
              title: 'Add any selected ' + props.titleCaseSingularType + 's to group',
              style: { marginRight: props.isScreen950 ? '5px' : '7px' },
            }} />

            <button className='black-button text-icon-button' title={'Delete selected ' + props.titleCaseSingularType + 's'} disabled={selectedItems.length < 1}
              onClick={deleteSelected} style={{ marginRight: props.isScreen950 ? '5px' : '7px' }}>
              <img src={imagePath + 'Trashcan.png'} width='18px' height='20px' style={{ margin: 'auto', marginLeft: '4px' }} />
              <span style={{ marginLeft: '5px', marginRight: '5px' }}>Delete</span>
            </button>

            <div className='checkbox' title='Select all visible cards' onClick={toggleSelectAll} style={{ marginRight: '5px' }}>
              <img className='checkbox-checkmark' src={imagePath + 'CheckmarkBlack.png'} width='20px' height='20px' style={{ display: (allSearchItemsSelected ? 'inline-block' : 'none') }} />
            </div>
            <span className='button-bar-label'>Select All</span>
          </div>

          {searchResults.length > 0 ? <div className='group-management-results'>
            {searchResults.sort((a: DropdownOption, b: DropdownOption) => {
              if (a.text == '') return 1;
              else if (b.text == '') return -1;
              else return 0;
            }).map((group: DropdownOption, index: number) => {
              if (group.text != '') {
                return <div className='group-search-group' key={'mgmt' + index.toString() + group.text} style={{ marginBottom: '5px' }}>
                  <GroupManagementGroupDrop {...{ className: 'group-header', groupName: group.text, dragTask: dragTask, moveDraggedItem: moveDraggedItem }}>
                    <div className='grid-left'>
                      <h3 title={group.text}>{group.text}</h3>
                    </div>
                    <div className='grid-right' style={{ display: 'grid', gridTemplateColumns: '1fr 30px' }}>
                      <div className='grid-left'>
                        <button className='black-button text-icon-button' title={'Delete group after moving out contained ' + props.titleCaseSingularType + 's'}
                          onClick={() => ungroupGroup(group.text)} style={{ marginRight: props.isScreen950 ? '5px' : '7px' }}>
                          <img src={imagePath + 'Ungroup.png'} width='18px' height='20px' style={{ margin: 'auto', marginLeft: '4px' }} />
                          <span style={{ marginLeft: '5px', marginRight: '5px' }}>Ungroup</span>
                        </button>
                        <button className='black-button text-icon-button' title={'Delete group and all contained ' + props.titleCaseSingularType + 's'}
                          onClick={() => deleteGroup(group.text)} style={{ marginRight: props.isScreen950 ? '5px' : '7px' }}>
                          <img src={imagePath + 'Trashcan.png'} width='18px' height='20px' style={{ margin: 'auto', marginLeft: '4px' }} />
                          <span style={{ marginLeft: '5px', marginRight: '5px' }}>Delete All</span>
                        </button>
                        <button className='black-button text-icon-button' title={'Rename group'} onClick={() => setRenameGroupName(group.text)} style={{ marginRight: '0px' }}>
                          <img src={imagePath + 'Edit.png'} width='18px' height='20px' style={{ margin: 'auto', marginLeft: '4px' }} />
                          <span style={{ marginLeft: '5px', marginRight: '5px' }}>Rename</span>
                        </button>
                      </div>
                      <div className='grid-right'>
                        <img className={'group-arrow'} src={imagePath + 'DownArrow.png'} width='24px' height='24px' onClick={() => toggleGroupExpand(group.text)}
                          style={{ paddingTop: '3px', transform: groupIsExpanded[group.text]??true == true ? 'rotate(180deg)' : '' }} />
                      </div>
                    </div>
                  </GroupManagementGroupDrop>
                  {groupIsExpanded[group.text]??true == true ? group.subItems?.map((item: DropdownOption, subindex: number) => {
                    return <GroupManagementItem key={'mgmtitem' + item.value.toString()} {...{
                      groupName: group.text,
                      indexInGroup: subindex,
                      item: item,
                      dragTask: dragTask,
                      moveDraggedItem: moveDraggedItem,
                      commitDraggedItem: commitDraggedItem,
                      setCurrentDragItem: setCurrentDragItem,
                      toggleSelectItem: toggleSelectItem,
                      isSelected: selectedItems.findIndex((sI: DropdownOption) => sI.value.toString() == item.value.toString()) != -1,
                      titleCaseSingularType: props.titleCaseSingularType,
                      isScreen950: props.isScreen950,
                    }} />;
                  }) : ''}
                </div>;
              }
              else {
                return <div key={'mgmtbase'}>{group.subItems?.map((item: DropdownOption, subindex: number) => {
                  return <GroupManagementItem key={'mgmtitem' + item.value.toString()} {...{
                    groupName: group.text,
                    indexInGroup: subindex,
                    item: item,
                    dragTask: dragTask,
                    moveDraggedItem: moveDraggedItem,
                    commitDraggedItem: commitDraggedItem,
                    setCurrentDragItem: setCurrentDragItem,
                    toggleSelectItem: toggleSelectItem,
                    isSelected: selectedItems.findIndex((sI: DropdownOption) => sI.value.toString() == item.value.toString()) != -1,
                    titleCaseSingularType: props.titleCaseSingularType,
                    isScreen950: props.isScreen950,
                  }} />;
                })}</div>;
              }
            })}
          </div> :
            <span className='empty-search'>No matching results.</span>}

          <div style={{ margin: '0px auto 0px auto' }}>
            <button className='black-button' style={{ marginRight: props.isScreen950 ? '5px' : '7px' }} disabled={!unsavedChanges}
              title='Discard changes and deletions' onClick={discardChanges}><span>Discard Changes</span></button>
            {saving ?
              <img src={'/images/LoadingAnim.png'} width='30px' height='30px' style={{ margin: 'auto 35px auto 35px', verticalAlign: 'middle' }} /> :
              <button className='black-button' style={{ marginRight: '0px' }} disabled={!unsavedChanges}
                title='Save changes and deletions' onClick={confirmSaveChanges}><span>Save Changes</span></button>}
          </div>
        </div>
      </div>
    </div>
  );
}
