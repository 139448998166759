import { useState, useContext, useEffect, ReactElement } from 'react';
import { cookies, onCookieChangeListeners, apiPath, imagePath, RarityLevel, sessionMemory,
  addCardsToBinder, shouldPagesRefresh, exportYdk, ExtraDeckSubtypes, MAIN_DECK_MAX, EXTRA_DECK_MAX,
  loadDeck, frontEndPath, contactString, lowercaseContactString, setCookie } from '../utils/Constants';
import { PackOpenerContext, AppContext } from '../utils/Contexts';
import HoverableCard from './HoverableCard';
import SaveAsBinderModal from './SaveAsBinderModal';
import BlackDropdown from './BlackDropdown';
import makeApiCall from '../utils/ApiMiddleware';
import { Prompt, useHistory } from 'react-router-dom';
import { openErrorModal } from '../utils/BasicModalFuncs';
import { cards, cardSetDates, cardSets } from '../utils/CardDataCache';
import PerformanceWarningModal from './PerformanceWarningModal';
import * as ydke from 'ydke';
import StringExportModal from './StringExportModal';
import PullsImageExport from './PullsImageExport';
import localforage from 'localforage';
import Ad from './Ad';

interface PackCard extends BaseCard {
  code: string,
  rarity: string,
  set: string,
  subtype: string[],
  count: number
}

interface DbPackInformation {
  cardCount: number,
  cardComposition: {
    [key: string]: number
  },
  foilRarityRatios: Map<string, string>
}

interface PackInformation {
  name: string,
  infoElement: ReactElement[] | null
}

let queuedCurrentBinder: string|null = null;

export default function OpenPack() {

  const [openNextButtonDisabled, setOpenNextButtonDisabled] = useState(true);
  const [flipButtonDisabled, setFlipButtonDisabled] = useState(true);
  const [openAllButtonDisabled, setOpenAllButtonDisabled] = useState(true);
  const [exportButtonsDisabled, setExportButtonsDisabled] = useState(true);
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);
  const [packPulls, setPackPulls] = useState<PackCard[][][][]>([]); // packPulls[indexOfBundle][indexOfSet][indexOfPack][indexOfCardInPack]
  const [pullsTotalLength, setPullsTotalLength] = useState(0);
  const [currentBundleIndex, setCurrentBundleIndex] = useState(0);
  const [currentSetIndex, setCurrentSetIndex] = useState(0);
  const [currentPackIndex, setCurrentPackIndex] = useState(0);
  const [currentOverallIndex, setCurrentOverallIndex] = useState(0);
  const [flippedCards, setFlippedCards] = useState<boolean[]>([]);
  const [draftCardAdded, setDraftCardAdded] = useState<boolean[]>([]);
  const [totalPulls, setTotalPulls] = useState<PackCard[]>([]);
  const [currentBinder, setCurrentBinder] = useState('');
  const [binderList, setBinderList] = useState<DropdownOption[]>([]);
  const [packInformation, setPackInformation] = useState<PackInformation[][]>([]);
  const [username, setUsername] = useState(cookies.get('user'));
  const [pullsSavedOrExported, setPullsSavedOrExported] = useState(false);
  const [savedToBinders, setSavedToBinders] = useState<string[]>([]);
  const [autoflipEnabled, setAutoflipEnabled] = useState<boolean>((cookies.get('autoflip')?.toLowerCase() == 'true')??false);

  const { state, dispatch: setPackOpenerState } = useContext(PackOpenerContext);
  const { refreshRouteFunc, refresh, isScreen950Query, isScreen1150Query } = useContext(AppContext);
  const [isScreen950, setIsScreen950] = useState(isScreen950Query.matches);
  const [isScreen1150, setIsScreen1150] = useState(isScreen1150Query.matches);
  const [navToLocation, setNavToLocation] = useState<any>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const [savingCards, setSavingCards] = useState(false);
  const [exportingPulls, setExportingPulls] = useState(false);
  const [exportingImage, setExportingImage] = useState(false);
  const [exportUrl, setExportUrl] = useState<{ type: string, exportString: string }>({ type: 'YDKe', exportString: '' });
  const [currentProduct, setCurrentProduct] = useState(state.currentSetToOpenPrefix.slice(0, -2)); // Remove the box info tag
  const [totalsGroupField, setTotalsGroupField] = useState(state.packOpenMode == 'Draft' ? 'type' : 'rarity');
  const history = useHistory();

  useEffect(() => { // Mount
    window.scrollTo({ top: 0, behavior: 'auto' });
    isScreen950Query.addEventListener('change', checkScreen950); // To avoid re-rendering the whole page and losing data, we have to listen and state change on the sublevel
    isScreen1150Query.addEventListener('change', checkScreen1150);

    if (state.packOpenMode == 'Sealed') {
      setCurrentProduct(state.currentSetToOpenPrefix.slice(0, -2));
      pullPack(currentProduct, state.packsToOpen[state.currentSetToOpenPrefix].productType, state.packsToOpen[state.currentSetToOpenPrefix].count);
    }
    else if (state.packOpenMode == 'Draft') {
      pullPack('', 'Draft', 1);
    }
    updateBinderList();
    onCookieChangeListeners.push(cookieRefresh);

    return () => { // Unmount
      isScreen950Query.removeEventListener('change', checkScreen950);
      isScreen1150Query.removeEventListener('change', checkScreen1150);
      window.onbeforeunload = null;
      if (!shouldPagesRefresh.val) {
        shouldPagesRefresh.val = true;
      }
      onCookieChangeListeners.splice(onCookieChangeListeners.indexOf(cookieRefresh), 1);
    };
  }, []);

  useEffect(() => {
    if (pullsSavedOrExported) {
      window.onbeforeunload = null;
      shouldPagesRefresh.val = true;
    }
    else {
      shouldPagesRefresh.val = false;
      window.onbeforeunload = (e) => {
        e.preventDefault();
        e.returnValue = 'You have not saved or exported your pulls, if you leave this page they will be lost.';
        return 'You have not saved or exported your pulls, if you leave this page they will be lost.'; // Custom message doesn't work on most browsers
      };
    }
  }, [pullsSavedOrExported]);

  useEffect(() => {
    if (!exportButtonsDisabled && (Object.values(state.packsToOpen) as PackCountInfo[])
      .filter((packInfo: PackCountInfo) => {return !packInfo.pullsCompleted;})?.length > 1) { // 1 to exclude this pack, which we haven't marked as completed yet
    }
  }, [exportButtonsDisabled]);

  useEffect(() => {
    if (confirmedNavigation) {
      if (navToLocation == 'PickNextPack') {
        goToNextSetPick();
      }
      else if (navToLocation) {
        history.push(navToLocation.pathname);
        refreshRouteFunc(!refresh);
      }
    }
  }, [confirmedNavigation]);

  useEffect(() => {
    queuedCurrentBinder = null;
    if (currentBinder != '') setSaveButtonDisabled(false);
  }, [currentBinder]);

  useEffect(() => {
    if (queuedCurrentBinder != null) setCurrentBinder(queuedCurrentBinder);
  }, [binderList]);

  useEffect(() => {
    if (packPulls?.length > 0) {
      if (autoflipEnabled && state.packOpenMode != 'Draft') {
        const newTotalPulls: PackCard[] = [];
        packPulls[0][0][0].forEach((card: PackCard) => {
          const cardInd: number = newTotalPulls.findIndex((pc: PackCard) => pc.name === card.name && pc.rarity === card.rarity);
          if (cardInd >= 0) newTotalPulls[cardInd].count += Number(1);
          else newTotalPulls.push({ ...card, count: Number(1) });
        });
        setTotalPulls(newTotalPulls);
      }

      if (state.packOpenMode != 'Draft' && !(packPulls.length == 1 &&
        packPulls[currentBundleIndex].length == 1 &&
        packPulls[currentBundleIndex][currentSetIndex].length == 1)) setOpenNextButtonDisabled(!autoflipEnabled);
    }
  }, [packPulls]);

  function checkScreen950() {
    setIsScreen950(isScreen950Query.matches);
  }

  function checkScreen1150() {
    setIsScreen1150(isScreen1150Query.matches);
  }

  function cookieRefresh() {
    setUsername(cookies.get('user'));
    setAutoflipEnabled((cookies.get('autoflip')?.toLowerCase() == 'true')??false);
    updateBinderList();
  }

  function toggleAutoflip() {
    setAutoflipEnabled((prevVal: boolean) => {
      setCookie('autoflip', !prevVal);
      if (!prevVal == true && flippedCards.some((fc: boolean) => fc == false)) flipAll();
      return !prevVal;
    });
  }

  function pullPack(prefix: string, productType: string, count: number) {
    let path = apiPath;
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    } as any;

    if (state.packOpenMode == 'Draft') {
      path += 'bundle/custom';
      requestOptions.method = 'POST';
      requestOptions.body = JSON.stringify({
        'packs': (Object.values(state.packsToOpen) as PackCountInfo[])
          .sort((a: PackCountInfo, b: PackCountInfo) => {
            return (a.order > b.order) ? 1 : -1;
          })
          .reduce((customBundle: any, set: PackCountInfo) => {
            customBundle.push({ 'set_code': set.prefix, 'count': set.count });
            return customBundle;
          }, []),
      });
    }
    else {
      switch (productType) {
        case 'Sets':
          path += 'pack?set=';
          break;
        case 'Boxes':
          path += 'box?set=';
          break;
        case 'Bundles':
        case 'Decks':
          path += 'bundle?code=';
          break;
      }
      path = path + prefix + '&count=' + count;
    }

    makeApiCall(cookies.get('refresh'), cookies.get('userId'), path, requestOptions)
      .then(async (response) => {
        if (!response.ok) {
          const error = response.status;
          openErrorModal('Issue generating ' + productType + ' pulls.<br />' +
            (error == 401 ? '<br />Try logging out and logging back in.<br />If this persists, ' + lowercaseContactString : '') +
            (error == 400 ? '<br />You have been inactive for a long time. Please log out and back in.<br /><br />If this persists, ' + lowercaseContactString : '') +
            (error != 401 && error != 400 ? '<br />' + contactString : ''));
          return Promise.reject(error);
        }

        const data = await response.json();

        setFlipButtonDisabled(autoflipEnabled);
        setOpenAllButtonDisabled(false);
        const pulls: PackCard[][][][] = [];
        const info: PackInformation[][] = [];
        let total = 0;
        let fc: boolean[] = [];
        let dc: boolean[] = [];
        switch (productType) { // packPulls[indexOfBundle][indexOfSet][indexOfPack][indexOfCardInPack]
          case 'Sets':
          case 'Boxes':
            total = data.packs.length;
            setPackPulls([[[...sortPacks([...data.packs], cardSets[currentProduct].name)]]]);
            setPackInformation([[{ name: cardSets[currentProduct].name, infoElement: createPackInfoElement(data.packInformation) }]]);
            fc = new Array<boolean>(data.packs[0].length).fill(autoflipEnabled);
            dc = new Array<boolean>(data.packs[0].length).fill(false);
            break;
          case 'Decks':
          case 'Bundles':
            data.bundles.forEach((bundle: any, bundleIndex: number) => {
              pulls.push([]); // Add pulls[bundleIndex]
              info.push([]);
              bundle.packs.forEach((set: any) => {
                total += set.packs.length; // The bundles are all the same type so we can use the same set of info for all of them
                info[bundleIndex].push({ name: set.name, infoElement: createPackInfoElement(set.packInformation) });
                pulls[bundleIndex].push(sortPacks(set.packs, set.name == 'Promos' ?
                  (cardSets[currentProduct].type == 'Modern Collectible Tins' ? cardSets[currentProduct].name : cardSets[currentProduct].type) : set.name)); // Add the array of packs at pulls[bundleIndex][setIndex]
              });
            });
            setPackPulls(pulls);
            setPackInformation(info);
            fc = new Array<boolean>(data.bundles[0].packs[0].packs[0].length).fill(autoflipEnabled);
            dc = new Array<boolean>(data.bundles[0].packs[0].packs[0].length).fill(false);
            break;
          case 'Draft':
            pulls.push([]); // Add pulls[bundleIndex]
            info.push([]);
            data.bundles.packs.forEach((set: any) => {
              total += set.packs.length; // The bundles are all the same type so we can use the same set of info for all of them
              info[0].push({ name: set.name, infoElement: createPackInfoElement(set.packInformation) });
              pulls[0].push(sortPacks(set.packs, set.name)); // Add the array of packs at pulls[bundleIndex][setIndex]
            });
            setPackPulls(pulls);
            setPackInformation(info);
            fc = new Array<boolean>(data.bundles.packs[0].packs[0].length).fill(autoflipEnabled);
            dc = new Array<boolean>(data.bundles.packs[0].packs[0].length).fill(false);
            break;
        }
        setPullsTotalLength(total);
        setFlippedCards(fc);
        setDraftCardAdded(dc);
        setOpenAllButtonDisabled(false);
        setCurrentOverallIndex(1);
      })
      .catch((err) => {
        openErrorModal('Issue generating ' + productType + ' pulls.<br />' + contactString);
      });
  }

  function createPackInfoElement(packInfoObject: DbPackInformation) {
    const packInfo = [];
    const ratioList = [];
    if (packInfoObject != null) {
      packInfo.push(<span key='cardCount'>There are {packInfoObject.cardCount} cards in each pack</span>);

      for (const [key, value] of Object.entries(packInfoObject.cardComposition)) {
        packInfo.push(<li key={value+key}>{value} guaranteed {key}</li>);
      }

      const foilCardRatios = Object.entries(packInfoObject.foilRarityRatios);
      let firstTime = 0;
      for (const [key, value] of foilCardRatios) {
        if (firstTime == 0) {
          packInfo.push(<li key={value+key}>One card which is either a {key} or a foil with these ratios: </li>);
          firstTime ++;
        }
        else {
          ratioList.push(<li key={value+key}>1 in {value} to be a {key}</li>);
        }
      }

      packInfo.push(<ul key='packInfoList' style={{ marginLeft: 0, left: 0, paddingLeft: '18px' }}>{ratioList}</ul>);
      return packInfo;
    }
    else return null;
  }

  function sortPacks(unsortedPacks: PackCard[][], setName: string) {
    const sortedPacks: PackCard[][] = [];
    unsortedPacks.forEach((pack: PackCard[]) => {
      sortedPacks.push(pack.map((c: PackCard) => {
        return {
          id: c.id,
          name: c.name,
          code: c.code,
          set: setName,
          rarity: c.rarity,
          subtype: cards[c.id].subtype,
          count: c.count,
        };
      }).sort((a: PackCard, b: PackCard) => {return (RarityLevel[a.rarity] >= RarityLevel[b.rarity]) ? 1 : -1;}));
    });

    return sortedPacks;
  }

  function pullCardClick(e: any) {
    const index: number = e.currentTarget.dataset.index;
    e.currentTarget.parentElement.style.transform = 'rotateY(180deg)'; // PullCardInner
    e.currentTarget.parentNode.children[1].children[e.currentTarget.parentNode.children[1].children.length - 1].style.filter = 'blur(0px)'; // Text gets weird glyph blockiness on flip, so we just handle it with blur
    const newFlipList = [...flippedCards];
    newFlipList[index] = true;
    setFlippedCards(newFlipList);

    if (state.packOpenMode == 'Sealed') addCardToTotal(index);

    let allFlipped = true;
    newFlipList.forEach((fc: boolean) => {
      if (fc == false) allFlipped = false;
    });
    setFlipButtonDisabled(allFlipped);
    if (state.packOpenMode == 'Sealed') {
      if (!(currentBundleIndex == packPulls.length - 1 &&
        currentSetIndex == packPulls[currentBundleIndex].length - 1 &&
        currentPackIndex == packPulls[currentBundleIndex][currentSetIndex].length - 1)) setOpenNextButtonDisabled(!allFlipped);
      else if (allFlipped) {
        setExportButtonsDisabled(false); // Enable saving buttons
      }
    }
  }

  function draftPlusClick(e: any) {
    const index: number = e.currentTarget.dataset.index;
    addCardToTotal(index);
    const newDraftAddedList = [...draftCardAdded];
    newDraftAddedList[index] = true;
    setDraftCardAdded(newDraftAddedList);

    if (currentBundleIndex == packPulls.length - 1 &&
      currentSetIndex == packPulls[currentBundleIndex].length - 1 &&
      currentPackIndex == packPulls[currentBundleIndex][currentSetIndex].length - 1) {
      setExportButtonsDisabled(false);
    }
    else setOpenNextButtonDisabled(false);
  }

  function draftUndoClick(e: any) {
    const index: number = e.currentTarget.dataset.index;
    addCardToTotal(index, -1);
    const newDraftAddedList = [...draftCardAdded];
    newDraftAddedList[index] = false;
    setDraftCardAdded(newDraftAddedList);

    if (newDraftAddedList.some((dca: boolean) => dca)) { // If any cards are still added
      if (currentBundleIndex == packPulls.length - 1 &&
        currentSetIndex == packPulls[currentBundleIndex].length - 1 &&
        currentPackIndex == packPulls[currentBundleIndex][currentSetIndex].length - 1) setExportButtonsDisabled(false);
      else setOpenNextButtonDisabled(false);
    }
    else {
      setOpenNextButtonDisabled(true);
      setExportButtonsDisabled(true);
    }
  }

  function addCardToTotal(index: number, count: number = 1) {
    const card: PackCard = packPulls[currentBundleIndex][currentSetIndex][currentPackIndex][index];
    const cardInd: number = totalPulls.findIndex((pc: PackCard) => pc.name === card.name && pc.rarity === card.rarity);
    if (cardInd >= 0) {
      const newTotalPulls: PackCard[] = [...totalPulls];
      newTotalPulls[cardInd].count += count;
      if (newTotalPulls[cardInd].count > 0) setTotalPulls(newTotalPulls);
      else {
        newTotalPulls.splice(cardInd, 1);
        setTotalPulls(newTotalPulls);
      }
    }
    else setTotalPulls([...totalPulls, { ...card, count: Number(1) }]);
  }

  function flipAll() {
    const pullCardInners = Array.from(document.getElementsByClassName('pull-card-inner') as HTMLCollectionOf<HTMLElement>);
    const flipRarityTexts = Array.from(document.getElementsByClassName('flip-rarity-text') as HTMLCollectionOf<HTMLElement>);

    if (state.packOpenMode == 'Sealed') {
      const newTotalPulls: PackCard[] = [...totalPulls];
      pullCardInners.forEach((element: HTMLElement) => {
        element.style.transform = 'rotateY(180deg)';
        if (typeof element.dataset.index !== 'undefined') {
          const index: number = parseInt(element.dataset.index);
          if (!flippedCards[index]) {
            const card: PackCard = packPulls[currentBundleIndex][currentSetIndex][currentPackIndex][index];
            const cardInd: number = newTotalPulls.findIndex((pc: PackCard) => pc.name === card.name && pc.rarity === card.rarity);
            if (cardInd >= 0) newTotalPulls[cardInd].count += Number(1);
            else newTotalPulls.push({ ...card, count: Number(1) });
          }
        }
      });
      setTotalPulls(newTotalPulls);
    }
    else {
      pullCardInners.forEach((element: HTMLElement) => {
        element.style.transform = 'rotateY(180deg)';
      });
    }

    flipRarityTexts.forEach((element: HTMLElement) => {
      element.style.filter = 'blur(0px)';
    });

    const newFlipList = [...flippedCards];
    newFlipList.fill(true);

    setFlippedCards(newFlipList);
    setFlipButtonDisabled(true);
    if (state.packOpenMode == 'Sealed') {
      if (!(currentBundleIndex == packPulls.length - 1 &&
        currentSetIndex == packPulls[currentBundleIndex].length - 1 &&
        currentPackIndex == packPulls[currentBundleIndex][currentSetIndex].length - 1)) setOpenNextButtonDisabled(false);
      else {
        setExportButtonsDisabled(false); // Enable all the saving buttons
      }
    }
  }

  function openNextPack() { // Important to note- the logic here assumes this function can never be called if there aren't more packs left
    let newFlipList = [...flippedCards];
    newFlipList.fill(autoflipEnabled);
    let newDraftAddedList = [...flippedCards];
    newDraftAddedList.fill(false);

    let pi = currentPackIndex + 1;
    let si = currentSetIndex;
    let bi = currentBundleIndex;
    if (pi >= packPulls[currentBundleIndex][currentSetIndex].length) { // Next pack is in the next set or bundle
      si += 1;
      if (si >= packPulls[currentBundleIndex].length) { // Next pack is in the next bundle
        bi += 1;
        if (bi == packPulls.length - 1 && (packPulls[bi].length < 2 && packPulls[bi][0].length < 2)) { // Next pack is in the final bundle and is the final pack in the final set
          setOpenNextButtonDisabled(true);
          setOpenAllButtonDisabled(true);
        }
        else setOpenAllButtonDisabled(false); // Next pack is in the next bundle and there are packs after it

        si = 0;
        setCurrentBundleIndex(bi);
      }
      else if (si == packPulls[currentBundleIndex].length - 1 && packPulls[currentBundleIndex][si].length < 2) {
        setOpenNextButtonDisabled(true);
        setOpenAllButtonDisabled(true); // Next pack is in the final set and is the final pack in that set
      }
      else setOpenAllButtonDisabled(false); // Next pack is in the next set and there are packs after it

      pi = 0;
      setCurrentSetIndex(si);
      newFlipList = new Array<boolean>(packPulls[bi][si][0].length).fill(autoflipEnabled);
      newDraftAddedList = new Array<boolean>(packPulls[bi][si][0].length).fill(false);
      if (state.packOpenMode == 'Draft') setOpenNextButtonDisabled(true);
    }
    else if (currentBundleIndex == packPulls.length - 1 &&
      currentSetIndex == packPulls[currentBundleIndex].length - 1 &&
      pi == packPulls[currentBundleIndex][currentSetIndex].length - 1) {
      setOpenAllButtonDisabled(true); // This was the last pack
      setOpenNextButtonDisabled(true);
      if (autoflipEnabled) setExportButtonsDisabled(false);
    }
    else {
      setOpenAllButtonDisabled(false); // Next pack is in this set still and there are more after it
      if (state.packOpenMode != 'Draft') setOpenNextButtonDisabled(!autoflipEnabled);
      else setOpenNextButtonDisabled(true);
    }
    setCurrentPackIndex(pi);
    setCurrentOverallIndex(() => currentOverallIndex + 1);

    const pullCardInners = Array.from(document.getElementsByClassName('pull-card-inner') as HTMLCollectionOf<HTMLElement>);
    const flipRarityTexts = Array.from(document.getElementsByClassName('flip-rarity-text') as HTMLCollectionOf<HTMLElement>);

    pullCardInners.forEach((element: HTMLElement) => {
      element.classList.add('no-transition');
      if (autoflipEnabled) element.style.transform = 'rotateY(180deg)';
      else element.style.removeProperty('transform');
      element.offsetHeight; // Flush CSS changes
      element.classList.remove('no-transition');
    });

    flipRarityTexts.forEach((element: HTMLElement) => {
      if (autoflipEnabled) element.style.filter = 'blur(0px)';
      else element.style.filter = 'blur(3px)';
    });

    if (autoflipEnabled && state.packOpenMode != 'Draft') {
      const newTotalPulls: PackCard[] = [...totalPulls];
      pullCardInners.forEach((element: HTMLElement) => {
        if (typeof element.dataset.index !== 'undefined') {
          const index: number = parseInt(element.dataset.index);
          const card: PackCard = packPulls[bi][si][pi][index];
          const cardInd: number = newTotalPulls.findIndex((pc: PackCard) => pc.name === card.name && pc.rarity === card.rarity);
          if (cardInd >= 0) newTotalPulls[cardInd].count += Number(1);
          else newTotalPulls.push({ ...card, count: Number(1) });
        }
      });
      setTotalPulls(newTotalPulls);
    }

    setFlippedCards(newFlipList);
    setDraftCardAdded(newDraftAddedList); // Same size, same default, can reuse here
    setFlipButtonDisabled(autoflipEnabled);
    if (window.scrollY > 235 || (isScreen1150 && window.scrollY > 180) || (isScreen950 && window.scrollY > 105)) window.scrollTo({ top: 0, behavior: 'auto' });
  }

  function openAllRemainingPacks() {
    const lastBundleIndex = packPulls.length - 1;
    const lastSetIndex = packPulls[lastBundleIndex].length - 1;
    const lastPackIndex = packPulls[lastBundleIndex][lastSetIndex].length - 1;
    setCurrentBundleIndex(lastBundleIndex);
    setCurrentSetIndex(lastSetIndex);
    setCurrentPackIndex(lastPackIndex);
    setCurrentOverallIndex(() => pullsTotalLength);

    const pullCardInners = Array.from(document.getElementsByClassName('pull-card-inner') as HTMLCollectionOf<HTMLElement>);
    const flipRarityTexts = Array.from(document.getElementsByClassName('flip-rarity-text') as HTMLCollectionOf<HTMLElement>);

    pullCardInners.forEach((element: HTMLElement) => {
      element.classList.add('no-transition');
      element.style.transform = 'rotateY(180deg)';
      element.offsetHeight; // Flush CSS changes
      element.classList.remove('no-transition');
    });

    flipRarityTexts.forEach((element: HTMLElement) => {
      element.style.filter = 'blur(0px)';
    });

    const newFlipList = new Array<boolean>(packPulls[lastBundleIndex][lastSetIndex][lastPackIndex].length).fill(true);

    const newTotalPulls: PackCard[] = [];
    packPulls.forEach((bundle: PackCard[][][]) => {
      bundle.forEach((set: PackCard[][]) => {
        set.forEach((pack: PackCard[]) => {
          pack.forEach((card: PackCard) => {
            const cardInd: number = newTotalPulls.findIndex((pc: PackCard) => pc.name === card.name && pc.rarity === card.rarity);
            if (cardInd >= 0) newTotalPulls[cardInd].count += Number(1);
            else newTotalPulls.push({ ...card, count: Number(1) });
          });
        });
      });
    });

    setTotalPulls(newTotalPulls);
    setFlippedCards(newFlipList);
    setFlipButtonDisabled(true);
    setOpenNextButtonDisabled(true);
    setOpenAllButtonDisabled(true);
    setExportButtonsDisabled(false);
    if (window.scrollY > 235 || (isScreen1150 && window.scrollY > 180) || (isScreen950 && window.scrollY > 105)) window.scrollTo({ top: 0, behavior: 'auto' });
  }

  function openNewBinderModal() {
    document.getElementById('create-binder-modal')!.style.display = 'flex';
  }

  function updateBinderList(newCurrent: string|null = null) {
    localforage.getItem('binders').then((binders: any) => {
      if (binders != null && binders.length > 0) {
        const binderOpts: DropdownOption[] = binders.map((binder: any) => {
          return { value: binder.id, text: binder.name } as DropdownOption;
        });
        queuedCurrentBinder = newCurrent;
        setBinderList(binderOpts);
      }
      else {
        setBinderList([]);
        setCurrentBinder('');
        sessionMemory.lastBinderSelected = '';
      }
    })
      .catch(() => {
        openErrorModal('Issue getting binders for Management.<br />' + contactString);
      });
  }

  function selectBinder(newBinder: string) {
    if (newBinder != '') {
      setCurrentBinder(newBinder);
      sessionMemory.lastBinderSelected = newBinder;
    }
  }

  function addPullCardsToBinder() {
    if (currentBinder != '') {
      const insertCards: BinderCardInfo[] = [];
      totalPulls.forEach((card: PackCard) => {
        insertCards.push({
          id: card.id,
          name: card.name,
          set: card.set,
          code: card.code,
          rarity: card.rarity,
          count: Number(card.count) });
      });

      setSavingCards(true);
      addCardsToBinder(cookies.get('token'), cookies.get('refresh'), cookies.get('userId'), currentBinder, insertCards,
        () => {
          setSavingCards(false);
          setPullsSavedOrExported(true);
          setSavedToBinders((prev) => [...prev, currentBinder]);
        },
        () => {
          setSavingCards(false);
        });
    }
  }

  function removePullCardsFromBinder() {
    if (currentBinder != '') {
      const insertCards: BinderCardInfo[] = [];
      totalPulls.forEach((card: PackCard) => {
        insertCards.push({
          id: card.id,
          name: card.name,
          set: card.set,
          code: card.code,
          rarity: card.rarity,
          count: -Number(card.count) });
      });

      setSavingCards(true);
      addCardsToBinder(cookies.get('token'), cookies.get('refresh'), cookies.get('userId'), currentBinder, insertCards,
        () => {
          setSavingCards(false);
          setPullsSavedOrExported(savedToBinders.length - 1 > 0);
          setSavedToBinders((prev) => prev.splice(prev.indexOf(currentBinder), 1));
        },
        () => {
          setSavingCards(false);
        });
    }
  }

  function exportCsvAsync() {
    return new Promise((resolve) => {
      let csvString = 'cardname,cardq,cardid,cardrarity,cardcondition,card_editon,cardset,cardcode\n';

      totalPulls.forEach((c: PackCard) => {
        csvString += '"' + c.name.replace(/"/g, '""') + '",' + c.count + ',' + c.id + ',"' + c.rarity + '","M","1st Edition","' + c.set + '","' + c.code + '"\n';
      });
      csvString.trimEnd();

      const element = document.createElement('a');
      const file = new Blob([csvString], { type: 'text/csv' });
      element.href = URL.createObjectURL(file);
      element.target = '_blank';
      element.download = (state.packOpenMode == 'Draft' ? 'Solo Draft' : cardSets[currentProduct].name) + ' Pulls.csv';
      document.body.appendChild(element);
      element.click();
      resolve(true);
    });
  }

  function exportToCsv() {
    setExportingPulls(true);
    exportCsvAsync()
      .then(async (resolved) => {
        const result = await resolved;
        setExportingPulls(false);
        setPullsSavedOrExported(true);
      });
  }

  function exportToYdk() {
    const exportMain: BaseCard[] = [];
    const exportExtra: BaseCard[] = [];
    totalPulls.forEach((card: PackCard) => {
      for (let i = 0; i < card.count; i++) {
        if (card.subtype.some((st: string) => ExtraDeckSubtypes.includes(st))) { // Card is an extra deck type
          exportExtra.push({
            id: card.id,
            name: card.name });
        }
        else { // Card is a main deck type
          exportMain.push({
            id: card.id,
            name: card.name });
        }
      }
    });

    setExportingPulls(true);
    exportYdk((state.packOpenMode == 'Draft' ? 'Solo Draft' : cardSets[currentProduct].name) + ' Pulls', exportMain, exportExtra, null)
      .then(async (resolved) => {
        const result = await resolved;
        setExportingPulls(false);
        setPullsSavedOrExported(true);
      });
  }

  function exportToYdke() {
    const exportMain: number[] = [];
    const exportExtra: number[] = [];
    totalPulls.forEach((card: PackCard) => {
      for (let i = 0; i < card.count; i++) {
        const cardInfo = cards[card.id];
        if (card.subtype.some((st: string) => ExtraDeckSubtypes.includes(st))) { // Card is an extra deck type
          exportExtra.push(card.id);
        }
        else { // Card is a main deck type
          exportMain.push(card.id);
        }
      }
    });

    setExportUrl({ type: 'YDKe', exportString: ydke.toURL({
      main: Uint32Array.from(exportMain),
      extra: Uint32Array.from(exportExtra),
      side: Uint32Array.from([]),
    }) });
  }

  function ydkeExportSuccess() {
    setExportUrl({ type: 'YDKe', exportString: '' });
    setPullsSavedOrExported(true);
  }

  function goToNextSetPick() { // Mark this set as done and go back to the selection
    setPackOpenerState({
      type: 'packsToOpen', payload: {
        ...state.packsToOpen,
        [state.currentSetToOpenPrefix]: { ...state.packsToOpen[state.currentSetToOpenPrefix], pullsCompleted: true },
      },
    });
    setPackOpenerState({ type: 'page', payload: 'PickNextPack' });
  }

  function showNavCheckModal(location: any) {
    if (!confirmedNavigation) { // Need this gate, otherwise when they confirm they'll just infinitely keep popping the dialog up
      setNavToLocation(location);
      document.getElementById('navCheckModal')!.style.display = 'flex';
      return false;
    }

    return true;
  }

  function closeNavCheckModal() {
    setNavToLocation(null);
    loadDeck.mainDeck = []; // Clear the load deck in case we populated it but aborted
    loadDeck.extraDeck = [];
    loadDeck.sideDeck = [];
    document.getElementById('navCheckModal')!.style.display = 'none';
  }

  function acceptNavCheckModal() {
    setConfirmedNavigation(true); // Will then trigger nav on callback from setState
  }

  function openRelevantBanlist() {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    };

    makeApiCall(cookies.get('refresh'), cookies.get('userId'), apiPath + 'banlist/bydate?date=' + JSON.stringify(cardSetDates[cardSets[currentProduct].name]), requestOptions)
      .then(async (response) => {
        if (!response.ok) {
          const error = response.status;
          openErrorModal('Issue finding banlist relevant to set.<br />' +
            (error == 401 ? '<br />Try logging out and logging back in.<br />If this persists, ' + lowercaseContactString : '') +
            (error == 400 ? '<br />You have been inactive for a long time. Please log out and back in.<br /><br />If this persists, ' + lowercaseContactString : '') +
            (error != 401 && error != 400 ? '<br />' + contactString : ''));
          return Promise.reject(error);
        }

        const data = await response.json();

        if (data == null) {
          openErrorModal('No relevant banlist found.' +
            '<br />' + contactString);
          return;
        }

        const element = document.createElement('a');
        element.href = frontEndPath + 'BanlistBuilder?b=' + data._id;
        element.target = '_blank';
        element.rel = 'noreferrer';
        document.body.appendChild(element);
        element.click();
      })
      .catch((err) => {
        openErrorModal('Issue finding banlist relevant to set.<br />' + contactString);
      });
  }

  function openDeckBuilder() {
    const mainDeck: DropCard[] = [];
    const extraDeck: DropCard[] = [];

    totalPulls.forEach((card: PackCard, index: number) => { // Add cards to the session deck to be loaded
      const uniqueID = (card.subtype.some((st: string) => ExtraDeckSubtypes.includes(st)) ? 'e' : 'm') +
          '-' + card.id;
      if (uniqueID[0] == 'e') {
        for (let i: number = 0; i < Number(card.count); i++) {
          extraDeck.push({
            ...cards[card.id],
            uniqueID: uniqueID + '-' + Date.now() + Math.random() + index + i,
          });
        }
      }
      else {
        for (let i: number = 0; i < Number(card.count); i++) {
          mainDeck.push({
            ...cards[card.id],
            uniqueID: uniqueID + '-' + Date.now() + Math.random() + index + i,
          });
        }
      }
    });

    loadDeck.mainDeck = mainDeck;
    loadDeck.extraDeck = extraDeck;
    loadDeck.sideDeck = [];

    if (!pullsSavedOrExported) {
      showNavCheckModal({ pathname: '/DeckBuilder' });
    }
    else {
      history.push('/DeckBuilder');
      refreshRouteFunc(!refresh);
    }
  }

  function openPerformanceWarningModal() {
    document.getElementById('performanceWarningModal')!.style.display = 'flex';
  }

  function checkPerformanceWarning() {
    if (cookies.get('ignorePerformanceWarnings') == 'false' && // This approximation is bad but it doesn't really need to be exact anyways
      packPulls.length * packPulls[0].length * packPulls[0][0].length * packPulls[0][0][0].length > MAIN_DECK_MAX + EXTRA_DECK_MAX) openPerformanceWarningModal();
    else openDeckBuilder();
  }

  function renderTotalPulls() {
    const binCounts: any = {};
    const groupedPulls = totalPulls.reduce((grouped: any, card) => {
      let group: string = '';
      switch (totalsGroupField) {
        case 'type':
          group = cards[card.id].type;
          break;
        default: // Rarity
          group = card.rarity;
          break;
      }
      if (!Object.prototype.hasOwnProperty.call(grouped, group)) {
        grouped[group] = [card];
        binCounts[group] = card.count;
      }
      else {
        grouped[group].push(card);
        binCounts[group] += card.count;
      }

      return grouped;
    }, {});
    return <div className='total-pulls-container content-box'>
      {Object.keys(groupedPulls)
        .sort((a: string, b: string) => {
          switch (totalsGroupField) {
            case 'type':
              return (a > b) ? 1 : -1;
            default: // Rarity
              return (RarityLevel[a] > RarityLevel[b]) ? -1 : 1;
          }
        }) // Sort by rarity, highest first
        .map((group: string) => {
          const groupedBin = groupedPulls[group];
          if (groupedBin.length > 0) {
            return (
              <div key={group + 'totals'}>
                <h3>{group + ' - ' + binCounts[group]}</h3>
                <div className='total-pulls-group-bin'>
                  {(() => {
                    // const groupedBin: {[key: string]: PackCard[]} = {}; // Group by card type
                    return groupedBin
                      .sort((a: PackCard, b: PackCard) => { // Sort by quantity then name
                        if (a.count > b.count) return -1;
                        else if (a.count == b.count) return a.name.localeCompare(b.name);
                        else return 1;
                      })
                      .map((card: PackCard) => {
                        return (
                          <div className='total-pulls-card' key={card.rarity + card.name}>
                            <HoverableCard {...{
                              cardID: card.id,
                              cardName: card.name,
                              classNames: 'total-pulls-card',
                              width: isScreen950 ? 68.57 : 137.14,
                              height: isScreen950 ? 100 : 200,
                              beforeElement: <div className='total-pulls-card-count'>
                                <span>{card.count}</span>
                              </div>,
                              afterElement: null,
                              style: { margin: 'auto', marginTop: isScreen950 ? '0px' : '5px' },
                              autosize: false,
                              contextMenu: null,
                              disableHover: false,
                            }} />
                          </div>
                        );
                      });
                  })()}
                </div>
              </div>
            );
          }
        })}
    </div>;
  }

  return (
    <main>
      <div id={'navCheckModal'} className='modal'>
        <button className='modal-close' title='Close modal' onClick={closeNavCheckModal} />
        <div className='content-box modal-content'>
          <h5 style={{ marginBottom: '0px' }}>Leaving Without Saving</h5>
          <p className='largetext' style={{ paddingTop: '5px', marginBottom: '0px' }}>You have not saved or exported your pulls,<br />if you leave this page they will be lost.</p>
          <button className='black-button white-button' style={{ marginRight: '7px' }} title='Leave page' onClick={acceptNavCheckModal}><span>Leave</span></button>
          <button className='black-button' style={{ marginRight: '7px' }} title='Stay on page' onClick={closeNavCheckModal}><span>Stay</span></button>
        </div>
      </div>
      <SaveAsBinderModal {...{ id: 'create-binder-modal', isRename: false, binderId: '', updateListDispatch: updateBinderList }} />

      <PerformanceWarningModal {...{
        text: 'This action will add a large number of cards to the Deck Builder,<br />and may cause performance issues on some machines.<br />' +
          'Add the cards to a binder then open that in the Deck Builder to avoid this.',
        callback: openDeckBuilder,
      }} />

      {exportingImage ? <PullsImageExport {...{
        id: 'img-export',
        totalPulls: totalPulls,
        totalsGroupField: totalsGroupField,
        setName: (state.packOpenMode == 'Draft' ? 'Solo Draft' : (cardSets[currentProduct].name + (state.packsToOpen[state.currentSetToOpenPrefix].productType == 'Boxes' ? ' (Box)' : ''))),
        finishedDispatch: setExportingImage,
      }} /> : ''}

      {exportUrl.exportString != '' ? <StringExportModal {...{
        id: 'url-export-modal',
        exportStringObj: exportUrl,
        dispatch: ydkeExportSuccess,
      }} /> : ''}

      <Prompt
        when={!pullsSavedOrExported}
        message={showNavCheckModal}
      />

      <Ad styleName='bar_ad' />
      <Ad styleName='left_sidebar_ad_1400' />
      <Ad styleName='right_sidebar_ad_1400' />

      <h2 style={{ marginTop: isScreen1150 ? '20px' : '35px' }}>
        {
          (state.packOpenMode == 'Draft' ? 'Solo Draft' : (cardSets[currentProduct].name + (state.packsToOpen[state.currentSetToOpenPrefix].productType == 'Boxes' ? ' (Box)' : ''))) +
          (packPulls.length > 1 ? ' (' + (currentBundleIndex + 1) + '/' + packPulls.length + ')' : '')
        }
      </h2>
      {state.packOpenMode != 'Draft' ? <h3 style={{ textAlign: 'center', fontSize: isScreen1150 ? '18px' : '20px', marginTop: '-5px', marginBottom: '7px' }}>
        {'TCG Release: ' + cardSetDates[cardSets[currentProduct].name].toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' })}
      </h3> : ''}
      {packInformation.length > 0 && (state.packOpenMode == 'Draft' || packInformation[currentBundleIndex].length > 1) ? <div>
        <h2 style={{ marginTop: '10px' }}>
          {
            packInformation[currentBundleIndex][currentSetIndex].name +
            (packPulls.length > 0 ? ' (' + (currentPackIndex + 1) + '/' + packPulls[currentBundleIndex][currentSetIndex].length + ')' : '')
          }
        </h2>
      </div> : ''}

      <div className='card-pulls-container content-box'>
        {packPulls.length > 0 ?
          (packPulls[currentBundleIndex][currentSetIndex][currentPackIndex] as PackCard[])
            .map((card: PackCard, index: number) => (
              <div className="pull-card" key={card.id + currentPackIndex + index}>
                <div className="pull-card-inner" data-index={index} style={flippedCards[index] ? { transform: 'rotateY(180deg)' } : {}}>
                  <div className="pull-card-back" data-index={index} onClick={pullCardClick}>
                    <img className='card-image' src={imagePath + 'CardBack.jpg'} alt='Card Back'
                      width={isScreen950 ? '91.88px' : '160.45px'} height={isScreen950 ? '134px' : '234px'} />
                  </div>
                  <div className='pull-card-front'>
                    <HoverableCard {...{
                      cardID: card.id,
                      cardName: card.name,
                      classNames: '',
                      width: isScreen950 ? 91.88 : 160.45,
                      height: isScreen950 ? 134 : 234,
                      beforeElement: <div style={state.packOpenMode == 'Draft' && draftCardAdded[index] ? { outline: '5px solid var(--accentColor)',
                        position: 'fixed', display: 'block', top: '0px', left: '0px', overflow: 'hidden',
                        width: isScreen950 ? '91.88px' : '160.45px', height: isScreen950 ? '134px' : '234px', outlineOffset: '-4px', zIndex: '1' } : {}}>
                        {(() => {
                          switch (card.rarity) {
                            case 'Common': // Nothing special for normals
                              return <div />;
                            default: // TODO: Add in different special effects based on rarity
                              return <div className='rare-effect' style={{
                                position: 'fixed', display: 'block', top: '0px', left: '0px', overflow: 'hidden',
                                width: isScreen950 ? '91.88px' : '160.45px', height: isScreen950 ? '134px' : '234px',
                              }} />;
                          }
                        })()}
                      </div>,
                      afterElement: null,
                      style: {},
                      autosize: false,
                      contextMenu: null,
                      disableHover: false,
                    }} />
                    {state.packOpenMode == 'Draft' ? (draftCardAdded[index] ?
                      <span className='flip-rarity-text' onClick={draftUndoClick} data-cardid={card.id}
                        data-index={index} style={flippedCards[index] ? { filter: 'blur(0px)', marginTop: '3px' } : { marginTop: '3px' }}>
                        <button className='draft-card-undo-button' title='Remove card from pulls total' disabled={false} style={{ marginLeft: '0px', marginRight: '3px' }}>
                          <img src={imagePath + 'BigMinus.png'} alt='+' width={isScreen950 ? '15px' : '18px'} height={isScreen950 ? '15px' : '18px'} />
                        </button>
                        {RarityLevel[card.rarity] < RarityLevel['Normal Parallel Rare'] ? 'Common' : card.rarity}
                      </span> :
                      <span className='flip-rarity-text' onClick={draftPlusClick} data-cardid={card.id}
                        data-index={index} style={flippedCards[index] ? { filter: 'blur(0px)', marginTop: '3px' } : { marginTop: '3px' }}>
                        <button className='draft-card-add-button' title='Add card to pulls total' disabled={false} style={{ marginLeft: '0px', marginRight: '3px' }}>
                          <img src={imagePath + 'BigPlus.png'} alt='-' width={isScreen950 ? '15px' : '18px'} height={isScreen950 ? '15px' : '18px'} />
                        </button>
                        {RarityLevel[card.rarity] < RarityLevel['Normal Parallel Rare'] ? 'Common' : card.rarity}
                      </span>) :
                      <span className='flip-rarity-text' style={flippedCards[index] ? { filter: 'blur(0px)' } : {}}>
                        {RarityLevel[card.rarity] < RarityLevel['Normal Parallel Rare'] ? 'Common' : card.rarity}
                      </span>}
                  </div>
                </div>
              </div>
            )) :
          <img src={'/images/LoadingAnim.png'} width='100px' height='100px' style={{ margin: 'auto' }} />
        }
      </div>
      <div className='card-pulls-button-container'>
        <div className='grid-left'>
          <button className='black-button' title='Flip all cards' style={{ marginRight: isScreen950 ? '5px' : '10px' }} disabled={flipButtonDisabled} onClick={flipAll}><span>Flip Cards</span></button>
          <button className='black-button' title='Open next pack in product' style={{ marginRight: isScreen950 ? '5px' : '10px' }} disabled={openNextButtonDisabled} onClick={openNextPack}>
            <span>Open Next Pack ({currentOverallIndex}/{pullsTotalLength})</span>
          </button>
          {isScreen950 ? <br /> : ''}
          <div className='checkbox' title='Automatically show cards as face-up when opening packs' onClick={toggleAutoflip} style={{ marginRight: '5px' }}>
            <img className='checkbox-checkmark' src={imagePath + 'CheckmarkBlack.png'} width='20px' height='20px' style={{ display: (autoflipEnabled ? 'inline-block' : 'none') }} />
          </div>
          <span className='button-bar-label'>Autoflip</span>
        </div>
        <div className='tooltip' style={{ display: 'inline-block' }}>
          {packInformation.length > 0 && packInformation[currentBundleIndex][currentSetIndex].infoElement != null ? <div>
            <span className='pack-odds-link grid-middle'>Pack Odds Breakdown</span>
            <ul className='tooltip-text' style={{ width: '180px', listStylePosition: 'inside', textIndent: '-18px', paddingLeft: '25px' }}>
              {packInformation[currentBundleIndex][currentSetIndex].infoElement}
            </ul>
          </div> : ''}
        </div>

        {state.packOpenMode == 'Sealed' ? <button className='black-button grid-right' title='Open all remaining packs in product' disabled={openAllButtonDisabled} onClick={openAllRemainingPacks}>
          <span>Open All Remaining Packs</span>
        </button> : ''}
      </div>

      <h2 style={{ marginTop: '35px' }}>Total Pulls From This {state.packOpenMode == 'Draft' ? 'Draft' : 'Product'}</h2>
      <div className='total-pulls-button-container' style={{ display: 'block', padding: '0px' }}>
        <span className='button-bar-label'>Group By:</span>
        <BlackDropdown {...{
          uniqueID: 'pulltotals-group',
          isSelector: true,
          disabledVar: false,
          optionsList: [{ value: 'rarity', text: 'Rarity' } as DropdownOption,
          { value: 'type', text: 'Card Type' } as DropdownOption],
          startingOption: null,
          defaultValue: state.packOpenMode == 'Draft' ? 'type' : 'rarity',
          defaultDisplay: state.packOpenMode == 'Draft' ? 'Card Type' : 'Rarity',
          nonSelectorDisplay: '',
          width: '100px',
          stateVar: totalsGroupField,
          dispatch: setTotalsGroupField,
          title: 'Select field to group and count total pulls by',
          style: { marginRight: isScreen950 ? '5px' : '7px' },
        }}/>
        {exportingPulls ?
          <img src={'/images/LoadingAnim.png'} width='30px' height='30px' style={{ margin: 'auto', verticalAlign: 'middle', marginLeft: '39px', marginRight: '42.5px' }} /> :
          <BlackDropdown {...{
            uniqueID: 'export-pulls',
            isSelector: false,
            disabledVar: exportingPulls,
            optionsList: [{ value: 'csv', text: 'Export to .csv', callback: exportToCsv },
              { value: 'ydk', text: 'Export to .ydk', callback: exportToYdk },
              { value: 'ydke', text: 'Export to YDKe', callback: exportToYdke },
              { value: 'png', text: 'Export to .png', callback: () => setExportingImage(true) }],
            startingOption: null,
            defaultValue: '',
            defaultDisplay: '',
            nonSelectorDisplay: <div className='text-icon-button'>
              <img src={imagePath + 'Export.png'} width={isScreen950 ? '14px' : '18px'} height={'16px'} style={{ margin: 'auto', marginLeft: '4px' }} />
              <span style={{ marginLeft: '5px', marginRight: '5px' }}>Export</span>
            </div>,
            width: 'initial',
            stateVar: '',
            dispatch: null,
            title: 'Export pull results',
            style: { marginRight: isScreen950 ? '5px' : '7px' },
          }}/>}

        <button className='black-button' style={{ marginRight: isScreen950 ? '5px' : '7px', marginBottom: isScreen1150 ? '3px' : 'initial' }} disabled={exportButtonsDisabled}
          title='Open pull results in Deck Builder' onClick={checkPerformanceWarning}><span>Open In Deck Builder</span></button>
      </div>
      <div className='total-pulls-button-container'>
        <div className='gridLeft'>

          <span className='button-bar-label'>Save to Binder:</span>
          <BlackDropdown {...{
            uniqueID: 'binder-select',
            isSelector: true,
            disabledVar: exportButtonsDisabled || typeof (username) == 'undefined',
            optionsList: binderList,
            startingOption: {
              value: sessionMemory.lastBinderSelected,
              text: binderList.find((b: DropdownOption) => b.value == sessionMemory.lastBinderSelected)?.text } as DropdownOption,
            defaultValue: '',
            defaultDisplay: 'Select Binder...',
            nonSelectorDisplay: '',
            width: '150px',
            stateVar: currentBinder,
            dispatch: selectBinder,
            title: 'Select binder to save pull results to',
            style: { marginRight: isScreen950 ? '5px' : '7px' },
            ulStyle: { width: '100%' },
          }} />

          {isScreen950 ? <br /> : ''}

          <button className='black-button text-icon-button' title='Add new binder' onClick={openNewBinderModal}
            disabled={typeof(username) == 'undefined' || exportButtonsDisabled} style={{ marginRight: '5px' }}>
            <img src={imagePath + 'BigPlus.png'} width='18px' height='20px' style={{ margin: 'auto', marginLeft: '4px' }} />
            <span style={{ marginLeft: '5px', marginRight: '5px' }}>New</span>
          </button>
          {savingCards ?
            <img src={'/images/LoadingAnim.png'} width='30px' height='30px' style={{ margin: 'auto', verticalAlign: 'middle', marginRight: '7px' }} /> :
            <button className='black-button' title='Save pull results to selected binder' style={{ marginRight: '5px' }}
              disabled={exportButtonsDisabled || saveButtonDisabled ||
                typeof (username) == 'undefined' || (currentBinder != '' && savedToBinders.includes(currentBinder))} onClick={addPullCardsToBinder}>
              <span>Save</span>
            </button>}
          {savingCards ?
            <img src={'/images/LoadingAnim.png'} width='30px' height='30px' style={{ margin: 'auto', verticalAlign: 'middle', marginRight: '7px' }} /> :
            <button className='black-button' title='Remove pull results from selected binder' style={{ marginRight: isScreen950 ? '5px' : '7px' }}
              disabled={exportButtonsDisabled || saveButtonDisabled ||
                typeof (username) == 'undefined' || (currentBinder == '' || !savedToBinders.includes(currentBinder))} onClick={removePullCardsFromBinder}>
              <span>Undo Save</span>
            </button>}

          {isScreen950 ? <br /> : ''}

          <span className='save-warning' style={{ display: exportButtonsDisabled ? 'inline-flex' : 'none' }}>
            Must finish {state.packOpenMode == 'Draft' ? 'drafting all packs' : 'opening all packs in set'}  before saving.
          </span>
          <span className='save-warning' style={{ display: !exportButtonsDisabled && typeof(username) == 'undefined' ? 'inline-flex' : 'none' }}>Must be logged in to save to binders.</span>
        </div>
        {state.packOpenMode == 'Sealed' ? <div className='grid-right' style={{ display: 'flex', alignItems: 'flex-end' }}>
          <div>
            {isScreen950 ? '' : <button className='black-button' style={{ marginRight: isScreen950 ? '0px' : '7px', marginLeft: isScreen950 ? '5px' : 'initial' }} disabled={false}
              title='Open banlist active on product release date' onClick={openRelevantBanlist}><span>Open Relevant Banlist</span></button>}
            <button className='black-button' title='Pick next product to open' disabled={exportButtonsDisabled}
              onClick={() => {pullsSavedOrExported ? goToNextSetPick() : showNavCheckModal('PickNextPack');}}>
              <span>Open Next Product</span>
            </button>
            {isScreen950 ? <br /> : ''}
            {isScreen950 ? <button className='black-button' style={{ marginRight: isScreen950 ? '0px' : '7px', marginLeft: isScreen950 ? '5px' : 'initial' }} disabled={false}
              title='Open banlist active on product release date' onClick={openRelevantBanlist}><span>Open Relevant Banlist</span></button> : ''}
          </div>
        </div> : ''}
      </div>
      {renderTotalPulls()}

      <Ad styleName='bar_ad_footer' />
    </main>
  );
}
