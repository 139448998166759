import { useEffect, useState } from 'react';
import { cookies, imagePath, setCookie } from '../utils/Constants';

export default function PerformanceWarningModal(props: { text: string, callback: any }) {
  const [ignoreWarnings, setIgnoreWarnings] = useState(cookies.get('ignorePerformanceWarnings') == 'true');

  useEffect(() => {
    return () => { // Unmount
      setCookie('ignorePerformanceWarnings', ignoreWarnings);
    };
  }, []);

  function closePerformanceWarningModal() {
    document.getElementById('performanceWarningModal')!.style.display = 'none';
  }

  function acceptPerformanceWarningModal() {
    props.callback();
    closePerformanceWarningModal();
  }

  function toggleIgnorePerformanceWarning() {
    setIgnoreWarnings((prev: boolean) => !prev);
  }

  return (
    <div id={'performanceWarningModal'} className='modal'>
      <button className='modal-close' title='Close modal' onClick={closePerformanceWarningModal} />
      <div className='content-box modal-content'>
        <h5 style={{ marginBottom: '0px' }}>Performance Warning</h5>
        <p className='largetext' style={{ paddingTop: '5px', marginBottom: '0px' }} dangerouslySetInnerHTML={{ __html: props.text }}></p>
        <button className='black-button' style={{ marginRight: '7px', marginBottom: '7px' }} title='Continue' onClick={acceptPerformanceWarningModal}><span>Continue</span></button>
        <button className='black-button white-button' style={{ marginRight: '7px', marginBottom: '7px' }} title='Cancel' onClick={closePerformanceWarningModal}><span>Cancel</span></button>
        <br />
        <div className='checkbox' title='Ignore future performance warnings' onClick={toggleIgnorePerformanceWarning} style={{ marginRight: '5px' }}>
          <img className='checkbox-checkmark' src={imagePath + 'CheckmarkBlack.png'} width='20px' height='20px' style={{ display: (ignoreWarnings ? 'inline-block' : 'none') }} />
        </div>
        <span className='largetext-label'>Do not show performance warnings</span>
      </div>
    </div>
  );
}
