import { Helmet } from 'react-helmet';
import Ad from './Ad';

export default function About(props: { refresh: any }) {
  return (
    <main>
      <Helmet>
        <meta content="YGO Prog - About" property="og:title" />
        <meta
          content="YGO Prog is a tool for simulating Yu-Gi-Oh! pack opening, collection management, deck building, banlist building, and more!"
          property="og:description"
        />
        <meta content="https://www.ygoprog.com/About" property="og:url" />
        <meta
          name="description"
          content="YGO Prog is a tool for simulating Yu-Gi-Oh! pack opening, collection management, deck building, banlist building, and more!"
        />
        <title>YGO Prog - About</title>
      </Helmet>

      <Ad styleName='bar_ad' />
      <Ad styleName='left_sidebar_ad_1200' />
      <Ad styleName='right_sidebar_ad_1200' />

      <h1>About This Site</h1>
      <p className='content-box largetext about-content'>
        Welcome to YGO Prog! We initially created this website in an effort to ease the pains associated with running your own progression series, but have since expanded its purpose.
        Thanks to iterations and user feedback we now have a range of useful tools for playing the Yu-Gi-Oh! TCG both online and in paper!
        <br /><br />There are a lot of moving parts when it comes to progression series and the Yu-Gi-Oh! TCG at large: opening packs, deckbuilding, banlists, maintaining
        a collection, special rulesets for winner rewards, pity packs for last place, etc. The end goal for this website is to provide a place where you can create a progression series between friends,
        pick your custom rulesets, and have the heavy lifting taken care of for you by the website, or just use some of the functions like the Deck Builder for other uses!
        As such, this tool will be tailored towards these functionalities; for all your other online Yu-Gi-Oh! needs such as public deck postings, forums, and news, we recommend you check
        out our friends over at <a className='in-text-link' href='https://ygoprodeck.com/' target='_blank' rel='noreferrer'>YGOPRODeck</a>, or official Konami sites and products.
        <br /><br />Please note that since this site is a free-time passion project between three full-time software engineers, there will be some issues along the way,
        but we want to thank everyone who is interested in using the site and testing new features as they come out.
        <br /> If you have any questions, concerns, or bug reports you can find us on twitter
        at <a className='in-text-link' href='https://twitter.com/ygoprog' target='_blank' rel='noreferrer'>@YGOProg</a>, join
        our community <a className='in-text-link' href='https://discord.com/invite/46tQbC69mN' target='_blank' rel='noreferrer'>Discord</a>,
        <br />or send us an email at <a className='in-text-link' href="mailto: admin@ygoprog.com">admin@ygoprog.com</a>.
        <br /><br />Loading icons provided by <a className='in-text-link' href='https://loading.io/'>loading.io</a>
        <br />TWITTER, TWEET, RETWEET and the Twitter Bird logo are trademarks of Twitter Inc. or its affiliates.
        <br />Import, export, trash, plus, minus, arrows, check, info, mail, user, menu, swords, X, reset, sort, swap,
        shuffle, broom, moon, filter, group, ungroup, tool, and search icons courtesy of <a className='in-text-link' href='https://icons8.com/'>icons8.com</a>
      </p>

      <h1>Special Thanks</h1>
      <p className='content-box largetext about-content'>
        Without the Yu-Gi-Oh! community this site wouldn&apos;t exist in the first place, so a big thanks to Konami and
        all the fantastic people that make the game and community what it is, and creators
        like <a className='in-text-link' href='https://www.youtube.com/user/Cimoooooooo' target='_blank' rel='noreferrer'>Cimoooooooo</a> and <a className='in-text-link'
          href='https://www.youtube.com/c/Nyhmnim' target='_blank' rel='noreferrer'>Nyhmnim</a> for pioneering the progression series format!
        <br />We also have a few people we&apos;d like to thank for being an additional huge help for parts of the site:
        <br /><a className='in-text-link' href='https://github.com/DawnbrandBots/yaml-yugi' target='_blank' rel='noreferrer'>yaml-yugi</a>
        &nbsp;and <a className='in-text-link' href='https://yugipedia.com' target='_blank' rel='noreferrer'>Yugipedia</a> provide our backing data and most product images.
        <br /><a className='in-text-link' href='https://ygoprodeck.com/' target='_blank' rel='noreferrer'>YGOPRODeck</a> provides our card images.
        <br /><a className='in-text-link' href='https://twitter.com/FieryDnce' target='_blank' rel='noreferrer'>@FieryDnce</a> designed our updated YGO Prog logos.
        <br /><a className='in-text-link' href='https://www.youtube.com/c/Ruxin34' target='_blank' rel='noreferrer'>Ruxin34</a>&apos;s videos were pivotal in determining
        many of our pack and box ratios.
        <br /><a className='in-text-link' href='https://twitter.com/diamonddudeygo' target='_blank' rel='noreferrer'>DiamondDude</a>&apos;s banlist
        repository <a className='in-text-link' href='https://pythonrepo.com/repo/diamonddudetcg-TimeWizard' target='_blank' rel='noreferrer'>TimeWizard</a> saved
        us a lot of time and headache in acquiring historical and alt banlists for templates.
      </p>

      <h1>About Our Pack Puller Ratios</h1>
      <div className='content-box largetext about-content'>
        <h5 style={{ textAlign: 'left' }}>What Makes Our Pack Simulator Unique</h5>
        <p style={{ textAlign: 'left' }}>
          We&apos;ve spent a lot of time scouring the internet for information regarding the exact rarity ratios associated with each set.
          <br /> This is a somewhat arduous task- because ratios aren&apos;t always printed on packs, we have to rely on peoples&apos; experiences relayed via forums years ago,
            or watching pack pulling videos and extrapolating out the odds from those.
          <br /> We have done our best to make our pack puller consistent with the actual ratios used for each physical set, and those ratios are detailed below.
          <br /> A huge thanks goes out to <a className='in-text-link' href='https://www.youtube.com/c/Ruxin34' target='_blank' rel='noreferrer'>Ruxin34</a>-
          some of the most complex and unknown pack ratios came from one of us sitting down to watch set unboxings on his channel.
        </p>

        <h5 style={{ textAlign: 'left' }}>Hobby Box vs Retail Box</h5>
        <p style={{ textAlign: 'left' }}>
          In physical releases, hobby boxes and retail boxes have different cards considered higher rarities from each other.
          <br /> In early sets they had differing secret rares, but the biggest difference between the two was during the era of ultimate rares.
          <br /> Retail boxes only had ultimate rares which were higher-rarity versions of rares, but hobby boxes only had ultimate rares of supers or ultras.
          <br /> Because we are not creating and distributing boxes, though we maintain the ultra rare ratios per pack, we are using a mix of hobby and retail boxes for our packs.
          <br /> This means that ultimate rares can be higher-rarity versions of rares, ultras, or supers, and you can pull any secret rares from both box types for a set.
        </p>

        <h5 style={{ textAlign: 'left' }}>First Edition Prints</h5>
        <p style={{ textAlign: 'left' }}>
          In first edition printings of the earliest sets there was not guaranteed to be a rare in every pack. However, reprints of older sets <i>did</i> guarantee a rare in every pack.
          <br /> With this in mind we ignore first edition prints, therefore none of our cards or packs will make a distinction between first edition and other editions.
          In those early sets you will be guaranteed a rare in every pack.
        </p>

        <h5 style={{ textAlign: 'left' }}>How We Use Ratios to Generate Pack Pulls</h5>
        <p style={{ textAlign: 'left' }}>
          Using LOB as an example, we take the rarity ratios for each rarity and construct a &quot;hat&quot; out of 372 possibilites for the foil (LCM of each ratio).
          <br />For each possible rarity we divide the hat size by the ratio and fill in the extra space with commons (or whatever the card would be if it isn&apos;t in the ratio).
          <br />So LOB has 12 secret rares, 31 ultra rares, 62 super rares, and 267 commons in this hat.
          <br />Then we just pick a rarity from the hat and that is the rarity for the foil in the pack.
        </p>

        <h5 style={{ textAlign: 'left' }}>How We Handle Short Prints</h5>
        <p style={{ textAlign: 'left' }}>
          We do not have ratios for Short Printed cards, unfortunately there is not enough data to be able to figure out what ratio short printed cards are pulled at.
          <br />For this reason we ignore short printed cards as a use case, and just consider them the rarity they are as a card.
        </p>

        <h5 style={{ textAlign: 'left' }}>Box Odds</h5>
        <p style={{ textAlign: 'left' }}>
          Unlike loose packs, packs in a box have a &quot;guaranteed&quot; distribution of rarity pulls based on the ratios within that box.
          <br />For example, the in a 24-Pack LOB Box - which has a 1:6 Super Rare Chance, a 1:12 Ultra Rare Chance, and a 1:32 Secret Rare Chance:
          <br />The Box would have 4 Super Rares, 2 Ultra Rares, and 1 or 0 Secret Rares.
        </p>

        <h5 style={{ textAlign: 'left' }}>Reporting Incorrect Ratios</h5>
        <p style={{ marginBottom: '0px', textAlign: 'left' }}>
          The ratios for our pack simulator are the one of our biggest reasons for making this tool, and making sure they are as accurate as possible is the goal.
          <br /> If you believe we have our ratios wrong for any pack or era please reach out to us via our email admin@ygoprog.com with sources!
          <br /> The sources part is extremely important here,
          a large amount of our data on the earlier eras of yugioh involve debates about pack ratios on forums and extrapolation of ratios from watching bulk set openings.
        </p>
      </div>

      <h1>Pack Ratios Per Set</h1>

      <h2 id='#core'>Core Sets</h2>
      <div className='content-box largetext leftbox about-content'>
        <p>
          Every core set is made up of 9 cards per pack. There are 7 guaranteed commons, 1 guaranteed rare (up until Eternity Code where
          they <a className='in-text-link' href='https://yugipedia.com/wiki/Eternity_Code' target='_blank' rel='noreferrer'>discontinued rares</a>),
          and the ninth card is either a foil pulled via the ratios, or a common (until Breakers of Shadow, from which point the foil became guaranteed).
          <br /> Most core sets can be separated into eras where the ratios stayed the same for several sets, and we have grouped them as such.
        </p>

        <h3 className='about-h3'>Pre Soul of the Duelist</h3>
        <p>
          For every set before Soul of the Duelist they used these ratios for the ninth card.
          <br /> - Super 1:6
          <br /> - Ultra 1:12
          <br /> - Secret 1:31
        </p>

        <h3 className='about-h3'>Soul of the Duelist through Cyberdark Impact</h3>
        <p>
          These sets are unique because the chance for an ultimate rare is actually two times the chance for an ultra
          (Ruxin talks about it briefly in this <a className='in-text-link' href='https://www.youtube.com/watch?v=Z_jxOfVx_A4&t=171s' target='_blank' rel='noreferrer'>video</a>)
          <br /> We couldn&apos;t find any good corroborating sources online for these ratios so we had to watch unboxing videos to verify the pack odds for the ninth card.
          <br /> - Super 1:6
          <br /> - Ultra 1:24
          <br /> - Ultimate 1:12
        </p>

        <h3 className='about-h3'>Strike of Neos and Force of the Breaker</h3>
        <p>
          These two sets used the exact same ratios as the previous sets, except they also added back secret rares.
          <br /> - Super 1:6
          <br /> - Ultra 1:24
          <br /> - Secret 1:31
          <br /> - Ultimate 1:12
        </p>

        <h3 className='about-h3'>Tactical Evolution through Absolute Powerforce</h3>
        <p>
          These sets are the first sets to include ghost rares. The ratio for these is talked about in
          this <a className='in-text-link' href='http://web.archive.org/web/20210128103203/https://yugiohblog.konami.com/articles/?p=7413' target='_blank' rel='noreferrer'>
          blog post</a> which talks about
          discontinuing ghost rares for Breakers of Shadows.
          As part of this post they talk about what the historic ratio was for these sets.
          <br /> - Super 1:5
          <br /> - Ultra 1:12
          <br /> - Secret 1:31
          <br /> - Ultimate 1:31
          <br /> - Ghost 1:288
        </p>

        <h3 className='about-h3'>Shining Darkness through Dimension of Chaos</h3>
        <p>
          In these sets the likelihood of getting a secret or ultra was improved to be 1 or 2 per box.
          <br /> - Super 1:5
          <br /> - Ultra 1:12
          <br /> - Secret 1:23
          <br /> - Ultimate 1:23
          <br /> - Ghost 1:288
        </p>

        <h3 className='about-h3'>Breakers of Shadow through Dark Neostorm</h3>
        <p>
          These sets greatly altered pack composition. Instead of the ninth card being either a common or a foil, the ninth card was guaranteed to be foil (super, ultra, or secret).
          They also removed ghost rares from this point
          onwards. <a className='in-text-link' href='http://web.archive.org/web/20210128103203/https://yugiohblog.konami.com/articles/?p=7413' target='_blank' rel='noreferrer'>Source</a>
          <br /> - Ultra 1:6
          <br /> - Secret 1:12
          <br /> - If it isn&apos;t an ultra or secret it is a super
        </p>

        <h3 className='about-h3'>Rising Rampage through Ignition Assault</h3>
        <p>
          These sets added a new rarity type of starlight rare, which could contend for the foil slot.
          <br /> - Ultra 1:6
          <br /> - Secret 1:12
          <br /> - Starlight 1:576
        </p>

        <h3 className='about-h3'>Eternity Code to Present</h3>
        <p style={{ marginBottom: '0px' }}>
          These sets use the same ratios as the rising rampage era, but from this point on rares had been discontinued from being a part of packs.
          This means that packs consist of 8 commons, and a foil, with the foil being the same ratio as above.
          <br /> - Ultra 1:6
          <br /> - Secret 1:12
          <br /> - Starlight 1:576
        </p>
      </div>
      <br />

      <h2>Tournament Pack Rewards</h2>
      <div className='content-box largetext leftbox about-content'>
        <p>
          This category consists of Tournament Packs, Champion Packs, Turbo Packs, Astral Packs, and OTS Packs.
          <br /> These packs are all 3 cards per pack, with two guaranteed commons, and a third card with different ratios.
        </p>

        <h3 className='about-h3'>Tournament Packs</h3>
        <p>
          For tournament packs you could get a common for the third card.
          <br /> - Rare 1:3
          <br /> - Super 1:18
          <br /> - Ultra 1:108
        </p>

        <h3 className='about-h3'>Champion Packs</h3>
        <p>
          For these packs onwards you could no longer get a common as the third card. If it was not one of the possible foils it would be a rare.
          <br /> - Super 1:12
          <br /> - Ultra 1:48
        </p>

        <h3 className='about-h3'>Turbo Packs</h3>
        <p>
          - Super 1:12
          <br /> - Ultra 1:32
          <br /> - Ultimate 1:32
        </p>

        <h3 className='about-h3'>Astral and OTS Packs</h3>
        <p style={{ marginBottom: '0px' }}>
          For these two pack types we couldn&apos;t find any good sources for rarity ratios, so we referred
          to <a className='in-text-link' href='https://www.youtube.com/c/Ruxin34' target='_blank' rel='noreferrer'>Ruxin34</a> pack openings to find the approximate ratio for ultimates.
          <br /> Also for these packs are are no rares, if the third card is not an ultimate it will be a super.
          <br /> - Ultimate 1:20
        </p>
      </div>
      <br />

      <h2>Duelist Packs</h2>
      <div className='content-box largetext leftbox about-content'>
        <p>
          Duelist packs have 5 cards per pack, 3 commons, 1 rare, and the last card being common if it&apos;s not a foil.
        </p>

        <h3 className='about-h3'>Duelist Pack Jaden Yuki 1 until Duelist Pack Jesse Anderson</h3>
        <p>
          - Super 1:6
          <br /> - Ultra 1:24
        </p>

        <h3 className='about-h3'>Duelist Pack Yusei until Duelist Pack Yugi</h3>
        <p>
          - Super 1:5
          <br /> - Ultra 1:15
        </p>

        <h3 className='about-h3'>Duelist Pack Kaiba</h3>
        <p>
          This is the only duelist pack we found which has ultimate rares, and has its ratios listed on
          the <a className='in-text-link' href='http://web.archive.org/web/20210727122054/https://www.yugioh-card.com/en/products/dp-kaiba.html' target='_blank' rel='noreferrer'>
            official product list</a>
          <br /> - Super 1:5
          <br /> - Ultra 1:15
          <br /> - Ultimate 1:24
        </p>

        <h3 className='about-h3'>Remaining Duelist Packs</h3>
        <p style={{ marginBottom: '0px' }}>
          Stopped printing ultimates and went back to the previous ratios
          <br /> - Super 1:5
          <br /> - Ultra 1:15
        </p>
      </div>
      <br />

      <h2>Reprint Packs</h2>
      <div className='content-box largetext leftbox about-content'>
        <p>
          Most Reprint packs cover all Dark Beginning, Dark Revelation, and Dark Legends packs. These packs have 12 cards per pack, and will always have
          6 monsters, 3 traps, and 3 spells.
          <br /> The packs also always had 10 commons, 1 rare, and the 12th card was either a common or a foil.
          <br /> There are also retro packs which have 9 cards a pack, 7 commons, 1 rare, and the 9th card was either foil or common.
        </p>

        <h3 className='about-h3'>Dark Beginning 1 until Dark Revelation 3</h3>
        <p>
          - Super 1:6
          <br /> - Ultra 1:12
        </p>

        <h3 className='about-h3'>Dark Revelation 4</h3>
        <p>
          For both Dark Revelation 4 and Dark Legends it was difficult to find a secret rare ratio for the packs, so we defaulted to using the secret rare ratio at the time
          which was 1:31 (Tactical Evolution Era).
          <br /> - Super 1:4
          <br /> - Ultra 1:8
          <br /> - Secret 1:31
        </p>

        <h3 className='about-h3'>Dark Legends</h3>
        <p>
          Dark Legends has no pull rate for secret rares, Gorz is a promo card that came with the packs. As a result it is not a card you can pull from our pack opener.
          <br /> - Super 1:5
          <br /> - Ultra 1:12
        </p>

        <h3 className='about-h3'>Retro Packs 1 and 2</h3>
        <p style={{ marginBottom: '0px' }}>
          For these packs we could not find good information on the secret rare ratio, so we used the ratio from core sets at their time.
          <br /> This feels correct as well, because the super and ultra ratios mirrored the core sets at the time.
          <br /> - Super 1:5
          <br /> - Ultra 1:12
          <br /> - Secret 1:31
        </p>
      </div>
      <br />

      <h2>Premium Gold Packs</h2>
      <div className='content-box largetext leftbox about-content'>
        <p>
          Premium gold packs have 5 cards per pack, and have different buckets for the gold rare.
          You are guaranteed to get one gold rare from a certain number range of cards, a couple from another, and then the gold secrets.
        </p>

        <h3 className='about-h3'>Premium Gold</h3>
        <p>
          <a className='in-text-link' href='https://yugipedia.com/wiki/Premium_Gold' target='_blank' rel='noreferrer'>Source</a>
          <br /> - 1 gold rare from cards 33-50
          <br /> - 2 Gold rares from cards 51-90
          <br /> - 2 Gold Secrets
        </p>

        <h3 className='about-h3'>Premium Gold: Return of the Bling</h3>
        <p>
          <a className='in-text-link' href='https://yugipedia.com/wiki/Premium_Gold:_Return_of_the_Bling' target='_blank' rel='noreferrer'>Source</a>
          <br /> - 1 gold rare from cards 70-91
          <br /> - 2 Gold rares from cards 22-69
          <br /> - 2 Gold Secrets
        </p>

        <h3 className='about-h3'>Premium Gold: Infinite Gold</h3>
        <p style={{ marginBottom: '0px' }}>
          <a className='in-text-link' href='https://yugipedia.com/wiki/Premium_Gold:_Infinite_Gold' target='_blank' rel='noreferrer'>Source</a>
          <br /> - 3 Gold Rares
          <br /> - 2 Gold Secrets
        </p>
      </div>

      <Ad styleName='bar_ad_footer' />
    </main>
  );
}
