import { useRef, useState, FC } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { imagePath } from '../utils/Constants';

interface SortablePackTrayEntryProps {
  text: string,
  index: number,
  prefix: string,
  currentDragEntry: any,
  setCurrentDragEntry: any,
  moveEntry: any,
}

export const SortablePackTrayEntry: FC<SortablePackTrayEntryProps> = ({ text, index, prefix, currentDragEntry, setCurrentDragEntry, moveEntry }) => {

  const ref = useRef<any>(null);
  const [creationTime] = useState(Date.now());

  const [{ handlerId }, drop] = useDrop(
    () => ({
      accept: 'sortablepacktrayentry',
      collect(monitor) {
        return {
          handlerId: monitor.getHandlerId(),
        };
      },
      hover(item: any, monitor) {
        if (!ref.current) return;

        const dragIndex = item.index;
        const hoverIndex = index;
        if (dragIndex == hoverIndex) return; // Don't drop it on itself

        // const hoverBoundsRect = ref.current?.getBoundingClientRect();
        // const hoverMiddleY = (hoverBoundsRect.bottom - hoverBoundsRect.top) / 2;
        // const clientOffset = monitor.getClientOffset();
        // const hoverClientY = (clientOffset?.y??0) - hoverBoundsRect.top;

        // if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return; // Move up on the top half and down on the bottom half of hovered item
        // if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;

        moveEntry(dragIndex, hoverIndex);
        item.index = hoverIndex; // Mutating the monitor here allows us to skip some costly index searches
      },
      drop(item, monitor) {
        setCurrentDragEntry({ prefix: '', creationTime: Date.now() });
      },
    }),
  );

  const [{ isDragging }, drag] = useDrag({
    type: 'sortablepacktrayentry',
    item: () => {
      setCurrentDragEntry({ prefix: prefix, creationTime: creationTime });
      return { index: index, prefix: prefix, dragTime: creationTime };
    },
    collect: (monitor: any) => ({
      isDragging: !!monitor.isDragging(),
    }),
    end: (item: any, monitor) => {
      setCurrentDragEntry({ prefix: '', creationTime: Date.now() });
    },
  });

  const opacity = prefix == currentDragEntry.prefix && creationTime != currentDragEntry.creationTime ? 0 : 1;
  // const background = prefix == currentDragEntry ? '#ED1C24' : 'initial';
  drag(drop(ref));
  return (
    <p key={prefix + index + 'sorttray'} ref={ref} style={{ opacity, cursor: 'move' }} data-handler-id={handlerId}>
      <img src={imagePath + 'Sort.png'} width='10px' height='10px' style={{ margin: 'auto', marginRight: '5px' }} />
      {text}
    </p>
  );
};
