import { useState, useEffect } from 'react';

interface ToggleBoxProps {
  uniqueID: string,
  disabledVar: boolean,
  options: string[], // Set this up using array so we can more easily extend it to a multi box later if needed
  defaultOption: string,
  optionWidth: string,
  toggleStateVar: string,
  dispatch: any,
  style: any,
  title: string,
}

export default function MultiToggleBox(props: ToggleBoxProps) {

  const optionID = props.uniqueID + '-option';

  const [toggleDisplay, setToggleDisplay] = useState('');

  useEffect(() => { // Mount
    // props.dispatch(props.defaultOption);
    switchToggleDisplay(props.defaultOption);
  }, []);

  useEffect(() => {
    if (props.toggleStateVar != toggleDisplay) switchToggleDisplay(props.toggleStateVar);
  }, [props.toggleStateVar]);

  function switchToggle(index: number) {
    const newValue: string = props.options[index];

    switchToggleDisplay(newValue);
  }

  function switchToggleDisplay(newValue: string) {
    if (newValue == toggleDisplay) return;

    document.getElementById(optionID + newValue.toString())?.classList.add('toggle-on');
    document.getElementById(optionID + toggleDisplay.toString())?.classList.remove('toggle-on');
    setToggleDisplay(newValue);
    props.dispatch(newValue);
  }

  return (
    <div className={'black-toggle'} style={props.style} title={props.title}>
      {
        props.options.map((option: string, index: number) => {
          return <button id={optionID + index.toString()} className={'black-toggle-option ' + (index == 0 ? 'left' : (index == props.options.length - 1 ? 'right' : 'middle')) + '-toggle-option' +
            (option == props.toggleStateVar ? ' toggle-on' : '')}
          onClick={() => switchToggle(index)} disabled={props.disabledVar} style={{ width: props.optionWidth, height: '30px' }} key={optionID + index.toString()}>
            <span>{option}</span>
          </button>;
        })
      }
    </div>
  );
}
