import { useState } from 'react';


export default function SaveAsGroupModal(props: { id: string, isRename: boolean, groupName: string, list: DropdownOption[], updateListDispatch: any }) {

  const [saveAsDisabled, setSaveAsDisabled] = useState(true);

  function closeSaveAsGroupModal() {
    document.getElementById(props.id)!.style.display = 'none';
    (document.getElementById(props.id + '-name')! as HTMLInputElement).value = '';
  }

  function saveGroup() {
    const newName = (document.getElementById(props.id + '-name')! as HTMLInputElement).value;
    if (newName == '') return;

    const newList = structuredClone(props.list);
    if (props.isRename) {
      for (let i: number = 0; i < newList.length; i++) {
        if (newList[i].text == props.groupName) {
          newList[i].text = newName;
          break;
        }
      }
    }
    else {
      newList.push({ text: newName, value: 'group', subItems: [] as DropdownOption[] } as DropdownOption);
    }

    props.updateListDispatch(newList, newName);
    closeSaveAsGroupModal();
  }

  function checkGroupName(e: any) {
    if (e.target.value != '' && e.target.value != 'Common' && props.list.findIndex((g: DropdownOption) => g.text == e.target.value) && e.target.value.length < 81) setSaveAsDisabled(false);
    else setSaveAsDisabled(true);
  }

  return (
    <div id={props.id} className='modal' style={{ zIndex: '9010' }}>
      <button className='modal-close' title='Close modal' onClick={closeSaveAsGroupModal} style={{ zIndex: '9010' }} />
      <div className='content-box modal-content' style={{ zIndex: '9011' }}>
        <h5 style={{ marginBottom: '0px' }}>{props.isRename ? 'Rename Group' : 'New Group'}</h5>
        <div className='vertical-form-text-input'>
          <label htmlFor={props.id + '-name'}>Name:</label>
          <div className='text-input'><input id={props.id + '-name'} type='text' onInput={checkGroupName} onKeyDown={(e) => {if (e.code === 'Enter') saveGroup();}} /></div>
        </div>
        <button className='black-button' title={props.isRename ? 'Rename group' : 'Create new group'} onClick={saveGroup} disabled={saveAsDisabled}>
          <span>{props.isRename ? 'Rename' : 'Create'}</span>
        </button>
      </div>
    </div>
  );
}
