import makeApiCall from './ApiMiddleware';
import Cookies from 'universal-cookie';
import { openErrorModal } from './BasicModalFuncs';
import { bundleLinks, cards } from './CardDataCache';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { TouchTransition, MouseTransition } from 'react-dnd-multi-backend';

export const apiPath = process.env.REACT_APP_YGOPROG_API_PATH!;
export const frontEndPath = process.env.REACT_APP_YGOPROG_FRONT_END_PATH!;
export const imagePath = process.env.REACT_APP_YGOPROG_IMAGE_PATH!;
export const cardImagePath = process.env.REACT_APP_YGOPROG_CARD_IMAGE_PATH!;
export const packImagePath = process.env.REACT_APP_YGOPROG_PACK_IMAGE_PATH!;

export const packOpenLimit = process.env.REACT_APP_YGOPROG_PACK_OPEN_LIMIT!;
export const boxOpenLimit = process.env.REACT_APP_YGOPROG_BOX_OPEN_LIMIT!;
export const env = process.env.REACT_APP_YGOPROG_ENV;

export const HTML5toTouch = { // Multi backend base code from: https://codesandbox.io/embed/bombs-lqoqd?codemirror=1
  backends: [
    {
      id: 'html5',
      backend: HTML5Backend,
      transition: MouseTransition,
      // by default, will dispatch a duplicate `mousedown` event when this backend is activated
    },
    {
      id: 'touch',
      backend: TouchBackend,
      // Note that you can call your backends with options
      options: { enableMouseEvents: true },
      transition: TouchTransition,
      preview: true,
      // // will not dispatch a duplicate `touchstart` event when this backend is activated
      // skipDispatchOnTransition: true
    },
  ],
};

export const RarityLevel: {[key: string]: number} = {
  'Common': 0,
  'Normal Parallel Rare': 5,
  'Duel Terminal Normal Parallel Rare': 10,
  'Duel Terminal Normal Rare Parallel Rare': 15,
  'Shatterfoil Rare': 20,
  'Starfoil Rare': 25,
  'Mosaic Rare': 30,
  'Rare': 35,
  'Duel Terminal Rare Parallel Rare': 40,
  'Super Rare': 45,
  'Super Parallel Rare': 50,
  'Duel Terminal Super Parallel Rare': 55,
  'Ultra Rare': 60,
  'Ultra Parallel Rare': 65,
  'Duel Terminal Ultra Parallel Rare': 70,
  'Secret Rare': 75,
  'Prismatic Secret Rare': 80,
  'Ultra Secret Rare': 85,
  'Ultimate Rare': 90,
  'Gold Rare': 95,
  'Gold Secret': 100,
  'Gold Secret Rare': 105,
  'Premium Gold Rare': 110,
  'Ghost/Gold Rare': 115,
  'Platinum Rare': 120,
  'Platinum Secret Rare': 125,
  'Collector\'s Rare': 130,
  'Ultra Rare (Pharaoh\'s Rare)': 135,
  'Ghost Rare': 140,
  'Quarter Century Secret Rare': 145,
  'Starlight Rare': 150,
  '10000 Secret Rare': 155,
  'Extra Secret Rare': 160,
};

export const PackSort: {[key: string]: number} = {
  'All': 0,
  'Sets': 1,
  'Boxes': 2,
  'Core': 10,
  'Side': 15,
  'Tournament': 20,
  'Duelist': 25,
  'LegendaryDuelist': 30,
  'Box': 35,
  'Gold': 40,
  'Mega': 45,
  'Compilation': 50,
  'Reprint': 55,
  'Battle': 60,
  'Star': 65,
  'Movie': 70,
  'Championship': 75,
  'Misc': 80,
  'Bundles': 85,
  'MiscB': 90,
  'CompilationBundles': 95,
  'Booster Pack Collectors Tins 2002': 100,
  'Collectible Tins 2003': 105,
  'Collectible Tins 2004': 110,
  'Collectible Tins 2005': 115,
  'Collectible Tins 2006': 120,
  'Collectible Tins 2007': 125,
  'Collectible Tins 2008': 130,
  'Collectible Tins 2009': 135,
  'Collectible Tins 2010': 140,
  'Collectible Tins 2011': 145,
  'Collectible Tins 2012': 150,
  '2013 Collectible Tins': 155,
  '2014 Mega-Tins': 160,
  '2015 Mega-Tins': 165,
  '2016 Mega-Tins': 170,
  '2017 Mega-Tins': 175,
  '2018 Mega-Tins': 180,
  'Modern Collectible Tins': 185,
  'Decks': 190,
  'Starter Deck': 195,
  'Structure Deck': 200,
};

export const TypeSort: {[key: string]: number} = {
  'Normal': 0,
  'Tuner': 5,
  'Effect': 10,
  'Ritual': 15,
  'Fusion': 20,
  'Synchro': 25,
  'Xyz': 30,
  'Link': 35,
  'Quick-Play': 40,
  'Continuous': 45,
  'Equip': 50,
  'Field': 55,
  'Counter': 60,
};

export const TypeGroup: { [key: string]: number } = {
  '': 0,
  'Normal': 0,
  'Tuner,Normal': 5,
  'Pendulum,Normal': 10,
  'Pendulum,Tuner,Normal': 15,

  'Effect': 20,
  'Flip,Effect': 25,
  'Flip,Tuner,Effect': 30,
  'Tuner,Effect': 35,
  'Gemini,Effect': 40,
  'Spirit,Effect': 45,
  'Toon,Effect': 50,
  'Union,Effect': 55,
  'Union,Tuner,Effect': 60,
  'Pendulum,Effect': 65,
  'Pendulum,Flip,Effect': 70,
  'Pendulum,Spirit,Effect': 75,
  'Pendulum,Tuner,Effect': 80,

  'Fusion': 85,
  'Fusion,Effect': 90,
  'Fusion,Tuner': 95,
  'Fusion,Tuner,Effect': 100,
  'Fusion,Pendulum,Effect': 105,

  'Synchro': 110,
  'Synchro,Effect': 115,
  'Synchro,Tuner,Effect': 120,
  'Synchro,Pendulum,Effect': 125,

  'Xyz': 130,
  'Xyz,Effect': 135,
  'Xyz,Pendulum,Effect': 140,

  'Link': 145,
  'Link,Effect': 150,

  'Ritual': 155,
  'Ritual,Effect': 160,
  'Ritual,Flip,Effect': 165,
  'Ritual,Spirit,Effect': 170,
  'Ritual,Tuner,Effect': 175,
  'Ritual,Pendulum,Effect': 180,
};

export const ExtraDeckSubtypes: string[] = [
  'Fusion',
  'Synchro',
  'Xyz',
  'Link',
];

export const RarityCode: {[key: string]: string} = {
  'Common': 'C',
  'Normal Parallel Rare': 'NPR',
  'Duel Terminal Normal Parallel Rare': 'DNPR',
  'Duel Terminal Normal Rare Parallel Rare': 'DNRPR',
  'Shatterfoil Rare': 'SHR',
  'Starfoil Rare': 'SFR',
  'Mosaic Rare': 'MSR',
  'Rare': 'R',
  'Duel Terminal Rare Parallel Rare': 'DRPR',
  'Super Rare': 'SR',
  'Super Parallel Rare': 'SPR',
  'Duel Terminal Super Parallel Rare': 'DSPR',
  'Ultra Rare': 'UR',
  'Ultra Parallel Rare': 'UPR',
  'Duel Terminal Ultra Parallel Rare': 'DUPR',
  'Secret Rare': 'ScR',
  'Prismatic Secret Rare': 'PScR',
  'Ultra Secret Rare': 'UScR',
  'Ultimate Rare': 'UtR',
  'Gold Rare': 'GUR',
  'Gold Secret': 'GSc',
  'Gold Secret Rare': 'GScR',
  'Premium Gold Rare': 'PG',
  'Ghost/Gold Rare': 'GGR',
  'Platinum Rare': 'PIR',
  'Platinum Secret Rare': 'PS',
  'Collector\'s Rare': 'CR',
  'Ultra Rare (Pharaoh\'s Rare)': 'UR(PR)',
  'Ghost Rare': 'GR',
  'Quarter Century Secret Rare': 'QCSE',
  'Starlight Rare': 'StR',
  '10000 Secret Rare': '10000ScR',
  'Extra Secret Rare': 'ESR',
};

export const filterSearchSortOptions: DropdownOption[] = [
  { value: 'name', text: 'Name' } as DropdownOption,
  { value: 'level', text: 'Level' } as DropdownOption,
  { value: 'atk', text: 'ATK' } as DropdownOption,
  { value: 'def', text: 'DEF' } as DropdownOption,
  { value: 'first_print', text: 'First Print' } as DropdownOption,
];

export const sortOrderOptions: DropdownOption[] = [
  { value: 'asc', text: 'Asc' } as DropdownOption,
  { value: 'desc', text: 'Desc' } as DropdownOption,
];

export const rarityOptions = Object.keys(RarityLevel).map((r: string) => ({ value: r, label: r }));

export const cardCategoryOptions = [
  { value: 'Monster', label: 'Monster' },
  { value: 'Spell', label: 'Spell' },
  { value: 'Trap', label: 'Trap' },
];

export const cardTypeOptions = [
  { value: 'Normal', label: 'Normal' },
  { value: 'Quick-Play', label: 'Quick-Play' },
  { value: 'Continuous', label: 'Continuous' },
  { value: 'Equip', label: 'Equip' },
  { value: 'Field', label: 'Field' },
  { value: 'Counter', label: 'Counter' },
  { value: 'Effect', label: 'Effect' },
  { value: 'Fusion', label: 'Fusion' },
  { value: 'Ritual', label: 'Ritual' },
  { value: 'Synchro', label: 'Synchro' },
  { value: 'Xyz', label: 'Xyz' },
  { value: 'Pendulum', label: 'Pendulum' },
  { value: 'Link', label: 'Link' },
  { value: 'Tuner', label: 'Tuner' },
  { value: 'Gemini', label: 'Gemini' },
  { value: 'Union', label: 'Union' },
  { value: 'Spirit', label: 'Spirit' },
  { value: 'Flip', label: 'Flip' },
  { value: 'Toon', label: 'Toon' },
];

export const subtypeOptions = [
  { value: 'Aqua', label: 'Aqua' },
  { value: 'Beast', label: 'Beast' },
  { value: 'Beast-Warrior', label: 'Beast-Warrior' },
  { value: 'Cyberse', label: 'Cyberse' },
  { value: 'Dinosaur', label: 'Dinosaur' },
  { value: 'Divine-Beast', label: 'Divine-Beast' },
  { value: 'Dragon', label: 'Dragon' },
  { value: 'Fairy', label: 'Fairy' },
  { value: 'Fiend', label: 'Fiend' },
  { value: 'Fish', label: 'Fish' },
  { value: 'Illusion', label: 'Illusion' },
  { value: 'Insect', label: 'Insect' },
  { value: 'Machine', label: 'Machine' },
  { value: 'Plant', label: 'Plant' },
  { value: 'Psychic', label: 'Psychic' },
  { value: 'Pyro', label: 'Pyro' },
  { value: 'Reptile', label: 'Reptile' },
  { value: 'Rock', label: 'Rock' },
  { value: 'Sea Serpent', label: 'Sea Serpent' },
  { value: 'Spellcaster', label: 'Spellcaster' },
  { value: 'Thunder', label: 'Thunder' },
  { value: 'Warrior', label: 'Warrior' },
  { value: 'Winged Beast', label: 'Winged Beast' },
  { value: 'Wyrm', label: 'Wyrm' },
  { value: 'Zombie', label: 'Zombie' },
];

export const attributeOptions = [
  { value: 'DARK', label: 'DARK' },
  { value: 'DIVINE', label: 'DIVINE' },
  { value: 'EARTH', label: 'EARTH' },
  { value: 'FIRE', label: 'FIRE' },
  { value: 'LIGHT', label: 'LIGHT' },
  { value: 'WATER', label: 'WATER' },
  { value: 'WIND', label: 'WIND' },
];

export const levelOptions = [
  { value: 0, label: '0' },
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4' },
  { value: 5, label: '5' },
  { value: 6, label: '6' },
  { value: 7, label: '7' },
  { value: 8, label: '8' },
  { value: 9, label: '9' },
  { value: 10, label: '10' },
  { value: 11, label: '11' },
  { value: 12, label: '12' },
  { value: 13, label: '13' },
];

export const linkMarkerOptions = [
  { value: 'Top', label: 'Top' },
  { value: 'Bottom', label: 'Bottom' },
  { value: 'Left', label: 'Left' },
  { value: 'Right', label: 'Right' },
  { value: 'Top-Left', label: 'Top-Left' },
  { value: 'Top-Right', label: 'Top-Right' },
  { value: 'Bottom-Left', label: 'Bottom-Left' },
  { value: 'Bottom-Right', label: 'Bottom-Right' },
];

export const contactString = 'Send us an email at <a class="in-text-link" href="mailto: admin@ygoprog.com">admin@ygoprog.com</a>' +
  ', join our <a class="in-text-link" href="https://discord.com/invite/46tQbC69mN" target="_blank" rel="noreferrer">Discord</a>' +
  ',<br />or DM/Tweet <a class="in-text-link" href="https://twitter.com/ygoprog" target="_blank" rel="noreferrer">@YGOProg</a> and we&apos;ll look into it.';
export const lowercaseContactString = 'send us an email at <a class="in-text-link" href="mailto: admin@ygoprog.com">admin@ygoprog.com</a>' +
  ', join our <a class="in-text-link" href="https://discord.com/invite/46tQbC69mN" target="_blank" rel="noreferrer">Discord</a>' +
  ',<br />or DM/Tweet <a class="in-text-link" href="https://twitter.com/ygoprog" target="_blank" rel="noreferrer">@YGOProg</a> and we&apos;ll look into it.';

export const multiSelectStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    minHeight: '30px',
    margin: '0px',
    verticalAlign: 'middle',
    border: '.5px solid #CACACA',
    boxShadow: state.isFocused ? '0 0 0 1px #1E1E1E' : 'initial',
    '&:hover': {
      borderColor: state.isFocused ? '#1E1E1E' : 'initial',
    },
  }),
  placeholder: (provided: any) => ({
    ...provided,
    fontFamily: 'Inter, Helvetica, Arial, sans-serif',
    fontSize: '14px',
    letterSpacing: '-0.05em',
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    padding: '2px 5px 2px 5px',
    textAlign: 'left',
    verticalAlign: 'middle',
    gap: '2px',
    maxHeight: '4vh',
    overflow: 'auto',
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    padding: '5px',
  }),
  clearIndicator: (provided: any) => ({
    ...provided,
    padding: '5px',
  }),
  input: (provided: any) => ({
    ...provided,
    margin: '0px',
    padding: '0px',
    height: '100%',
    fontFamily: 'Inter, Helvetica, Arial, sans-serif',
    fontSize: '14px',
    letterSpacing: '-0.05em',
    cursor: 'text',
  }),
  container: (provided: any) => ({
    ...provided,
    borderRadius: '4px',
    marginBottom: '5px',
    width: '100%',
  }),
  multiValue: (provided: any) => ({
    ...provided,
    margin: '0px 0px 0px 0px',
    fontFamily: 'Inter, Helvetica, Arial, sans-serif',
    letterSpacing: '-0.05em',
    borderRadius: '4px',
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    fontFamily: 'Inter, Helvetica, Arial, sans-serif',
    letterSpacing: '-0.05em',
    fontSize: '14px',
    whiteSpace: 'nowrap',
    textAlign: 'left',
    backgroundColor: state.isSelected ? '#F4767B' : 'initial',
    '&:hover': {
      backgroundColor: '#FBD1D3',
    },
    '&:active': {
      backgroundColor: '#F4767B',
    },
  }),
  menu: (provided: any) => ({
    ...provided,
    marginTop: '2px',
    overflow: 'auto',
  }),
  noOptionsMessage: (provided: any) => ({
    ...provided,
    fontFamily: 'Inter, Helvetica, Arial, sans-serif',
    letterSpacing: '-0.05em',
    fontSize: '14px',
  }),
};

export const MAIN_DECK_MAX = 100;
export const EXTRA_DECK_MAX = 30;
export const SIDE_DECK_MAX = 30;
export const DATE_MIN = new Date(0);
export const DATE_MAX = new Date(8640000000000000);

export function cardLink(id: number) {
  return 'https://yugipedia.com/wiki/' + id.toString().padStart(8, '0'); // Ambiguous cards get the necessary bit added to the link
}

export function productLink(name: string) {
  return Object.prototype.hasOwnProperty.call(bundleLinks, name) ? bundleLinks[name] : 'https://yugipedia.com/wiki/' + encodeURI(name);
}

export const mouseProps = {
  hoveredElement: null as any,
  mouseX: 0,
  mouseY: 0,
};

export function isTouchDevice() {
  return (('ontouchstart' in window) ||
     (navigator.maxTouchPoints > 0));
}

export const sessionMemory = {
  lastBinderSelected: '',
};

export const loadDeck = { mainDeck: [] as DropCard[], extraDeck: [] as DropCard[], sideDeck: [] as DropCard[] };

export function exportYdk(filename: string, mainDeck: BaseCard[] | null, extraDeck: BaseCard[] | null, sideDeck: BaseCard[] | null) {
  return new Promise((resolve) => {
    let ydkString = '';

    ydkString += '#main\n';

    mainDeck?.forEach((card: BaseCard) => {
      ydkString += card.id + '\n';
    });
    ydkString += '\n';

    ydkString += '#extra\n';

    extraDeck?.forEach((card: BaseCard) => {
      ydkString += card.id + '\n';
    });
    ydkString += '\n';

    ydkString += '!side\n';

    sideDeck?.forEach((card: BaseCard) => {
      ydkString += card.id + '\n';
    });
    ydkString += '\n';

    const element = document.createElement('a');
    const file = new Blob([ydkString], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.target = '_blank';
    element.download = filename + '.ydk';
    document.body.appendChild(element);
    element.click();
    resolve(true);
  });
}

export function addCardsToBinder(token: string, refresh: string, userId: string, binderID: string,
  cardList: BinderCardInfo[], callback: any = null, preReadCallback: any = null) {
  const cardsData: any[] = cardList.map((card: any) => {
    return {
      cardId: card.id,
      name: card.name,
      set: card.set,
      code: card.code,
      rarity: card.rarity,
      count: card.count,
    };
  });
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
    body: JSON.stringify({ cards: cardsData }),
  };
  makeApiCall(refresh, userId, apiPath + 'binder/' + binderID + '/cards', requestOptions)
    .then(async (response) => {
      if (preReadCallback != null) preReadCallback();

      if (!response.ok) {
        const error = response.status;
        openErrorModal('Issue adding cards to binder.<br />' +
          (error == 401 ? '<br />Try logging out and logging back in.<br />If this persists, ' + lowercaseContactString : '') +
          (error == 400 ? '<br />You have been inactive for a long time. Please log out and back in.<br /><br />If this persists, ' + lowercaseContactString : '') +
          (error != 401 && error != 400 ? '<br />' + contactString : ''));
        return Promise.reject(error);
      }

      // const data = await response.json();

      if (callback != null) callback();
    });
}

export function shuffle(array: any[]) { // Fisher-Yates implementation courtesy of https://bost.ocks.org/mike/shuffle/
  let m = array.length;
  let t: any;
  let i: number;

  while (m) { // While there's more to shuffle
    i = Math.floor(Math.random() * m--); // Pick an element and swap it

    t = array[m];
    array[m] = array[i];
    array[i] = t;
  }

  return array;
}

export function getDateString(date: Date) {
  return date.toISOString().split('T')[0].replace(/\+/, '');
}

export function zoneSortFunc(a: DropCard, b: DropCard) {
  if (a.type < b.type) return -1;
  else if (a.type > b.type) return 1;

  let aSubtype: string;
  let bSubtype: string;
  if (a.subtype.length > 1 && (a.subtype[1] == 'Ritual' || ExtraDeckSubtypes.includes(a.subtype[1]))) aSubtype = a.subtype[1];
  else aSubtype = a.subtype[a.subtype.length-1];
  if (b.subtype.length > 1 && (b.subtype[1] == 'Ritual' || ExtraDeckSubtypes.includes(b.subtype[1]))) bSubtype = b.subtype[1];
  else bSubtype = b.subtype[b.subtype.length-1];

  if (TypeSort[aSubtype] < TypeSort[bSubtype]) return -1;
  else if (TypeSort[aSubtype] > TypeSort[bSubtype]) return 1;

  if (a.level > b.level) return -1;
  else if (a.level < b.level) return 1;

  if (a.atk > b.atk) return -1;
  else if (a.atk < b.atk) return 1;

  if (a.name < b.name) return -1;
  else return 1;
}

export function builderSearchSort(results: BinderCard[] | Card[], sortField: keyof (BinderCard | Card), sortAsc: boolean) {
  return results
    .sort((a: BinderCard | Card, b: BinderCard | Card) => {
      if (a.type > b.type) return 1;
      else if (a.type < b.type) return -1;

      if (sortField != 'name') {
        if (a[sortField] != null && b[sortField] != null) {
          if (a[sortField]! > b[sortField]!) return sortAsc ? 1 : -1;
          else if (a[sortField]! < b[sortField]!) return sortAsc ? -1 : 1;
        }
        else if (a[sortField] == null && b[sortField] != null) return sortAsc ? -1 : 1;
        else if (a[sortField] != null && b[sortField] == null) return sortAsc ? 1 : -1;
      }

      if (a.type == 'Monster') { // We know both are the same type at this point
        if (a.subtype.length > 1 && b.subtype.length > 1) {
          if (TypeGroup[a.subtype.slice(1).join()] > TypeGroup[b.subtype.slice(1).join()]) return 1;
          else if (TypeGroup[a.subtype.slice(1).join()] < TypeGroup[b.subtype.slice(1).join()]) return -1;
        }
        else if (a.subtype.length < 2 && b.subtype.length > 1) return -1;
        else if (a.subtype.length > 1 && b.subtype.length < 1) return 1;
      }
      else {
        if (a.subtype[0] > b.subtype[0]) return 1;
        else if (a.subtype[0] < b.subtype[0]) return -1;
      }

      if (a.name > b.name) return sortField == 'name' && !sortAsc ? -1 : 1; // If sorting name and descending then flip these
      else if (a.name < b.name) return sortField == 'name' && !sortAsc ? 1 : -1;

      return 0;
    });
}

export const shouldPagesRefresh = { val: true, resetPackOpener: false };

export const onCookieChangeListeners: any[] = [];

export const cookies = new Cookies();

export function setCookie(name: string, value: any, bypassListeners?: boolean) { // Account cookies should be set to 90 days refreshing when new token is received
  cookies.set(name, value, { path: '/', maxAge: 34560000 });
  if (!bypassListeners) onCookieChangeListeners.forEach((listener: any) => listener());
}

export function removeCookie(name: string) {
  cookies.remove(name, { path: '/' });
  onCookieChangeListeners.forEach((listener: any) => listener());
}

export class ClassWatcher {

  targetNode: any;
  classToWatch: any;
  classAddedCallback: any;
  classRemovedCallback: any;
  observer: any;
  lastClassState: any;

  constructor(targetNode: any, classToWatch: any, classAddedCallback: any, classRemovedCallback: any) {
    this.targetNode = targetNode;
    this.classToWatch = classToWatch;
    this.classAddedCallback = classAddedCallback;
    this.classRemovedCallback = classRemovedCallback;
    this.observer = null;
    this.lastClassState = targetNode.classList.contains(this.classToWatch);

    this.init();
  }

  init() {
    this.observer = new MutationObserver(this.mutationCallback);
    this.observe();
  }

  observe() {
    this.observer.observe(this.targetNode, { attributes: true });
  }

  disconnect() {
    this.observer.disconnect();
  }

  mutationCallback = (mutationsList: any) => {
    for (const mutation of mutationsList) {
      if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
        const currentClassState = mutation.target.classList.contains(this.classToWatch);
        if (this.lastClassState !== currentClassState) {
          this.lastClassState = currentClassState;
          if (currentClassState) {
            this.classAddedCallback();
          }
          else {
            this.classRemovedCallback();
          }
          this.lastClassState = currentClassState;
        }
      }
    }
  };

}
